const localHost = "localhost";
export const API_BASE ='https://apiworkplace.culture-catalyst.in'
// export const API_BASE = 'http://localhost:4000'
const localHostBaseEndpoint = `${API_BASE}/api/v1`;
export const assetURL = `${API_BASE}`;
// export const FRONTEND_BASE = process.env.FRONTEND_LINK ? process.env.FRONTEND_LINK : 'http://localhost:3000/mentor_signin'
export const FRONTEND_BASE =  "https://workplace.culture-catalyst.in/mentor_signin/"
export const secretKey = (process.env.REACT_APP_SECRETKEY) ? process.env.REACT_APP_SECRETKEY : "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7";

export const getBaseEndpointUrl = () => {
  return localHostBaseEndpoint;
};

function getHostURL(hostName) {
  if (hostName.includes(localHost)) {
    return localHostBaseEndpoint;
  }
}

export const Logout = () => {
  localStorage.clear()
  return true
}

export const gradeCalc = points => {
  let grade;
  if (points >= 75 && points <= 100) {
    grade = "A";
  } else if (points >= 50 && points < 75) {
    grade = "B";
  } else if (points >= 35 && points < 50) {
    grade = "C";
  } else if (points < 35 && points > 0) {
    grade = "D";
  } else {
    grade = "No"
  }
  return grade;
}
