import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  MentorDataList,
  MentorSelector,
  MentorDataAdd,
  clearData,
} from "../../store/reducer/MentorReducer";
import {
  departmentLists,
  departmentUpdates,
  placementSelector,
  testLists,
} from "../../store/reducer/PlacementReducer";
import { localFrontend, FRONTEND_BASE } from "../../services/config";
import { LoadingButton } from "@mui/lab";
const MentorAdd = () => {
  const title = "Mentor Add";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [departmentID, setDepartmentID] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [errorMsg, seterrorMsg] = useState({
    doj: false,
  });

  useEffect(() => {
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    dispatch(departmentLists(departmentQuery));
  }, []);

  const { departmentData } = useSelector(placementSelector);

  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/[@$!%*?&]/, getCharacterValidationError("special")),
    avaiablity: Yup.number().required("Availability Hours is required"),
  });
  const initialValues = { name: "", password: "", email: "", avaiablity: "" };
  const onSubmit = (formData) => {
    if (!departmentID) {
      setValidated(false);
    } else {
      formData.domain = departmentID;
      formData.resetLink = FRONTEND_BASE;
      dispatch(MentorDataAdd({ formData }));
    }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const { mentorListDatas, mentorLoading, mentorAddLoading, errorMessage } =
    useSelector(MentorSelector);

  useEffect(() => {
    console.log(mentorListDatas, "mentorListDatas");
  }, [mentorListDatas]);

  useEffect(() => {
    if (mentorAddLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Mentor Added Successfully!",
        icon: "success",
      });
      dispatch(clearData());
      navigate("/mentor");
    }
    if (mentorAddLoading === API_STATUS.REJECTED && errorMessage) {
      if (errorMessage.message)
        Swal.fire({
          title: errorMessage.message,
          icon: "error",
        });
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        Swal.fire({
          title: data,
          icon: "error",
        });
      }
      dispatch(clearData());
    }
  }, [mentorAddLoading, errorMessage]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Link to="/mentor">
              <div className="text-end mb-2">
                <Button variant="success" type="submit">
                  Back
                </Button>
              </div>
            </Link>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <form
                id="mentorAddForm"
                className={
                  validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                }
                onSubmit={(e) => {
                  e.preventDefault();
                  setValidated(true);
                  handleSubmit();
                }}
              >
                <div className="mb-3 filled form-group tooltip-end-top">
                  {Loginerror && Loginerror.error && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {Loginerror.error}
                    </Form.Control.Feedback>
                  )}
                </div>
                <Row className="mb-3 g-3">
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="user" />
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Mentor Name"
                        value={values.name}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.name && touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file-text" />
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setDepartmentID(e.target.value);
                        }}
                        value={departmentID}
                        className="is-invalid"
                        placeholder="Domain"
                      >
                        {!isClicked ? <option>Select Domain</option> : ""}
                        {departmentData &&
                          departmentData?.rows?.map((fields) => {
                            return (
                              <option value={fields.id}>{fields.name}</option>
                            );
                          })}
                      </Form.Control>
                      {errorMsg?.doj && (
                        <p className="text-danger">Domain is required</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="email" />
                      <Form.Control
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        className="is-invalid"
                        placeholder="Email"
                      />
                      {errors.email && touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="lock-off" />
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        className="is-invalid"
                        placeholder="Create your own Password"
                      />
                      {errors.password && touched.password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                      <p className="py-1">
                        Use Upper case, Lower case, Numbers & Symbols.Password
                        length should be minimum 8 characters.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="clock" />
                      <Form.Control
                        type="text"
                        name="avaiablity"
                        placeholder="Availability Hours"
                        value={values.avaiablity}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.avaiablity && touched.avaiablity && (
                        <Form.Control.Feedback type="invalid">
                          {errors.avaiablity}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="text-end">
                  <LoadingButton
                    variant="contained"
                    loading={
                      ![API_STATUS.FULFILLED, "initial"].includes(
                        mentorAddLoading
                      )
                    }
                    type="submit"
                    onClick={() => {
                      if (!departmentID) {
                        seterrorMsg({
                          doj: !departmentID,
                        });
                        setValidated(false);
                      }
                    }}
                  >
                    Create
                  </LoadingButton>
                </div>
              </form>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default MentorAdd;
