import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ProfileStudent,
  RegisterSession,
  RegisteredSessionList,
  StudentSelector,
  clearData,
} from "../../store/reducer/StudentReducer";
import {
  DashboardSelector,
  SessionList,
} from "../../store/reducer/DashboardReducer";
import { Settings, settingSelector } from "../../store/reducer/settingsSlice";
import dayjs from "dayjs";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { API_STATUS } from "../../utils/Constants";

const Sessions = () => {
  const dispatch = useDispatch();
  const { studentProfileDatas, RegisterSessionLoad, registeredSessionData } =
    useSelector(StudentSelector);
  const { sessionDetails } = useSelector(DashboardSelector);
  const { settingData } = useSelector(settingSelector);
  const [sessions, setSessions] = useState([]);
  const [show, setShow] = useState(false);
  const [Id, setId] = useState();
  const registerData = sessions?.find((ele) => {
    return ele?.id == Id;
  });
  console.log(studentProfileDatas, "registeredSessionData1");

  useEffect(() => {
    let formData = {};
    dispatch(ProfileStudent(formData));
    dispatch(Settings({}));
  }, []);

  useEffect(() => {
    console.log(
      studentProfileDatas?.studentData?.department,
      "studentProfileDatas12"
    );

    dispatch(
      SessionList({
        page: "",
        limit: "",
        query: "",
        sortby: "",
        order: "",
        domain: studentProfileDatas?.studentData?.department,
        guest_speaker_id: "",
      })
    );
    dispatch(
      RegisteredSessionList({
        student_id: studentProfileDatas?.studentData?.id,
      })
    );
  }, [studentProfileDatas]);

  function isUpcoming(event) {
    const eventDateTime = dayjs(event.date_time);
    const limit = parseInt(settingData?.reg_session_bfr);
    const currentDateTime = dayjs().add(limit, "minutes");
    const today = currentDateTime.startOf("day");
    if (eventDateTime.isSame(today, "day")) {
      return eventDateTime.isAfter(currentDateTime);
    } else {
      return eventDateTime.isAfter(today);
    }
  }

  useEffect(() => {
    const upcomingEvents = sessionDetails.filter(isUpcoming);
    setSessions(upcomingEvents);
  }, [sessionDetails, settingData]);

  const handleRegister = (id) => {
    setShow(true);
    setId(id);
  };

  const handleClose = () => {
    setShow(false);
  };

  const ConfirmRegister = () => {
    const formData = {
      session_id: Id,
      student_id: studentProfileDatas?.studentData?.id,
    };
    console.log(formData, "FORMDATDA");
    dispatch(RegisterSession({ formData }));
  };

  useEffect(() => {
    if (RegisterSessionLoad === API_STATUS.FULFILLED) {
      handleClose();
      dispatch(
        RegisteredSessionList({
          student_id: studentProfileDatas?.studentData?.id,
        })
      );
      dispatch(clearData());
    }
  }, [RegisterSessionLoad, studentProfileDatas]);

  return (
    <div>
      <h1>
        Sessions : <b> {studentProfileDatas?.studentData?.departments?.name}</b>
      </h1>
      <Row>
        <Col md={8}>
          <Card className="p-3">
            <Row>
              <h2>
                <b>Upcoming Sessions:</b>
              </h2>
            </Row>
            {sessions?.map((data) => {
              console.log(data, "NEWDATA");
              return (
                <>
                  <hr />
                  <Card className="p-3">
                    <div className="d-flex w-100 justify-content-between mx-2">
                      <h3>
                        <b>{data?.topic}</b>
                      </h3>
                      {registeredSessionData?.some((ele) => {
                        return ele?.session_id == data?.id;
                      }) ? (
                        <Button variant="primary">Registered</Button>
                      ) : (
                        <Button
                          variant="success"
                          onClick={() => handleRegister(data?.id)}
                        >
                          Register
                        </Button>
                      )}
                    </div>
                  </Card>
                </>
              );
            })}
          </Card>
        </Col>
        <Col>
          <Card className="p-3">
            <Row>
              <h2>
                <b>Attended Sessions:</b>
              </h2>
            </Row>
            {registeredSessionData?.map((data) => {
              console.log(data, "DATASDF");
              if (data?.status == 2) {
                return (
                  <>
                    <hr />
                    <Card className="p-3">
                      <div className="d-flex w-100 justify-content-between mx-2">
                        <h3>
                          <b>{data?.guest_speaker?.topic}</b>
                        </h3>
                        <Button
                          variant="success"
                          // onClick={() => handleRegister(data?.id)}
                        >
                          Feedback
                        </Button>
                      </div>
                    </Card>
                  </>
                );
              }
            })}
          </Card>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <h2>Are You Sure ?</h2>
        </Modal.Header>
        <Modal.Body>
          <h4>
            <b className="me-1">On Registering :</b> {registerData?.topic}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between mx-2 w-100">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="success" onClick={ConfirmRegister}>
              Sure
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sessions;
