import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  EmptyCourseData,
  courseDetails,
  courseSelector,
  planDetail,
  postPlanDetails,
} from "../../store/reducer/CourseReducer";
import {
  ProfileStudent,
  StudentSelector,
} from "../../store/reducer/StudentReducer";
import { useFormik } from "formik";
import { planValidator } from "../../validators/Validators";
import CsLineIcons from "../cs-line-icons/CsLineIcons";

const Sprint1 = () => {
  //id, query, order, limit, sort, page
  const name = localStorage.getItem("username");
  const [show, setShow] = useState(false);
  const [studentId, setstudentId] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, qweryId } = useParams();
  const [order, setOrder] = useState("");
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [query, setQuery] = useState("");
  const { courseData, planDetails } = useSelector(courseSelector);
  const { studentProfileDatas } = useSelector(StudentSelector);
  const [validated, setValidated] = useState(false);
  console.log(studentProfileDatas, "studentProfileDatas");
  console.log(planDetails, "planDetails");
  const [initialValues, setInitialValues] = useState({
    plan: "",
    plan_duration: "",
  });

  useEffect(() => {
    if (qweryId) {
      dispatch(EmptyCourseData());
      dispatch(
        courseDetails({
          department_id: id,
          order,
          limit,
          sort,
          page,
          id: qweryId,
          query,
        })
      );
      dispatch(
        planDetail({
          page,
          limit,
          query,
          sortby: sort,
          order,
          status: 0,
          student_id: studentId,
          sprint_id: qweryId,
          activity_id: 1,
        })
      );
      dispatch(ProfileStudent({}));
    }
  }, [qweryId, studentId]);
  const onSubmit = (formData) => {
    console.log(formData, "formdata plan");
    formData.sprint_id = qweryId;
    formData.activity_id = 1;
    dispatch(postPlanDetails({ formData }));
  };

  useEffect(() => {
    setstudentId(studentProfileDatas?.studentData?.id);
  }, [studentProfileDatas]);

  const handlePlan = () => {
    dispatch(
      planDetail({
        page,
        limit,
        query,
        sortby: sort,
        order,
        status: 0,
        student_id: studentId,
        sprint_id: qweryId,
        activity_id: 1,
      })
    );
    setShow(!show);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: planValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  useEffect(() => {
    console.log(courseData, "courseData");
  }, [courseData]);
  return (
    <div className="container-fluid mt-4">
      <h1>Hi {name}, </h1>
      {studentProfileDatas?.studentData?.current_sprint}
      <Row>
        <Col md={9}>
          <Card className="mb-3 p-4">
            {courseData?.data?.map((sprints) => {
              return (
                <>
                  <div className="d-flex justify-content-between w-100  p-3">
                    <h3>{sprints?.name}</h3>
                    <p className="me-5">
                      Duration: <span>{sprints?.duration}</span>
                    </p>
                  </div>
                  <div className="px-3">{sprints?.description}</div>
                </>
              );
            })}
          </Card>
          <Card className="p-4">
            <Row>
              <h1 className="mt-3">Module 1</h1>
              <div className="px-3">
                What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a
                type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining essentially
                unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum. Why do we use it? It is a
                long established fact that a reader will be distracted by the
                readable content of a page when looking at its layout. The point
                of using Lorem Ipsum is that it has a more-or-less normal
                distribution of letters, as opposed to using 'Content here,
                content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
                Where does it come from? Contrary to popular belief, Lorem Ipsum
                is not simply random text. It has roots in a piece of classical
                Latin literature from 45 BC, making it over 2000 years old.
                Richard McClintock, a Latin professor at Hampden-Sydney College
                in Virginia, looked up one of the more obscure Latin words,
                consectetur, from a Lorem Ipsum passage, and going through the
                cites of the word in classical literature, discovered the
                undoubtable source. Lorem Ipsum comes from sections 1.10.32 and
                1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good
                and Evil) by Cicero, written in 45 BC. This book is a treatise
                on the theory of ethics, very popular during the Renaissance.
                The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..",
                comes from a line in section 1.10.32. The standard chunk of
                Lorem Ipsum used since the 1500s is reproduced below for those
                interested. Sections 1.10.32 and 1.10.33 from "de Finibus
                Bonorum et Malorum" by Cicero are also reproduced in their exact
                original form, accompanied by English versions from the 1914
                translation by H. Rackham.
              </div>
            </Row>
            <Accordion defaultActiveKey="0" className="mt-3">
              <Accordion.Item eventKey={0}>
                <Accordion.Header className="w=100">
                  <div className="d-flex justify-content-between w-100">
                    <h6 className="">Referal Links/Attachments</h6>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={1}>
                      <Card className="sh-10 hover-border-secondary  border-secondary mt-3 mb-3 ">
                        <Card.Body className="p-3 text-center align-items-center  d-flex flex-column justify-content-between">
                          <div className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl">
                            <CsLineIcons icon="link" className="text-white" />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={1}>
                      <Card className="sh-10 hover-border-primary border-primary mt-3 mb-3">
                        <Card.Body className="p-3 text-center align-items-center d-flex flex-column justify-content-between">
                          <div className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl">
                            <CsLineIcons
                              icon="file-text"
                              className="text-white"
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={1}>
                      <Card className="sh-10 hover-border-primary border-primary mt-3 mb-3">
                        <Card.Body className="p-3 text-center align-items-center d-flex flex-column justify-content-between">
                          <div className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl">
                            <CsLineIcons
                              icon="file-video"
                              className="text-white"
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Col md={12}>
                    <iframe
                      src="https://www.youtube.com/embed/E7wJTI-1dvQ"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      title="video"
                      width="100%"
                      height="500"
                    />
                    <iframe
                      src="https://www.dhiwise.com/post/react-iframe-component-embed-external-content-in-react-apps"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      title="video"
                      width="100%"
                      height="500"
                    />
                  </Col>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={1}>
                <Accordion.Header className="w=100">
                  <div className="d-flex justify-content-between w-100">
                    <h6 className="">Plan for the day</h6>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {planDetails &&
                    planDetails?.rows?.map((plans) => {
                      return (
                        <Row className="g-0 mb-2">
                          <Col xs="auto">
                            <div className="sw-3 d-inline-block d-flex justify-content-start align-items-center h-100">
                              <div className="sh-3">
                                <CsLineIcons
                                  icon="chevron-right"
                                  className="text-secondary align-top"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="d-flex flex-column pt-0 pb-0 ps-3 pe-4 h-100 justify-content-center">
                              <div className="d-flex flex-column">
                                <div className="text-alternate mt-n1 lh-1-25">
                                  {plans?.plan}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xs="auto">
                            <div className="d-inline-block d-flex justify-content-end align-items-center h-100">
                              <div className="text-muted ms-2 mt-n1 lh-1-25">
                                {plans?.plan_duration} hrs
                              </div>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={2}>
                <Accordion.Header className="w=100">
                  <div className="d-flex justify-content-between w-100">
                    <h6 className="">EOD Status</h6>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={1}>
                      <Card className="sh-10 hover-border-secondary  border-secondary mt-3 mb-3 ">
                        <Card.Body className="p-3 text-center align-items-center  d-flex flex-column justify-content-between">
                          <div className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl">
                            <CsLineIcons icon="link" className="text-white" />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={1}>
                      <Card className="sh-10 hover-border-primary border-primary mt-3 mb-3">
                        <Card.Body className="p-3 text-center align-items-center d-flex flex-column justify-content-between">
                          <div className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl">
                            <CsLineIcons
                              icon="file-text"
                              className="text-white"
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={1}>
                      <Card className="sh-10 hover-border-primary border-primary mt-3 mb-3">
                        <Card.Body className="p-3 text-center align-items-center d-flex flex-column justify-content-between">
                          <div className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl">
                            <CsLineIcons
                              icon="file-video"
                              className="text-white"
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card>
        </Col>
        <Col>
          <Button className="w-100 mb-3" variant="success" onClick={handlePlan}>
            Plan for the Day
          </Button>
          <Button
            className="w-100 mb-3"
            disabled={true}
            variant="danger"
            onClick={() => setShow(!show)}
          >
            EOD Status
          </Button>
          <Card className="p-4">
            <h1>Sprint Name</h1>
            <ListGroup>
              {courseData?.data &&
                courseData?.data[0]?.sprintactivity?.map((activities, i) => {
                  console.log(activities, "hasdusygd");
                  return (
                    <>
                      <ListGroup.Item key={i} className="p-2">
                        <div className="d-flex justify-content-between w-100">
                          <p>{activities?.name}</p>
                          <p>{activities?.duration}</p>
                        </div>
                      </ListGroup.Item>
                    </>
                  );
                })}
            </ListGroup>
          </Card>
        </Col>

        <Modal
          show={show}
          id="settings"
          onHide={() => setShow(false)}
          className="modal-right scroll-out-negative"
          dialogClassName="full"
          aria-labelledby="settings"
          tabIndex="-1"
          scrollable
        >
          <div className="p-3 mt-2">
            {planDetails?.count === 0 ? (
              <>
                <h1>Plan for the day</h1>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="mb-3 filled form-group tooltip-end-top">
                    {/* <CsLineIcons icon="user" /> */}
                    <Form.Control
                      as={"textarea"}
                      rows={6}
                      name="plan"
                      placeholder="Plan"
                      value={values.plan}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.plan && touched.plan && (
                      <Form.Control.Feedback type="invalid">
                        {errors.plan}
                      </Form.Control.Feedback>
                    )}
                  </div>
                  <div className="mb-3 filled form-group tooltip-end-top">
                    {/* <CsLineIcons icon="user" /> */}
                    <Form.Control
                      type="text"
                      rows={6}
                      name="plan_duration"
                      placeholder="Plan Duration in Hrs."
                      value={values.plan_duration}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.plan_duration && touched.plan_duration && (
                      <Form.Control.Feedback type="invalid">
                        {errors.plan_duration}
                      </Form.Control.Feedback>
                    )}
                  </div>
                  <Button variant="success" className="w-100" type="submit">
                    Confirm
                  </Button>
                </Form>
              </>
            ) : (
              <></>
            )}
          </div>
        </Modal>
      </Row>
    </div>
  );
};

export default Sprint1;
