import React, { useEffect, useState } from "react";
import { Card, Col, Row, Modal, ToggleButton } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsPencil } from "react-icons/bs";
import { MdClose, MdCheck, MdPersonPin } from "react-icons/md";
import _, { unset } from "lodash";
import { TablePagination } from "@mui/material";
import {
  ProjectListData,
  StudentListByMentor,
  AssignedStudentsByProject,
  AssignStudentsadd,
  clearHiringMentorData,
  hiringmentorSelector,
} from "../../store/reducer/HiringMentorReducer";

const FitmentProject = () => {
  const title = "Projects Fitment";
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [assignstudents, setAssignstudents] = useState([]);
  const [removestudents, setRemovestudents] = useState([]);
  const [show, setShow] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  useEffect(() => {
    dispatch(ProjectListData({ page, limit, query }));
    let studentpage = 0;
    let studentlimit = 0;
    let studentquery = "";
    dispatch(
      StudentListByMentor({
        page: studentpage,
        limit: studentlimit,
        query: studentquery,
      })
    );
  }, []);

  const {
    projectListDetails,
    studentsList,
    studentsListProject,
    UpdateJobScehdulerLoading,
    CreateJobSchedulerLoading,
    ViewJobSchedulerLoading,
    assignstudentLoading,
  } = useSelector(hiringmentorSelector);

  const studentsAssign = (row) => {
    console.log(row);
    let id = row.id;
    setProjectName(row?.title);
    setProjectId(row?.id);
    setShow(true);
    dispatch(AssignedStudentsByProject({ id }));
  };
  const assignUpdate = (row) => {
    let studentAssign = assignstudents.filter((keys) => {
      return !keys.id;
    });
    let formData = {
      assignstudents: studentAssign,
      removestudents: removestudents,
    };
    dispatch(AssignStudentsadd({ formData }));
  };

  const SelectStudent = (student_id) => {
    let index = _.findIndex(assignstudents, { student_id: student_id });
    if (index < 0) {
      return false;
    } else {
      return true;
    }
  };

  const checkStudent = (student_id) => {
    let index = _.findIndex(assignstudents, { student_id: student_id });
    console.log(index);
    if (index < 0) {
      assignstudents.push({ student_id: student_id, project_id: projectId });
      setAssignstudents([...assignstudents]);
    } else {
      if (assignstudents[index]?.id) {
        removestudents.push(assignstudents[index]?.id);
        setRemovestudents([...removestudents]);
      }
      let removed = _.reject(assignstudents, { student_id: student_id });
      setAssignstudents([...removed]);
    }
  };
  useEffect(() => {
    // console.log(show, "departments");
    if (studentsListProject && studentsListProject?.length > 0) {
      let assigned = studentsListProject.map((data) => {
        return {
          student_id: data.student_id,
          id: data.id,
          project_id: projectId,
        };
      });
      setAssignstudents([...assigned]);
    }
  }, [studentsListProject]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = projectListDetails?.count % 10;
    let remainder = projectListDetails?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [projectListDetails]);

  useEffect(() => {
    dispatch(ProjectListData({ page, limit, query }));
  }, [page, limit, query]);

  useEffect(() => {
    if (assignstudentLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Project Fitment Assigned Successfully!",
        icon: "success",
      });
      setShow(false);
      setAssignstudents([]);
      setRemovestudents([]);
      dispatch(clearHiringMentorData());
      dispatch(ProjectListData({ page, limit, query }));
      let studentpage = 0;
      let studentlimit = 0;
      let studentquery = "";
      dispatch(
        StudentListByMentor({
          page: studentpage,
          limit: studentlimit,
          query: studentquery,
        })
      );
    }
  }, [assignstudentLoading]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Link to="/mentor/createproject">
              <div className="text-end">
                <Button
                  variant="foreground"
                  className="btn-icon btn-icon-start hover-outline mt-1 mb-1"
                >
                  <CsLineIcons icon="plus" /> <span>Add New Project</span>
                </Button>
              </div>
            </Link>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Project</th>
                    <th>Description</th>
                    <th>Total Activities</th>
                    <th>Total Students Assigned</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {projectListDetails &&
                    projectListDetails?.rows?.map((jobValues, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{jobValues?.title}</td>
                            <td>{jobValues?.description}</td>
                            <td>{jobValues?.project_activity.length}</td>
                            <td>{jobValues?.project_student?.length}</td>
                            <td>
                              <Button
                                title="Assign Students"
                                className="btn btn-primary mx-1 "
                                onClick={(e) => studentsAssign(jobValues)}
                              >
                                <MdPersonPin />
                              </Button>
                              <Link
                                to={`/mentor/editproject/${jobValues.id}`}
                                title="Edit"
                                className="btn btn-primary mx-1 "
                              >
                                <BsPencil />
                              </Link>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between px-2">
                <strong>Total count : {projectListDetails?.count}</strong>
                <TablePagination
                  component="div"
                  count={projectListDetails?.count}
                  page={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
      <Modal
        show={show}
        id="settings"
        dialogClassName="modal-100w"
        aria-labelledby="settings"
        tabIndex="-1"
        scrollable
        size="xl"
      >
        <div className="p-3 mt-2" style={{ overflow: "auto" }}>
          <>
            <h1>Assign Culture Catalyst for project</h1>
            <Row>
              <Col md="12">
                <h5>
                  <label htmlFor="">
                    Project Name: <strong>{projectName}</strong>
                  </label>
                </h5>
              </Col>
            </Row>
            <Row className="mt-2">
              <h5>
                {" "}
                <label htmlFor="">Culture Catalyst List</label>
              </h5>
              {studentsList &&
                studentsList?.rows?.map((student) => {
                  return (
                    <>
                      <Col md="2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="terms"
                          id="formikTermsCheck"
                          checked={
                            SelectStudent(student?.student?.id) == true
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            checkStudent(student?.student?.id);
                          }}
                        />
                        <label
                          className="form-check-label align-middle mx-1"
                          htmlFor="formikTermsCheck"
                        >
                          {student?.student?.name}
                        </label>
                      </Col>
                    </>
                  );
                })}

              {/* checked={checkedPrimaryIds.includes("2")}
                      onChange={() => checkPrimaryId("2")} */}
            </Row>
            <div className="text-end">
              <Button
                variant="success"
                className="mx-1"
                type="submit"
                onClick={assignUpdate}
              >
                Confirm
              </Button>
              <Button
                variant="primary"
                className="mx-1"
                onClick={() => {
                  setShow(false);
                  setAssignstudents([]);
                  setRemovestudents([]);
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        </div>
      </Modal>
    </>
  );
};

export default FitmentProject;
