import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import SignInReducer from "./reducer/SignInReducer";
import SignUpReducer from "./reducer/SignUpReducer";
import HiringPartnerReducer from "./reducer/HiringPartnerReducer";
import StudentReducer from "./reducer/StudentReducer";

import settingsReducer from "./reducer/settingsSlice";
import menuReducer from "./reducer/menuSlice";
import PlacementReducer from "./reducer/PlacementReducer";
import UserReducer from "./reducer/UserReducer";
import CourseReducer from "./reducer/CourseReducer";
import MentorReducer from "./reducer/MentorReducer";
import HiringMentorReducer from "./reducer/HiringMentorReducer";
import DashboardReducer from "./reducer/DashboardReducer";
import CCMentorReducer from "./reducer/CCMentorReducer";
import AdminReducer from "./reducer/AdminReducer";
import SprintReducer from "./reducer/SprintReducer";
import GeneralSettingsReducer from "./reducer/GeneralSettingsReducer";
import CertificateReducer from "./reducer/CertificateReducer";

export const reducer = {
  signInConfiguration: SignInReducer,
  signUpConfiguration: SignUpReducer,
  hiringPartner: HiringPartnerReducer,
  settings: settingsReducer,
  menu: menuReducer,
  placement: PlacementReducer,
  student: StudentReducer,
  User: UserReducer,
  course: CourseReducer,
  mentor: MentorReducer,
  hiringMentor: HiringMentorReducer,
  dashboard: DashboardReducer,
  cc_mentor: CCMentorReducer,
  admin: AdminReducer,
  sprint: SprintReducer,
  general_settings: GeneralSettingsReducer,
  certificate: CertificateReducer,
};

const store = configureStore({
  reducer,
  // middleware: [ // Because we define the middleware property here, we need to explictly add the defaults back in.
  //     ...getDefaultMiddleware(),
  // ]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
