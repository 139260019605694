import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS, TYPE_OF_ACTIVITY } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TypeActivityAdd, GeneralSettingsSelector, TypeActivity_ByID, TypeActivityUpdate, clearGeneralSettingsData } from "../../store/reducer/GeneralSettingsReducer"
import { localFrontend, FRONTEND_BASE } from "../../services/config";
import { LoadingButton } from "@mui/lab";
import _ from 'lodash';

const ActivityTypeManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const title = id ? "Activity Type Edit" : "Activity Type Add";
    const [validated, setValidated] = useState(false);
    const [Loginerror, setLoginerror] = useState(false);
    const [errorTypeMsg, seterrorTypeMsg] = useState(false);
    const [isClicked, setIsClicked] = useState(false)
    const [typeID, settypeID] = useState();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Activity Type is required"),
    })

    const [initialValues, setInitialValues] = useState({
        name: "", type: ""
    })
    const onSubmit = (formData) => {
        if ((!typeID)) {
            seterrorTypeMsg(true)
            setValidated(false)
        } else {
            formData.type = (typeID == '2') ? ('1,2') : ('1')
            if (id) {
                dispatch(TypeActivityUpdate({ id, formData }))
            } else {
                dispatch(TypeActivityAdd({ formData }));
            }
        }

    };

    const formik = useFormik({ initialValues, validationSchema, onSubmit });
    const { handleSubmit, handleChange, values, touched, errors } = formik;


    const { genereal_settings_errorMessage, typesIdData, type_activity_add_Loading, type_activity_Loading, type_activity_update_Loading } = useSelector(GeneralSettingsSelector)

    useEffect(() => {
        if (id)
            dispatch(TypeActivity_ByID({ id: id }))
    }, [id]);

    useEffect(() => {
        if (id && typesIdData !== "") {

            formik.setValues({
                name: typesIdData?.name
            })
            let TypeData = _.split(typesIdData?.type, ',')
            if (TypeData.length == 2)
                settypeID(2)
            else
                settypeID(1)
        }
    }, [typesIdData])

    useEffect(() => {
        if (type_activity_add_Loading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: "Activity Type Added Successfully!",
                icon: 'success'
            });
            dispatch(clearGeneralSettingsData());
            navigate("/admin/activity");
        }

        if (type_activity_update_Loading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: "Activity Type Updated Successfully!",
                icon: 'success'
            }); dispatch(clearGeneralSettingsData());
            navigate("/admin/activity");
        }
        if (((type_activity_add_Loading === API_STATUS.REJECTED) || (type_activity_update_Loading === API_STATUS.REJECTED)) && (genereal_settings_errorMessage != null)) {
            if (genereal_settings_errorMessage.message) Swal.fire({
                title: genereal_settings_errorMessage.message,
                icon: 'error'
            });
            else if (genereal_settings_errorMessage.errors) {
                let data = "";
                genereal_settings_errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                Swal.fire({
                    title: data,
                    icon: 'error'
                })
            }
            dispatch(clearGeneralSettingsData());
        }
    }, [type_activity_add_Loading, type_activity_update_Loading, genereal_settings_errorMessage]);

    return (
        <>
            <Row>
                <Col>
                    {/* Title Start */}
                    <section className="scroll-section" id="title">
                        <div className="page-title-container">
                            <h1 className="mb-0 pb-0 display-4">{title}</h1>
                            <Breadcrumb className="breadcrumb-container d-inline-block">
                                <Breadcrumb.Item key={`breadCrumb1`}>
                                </Breadcrumb.Item>
                            </Breadcrumb>

                        </div>
                        <Link to="/admin/activity">
                            <div className="text-end mb-2">
                                <Button variant='success' type='submit'>Back</Button>
                            </div>
                        </Link>
                    </section>
                    {/* Title End */}

                    {/* Standard Start */}
                    <section className="scroll-section" id="standard">
                        {/* <h2 className="small-title">Standard</h2> */}
                        <Card body className="mb-5">
                            {/* List Header Start */}
                            <form
                                id="collegeForm"
                                className={
                                    validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                                }
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setValidated(true);
                                    handleSubmit();
                                }}
                            >
                                <div className="mb-3 filled form-group tooltip-end-top">
                                    {Loginerror && Loginerror.error && (
                                        <Form.Control.Feedback
                                            type="invalid"
                                            style={{ display: "block" }}
                                        >
                                            {Loginerror.error}
                                        </Form.Control.Feedback>
                                    )}
                                </div>
                                <Row>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file" />
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder="Activity Name"
                                                value={values.name}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.name && touched.name && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file-text" />
                                            <Form.Control
                                                as="select"
                                                onChange={(e) => {
                                                    settypeID(e.target.value);
                                                }}
                                                value={typeID}
                                                className="is-invalid"
                                                placeholder="Domain">
                                                <option value="">Select Type</option>
                                                {_.toArray(TYPE_OF_ACTIVITY) &&
                                                    _.toArray(TYPE_OF_ACTIVITY).map((fields, key) => {
                                                        return (
                                                            <option value={key + 1}> {fields}</option>
                                                        );
                                                    })}
                                            </Form.Control>                                            
                                            {errorTypeMsg &&
                                                (
                                                    <p className='text-danger'>Type of Activity is required</p>
                                                )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-end mt-2">

                                    <LoadingButton variant='contained' loading={![API_STATUS.FULFILLED, 'initial'].includes(type_activity_add_Loading || type_activity_update_Loading)} type="submit"
                                        onClick={() => {
                                            if ((!typeID)) {
                                                seterrorTypeMsg(!typeID)
                                                setValidated(false)
                                            }
                                        }} >
                                        {id ? "Update" : "Create"}
                                    </LoadingButton>
                                </div>
                            </form>
                        </Card>
                    </section>

                    {/* Positions End */}
                </Col>
            </Row >
        </>
    );
};

export default ActivityTypeManagement;
