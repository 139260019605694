import { Button, Card, Col, Row } from "react-bootstrap";
import {
  ProfileStudent,
  StudentSelector,
  fitmentProjectScheduler,
} from "../../store/reducer/StudentReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  hiring_partnerLists,
  hiringpartnerSelector,
} from "../../store/reducer/HiringPartnerReducer";
import { useNavigate } from "react-router-dom";

const AssignedFitment = () => {
  const dispatch = useDispatch();
  const { studentProfileDatas } = useSelector(StudentSelector);
  const { hiringPartnerDatas } = useSelector(hiringpartnerSelector);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(ProfileStudent({}));
  }, []);

  useEffect(() => {
    let query = [];
    query = studentProfileDatas?.studentData?.hiring_partner_student?.map(
      (ele) => {
        if (ele.student_acceptance_status == 3) return ele.org_id;
      }
    );
    let limit = 0;
    let page = 0;
    dispatch(hiring_partnerLists({ query, limit, page }));
  }, [studentProfileDatas]);

  useEffect(() => {
    console.log(hiringPartnerDatas, "hiringPartnerDatas");
  }, [hiringPartnerDatas]);

  const handleProject = (hiring_partner_id) => [
    navigate("project/" + hiring_partner_id),
  ];

  return (
    <div>
      <Row>
        <Col md={8}>
          <h1>
            <strong>Course : </strong>{" "}
            {studentProfileDatas?.studentData?.departments?.name}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          {hiringPartnerDatas &&
            hiringPartnerDatas?.rows?.map((hiringPartner) => {
              return (
                <>
                  <Card className="rounded-3 p-3 mt-2">
                    <div>
                      <h2>
                        <strong>{hiringPartner?.name}</strong>
                      </h2>
                      <hr />
                      <div className="text-end">
                        <Button
                          className="btn btn-warning"
                          onClick={() => handleProject(hiringPartner?.id)}
                        >
                          Shortlisted
                        </Button>
                      </div>
                    </div>
                  </Card>
                </>
              );
            })}
        </Col>
      </Row>
    </div>
  );
};

export default AssignedFitment;
