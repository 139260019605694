import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createProjectValidator } from "../../validators/Validators";
import {
  CreateProjectScheduler,
  UpdateProjectScehduler,
  ViewProjectScheduler,
  clearHiringMentorData,
  hiringmentorSelector,
} from "../../store/reducer/HiringMentorReducer";
import {
  departmentLists,
  departmentUpdates,
  placementSelector,
  testLists,
} from "../../store/reducer/PlacementReducer";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { API_STATUS } from "../../utils/Constants";
import Feedback from "react-bootstrap/esm/Feedback";
const CreateProject = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobTypeValue, setjobTypeValues] = useState();
  const [salaryLable, setsalaryLable] = useState("Salary");
  const [validated, setValidated] = useState(false);
  const [dojValue, setDojValue] = useState();
  const [departmentID, setDepartmentID] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [activities, setActivities] = useState([
    { title: "", description: "", id: "" },
  ]);
  const [errorMsg, seterrorMsg] = useState({
    doj: false,
    dom: false,
    jobType: false,
  });
  //   console.log(errorMsg, "errorMsg");
  const title = "Project Fitment";

  const {
    projectDetails,
    UpdateJobScehdulerLoading,
    CreateJobSchedulerLoading,
    ViewProjectSchedulerLoading,
  } = useSelector(hiringmentorSelector);
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
  });
  const onSubmit = (formData) => {
    formData.activities = activities;
    console.log(formData, "formData");
    if (id) {
      dispatch(UpdateProjectScehduler({ id, formData }));
    } else {
      dispatch(CreateProjectScheduler({ formData }));
    }
  };

  const add_activity = () => {
    activities.push({ title: "", description: "", id: "" });
    setActivities([...activities]);
  };
  useEffect(() => {
    if (id) dispatch(ViewProjectScheduler({ formData: id }));
  }, [id]);
  useEffect(() => {
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    dispatch(departmentLists(departmentQuery));
  }, []);

  const { departmentData } = useSelector(placementSelector);

  const formik = useFormik({
    initialValues,
    validationSchema: createProjectValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  useEffect(() => {
    console.log(activities, "activities");
    console.log(errors, "errors");
  }, [activities, errors]);
  useEffect(() => {
    if (projectDetails !== "") {
      formik.setValues({
        title: projectDetails?.title,
        description: projectDetails?.description,
      });

      let newData = projectDetails?.project_activity.map((item) =>
        Object.assign({}, item)
      );
      setActivities([...newData]);
    }

    dispatch(clearHiringMentorData());
  }, [projectDetails]);

  const handleJobType = (e) => {
    setjobTypeValues(e);
    if (e == "Intership") setsalaryLable("Stipend");
    else setsalaryLable("Salary");
  };

  const handleDateChange = (date) => {
    setDojValue(date);
  };

  const handleActivityChange = (e, row, type) => {
    console.log(row, e.target.value);
    if (type == "title") {
      activities[row].title = e.target.value;
    }
    if (type == "description") {
      activities[row].description = e.target.value;
    }
    setActivities([...activities]);
  };
  useEffect(() => {
    if (CreateJobSchedulerLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Project Fitment Created Successfully!",
        icon: "success",
      });
      dispatch(clearHiringMentorData());
      navigate("/mentor/projectfitment");
    }
    if (UpdateJobScehdulerLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Project Fitment Updated Successfully!",
        icon: "success",
      });
      dispatch(clearHiringMentorData());
      navigate("/mentor/projectfitment");
    }
    if (ViewProjectSchedulerLoading === API_STATUS.REJECTED) {
      navigate("/mentor/projectfitment");
      dispatch(clearHiringMentorData());
    }
  }, [
    CreateJobSchedulerLoading,
    UpdateJobScehdulerLoading,
    ViewProjectSchedulerLoading,
  ]);
  return (
    <div className="container">
      <section className="scroll-section" id="title">
        <div className="page-title-container">
          <h1 className="mb-0 pb-0 display-4">{title}</h1>
          <Breadcrumb className="breadcrumb-container d-inline-block">
            <Breadcrumb.Item key={`breadCrumb1`}>
              {id ? "Edit Fitment Project" : "Create Fitment Project"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Link to="/mentor/projectfitment">
          <div className="text-end mb-2">
            <Button variant="success" type="submit">
              Back
            </Button>
          </div>
        </Link>
      </section>
      <Row>
        <Col>
          <Card className="p-3 create_job mt-3">
            <Form
              id="jobForm"
              className={
                validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
              }
              onSubmit={(e) => {
                e.preventDefault();
                setValidated(true);
                handleSubmit();
              }}
            >
              <Row>
                <Col md="6">
                  <div className="filled form-group tooltip-end-top">
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Project Title"
                      value={values.title}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.title && touched.title && (
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="filled form-group tooltip-end-top">
                    {/* <CsLineIcons icon="user" /> */}
                    <Form.Control
                      as="textarea"
                      name="description"
                      placeholder="Project Description"
                      value={values.description}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.description && touched.description && (
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Button variant="success" onClick={add_activity}>
                    Add Activity
                  </Button>
                  {activities &&
                    activities.map((activity, index) => {
                      return (
                        <>
                          <Row>
                            <Col md="6">
                              <div className="filled form-group tooltip-end-top mt-3">
                                <Form.Control
                                  type="text"
                                  name="activitytitle"
                                  placeholder="Activity Title"
                                  value={activity.title}
                                  className="is-invalid"
                                  onChange={(e) => {
                                    handleActivityChange(e, index, "title");
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <div className="filled form-group tooltip-end-top mt-3 mb-3">
                                <Form.Control
                                  as="textarea"
                                  name="description"
                                  placeholder="Activity Description"
                                  value={activity.description}
                                  className="is-invalid"
                                  rows={5}
                                  onChange={(e) => {
                                    handleActivityChange(
                                      e,
                                      index,
                                      "description"
                                    );
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <hr />
                        </>
                      );
                    })}
                  <div>{}</div>
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-4">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => {
                    if (!dojValue || !jobTypeValue || !departmentID) {
                      seterrorMsg({
                        doj: !dojValue,
                        dom: !departmentID,
                        jobType: !jobTypeValue,
                      });
                      setValidated(false);
                    }
                  }}
                >
                  {id ? "Update" : "Create"}
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateProject;
