import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  sprint_list,
  domain_activity_list,
  domain_attachments_list,
  create_sprint,
  get_sprint_by_id,
  update_sprint_data,
  create_activity,
  get_activity_by_id,
  update_activity_data,
  create_attachment,
  get_attachment_by_id,
  update_attachment_data,
  upload_sprint,
  upload_activity,
  upload_attachment,
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "sprint";

const initialState = {
  loading: "initial",
  sprint_errorMessage: "",
  sprintListData: [],
  activityListData: [],
  attachmentListData: [],
  getSprintData: [],
  getActivityData: [],
  getAttachmentData: [],
  sprint_List_Loading: "initial",
  activity_List_Loading: "initial",
  attachment_List_Loading: "initial",
  create_sprint_Loading: "initial",
  get_sprint_Loading: "initial",
  update_sprint_Loading: "initial",
  create_activity_Loading: "initial",
  get_activity_Loading: "initial",
  update_activity_Loading: "initial",
  create_attachment_Loading: "initial",
  update_attachment_Loading: "initial",
  get_attachment_Loading: "initial",
  sprint_upload_Loading: "initial",
  activity_upload_Loading: "initial",
  attachment_upload_Loading: "initial",
};

export const sprint_Lists = createAsyncThunk(
  `${namespace}/sprint_Lists`,
  async (
    { page, limit, query, order, department_id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await sprint_list(
        page,
        limit,
        query,
        order,
        department_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const Domain_ActivityList = createAsyncThunk(
  `${namespace}/Domain_ActivityList`,
  async (
    { activitypage, activitylimit, activityquery, order, sprint_id, id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await domain_activity_list(
        activitypage,
        activitylimit,
        activityquery,
        order,
        sprint_id,
        id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const Domain_AttachmentList = createAsyncThunk(
  `${namespace}/Domain_AttachmentList`,
  async (
    {
      attachmentpage,
      attachmentlimit,
      attachmentquery,
      order,
      activity_id,
      sprint_id,
      id,
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await domain_attachments_list(
        attachmentpage,
        attachmentlimit,
        attachmentquery,
        order,
        activity_id,
        sprint_id,
        id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CreateSprint = createAsyncThunk(
  `${namespace}/CreateSprint`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await create_sprint(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const Get_Sprint_ByID = createAsyncThunk(
  `${namespace}/Get_Sprint_ByID`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await get_sprint_by_id(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const Update_Sprint = createAsyncThunk(
  `${namespace}/Update_Sprint`,
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await update_sprint_data(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CreateActivity = createAsyncThunk(
  `${namespace}/CreateActivity`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await create_activity(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const Get_Activity_ByID = createAsyncThunk(
  `${namespace}/Get_Activity_ByID`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await get_activity_by_id(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const UpdateActivity = createAsyncThunk(
  `${namespace}/UpdateActivity`,
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formDATARE");
      const { data } = await update_activity_data(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CreateAttachment = createAsyncThunk(
  `${namespace}/CreateAttachment`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await create_attachment(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const Get_Attachment_ByID = createAsyncThunk(
  `${namespace}/Get_Attachment_ByID`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await get_attachment_by_id(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const UpdateAttachment = createAsyncThunk(
  `${namespace}/UpdateAttachment`,
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formDATARE");
      const { data } = await update_attachment_data(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const UploadSprint = createAsyncThunk(
  `${namespace}/UploadSprint`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await upload_sprint(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const UploadActivity = createAsyncThunk(
  `${namespace}/UploadActivity`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await upload_activity(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const UploadAttachment = createAsyncThunk(
  `${namespace}/UploadAttachment`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "REDFORM");
      const { data } = await upload_attachment(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const SprintSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearsprintAllData: () => {
      return initialState;
    },
    clearSprintData: (state) => {
      state.sprint_List_Loading = "initial";
      state.activity_List_Loading = "initial";
      state.attachment_List_Loading = "initial";
      state.create_sprint_Loading = "initial";
      state.get_sprint_Loading = "initial";
      state.update_sprint_Loading = "initial";
      state.create_activity_Loading = "initial";
      state.update_activity_Loading = "initial";
      state.get_activity_Loading = "initial";
      state.create_attachment_Loading = "initial";
      state.get_attachment_Loading = "initial";
      state.update_attachment_Loading = "initial";
      state.sprint_upload_Loading = "initial";
      state.activity_upload_Loading = "initial";
      state.attachment_upload_Loading = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(sprint_Lists.pending, (state) => {
      state.sprint_List_Loading = API_STATUS.PENDING;
    });
    builder.addCase(sprint_Lists.fulfilled, (state, { payload }) => {
      state.sprint_List_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.sprintListData = payloaddata;
      console.log(payloaddata?.rows, "sprint_Lists");
    });
    builder.addCase(sprint_Lists.rejected, (state, action) => {
      state.sprint_List_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "payloadsprint_Lists");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(Domain_ActivityList.pending, (state) => {
      state.activity_List_Loading = API_STATUS.PENDING;
    });
    builder.addCase(Domain_ActivityList.fulfilled, (state, { payload }) => {
      state.activity_List_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.activityListData = payloaddata;
      // state.activityListData = payload?.datas?
      console.log(state.activityListData, "Domain_ActivityList");
    });
    builder.addCase(Domain_ActivityList.rejected, (state, action) => {
      state.activity_List_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "Domain_ActivityList");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(Domain_AttachmentList.pending, (state) => {
      state.attachment_List_Loading = API_STATUS.PENDING;
    });
    builder.addCase(Domain_AttachmentList.fulfilled, (state, { payload }) => {
      state.attachment_List_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.attachmentListData = payloaddata;
      // state.activityListData = payload?.datas?
      console.log(state.attachmentListData, "Domain_AttachmentList");
    });
    builder.addCase(Domain_AttachmentList.rejected, (state, action) => {
      state.attachment_List_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "Domain_AttachmentList");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(CreateSprint.pending, (state) => {
      state.create_sprint_Loading = API_STATUS.PENDING;
    });
    builder.addCase(CreateSprint.fulfilled, (state, { payload }) => {
      state.create_sprint_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(CreateSprint.rejected, (state, action) => {
      state.create_sprint_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "Create_Sprint");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(Get_Sprint_ByID.pending, (state) => {
      state.get_sprint_Loading = API_STATUS.PENDING;
    });
    builder.addCase(Get_Sprint_ByID.fulfilled, (state, { payload }) => {
      state.get_sprint_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.getSprintData = payloaddata?.data;
      console.log(state.getSprintData, "Get_Sprint_ByID");
    });
    builder.addCase(Get_Sprint_ByID.rejected, (state, action) => {
      state.get_sprint_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "Get_Sprint_ByID");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(Update_Sprint.pending, (state) => {
      state.update_sprint_Loading = API_STATUS.PENDING;
    });
    builder.addCase(Update_Sprint.fulfilled, (state, { payload }) => {
      state.update_sprint_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(Update_Sprint.rejected, (state, action) => {
      state.update_sprint_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "Update_Sprint");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(CreateActivity.pending, (state) => {
      state.create_activity_Loading = API_STATUS.PENDING;
    });
    builder.addCase(CreateActivity.fulfilled, (state, { payload }) => {
      state.create_activity_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(CreateActivity.rejected, (state, action) => {
      state.create_activity_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "CreateActivity");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(Get_Activity_ByID.pending, (state) => {
      state.get_activity_Loading = API_STATUS.PENDING;
    });
    builder.addCase(Get_Activity_ByID.fulfilled, (state, { payload }) => {
      state.get_activity_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.getActivityData = payloaddata?.data;
      console.log(state.getActivityData, "Get_Activity_ByID");
    });
    builder.addCase(Get_Activity_ByID.rejected, (state, action) => {
      state.get_activity_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "Get_Activity_ByID");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(UpdateActivity.pending, (state) => {
      state.update_activity_Loading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateActivity.fulfilled, (state, { payload }) => {
      state.update_activity_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateActivity.rejected, (state, action) => {
      state.update_activity_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "UpdateActivity");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(CreateAttachment.pending, (state) => {
      state.create_attachment_Loading = API_STATUS.PENDING;
    });
    builder.addCase(CreateAttachment.fulfilled, (state, { payload }) => {
      state.create_attachment_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(CreateAttachment.rejected, (state, action) => {
      state.create_attachment_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "CreateAttachment");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(Get_Attachment_ByID.pending, (state) => {
      state.get_attachment_Loading = API_STATUS.PENDING;
    });
    builder.addCase(Get_Attachment_ByID.fulfilled, (state, { payload }) => {
      state.get_attachment_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.getAttachmentData = payloaddata?.data;
      console.log(state.getAttachmentData, "Get_Attachment_ByID");
    });
    builder.addCase(Get_Attachment_ByID.rejected, (state, action) => {
      state.get_attachment_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "Get_Attachment_ByID");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(UpdateAttachment.pending, (state) => {
      state.update_attachment_Loading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateAttachment.fulfilled, (state, { payload }) => {
      state.update_attachment_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateAttachment.rejected, (state, action) => {
      state.update_attachment_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "UpdateAttachment");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(UploadSprint.pending, (state) => {
      state.sprint_upload_Loading = API_STATUS.PENDING;
    });
    builder.addCase(UploadSprint.fulfilled, (state, { payload }) => {
      state.sprint_upload_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(UploadSprint.rejected, (state, action) => {
      state.sprint_upload_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "UploadSprint");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(UploadActivity.pending, (state) => {
      state.activity_upload_Loading = API_STATUS.PENDING;
    });
    builder.addCase(UploadActivity.fulfilled, (state, { payload }) => {
      state.activity_upload_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(UploadActivity.rejected, (state, action) => {
      state.activity_upload_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "UploadActivity");
      state.sprint_errorMessage = action?.payload?.data;
    });
    builder.addCase(UploadAttachment.pending, (state) => {
      state.attachment_upload_Loading = API_STATUS.PENDING;
    });
    builder.addCase(UploadAttachment.fulfilled, (state, { payload }) => {
      state.attachment_upload_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(UploadAttachment.rejected, (state, action) => {
      state.attachment_upload_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "UploadAttachment");
      state.sprint_errorMessage = action?.payload?.data;
    });
  },
});

export const { clearsprintAllData, clearSprintData } = SprintSlice.actions;

export const SprintSelector = (state) => state.sprint;

export default SprintSlice.reducer;
