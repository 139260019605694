import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  domain_create,
  domain_by_id,
  domain_update,
  college_create,
  college_by_id,
  college_update,
  type_activity_create,
  type_activity_update,
  type_activity_by_id,
  soft_skill_create,
  soft_skill_by_id,
  soft_skill_update,
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "general_settings";

const initialState = {
  loading: "initial",
  genereal_settings_errorMessage: "",
  domainData: [],
  collegeIdData: [],
  typesIdData: null,
  softskillIdData: null,
  domain_add_Loading: "initial",
  domain_Loading: "initial",
  domain_update_Loading: "initial",
  college_add_Loading: "initial",
  college_Loading: "initial",
  college_update_Loading: "initial",
  type_activity_add_Loading: "initial",
  type_activity_Loading: "initial",
  type_activity_update_Loading: "initial",
  soft_skill_add_Loading: "initial",
  soft_skill_Loading: "initial",
  soft_skill_update_Loading: "initial",
};

export const DomainAdd = createAsyncThunk(
  `${namespace}/DomainAdd`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("DomainAdd");
      const { data } = await domain_create(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const Get_Domain_ByID = createAsyncThunk(
  `${namespace}/Get_Domain_ByID`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      console.log("Get_Domain_ByID");
      const { data } = await domain_by_id(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const DomainUpdate = createAsyncThunk(
  `${namespace}/DomainUpdate`,
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("DomainUpdate");
      const { data } = await domain_update(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CollegeAdd = createAsyncThunk(
  `${namespace}/CollegeAdd`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("CollegeAdd");
      const { data } = await college_create(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const Get_College_ByID = createAsyncThunk(
  `${namespace}/Get_College_ByID`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      console.log("Get_College_ByID");
      const { data } = await college_by_id(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CollegeUpdate = createAsyncThunk(
  `${namespace}/CollegeUpdate`,
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("CollegeUpdate");
      const { data } = await college_update(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const TypeActivityAdd = createAsyncThunk(
  `${namespace}/TypeActivityAdd`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("TypeActivityAdd");
      const { data } = await type_activity_create(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const TypeActivity_ByID = createAsyncThunk(
  `${namespace}/TypeActivity_ByID`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      console.log("TypeActivity_ByID");
      const { data } = await type_activity_by_id(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const TypeActivityUpdate = createAsyncThunk(
  `${namespace}/TypeActivityUpdate`,
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("TypeActivityUpdate");
      const { data } = await type_activity_update(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const SoftSkillAdd = createAsyncThunk(
  `${namespace}/SoftSkillAdd`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("SoftSkillAdd");
      const { data } = await soft_skill_create(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const SoftSkill_ByID = createAsyncThunk(
  `${namespace}/SoftSkill_ByID`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      console.log("SoftSkill_ByID");
      const { data } = await soft_skill_by_id(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const SoftSkillUpdate = createAsyncThunk(
  `${namespace}/SoftSkillUpdate`,
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("SoftSkillUpdate");
      const { data } = await soft_skill_update(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const GeneralSettingsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearGeneralSettingsData: () => {
      return initialState;
    },
    generalSettingsclearData: (state) => {
      state.domain_add_Loading = "initial";
      state.domain_Loading = "initial";
      state.domain_update_Loading = "initial";
      state.college_add_Loading = "initial";
      state.college_Loading = "initial";
      state.college_update_Loading = "initial";
      state.type_activity_add_Loading = "initial";
      state.type_activity_Loading = "initial";
      state.type_activity_update_Loading = "initial";
      state.soft_skill_add_Loading = "initial";
      state.soft_skill_update_Loading = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(DomainAdd.pending, (state) => {
      state.domain_add_Loading = API_STATUS.PENDING;
    });
    builder.addCase(DomainAdd.fulfilled, (state, { payload }) => {
      state.domain_add_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(DomainAdd.rejected, (state, action) => {
      state.domain_add_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "DomainAdd");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(Get_Domain_ByID.pending, (state) => {
      state.domain_Loading = API_STATUS.PENDING;
    });
    builder.addCase(Get_Domain_ByID.fulfilled, (state, { payload }) => {
      state.domain_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.domainData = payloaddata?.data;
      console.log(state.domainData, "domainData");
    });
    builder.addCase(Get_Domain_ByID.rejected, (state, action) => {
      state.domain_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "Get_Domain_ByID");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(DomainUpdate.pending, (state) => {
      state.domain_update_Loading = API_STATUS.PENDING;
    });
    builder.addCase(DomainUpdate.fulfilled, (state, { payload }) => {
      state.domain_update_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(DomainUpdate.rejected, (state, action) => {
      state.domain_update_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "DomainUpdate");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(Get_College_ByID.pending, (state) => {
      state.college_Loading = API_STATUS.PENDING;
    });
    builder.addCase(Get_College_ByID.fulfilled, (state, { payload }) => {
      state.college_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.collegeIdData = payloaddata?.data;
      console.log(state.collegeIdData, "collegeIdData");
    });
    builder.addCase(Get_College_ByID.rejected, (state, action) => {
      state.college_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "Get_College_ByID");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(CollegeAdd.pending, (state) => {
      state.college_add_Loading = API_STATUS.PENDING;
    });
    builder.addCase(CollegeAdd.fulfilled, (state, { payload }) => {
      state.college_add_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(CollegeAdd.rejected, (state, action) => {
      state.college_add_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "CollegeAdd");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(CollegeUpdate.pending, (state) => {
      state.college_update_Loading = API_STATUS.PENDING;
    });
    builder.addCase(CollegeUpdate.fulfilled, (state, { payload }) => {
      state.college_update_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(CollegeUpdate.rejected, (state, action) => {
      state.college_update_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "CollegeUpdate");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(TypeActivityAdd.pending, (state) => {
      state.type_activity_add_Loading = API_STATUS.PENDING;
    });
    builder.addCase(TypeActivityAdd.fulfilled, (state, { payload }) => {
      state.type_activity_add_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(TypeActivityAdd.rejected, (state, action) => {
      state.type_activity_add_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "DomainAdd");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(TypeActivity_ByID.pending, (state) => {
      state.type_activity_Loading = API_STATUS.PENDING;
    });
    builder.addCase(TypeActivity_ByID.fulfilled, (state, { payload }) => {
      state.type_activity_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.typesIdData = payloaddata?.data;
      console.log(state.typesIdData, "TypeActivity_ByID1");
    });
    builder.addCase(TypeActivity_ByID.rejected, (state, action) => {
      state.type_activity_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "TypeActivity_ByID");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(TypeActivityUpdate.pending, (state) => {
      state.type_activity_update_Loading = API_STATUS.PENDING;
    });
    builder.addCase(TypeActivityUpdate.fulfilled, (state, { payload }) => {
      state.type_activity_update_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(TypeActivityUpdate.rejected, (state, action) => {
      state.type_activity_update_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "TypeActivityUpdate");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(SoftSkillAdd.pending, (state) => {
      state.soft_skill_add_Loading = API_STATUS.PENDING;
    });
    builder.addCase(SoftSkillAdd.fulfilled, (state, { payload }) => {
      state.soft_skill_add_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(SoftSkillAdd.rejected, (state, action) => {
      state.soft_skill_add_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "SoftSkillAdd");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(SoftSkill_ByID.pending, (state) => {
      state.soft_skill_Loading = API_STATUS.PENDING;
    });
    builder.addCase(SoftSkill_ByID.fulfilled, (state, { payload }) => {
      state.soft_skill_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.softskillIdData = payloaddata?.data;
      console.log(state.softskillIdData, "soft_skill_ByID1");
    });
    builder.addCase(SoftSkill_ByID.rejected, (state, action) => {
      state.soft_skill_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "soft_skill_ByID");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
    builder.addCase(SoftSkillUpdate.pending, (state) => {
      state.soft_skill_update_Loading = API_STATUS.PENDING;
    });
    builder.addCase(SoftSkillUpdate.fulfilled, (state, { payload }) => {
      state.soft_skill_update_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(SoftSkillUpdate.rejected, (state, action) => {
      state.soft_skill_update_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "soft_skill_update_Loading");
      state.genereal_settings_errorMessage = action?.payload?.data;
    });
  },
});

export const { clearGeneralSettingsData, generalSettingsclearData } =
  GeneralSettingsSlice.actions;

export const GeneralSettingsSelector = (state) => state.general_settings;

export default GeneralSettingsSlice.reducer;
