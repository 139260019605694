import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsPencil } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { TablePagination } from "@mui/material";
import {
  MentorDataList,
  MentorSelector,
  clearData,
  MentorDelete,
} from "../../store/reducer/MentorReducer";

const MentorList = () => {
  const title = "Mentor List";
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  useEffect(() => {
    dispatch(MentorDataList({ page, limit, query }));
  }, []);

  const { mentorListDatas, mentorLoading, mentorDelLoading, errorMessage } =
    useSelector(MentorSelector);

  useEffect(() => {
    console.log(mentorListDatas, "mentorListDatas");
  }, [mentorListDatas]);

  const deleteMentor = (row) => {
    let formData = {};
    formData.id = row.id;
    Swal.fire({
      title: "Are You Sure to Delete the Mentor?",
      icon: "warning",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(MentorDelete({ formData }));
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = mentorListDatas?.count % 10;
    let remainder = mentorListDatas?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [mentorListDatas]);

  useEffect(() => {
    dispatch(MentorDataList({ page, limit, query }));
  }, [page, limit, query]);

  useEffect(() => {
    if (mentorDelLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Mentor Deleted Successfully!",
        icon: "success",
      });
      dispatch(clearData());
      dispatch(MentorDataList({}));
    }
  }, [mentorDelLoading]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Link to="/mentor/add">
              <div className="text-end">
                <Button
                  variant="foreground"
                  className="btn-icon btn-icon-start hover-outline mt-1 mb-1"
                >
                  <CsLineIcons icon="plus" /> <span>Add New Mentor</span>
                </Button>
              </div>
            </Link>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Mentor Name</th>
                    <th>Email</th>
                    <th>Domain</th>
                    <th>Availability</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {mentorListDatas &&
                    mentorListDatas?.rows?.map((mentorValues, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{mentorValues?.name}</td>
                            <td>{mentorValues?.email}</td>
                            <td>{mentorValues?.departments?.name}</td>
                            <td>{mentorValues?.avaiablity}</td>
                            <td>
                              {mentorValues?.status == 1
                                ? "Active"
                                : "In-Active"}
                            </td>
                            <td>
                              <Link
                                to={`edit/${mentorValues.id}`}
                                title="Edit"
                                className="btn btn-primary mx-1 "
                              >
                                <BsPencil />
                              </Link>
                              <button
                                title="Delete"
                                className="btn btn-danger"
                                onClick={(e) => {
                                  deleteMentor(mentorValues);
                                }}
                              >
                                <MdDelete />
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between px-2">
                <strong>Total count : {mentorListDatas?.count}</strong>
                <TablePagination
                  component="div"
                  count={mentorListDatas?.count}
                  page={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default MentorList;
