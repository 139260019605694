import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TablePagination } from "@mui/material";
import {
  ActivityList,
  EmptyCourseData,
  checkSprintStatus,
  clearCourseLoadings,
  courseDetails,
  courseSelector,
  getActivitiesTypeScheduler,
  getAllEodDetails,
  getEodDetails,
  planDetail,
  postEODDetails,
  postPlanDetails,
  updateSprint,
} from "../../store/reducer/CourseReducer";
import {
  StudentById,
  StudentSelector,
} from "../../store/reducer/StudentReducer";
import {
  MentorFeedback,
  MentorSelector,
} from "../../store/reducer/MentorReducer";
import { useFormik } from "formik";
import { eodValidator, planValidator } from "../../validators/Validators";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import {
  API_STATUS,
  EODSTATUS,
  TYPE_OF_WORK,
  COURSE_STATUS,
} from "../../utils/Constants";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { API_BASE } from "../../services/config";
import { BiSolidCheckCircle, BiLoader } from "react-icons/bi";
import { SkillList, settingSelector } from "../../store/reducer/settingsSlice";
import { Rating, Stack } from "@mui/material";
import {
  ApproveRejectActivity,
  CC_MentorSelector,
  clearCCMentorData,
} from "../../store/reducer/CCMentorReducer";
import _ from "lodash";

const Sprint = () => {
  const role = localStorage.getItem("role");
  const [show, setShow] = useState({ pfd: false, eod: false });
  const [sprintActivity, setSprintActivity] = useState([]);
  const [currentActivityId, setCurrentActivityId] = useState();
  console.log(currentActivityId, "currentActivityId");
  const [iframeId, setIframeId] = useState();
  const [eodIframeId, setEodIframeId] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, student_id, qweryId } = useParams();
  const [order, setOrder] = useState("");
  const [totalStar, setTotalStar] = useState(0);
  const [ratings, setRatings] = useState([]);
  console.log(ratings, "ratingsratings");
  const [limit, setLimit] = useState("");
  const [keySet, setKeySet] = useState("pfd");
  const [page, setPage] = useState(1);
  const [feedbackShow, setFeedbackShow] = useState(false);
  const [sort, setSort] = useState("");
  const [disablePlan, setDisablePlan] = useState(false);
  const [query, setQuery] = useState("");
  const {
    courseData,
    planDetails,
    sprintstatusDetails,
    activityData,
    planDetailLoader,
    activitiesTypeLoader,
    activityTypes,
    updateSprintLoader,
    EODDetailLoader,
    eodDatas,
    checkSprintStatusLoader,
    AllEODdata,
  } = useSelector(courseSelector);
  const { studentDetailsDatas } = useSelector(StudentSelector);
  const { skillDatas } = useSelector(settingSelector);
  const { feedbackData } = useSelector(MentorSelector);
  const { ApproveRejectActivityLoad } = useSelector(CC_MentorSelector);
  const [validated, setValidated] = useState(false);
  const [tasks, setTasks] = useState([
    { plan: "", plan_duration: "", activity_type_id: "", activity_id: "" },
  ]);

  const [planpage, setplanPage] = useState(1);
  const [planlimit, setplanLimit] = useState(10);
  const [planquery, setplanQuery] = useState("");
  const [planpageSize, setplanPageSize] = useState(1);
  const [planrowsPerPage, setplanRowsPerPage] = useState(0);
  const [planDetailValues, setplanDetailValues] = useState([]);

  const [eodpage, seteodPage] = useState(1);
  const [eodlimit, seteodLimit] = useState(10);
  const [eodquery, seteodQuery] = useState("");
  const [eodpageSize, seteodPageSize] = useState(1);
  const [eodrowsPerPage, seteodRowsPerPage] = useState(0);
  const [eodDetailValues, seteodDetailValues] = useState([]);

  const [eods, setEods] = useState([
    {
      actual_duration: "",
      status: "",
      attachment: "",
      activity_type_id: "",
      activity_id: "",
      planned_hrs: "",
      comments: "",
      planned_desc: "",
    },
  ]);
  const [errorMsg, seterrorMsg] = useState({
    feedbackError: false,
  });
  const [feedbackValue, setfeedbackValue] = useState("");

  const keysArray = [
    "activity_id",
    "planned_desc",
    "planned_hrs",
    "activity_type_id",
    "actual_duration",
    "comments",
    "attachment",
    "status",
  ];
  useEffect(() => {
    console.log(courseData, "courseDat");
    // let data = activityData?.filter((datas) => sprintstatusDetails?.find((ele) => ele.activity_id === datas?.id));
    setSprintActivity(activityData);
  }, [courseData, sprintstatusDetails, activityData]);

  const handleActivity = (activityId) => {
    setCurrentActivityId(activityId);
    const formData = {
      sprint_id: parseInt(qweryId),
      activity_id: parseInt(currentActivityId),
      status: 0,
      student_id: parseInt(student_id),
      domain: id,
    };
    console.log(formData, "FOEMDSFASD");
    dispatch(updateSprint({ formData }));
  };

  const handleFeedback = () => {
    if (role == 5 || role == 1) {
      setFeedbackShow(true);
    }
  };

  const handleCloseFeedback = () => {
    setFeedbackShow(false);
  };

  const handleApprove = (status) => {
    const formData = {
      activity_status: status,
      student_id: Number(student_id),
      domain: Number(id),
      sprint_id: Number(qweryId),
      feedback: feedbackValue,
      overall_rankings: parseFloat(totalStar),
      rankings: ratings,
      activity_id: currentActivityId,
      activity_counts: courseData?.rows[0]?.sprintactivity.length,
    };
    if (feedbackValue !== "") {
      dispatch(ApproveRejectActivity({ formData }));
    } else {
      seterrorMsg({ feedbackError: true });
    }
  };

  const handleRatings = (star, Skill) => {
    const { value } = star.target;
    const rate = ratings;
    const data = {
      skill_id: Skill?.id,
      value: parseFloat(value),
    };
    let exists = _.findIndex(rate, ["skill_id", Skill?.id]);
    console.log(exists, "ratingsratings");
    if (exists != -1) {
      rate[exists].value = parseFloat(value);
    } else {
      rate.push(data);
    }
    console.log(Skill, value, "skill data");
    setTotalStar(_.sumBy(rate, "value"));
    setRatings(rate);
  };

  useEffect(() => {
    if (qweryId && student_id) {
      dispatch(EmptyCourseData());
      dispatch(
        courseDetails({
          department_id: id,
          order,
          limit,
          sort,
          page,
          id: qweryId,
          query,
        })
      );

      dispatch(
        SkillList({
          page,
          limit,
          query: id,
          sortby: sort ?? "",
          order: order ?? "",
        })
      );

      dispatch(
        checkSprintStatus({
          page,
          limit,
          query,
          sortby: sort,
          order,
          status: 0,
          student_id: student_id,
          sprint_id: qweryId,
        })
      );
    }
  }, [qweryId, student_id]);

  const initHit = () => {
    dispatch(
      ActivityList({
        page,
        limit,
        query,
        sortby: sort,
        order,
        status: 0,
        student_id: student_id,
        sprint_id: qweryId,
        id:
          currentActivityId ??
          (sprintstatusDetails && sprintstatusDetails[0]?.activity_id),
      })
    );
    dispatch(
      planDetail({
        planpage,
        planlimit,
        planquery,
        sortby: sort,
        order,
        status: "",
        student_id: student_id,
        sprint_id: qweryId,
        date: "",
        activity_id:
          currentActivityId ??
          (sprintstatusDetails && sprintstatusDetails[0]?.activity_id),
      })
    );
    dispatch(
      getAllEodDetails({
        activity_id: currentActivityId ??  (sprintstatusDetails && sprintstatusDetails[0]?.activity_id),
        student_id: student_id,
        sprint_id: qweryId,
      })
    );
    dispatch(
      getEodDetails({
        eodpage,
        eodlimit,
        eodquery,
        sortby: sort,
        order,
        activity_id:
          currentActivityId ??
          (sprintstatusDetails && sprintstatusDetails[0]?.activity_id),
        student_id: student_id,
        sprint_id: qweryId,
      })
    );
  };

  useEffect(() => {
    dispatch(
      ActivityList({
        page,
        limit,
        query,
        sortby: sort,
        order,
        status: 0,
        student_id: student_id,
        sprint_id: qweryId,
        id: currentActivityId,
      })
    );
    const formData = {
      student_id: student_id,
      sprint_id: qweryId,
    };
    dispatch(
      MentorFeedback({
        formData,
      })
    );
    let planData = [];
    planData = planDetails?.rows?.filter((ele) => {
      return ele.activity_id == currentActivityId;
    });
    setplanDetailValues(planData);
    console.log(planData, "PLANDATAs");
    let eodData = [];
    eodData = eodDatas?.data?.filter((ele) => {
      return ele.activity_id == currentActivityId;
    });
    seteodDetailValues(eodData);
  }, [currentActivityId]);

  const actualWorked = AllEODdata?.data?.reduce((accumulator, object) => {
    return accumulator + object.actual_duration;
  }, 0);

  useEffect(() => {
    sprintstatusDetails &&
      setCurrentActivityId(sprintstatusDetails[0]?.activity_id);
    initHit();
  }, [sprintstatusDetails, student_id, qweryId]);
  const onSubmit = (formData) => {
    const data = show.pfd ? tasks : eods;
    console.log(data, "test error");
    const excludedKey = "attachment";
    const hasEmptyFields = show.pfd
      ? tasks.some((ele) => Object.values(ele).some((value) => value === ""))
      : eods.some((ele) =>
          Object.entries(ele).some(
            ([key, value]) => key !== excludedKey && value === ""
          )
        );

    if (hasEmptyFields) {
      Swal.fire({ title: "Fill all fields", icon: "error" });
    } else {
      data.forEach((ele) => {
        ele.sprint_id = qweryId;
        ele.student_id = student_id;
        if (ele.status || ele.status == "") {
          ele["eod_status"] = ele.status;
          delete ele["status"];
        }
      });
      if (show.pfd) dispatch(postPlanDetails({ formData: data }));
      else dispatch(postEODDetails({ formData: data }));
    }
  };

  /** Table Pagination for Plan for the Day  */
  const planhandleChangePage = (event, newPage) => {
    setplanPage(newPage + 1);
    setplanRowsPerPage(newPage);
  };

  const planhandleChangeRowsPerPage = (event) => {
    setplanLimit(parseInt(event.target.value, 10));
    setplanPage(0);
  };

  useEffect(() => {
    let count = planDetails?.rows?.length % 10;
    let remainder = planDetails?.rows?.length / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setplanPageSize(pageLength);
  }, [planDetails?.rows]);

  useEffect(() => {
    dispatch(
      planDetail({
        planpage,
        planlimit,
        planquery,
        sortby: sort,
        order,
        status: "",
        student_id: student_id,
        sprint_id: qweryId,
        date: "",
        activity_id: currentActivityId,
      })
    );
  }, [planpage, planlimit, planquery, currentActivityId]);

  /** Table Pagination for EOD  */
  const eodhandleChangePage = (event, newPage) => {
    seteodPage(newPage + 1);
    seteodRowsPerPage(newPage);
  };

  const eodhandleChangeRowsPerPage = (event) => {
    seteodLimit(parseInt(event.target.value, 10));
    seteodPage(0);
  };

  useEffect(() => {
    let count = eodDatas?.data?.length % 10;
    let remainder = eodDatas?.data?.length / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    seteodPageSize(pageLength);
  }, [eodDatas?.data]);

  useEffect(() => {
    dispatch(
      getEodDetails({
        eodpage,
        eodlimit,
        eodquery,
        sortby: sort,
        order,
        activity_id: currentActivityId,
        student_id: student_id,
        sprint_id: qweryId,
      })
    );
  }, [eodpage, eodlimit, eodquery]);

  useEffect(() => {
    if (planDetailLoader === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Added Succesfully",
        icon: "success",
      }).then(() => {
        setShow({ pfd: false, eod: false });
        setTasks([
          {
            plan: "",
            plan_duration: "",
            activity_type_id: "",
            activity_id: "",
          },
        ]);
      });
      initHit();
      dispatch(clearCourseLoadings());
    }
    if (activitiesTypeLoader === API_STATUS.FULFILLED) {
      initHit();
      setShow({
        ...show,
        [keySet]: true,
      });
      dispatch(clearCourseLoadings());
    }
    if (EODDetailLoader === API_STATUS.FULFILLED) {
      initHit();
      setShow({
        ...show,
        [keySet]: false,
      });
      dispatch(clearCourseLoadings());
    }

    if (updateSprintLoader === API_STATUS.FULFILLED) {
      initHit();
      dispatch(clearCourseLoadings());
    }

    if (checkSprintStatusLoader === API_STATUS.FULFILLED) {
      dispatch(clearCourseLoadings());
    }

    if (ApproveRejectActivityLoad === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Feedback Submitted Succesfully",
        icon: "success",
      });
      const formData = {
        student_id: student_id,
        sprint_id: qweryId,
      };
      dispatch(
        MentorFeedback({
          formData,
        })
      );
      dispatch(clearCCMentorData());
      setFeedbackShow(false);
      setfeedbackValue("");
      setRatings([]);
      setTotalStar(0);
    }
  }, [
    planDetailLoader,
    activitiesTypeLoader,
    EODDetailLoader,
    updateSprintLoader,
    checkSprintStatusLoader,
    ApproveRejectActivityLoad,
  ]);

  useEffect(() => {
    let studentParam = {};
    studentParam.student_id = student_id;
    dispatch(StudentById({ studentParam }));
  }, []);

  const initialValues = show.pfd ? tasks : eods;
  const validationSchema = show.pfd ? planValidator : eodValidator;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  let FeedBack_Status = feedbackData?.find(
    (ele) => ele.activity_id === currentActivityId && ele.activity_status == 2
  );
  console.log(FeedBack_Status, "FeedBack_Status");
  return (
    <div className="container-fluid mt-4">
      <Row>
        <Col md={8}></Col>
        <Col md={4}>
          <div className="d-flex w-100 justify-content-end">
            <Button
              className="mb-3 mx-1"
              variant="success"
              onClick={(e) => {
                navigate("/hiring_partner/" + student_id + "/course/" + id, {
                  page: "Activity",
                });
              }}
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>
      <Card className="mb-3 p-4">
        <Row className="mt-2">
          <Col md={6} className="pe-0 ps-5">
            <h1>
              <label htmlFor="">
                Culture Catalyst Name:{" "}
                <strong>{studentDetailsDatas?.name}</strong>
              </label>
            </h1>
          </Col>
          <Col md={6} className="text-end pe-5 ps-0">
            <h1>
              Domain: <strong>{studentDetailsDatas?.departments?.name}</strong>
            </h1>
          </Col>
        </Row>
      </Card>
      {sprintstatusDetails?.map((sprint_view) => {
        return <></>;
      })}
      <Row>
        <Col md={9}>
          <Card className="mb-3 p-4">
            {courseData?.rows?.map((sprints) => {
              let timeLeft = sprints?.duration - actualWorked;
              // alert(timeLeft>0)
              return (
                <>
                  <div
                    className={`d-flex justify-content-between w-100  p-3  ${
                      timeLeft >= 0 ? "" : "text-danger"
                    }`}
                  >
                    <h3>{sprints?.name}</h3>
                    <p className="me-5 fs-5">
                      Time Left:{" "}
                      <span>
                        {timeLeft >= 0
                          ? timeLeft
                          : "You Took much time than given time"}
                      </span>{" "}
                      hrs
                    </p>
                  </div>
                  <div className="px-3">{sprints?.description}</div>
                </>
              );
            })}
          </Card>
          <Card className="p-4">
            {sprintActivity?.map((module) => {
              return (
                <>
                  <Row>
                    <Col md="9">
                      <h1 className="mt-3">{module?.name}</h1>
                      <div className="px-3">{module?.description}</div>
                    </Col>

                    <Col md="3" className="text-end">
                      {FeedBack_Status &&
                      FeedBack_Status?.activity_status == 2 ? (
                        <h1 className={"text-success"}>
                          <BiSolidCheckCircle size={50} color={"green"} />
                          {COURSE_STATUS[FeedBack_Status?.activity_status]}
                        </h1>
                      ) : (
                        <>
                          <Button onClick={handleFeedback}>Feedback</Button>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Accordion defaultActiveKey="0" className="mt-3">
                    <Accordion.Item eventKey={0}>
                      <Accordion.Header className="w=100">
                        <div className="d-flex justify-content-between w-100">
                          <h6 className="">Referal Links/Attachments</h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          {module?.activityattachment?.map((attachments, i) => (
                            <>
                              {attachments?.type === 0 ? (
                                <>
                                  <Col md={1}>
                                    <Card
                                      className="sh-10 hover-border-secondary  border-secondary mt-3 mb-3 "
                                      onClick={() => setIframeId(i)}
                                    >
                                      <Card.Body className="p-3 text-center align-items-center  d-flex flex-column justify-content-between">
                                        <div className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl">
                                          <CsLineIcons
                                            icon="link"
                                            className="text-white"
                                          />
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </>
                              ) : (
                                <Col md={1}>
                                  <Card
                                    className="sh-10 hover-border-primary border-primary mt-3 mb-3"
                                    onClick={() => setIframeId(i)}
                                  >
                                    <Card.Body className="p-3 text-center align-items-center d-flex flex-column justify-content-between">
                                      <div className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl">
                                        <CsLineIcons
                                          icon="file-text"
                                          className="text-white"
                                        />
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              )}
                            </>
                          ))}
                        </Row>

                        <Col md={12}>
                          <iframe
                            src={
                              module?.activityattachment[iframeId ?? 0]?.file
                            }
                            frameborder="0"
                            allow="autoplay; encrypted-media"
                            allowfullscreen
                            title="video"
                            width="100%"
                            height="500"
                          />
                        </Col>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={1}>
                      <Accordion.Header className="w=100">
                        <div className="d-flex justify-content-between w-100">
                          <h6 className="">Plan's of this Activity</h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Plan</th>
                              <th>Planned Date</th>
                              <th>Planned (Hrs)</th>
                              <th>Type of Work</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {planDetails &&
                              planDetails?.rows?.map((plans) => {
                                if (plans?.activity_id == currentActivityId)
                                  return (
                                    <tr>
                                      <td> {plans?.plan} </td>
                                      <td>
                                        {dayjs(plans?.updated_at).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </td>
                                      <td>{plans?.plan_duration}</td>
                                      <td>
                                        {TYPE_OF_WORK[plans?.activity_type_id]}
                                      </td>
                                      <td>
                                        {plans?.status != 0
                                          ? EODSTATUS[plans?.status]
                                          : "Yet To Update"}
                                      </td>
                                    </tr>
                                  );
                              })}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between px-2">
                          <strong>
                            Total count : {planDetails?.rows?.length}
                          </strong>
                          <TablePagination
                            component="div"
                            count={planDetails?.rows?.length}
                            page={planrowsPerPage}
                            onPageChange={planhandleChangePage}
                            rowsPerPage={planlimit}
                            onRowsPerPageChange={planhandleChangeRowsPerPage}
                          />
                        </div>
                        {/* {/* {planDetails &&
                                                        planDetails?.rows?.map((plans) => {
                                                            if (plans?.activity_id == currentActivityId)
                                                                return (
                                                                    <Row className="g-0 mb-2">
                                                                        <Col xs="auto">
                                                                            <div className="sw-3 d-inline-block d-flex justify-content-start align-items-center h-100">
                                                                                <div className="sh-3">
                                                                                    <CsLineIcons
                                                                                        icon="chevron-right"
                                                                                        className="text-secondary align-top"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div className="d-flex flex-column pt-0 pb-0 ps-3 pe-4 h-100 justify-content-center">
                                                                                <div className="d-flex flex-column">
                                                                                    <div className="text-alternate mt-n1 lh-1-25">
                                                                                        {plans?.plan}{" "} ({dayjs(plans.updated_at).format("YYYY/MM/DD")})
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            <div className="d-inline-block d-flex justify-content-end align-items-center h-100">
                                                                                <div className="text-muted ms-2 mt-n1 lh-1-25">
                                                                                    {plans?.plan_duration} hrs
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row> 
                                                                );
                                                        })} */}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={2}>
                      <Accordion.Header className="w=100">
                        <div className="d-flex justify-content-between w-100">
                          <h6 className="">EOD Status for this Activity</h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Comment</th>
                              <th>Planned Date</th>
                              <th>Planned (Hrs)</th>
                              <th>Type of Work</th>
                              <th>Status</th>
                              <th>Attachment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {eodDatas?.data?.map((eod, i) => {
                              if (eod?.activity_id === currentActivityId)
                                return (
                                  <tr>
                                    <td> {eod?.eod_status} </td>
                                    <td>
                                      {dayjs(eod?.updated_at).format(
                                        "YYYY/MM/DD"
                                      )}
                                    </td>
                                    <td>{eod?.actual_duration}</td>
                                    <td>
                                      {TYPE_OF_WORK[eod?.activity_type_id]}
                                    </td>
                                    <td>
                                      {eod?.status != 0
                                        ? EODSTATUS[eod?.status]
                                        : "Yet To Update"}
                                    </td>
                                    <td>
                                      {" "}
                                      <div
                                        className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl"
                                        onClick={() =>
                                          window.open(
                                            `${API_BASE}/${eod?.attachment}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <CsLineIcons
                                          icon="file-text"
                                          className="text-white"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                            })}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between px-2">
                          <strong>
                            Total count : {eodDatas?.data?.length}
                          </strong>
                          <TablePagination
                            component="div"
                            count={eodDatas?.data?.length}
                            page={eodrowsPerPage}
                            onPageChange={eodhandleChangePage}
                            rowsPerPage={eodlimit}
                            onRowsPerPageChange={eodhandleChangeRowsPerPage}
                          />
                        </div>
                        {/* {
                                                        eodDatas?.map((eod, i) => {
                                                            if (eod?.activity_id === currentActivityId)
                                                                return (
                                                                    <Row className="g-0 mb-2">
                                                                        <Col xs="auto">
                                                                            <div className="sw-3 d-inline-block d-flex justify-content-start align-items-center h-100">
                                                                                <div className="sh-3">
                                                                                    <CsLineIcons
                                                                                        icon="chevron-right"
                                                                                        className="text-secondary align-top"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div className="d-flex flex-column pt-0 pb-0 ps-3 pe-4 h-100 justify-content-center">
                                                                                <div className="d-flex flex-column">
                                                                                    <div className="text-alternate mt-n1 lh-1-25">
                                                                                        {eod?.eod_status}{" "} ({dayjs(eod.updated_at).format("YYYY/MM/DD")}) - {EODSTATUS[eod?.status]}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        {
                                                                            eod?.attachments ?

                                                                                <Col md={1}>
                                                                                    <Card className="sh-10 hover-border-primary border-primary mt-3 mb-3" onClick={() => window.open(`${API_BASE}/${eod?.attachments}`, "_blank")}>
                                                                                        <Card.Body className="p-3 text-center align-items-center d-flex flex-column justify-content-between">
                                                                                            <div className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl">
                                                                                                <CsLineIcons
                                                                                                    icon="file-text"
                                                                                                    className="text-white"
                                                                                                />
                                                                                            </div>
                                                                                        </Card.Body>
                                                                                    </Card>
                                                                                </Col>
                                                                                :
                                                                                ""}
                                                                    </Row>
                                                                )
                                                        })
                                                    } */}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={3}>
                      <Accordion.Header className="w=100">
                        <div className="d-flex justify-content-between w-100">
                          <h6 className="">Mentor feedback</h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {feedbackData?.map((feedback) => {
                          console.log(feedback, "feedbackfeedbackfeedback");
                          if (
                            feedback?.sprint_id == qweryId &&
                            currentActivityId == feedback?.activity_id
                          )
                            return (
                              <>
                                <Row>
                                  <Col md="6">
                                    <h2 className="">
                                      Feedback On:{" "}
                                      <strong>
                                        {dayjs(feedback?.updated_at).format(
                                          "YYYY/MM/DD"
                                        )}{" "}
                                      </strong>
                                    </h2>
                                  </Col>
                                  <Col md="6">
                                    <h2 className="">
                                      Overall Rating:{" "}
                                      <strong>
                                        {" "}
                                        {feedback?.overall_rankings}{" "}
                                      </strong>
                                    </h2>
                                  </Col>
                                </Row>
                                <Row className="">
                                  {skillDatas?.rows?.map(
                                    (data, skill_index) => {
                                      let parent =
                                        feedback?.sprint_feedback_rankings;
                                      let skillrank = parent?.find((ele) => {
                                        return ele.skill_id == data.id;
                                      });
                                      console.log(
                                        skillrank,
                                        "feedback status RANTINGS XXX"
                                      );

                                      return (
                                        <>
                                          <Col md="6">
                                            <h2>{data?.name}</h2>
                                            <Stack spacing={1}>
                                              <Rating
                                                name="half-rating"
                                                precision={0.5}
                                                value={
                                                  skillrank
                                                    ? skillrank?.rankings
                                                    : 0
                                                }
                                                disabled
                                              />
                                            </Stack>
                                          </Col>
                                        </>
                                      );
                                    }
                                  )}
                                  <Col md="6">
                                    <h5>
                                      {" "}
                                      <label htmlFor="">Feedback : </label>
                                    </h5>
                                    <div className="mb-3 filled form-group tooltip-end-top">
                                      <CsLineIcons icon="file-text" />
                                      <div className="filled form-group tooltip-end-top mt-3">
                                        {/* <CsLineIcons icon="user" /> */}
                                        <Form.Control
                                          as="textarea"
                                          name="feedback"
                                          placeholder="Feedback"
                                          className="is-invalid"
                                          defaultValue={
                                            feedback?.feedback ?? ""
                                          }
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <hr />
                              </>
                            );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              );
            })}
          </Card>
        </Col>
        <Col>
          <Card className="p-4">
            {courseData?.rows?.map((sprints) => {
              return (
                <>
                  <div className="d-flex justify-content-between w-100  pt-3">
                    <h3>{sprints?.name}</h3>
                    <span> Duration: {sprints?.duration} hrs</span>
                  </div>
                </>
              );
            })}
            <ListGroup>
              {courseData?.rows &&
                courseData?.rows[0]?.sprintactivity?.map((activities, i) => {
                  return (
                    <>
                      <ListGroup.Item
                        key={i}
                        className="p-2"
                        onClick={() => handleActivity(activities?.id)}
                      >
                        <a href={null} className="cursor-pointer">
                          <div className="d-flex justify-content-between w-100">
                            <p>{activities?.name}</p>
                            <p>{activities?.duration} hrs</p>
                          </div>
                        </a>
                      </ListGroup.Item>
                    </>
                  );
                })}
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Modal size="xl" show={feedbackShow} onHide={handleCloseFeedback}>
        <div className="container p-3">
          <Row>
            <Col md={6}>
              <h2 className="text-decoration-underline fw-bolder">
                Activity Feedback
              </h2>
            </Col>
            <Col md={6}>
              <h5>
                <strong>OverAll Rank : {totalStar?.toFixed(2)} </strong>
              </h5>
            </Col>
          </Row>

          <Row>
            {skillDatas?.rows?.map((data, skill_index) => {
              // let parent = feedback?.project_feedback_rankings;
              // let skillrank = parent.find((ele) => { return ele.skill_id == data.id })
              // console.log(skillrank, "RANTINGS")
              return (
                <>
                  <Col md="6">
                    <h2>{data?.name}</h2>
                    <Stack spacing={1}>
                      <Rating
                        name="half-rating"
                        precision={0.5}
                        onChange={(e) => handleRatings(e, data)}
                      />
                    </Stack>
                  </Col>
                </>
              );
            })}
          </Row>
          <Row>
            <div className="mb-3 filled form-group tooltip-end-top">
              <div className="filled form-group tooltip-end-top mt-3">
                <CsLineIcons icon="file-text" />
                {/* <CsLineIcons icon="user" /> */}
                <Form.Control
                  as="textarea"
                  name="feedback"
                  placeholder="Feedback"
                  value={feedbackValue}
                  className="is-invalid"
                  onChange={(e) => {
                    seterrorMsg({ feedbackError: false });
                    setfeedbackValue(e.target.value);
                  }}
                />
                {errorMsg?.feedbackError && (
                  <p className="text-danger">Feedback is required</p>
                )}
              </div>
            </div>
          </Row>
        </div>
        <Row>
          <div className="d-flex justify-content-between mb-1">
            <div className="mx-2">
              <Button onClick={handleCloseFeedback}>Back</Button>
            </div>
            <div>
              <Button
                className="mx-2"
                variant="success"
                onClick={() => handleApprove(2)}
              >
                Approve
              </Button>
              <Button
                className="mx-2"
                variant="danger"
                onClick={() => handleApprove(1)}
              >
                Reject
              </Button>
            </div>
          </div>
        </Row>
      </Modal>
    </div>
  );
};

export default Sprint;
