import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import { getSessions, hiring_partner_dashboard, admin_dashboard } from "../../services/api";

const namespace = "dashboard";

const initialState = {
    loading: "initial",
    dashboarderrorMessage: "",
    hiringpartnerdashboardDatas: [],
    sessionDetails:[],
    admindashboardDatas:[],
    admindashboardLoading: "initial",
    hiringpartnerdashboardLoading: "initial",
    SessionListLoader:"initial",
    count: 0,
};

export const HiringPartnerDashboard = createAsyncThunk(
    `${namespace}/HiringPartnerDashboard`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await hiring_partner_dashboard();
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);
export const AdminDashboards = createAsyncThunk(
    `${namespace}/AdminDashboards`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await admin_dashboard();
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const SessionList = createAsyncThunk(
    `${namespace}/SessionList`,
    async ({page="", limit="", query="", sortby="", order="", guest_speaker_id, domain }, { rejectWithValue, dispatch }) => {
        try {
            console.log(domain,"TESTSDGHHD");
            const { data } = await getSessions(page, limit, query, sortby, order, guest_speaker_id, domain);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


const DashboardSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        cleardashboardData: () => {
            return initialState;
        },
        clearDataLoadings: (state) => {
            state.hiringpartnerdashboardLoading = "initial";
            state.SessionListLoader = "initial"
        }
    },

    extraReducers: (builder) => {
        builder.addCase(HiringPartnerDashboard.pending, (state) => {
            state.hiringpartnerdashboardLoading = API_STATUS.PENDING;
        });
        builder.addCase(HiringPartnerDashboard.fulfilled, (state, { payload }) => {
            state.hiringpartnerdashboardLoading = API_STATUS.FULFILLED;
            state.hiringpartnerdashboardDatas = payload?.data;
            console.log(payload, "DashboardList");
        });
        builder.addCase(HiringPartnerDashboard.rejected, (state, action) => {
            state.hiringpartnerdashboardLoading = API_STATUS.REJECTED;
            console.log(action.payload, "DashboardListError");
            state.dashboarderrorMessage = action?.payload?.data;
        });
        builder.addCase(AdminDashboards.pending, (state) => {
            state.admindashboardLoading = API_STATUS.PENDING;
        });
        builder.addCase(AdminDashboards.fulfilled, (state, { payload }) => {
            state.admindashboardLoading = API_STATUS.FULFILLED;
            state.admindashboardDatas = payload?.data;
            console.log(payload, "DashboardList");
        });
        builder.addCase(AdminDashboards.rejected, (state, action) => {
            state.admindashboardLoading = API_STATUS.REJECTED;
            console.log(action.payload, "DashboardListError");
            state.dashboarderrorMessage = action?.payload?.data;
        });
        builder.addCase(SessionList.pending, (state) => {
            state.SessionListLoader = API_STATUS.PENDING;
        });
        builder.addCase(SessionList.fulfilled, (state, { payload }) => {
            state.SessionListLoader = API_STATUS.FULFILLED;
            state.sessionDetails = payload?.rows;
            console.log(payload, "DashboardList");
        });
        builder.addCase(SessionList.rejected, (state, action) => {
            state.SessionListLoader = API_STATUS.REJECTED;
            console.log(action.payload, "DashboardListError");
            state.dashboarderrorMessage = action?.payload?.data;
        });
    },
});

export const { cleardashboardData, clearDataLoadings } = DashboardSlice.actions;

export const DashboardSelector = (state) => state.dashboard;

export default DashboardSlice.reducer;
