import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  admin_student_list,
  admin_student_career,
  assignstudent_hp,
  updatejobstatus,
  guestspeaker_list,
  guestspeaker_add,
  guest_speaker_by_id,
  update_guest_speaker,
  approve_student_sprint,
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "admin";

const initialState = {
  loading: "initial",
  admin_errorMessage: "",
  adminStudentListData: [],
  guestSpeakerListData: [],
  guestspeakerDatas: [],
  admin_student_List_Loading: "initial",
  admin_student_approve_Loading: "initial",
  student_to_hp_loading: "initial",
  updateJobStatusLoad: "initial",
  guestspeakerListLoading: "initial",
  guestSpeakerAddLoading: "initial",
  guestspeakerLoading: "initial",
  update_guestspeaker_Loading: "initial",
  approvestudentLoading: "initial",
};

export const AdminStudentList = createAsyncThunk(
  `${namespace}/AdminStudentList`,
  async (
    { page, limit, query, order, college_id, domain },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await admin_student_list(
        page,
        limit,
        query,
        order,
        college_id,
        domain
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const approveStudentCareer = createAsyncThunk(
  `${namespace}/approveStudentCareer`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await admin_student_career(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const assignstudentTohiringpartner = createAsyncThunk(
  `${namespace}/assignstudentTohiringpartner`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await assignstudent_hp(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateJobStatusScheme = createAsyncThunk(
  `${namespace}/updateJobStatusScheme`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await updatejobstatus(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const GuestSpeakerListScheme = createAsyncThunk(
  `${namespace}/GuestSpeakerListScheme`,
  async (
    { page, limit, query, order, domain, company_details },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await guestspeaker_list(
        page,
        limit,
        query,
        order,
        domain,
        company_details
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const guestSpeakerAdd = createAsyncThunk(
  `${namespace}/guestSpeakerAdd`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await guestspeaker_add(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const Get_Guest_Speaker_ByID = createAsyncThunk(
  `${namespace}/Get_Guest_Speaker_ByID`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      console.log("Get_Guest_Speaker_ByID");
      const { data } = await guest_speaker_by_id(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const UpdateGuestSpeaker = createAsyncThunk(
  `${namespace}/UpdateGuestSpeaker`,
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "UpdateGuestSpeaker");
      const { data } = await update_guest_speaker(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const approveStudentSprint = createAsyncThunk(
  `${namespace}/approveStudentSprint`,
  async ({ datas }, { rejectWithValue, dispatch }) => {
    try {
      console.log(datas, "approveStudentSprint");
      const { data } = await approve_student_sprint(datas);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const AdminSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearadminAllData: () => {
      return initialState;
    },
    clearAdminData: (state) => {
      state.admin_student_List_Loading = "initial";
      state.admin_student_approve_Loading = "initial";
      state.student_to_hp_loading = "initial";
      state.updateJobStatusLoad = "initial";
      state.guestSpeakerAddLoading = "initial";
      state.approvestudentLoading = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(AdminStudentList.pending, (state) => {
      state.admin_student_List_Loading = API_STATUS.PENDING;
    });
    builder.addCase(AdminStudentList.fulfilled, (state, { payload }) => {
      state.admin_student_List_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.adminStudentListData = payloaddata;
      console.log(payloaddata, "STUDENTS");
    });
    builder.addCase(AdminStudentList.rejected, (state, action) => {
      state.admin_student_List_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.admin_errorMessage = action?.payload?.data;
    });
    builder.addCase(approveStudentCareer.pending, (state) => {
      state.admin_student_approve_Loading = API_STATUS.PENDING;
    });
    builder.addCase(approveStudentCareer.fulfilled, (state, { payload }) => {
      state.admin_student_approve_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(approveStudentCareer.rejected, (state, action) => {
      state.admin_student_approve_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.admin_errorMessage = action?.payload?.data;
    });
    builder.addCase(assignstudentTohiringpartner.pending, (state) => {
      state.student_to_hp_loading = API_STATUS.PENDING;
    });
    builder.addCase(
      assignstudentTohiringpartner.fulfilled,
      (state, { payload }) => {
        state.student_to_hp_loading = API_STATUS.FULFILLED;
      }
    );
    builder.addCase(assignstudentTohiringpartner.rejected, (state, action) => {
      state.student_to_hp_loading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.admin_errorMessage = action?.payload?.data;
    });

    builder.addCase(updateJobStatusScheme.pending, (state) => {
      state.updateJobStatusLoad = API_STATUS.PENDING;
    });
    builder.addCase(updateJobStatusScheme.fulfilled, (state, { payload }) => {
      state.updateJobStatusLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(updateJobStatusScheme.rejected, (state, action) => {
      state.updateJobStatusLoad = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.admin_errorMessage = action?.payload?.data;
    });

    builder.addCase(GuestSpeakerListScheme.pending, (state) => {
      state.guestspeakerListLoading = API_STATUS.PENDING;
    });
    builder.addCase(GuestSpeakerListScheme.fulfilled, (state, { payload }) => {
      state.guestspeakerListLoading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.guestSpeakerListData = payloaddata;
      console.log(state.guestSpeakerListData, "sC_MentorDataList");
    });
    builder.addCase(GuestSpeakerListScheme.rejected, (state, action) => {
      state.guestspeakerListLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.admin_errorMessage = action?.payload?.data;
    });
    builder.addCase(guestSpeakerAdd.pending, (state) => {
      state.guestSpeakerAddLoading = API_STATUS.PENDING;
    });
    builder.addCase(guestSpeakerAdd.fulfilled, (state, { payload }) => {
      state.guestSpeakerAddLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(guestSpeakerAdd.rejected, (state, action) => {
      state.guestSpeakerAddLoading = API_STATUS.REJECTED;
      console.log(action.payload, "GuestSpeakerAdd");
      state.admin_errorMessage = action?.payload?.data;
    });
    builder.addCase(Get_Guest_Speaker_ByID.pending, (state) => {
      state.guestspeakerLoading = API_STATUS.PENDING;
    });
    builder.addCase(Get_Guest_Speaker_ByID.fulfilled, (state, { payload }) => {
      state.guestspeakerLoading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.guestspeakerDatas = payloaddata?.data;
      console.log(payloaddata, "Get_Guest_Speaker_ByID");
    });
    builder.addCase(Get_Guest_Speaker_ByID.rejected, (state, action) => {
      state.guestspeakerLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.admin_errorMessage = action?.payload?.data;
    });
    builder.addCase(UpdateGuestSpeaker.pending, (state) => {
      state.update_guestspeaker_Loading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateGuestSpeaker.fulfilled, (state, { payload }) => {
      state.update_guestspeaker_Loading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateGuestSpeaker.rejected, (state, action) => {
      state.update_guestspeaker_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "UpdateGuestSpeaker");
      state.admin_errorMessage = action?.payload?.data;
    });
    builder.addCase(approveStudentSprint.pending, (state) => {
      state.approvestudentLoading = API_STATUS.PENDING;
    });
    builder.addCase(approveStudentSprint.fulfilled, (state, { payload }) => {
      state.approvestudentLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(approveStudentSprint.rejected, (state, action) => {
      state.approvestudentLoading = API_STATUS.REJECTED;
      console.log(action.payload, "approveStudentSprint");
      state.admin_errorMessage = action?.payload?.data;
    });
  },
});

export const { clearadminAllData, clearAdminData } = AdminSlice.actions;

export const AdminSelector = (state) => state.admin;

export default AdminSlice.reducer;
