import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_SETTINGS } from "../../utils/config.js";
import {
  getPlacementList,
  getSkills,
  getsettings,
} from "../../services/api.js";
import { API_STATUS } from "../../utils/Constants.js";

const getComputedValues = () => {
  const rootStyle = window.getComputedStyle(document.body);
  return {
    primary: rootStyle.getPropertyValue("--primary").trim(),
    secondary: rootStyle.getPropertyValue("--secondary").trim(),
    tertiary: rootStyle.getPropertyValue("--tertiary").trim(),
    quaternary: rootStyle.getPropertyValue("--quaternary").trim(),
    body: rootStyle.getPropertyValue("--body").trim(),
    alternate: rootStyle.getPropertyValue("--alternate").trim(),
    lightText: rootStyle.getPropertyValue("--light-text").trim(),
    warning: rootStyle.getPropertyValue("--warning").trim(),
    danger: rootStyle.getPropertyValue("--danger").trim(),
    success: rootStyle.getPropertyValue("--success").trim(),
    info: rootStyle.getPropertyValue("--info").trim(),

    font: rootStyle.getPropertyValue("--font").trim(),
    fontHeading: rootStyle.getPropertyValue("--font-heading").trim(),

    background: rootStyle.getPropertyValue("--background").trim(),
    foreground: rootStyle.getPropertyValue("--foreground").trim(),
    separator: rootStyle.getPropertyValue("--separator").trim(),
    separatorLight: rootStyle.getPropertyValue("--separator-light").trim(),

    primaryrgb: rootStyle.getPropertyValue("--primary-rgb").trim(),
    secondaryrgb: rootStyle.getPropertyValue("--secondary-rgb").trim(),
    tertiaryrgb: rootStyle.getPropertyValue("--tertiary-rgb").trim(),
    quaternaryrgb: rootStyle.getPropertyValue("--quaternary-rgb").trim(),

    transitionTimeShort: rootStyle
      .getPropertyValue("--transition-time-short")
      .trim()
      .replace("ms", ""),
    transitionTime: rootStyle
      .getPropertyValue("--transition-time")
      .trim()
      .replace("ms", ""),
    navSizeSlim: rootStyle.getPropertyValue("--nav-size-slim").trim(),

    borderRadiusXl: rootStyle.getPropertyValue("--border-radius-xl").trim(),
    borderRadiusLg: rootStyle.getPropertyValue("--border-radius-lg").trim(),
    borderRadiusMd: rootStyle.getPropertyValue("--border-radius-md").trim(),
    borderRadiusSm: rootStyle.getPropertyValue("--border-radius-sm").trim(),
    spacingHorizontal: rootStyle
      .getPropertyValue("--main-spacing-horizontal")
      .trim(),

    sm: rootStyle.getPropertyValue("--sm").trim(),
    md: rootStyle.getPropertyValue("--md").trim(),
    lg: rootStyle.getPropertyValue("--lg").trim(),
    xl: rootStyle.getPropertyValue("--xl").trim(),
    xxl: rootStyle.getPropertyValue("--xxl").trim(),
    direction: "ltr",
  };
};
const wait = (timeToDelay) =>
  new Promise((resolve) => setTimeout(resolve, timeToDelay));
const namespace = "settingsSlice";

const initialState = {
  color: DEFAULT_SETTINGS.COLOR,
  layout: DEFAULT_SETTINGS.LAYOUT,
  radius: DEFAULT_SETTINGS.RADIUS,
  navColor: DEFAULT_SETTINGS.NAV_COLOR,
  themeValues: getComputedValues(),
  skillsLoading: "initial",
  SettingsLoad: "initial",
  placementListLoad: "initial",
  skillDatas: [],
  settingData: [],
  placementData: [],
};

export const SkillList = createAsyncThunk(
  `${namespace}/SkillList`,
  async (
    { page, limit, query, sortby, order },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("SkillList");
      const { data } = await getSkills(page, limit, query, sortby, order);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const placementList = createAsyncThunk(
  `${namespace}/placementList`,
  async (
    { page, limit, query, sortby, order },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("placementList");
      const { data } = await getPlacementList(
        page,
        limit,
        query,
        sortby,
        order
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const Settings = createAsyncThunk(
  `${namespace}/Settings`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getsettings();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setColor(state, action) {
      state.color = action.payload;
    },
    setThemeValues(state) {
      state.themeValues = getComputedValues();
    },
    settingsChangeRadius(state, action) {
      state.radius = action.payload;
    },
    settingsChangeNavColor(state, action) {
      state.navColor = action.payload;
    },
    settingsChangeLayout(state, action) {
      state.layout = action.payload;
    },
    settingsResetLayout(state) {
      state.layout = DEFAULT_SETTINGS.LAYOUT;
    },
    clearSettingLoaders: (state) => {
      state.skillsLoading = "initial";
      state.SettingsLoad = "initial";
      state.placementListLoad = "initial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(SkillList.pending, (state) => {
      state.skillsLoading = API_STATUS.PENDING;
    });
    builder.addCase(SkillList.fulfilled, (state, { payload }) => {
      state.skillsLoading = API_STATUS.FULFILLED;
      state.skillDatas = payload?.datas;
      console.log(state.skillDatas, "SKILSDFASDF");
    });
    builder.addCase(SkillList.rejected, (state, action) => {
      state.skillsLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(Settings.pending, (state) => {
      state.SettingsLoad = API_STATUS.PENDING;
    });
    builder.addCase(Settings.fulfilled, (state, { payload }) => {
      state.SettingsLoad = API_STATUS.FULFILLED;
      state.settingData = payload?.data;
    });
    builder.addCase(Settings.rejected, (state, action) => {
      state.SettingsLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(placementList.pending, (state) => {
      state.placementListLoad = API_STATUS.PENDING;
    });
    builder.addCase(placementList.fulfilled, (state, { payload }) => {
      state.placementListLoad = API_STATUS.FULFILLED;
      console.log(payload?.datas,'kdfgaduifi')
      state.placementData = payload?.datas;
    });
    builder.addCase(placementList.rejected, (state, action) => {
      state.placementListLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});
export const { setColor, setThemeValues } = settingsSlice.actions;

export const settingsChangeColor = (color) => async (dispatch) => {
  dispatch(setColor(color));
  await wait(10);
  dispatch(setThemeValues());
};
export const {
  settingsChangeRadius,
  settingsChangeNavColor,
  clearSettingLoaders,
  settingsChangeLayout,
  settingsResetLayout,
} = settingsSlice.actions;
const settingsReducer = settingsSlice.reducer;
export const settingSelector = (state) => state.settings;

export default settingsReducer;
