import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { clearAdminData, AdminSelector, Get_Guest_Speaker_ByID, guestSpeakerAdd, UpdateGuestSpeaker } from "../../store/reducer/AdminReducer"
import { departmentLists, departmentUpdates, placementSelector, testLists } from '../../store/reducer/PlacementReducer';
import { localFrontend, FRONTEND_BASE } from "../../services/config";
import { LoadingButton } from "@mui/lab";

const GuestSpeakerManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const title = id ? "Guest Speaker Details Edit" : "Guest Speaker Details Add";
    const [validated, setValidated] = useState(false);
    const [Loginerror, setLoginerror] = useState(false);
    const [departmentID, setDepartmentID] = useState();
    const [sessionDateValue, setsessionDateValue] = useState();
    const [filePI, setfilePI] = useState({});
    const [isClicked, setIsClicked] = useState(false)
    const [errorMsg, seterrorMsg] = useState({
        domainerror: false,
        dateerror: false,
    });

    useEffect(() => {
        let departmentQuery = {};
        departmentQuery.page = 0;
        departmentQuery.limit = 0;
        departmentQuery.query = "";        
        dispatch(departmentLists(departmentQuery ));
    }, [])

    const { departmentData } = useSelector(placementSelector);
    const { guestSpeakerAddLoading, guestspeakerDatas, admin_errorMessage, update_guestspeaker_Loading } = useSelector(AdminSelector);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Guest Speaker Name is required"),
        designation: Yup.string().required("Designation is required"),
        about_speaker: Yup.string().required("About Speaker is required"),
        topic: Yup.string().required("Topic is required"),
        topic_description: Yup.string().required("Topic Description is required"),
        meeting_link: Yup.string().required("Meeting Link is required"),
    })

    const [initialValues, setInitialValues] = useState({
        name: "", designation: "", about_speaker: "", company_details: "", topic: "", topic_description: "", meeting_link: ""
    })

    const onSubmit = (formData) => {
        if ((!departmentID) || (!sessionDateValue)) {
            setValidated(false)
        } else {
            formData.domain = departmentID;
            formData.date_time = sessionDateValue;
            formData.profile_image = filePI;
            if (id) {
                dispatch(UpdateGuestSpeaker({ id, formData }))
            } else {
                dispatch(guestSpeakerAdd({ formData }));
            }
        }
    };
    const handleForm = (event) => {
        const { files } = event.target;
        setfilePI(files[0])
    };
    const formik = useFormik({ initialValues, validationSchema, onSubmit });
    const { handleSubmit, handleChange, values, touched, errors } = formik;

    useEffect(() => {
        if (id)
            dispatch(Get_Guest_Speaker_ByID({ id: id }))
    }, [id]);
    useEffect(() => {
        if (id && guestspeakerDatas !== "") {
            formik.setValues({
                name: guestspeakerDatas?.name,
                designation: guestspeakerDatas?.designation,
                about_speaker: guestspeakerDatas?.about_speaker,
                company_details: guestspeakerDatas?.company_details,
                topic: guestspeakerDatas?.topic,
                topic_description: guestspeakerDatas?.topic_description,
                meeting_link: guestspeakerDatas?.meeting_link,
            })
            setDepartmentID(guestspeakerDatas?.domain)
            setfilePI(guestspeakerDatas?.profile_image)
            if (guestspeakerDatas?.date_time)
                setsessionDateValue(new Date(guestspeakerDatas?.date_time))
            else {
                setsessionDateValue('')
            }
        }

        // dispatch(clearsprintAllData())
    }, [guestspeakerDatas])

    useEffect(() => {
        if (guestSpeakerAddLoading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: "Guest Speaker Added Successfully!",
                icon: 'success'
            });
            dispatch(clearAdminData());
            navigate("/admin/guest_speaker/");
        }
        if (update_guestspeaker_Loading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: "Guest Speaker Updated Successfully!",
                icon: 'success'
            });
            dispatch(clearAdminData());
            navigate("/admin/guest_speaker/");
        }
        if (((guestSpeakerAddLoading === API_STATUS.REJECTED) || (update_guestspeaker_Loading === API_STATUS.REJECTED)) && admin_errorMessage != null) {
            if (admin_errorMessage.message) Swal.fire({
                title: admin_errorMessage.message,
                icon: 'error'
            });
            else if (admin_errorMessage.errors) {
                let data = "";
                admin_errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                Swal.fire({
                    title: data,
                    icon: 'error'
                })
            }
            dispatch(clearAdminData());
        }
    }, [guestSpeakerAddLoading, admin_errorMessage, update_guestspeaker_Loading]);

    return (
        <>
            <Row>
                <Col>
                    {/* Title Start */}
                    <section className="scroll-section" id="title">
                        <div className="page-title-container">
                            <h1 className="mb-0 pb-0 display-4">{title}</h1>
                            <Breadcrumb className="breadcrumb-container d-inline-block">
                                <Breadcrumb.Item key={`breadCrumb1`}>
                                </Breadcrumb.Item>
                            </Breadcrumb>

                        </div>
                        <Link to="/admin/guest_speaker">
                            <div className="text-end mb-2">
                                <Button variant='success' type='submit'>Back</Button>
                            </div>
                        </Link>
                    </section>
                    {/* Title End */}

                    {/* Standard Start */}
                    <section className="scroll-section" id="standard">
                        {/* <h2 className="small-title">Standard</h2> */}
                        <Card body className="mb-5">
                            {/* List Header Start */}
                            <form
                                id="guestSpeakerAdd"
                                className={
                                    validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                                }
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setValidated(true);
                                    handleSubmit();
                                }}
                            >
                                <div className="mb-3 filled form-group tooltip-end-top">
                                    {Loginerror && Loginerror.error && (
                                        <Form.Control.Feedback
                                            type="invalid"
                                            style={{ display: "block" }}
                                        >
                                            {Loginerror.error}
                                        </Form.Control.Feedback>
                                    )}
                                </div>
                                <Row>

                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file" />
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder="Guest Speaker Name"
                                                value={values.name}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.name && touched.name && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file-text" />
                                            <Form.Control
                                                as="select"
                                                onChange={(e) => {
                                                    setDepartmentID(e.target.value);
                                                }}
                                                value={departmentID}
                                                className="is-invalid"
                                                placeholder="Domain">
                                                {
                                                    !isClicked ? <option>Select Domain</option> : ""
                                                }
                                                {departmentData &&
                                                    departmentData?.rows?.map((fields) => {
                                                        return (
                                                            <option value={fields.id}>{fields.name}</option>
                                                        );
                                                    })}
                                            </Form.Control>
                                            {errorMsg?.domainerror &&
                                                (
                                                    <p className='text-danger'>Domain is required</p>
                                                )}
                                        </div>
                                    </Col>


                                </Row>
                                <Row className="g-3 mt-3">
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="time" />
                                            <Form.Control
                                                type="text"
                                                name="designation"
                                                onChange={handleChange}
                                                value={values.designation}
                                                className="is-invalid"
                                                placeholder="Designation"
                                            />
                                            {errors.designation && touched.designation && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.designation}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="time" />
                                            <Form.Control
                                                type="text"
                                                name="company_details"
                                                onChange={handleChange}
                                                value={values.company_details}
                                                className="is-invalid"
                                                placeholder="Company Details"
                                            />
                                            {errors.company_details && touched.company_details && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.company_details}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-3 mt-2">
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="time" />
                                            <Form.Control
                                                type="text"
                                                name="topic"
                                                onChange={handleChange}
                                                value={values.topic}
                                                className="is-invalid"
                                                placeholder="Topic"
                                            />
                                            {errors.topic && touched.topic && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.topic}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="time" />
                                            <Form.Control
                                                as="textarea"
                                                name="topic_description"
                                                placeholder="Topic Description"
                                                value={values.topic_description}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.topic_description && touched.topic_description && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.topic_description}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-3 mt-2">
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <Form.Group controlId="formDate">
                                                <DatePicker
                                                    selected={sessionDateValue}
                                                    className="form-control"
                                                    onChange={(date) => setsessionDateValue(date)}
                                                    timeInputLabel="Time:"
                                                    dateFormat="dd/MM/yyyy H:mm"
                                                    showTimeInput
                                                    name="session_date"
                                                    minDate={new Date()}
                                                    placeholderText='Session Date & Time'
                                                />
                                            </Form.Group>
                                            {errorMsg?.dateerror &&
                                                (
                                                    <p className='text-danger'>Date and Time is required</p>
                                                )}                                    </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="filled form-group tooltip-end-top">
                                            <CsLineIcons icon="time" />
                                            <Form.Control
                                                type="text"
                                                name="meeting_link"
                                                onChange={handleChange}
                                                value={values.meeting_link}
                                                className="is-invalid"
                                                placeholder="Meeting Link"
                                            />
                                            {errors.meeting_link && touched.meeting_link && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.meeting_link}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-3 mt-2">
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file" />
                                            <Form.Control
                                                type="file"
                                                name="profile_image"
                                                onChange={(e) => handleForm(e)}
                                                placeholder="Profile Image"
                                                values={filePI}
                                            />
                                            {errors.profile_image && touched.profile_image && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.profile_image}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="filled form-group tooltip-end-top">
                                            {/* <CsLineIcons icon="user" /> */}
                                            <Form.Control
                                                as="textarea"
                                                name="about_speaker"
                                                placeholder="About Speaker"
                                                value={values.about_speaker}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.about_speaker && touched.about_speaker && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.about_speaker}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-end mt-2">

                                    <LoadingButton variant='contained' loading={![API_STATUS.FULFILLED, 'initial'].includes((guestSpeakerAddLoading || update_guestspeaker_Loading))} type="submit" onClick={() => {
                                        if ((!departmentID) || (!sessionDateValue)) {
                                            seterrorMsg({
                                                domainerror: !departmentID,
                                                dateerror: !sessionDateValue
                                            });
                                            setValidated(false)
                                        }
                                    }}>
                                        {id ? "Update" : "Create"}
                                    </LoadingButton>
                                </div>
                            </form>
                        </Card>
                    </section>

                    {/* Positions End */}
                </Col>
            </Row >
        </>
    );
};

export default GuestSpeakerManagement;
