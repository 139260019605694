import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CsLineIcons from '../cs-line-icons/CsLineIcons';
import { forgotPassword, updateforgotPassword, signInSelector, clearData } from '../../store/reducer/SignInReducer';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Swal from 'sweetalert2'
import { API_STATUS } from "../../utils/Constants";

const UpdateForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { email } = useParams();
    const [validated, setValidated] = useState(false);
    const [Loginerror, setLoginerror] = useState(false);
    const { signInData, signInLoading, forgotPasswordLoading, errorMessage } =
        useSelector(signInSelector);

    useEffect(() => {
        document.body.classList.add('h-100');
        const root = document.getElementById('root');
        if (root) {
            root.classList.add('h-100');
        }
        return () => {
            document.body.classList.remove('h-100');
            if (root) {
                root.classList.remove('h-100');
            }
        };
    }, []);

    const title = 'Update Forgot Password';
    const description = 'Update Forgot Password Page';

    const getCharacterValidationError = (str) => {
        return `Your password must have at least 1 ${str} character`;
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().string().required("Password is required").min(8, "Password must have at least 8 characters")
            .matches(/[0-9]/, getCharacterValidationError("digit"))
            .matches(/[a-z]/, getCharacterValidationError("lowercase"))
            .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
            .matches(/[@$!%*?&]/, getCharacterValidationError("special")),
        confirm_password: Yup.string().required("Confirm Password is required")
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
    const initialValues = { password: "", confirm_password: "" };
    const onSubmit = (formData) => {
        formData.email = email;
        dispatch(updateforgotPassword({ formData }));
    };

    const formik = useFormik({ initialValues, validationSchema, onSubmit });
    const { handleSubmit, handleChange, values, touched, errors } = formik;

    useEffect(() => {
        console.log(forgotPasswordLoading, "forgotPasswordLoading");
        if (forgotPasswordLoading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: 'Password Updated Successfully!',
                icon: 'success'
            });
            dispatch(clearData())
            navigate('/');
        }
        if (forgotPasswordLoading === API_STATUS.REJECTED && errorMessage) {
            Swal.fire({
                title: errorMessage.error,
                icon: 'error'
            });
            setLoginerror(errorMessage.error);
            dispatch(clearData())
        }
    }, [forgotPasswordLoading, errorMessage]);

    return (
        <>
            {/* Background Start */}
            <div className="fixed-background" />
            {/* Background End */}

            <div className="container-fluid p-0 h-100 position-relative login_pages">
                <div className="row g-0 h-100">
                    {/* Left Side Start */}
                    <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
                        <div className="min-h-100 d-flex align-items-center">
                            <div className="w-100 w-lg-75 w-xxl-50">
                                <div>
                                    <div className="mb-5">
                                        <h1 className="display-3 text-white">Multiple Niches</h1>
                                        <h1 className="display-3 text-white">
                                            Ready for Your Project
                                        </h1>
                                    </div>
                                    <p className="h6 text-white lh-1-5 mb-5">
                                        Dynamically target high-payoff intellectual capital for
                                        customized technologies. Objectively integrate emerging core
                                        competencies before process-centric communities...
                                    </p>
                                    <div className="mb-5">
                                        <Button size="lg" variant="outline-white" href="/">
                                            Learn More
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Left Side End */}

                    {/* Right Side Start */}
                    <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                        <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
                            <div className="sw-lg-50 px-5">
                                <div className="sh-11">
                                    <NavLink to="/">
                                        <div className="logo-default" />
                                    </NavLink>
                                </div>
                                <div className="mb-5">
                                    <h2 className="cta-1 mb-0 text-primary">Password trouble?</h2>
                                    <h2 className="cta-1 text-primary">Renew it here!</h2>
                                </div>
                                <div className="mb-5">
                                    <p className="h6">Please use below form to reset your password.</p>
                                    <p className="h6">
                                        If you are a member, please <NavLink to="/login">login</NavLink>.
                                    </p>
                                </div>
                                <div>
                                    <form id="loginForm" className={validated ? "tooltip-end-bottom" : "tooltip-end-bottom "}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            setValidated(true);
                                            handleSubmit();
                                        }}>
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            {Loginerror && Loginerror.error && (
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    style={{ display: "block" }}
                                                >
                                                    {Loginerror.error}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="email" />
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                value={values.email}
                                                className="is-invalid"
                                                disabled
                                                defaultValue={email}
                                            />
                                        </div>
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="lock-off" />
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder="New Password"
                                                value={values.password}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.password && touched.password && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="lock-off" />
                                            <Form.Control
                                                type="password"
                                                name="confirm_password"
                                                placeholder="New Confirm Password"
                                                value={values.confirm_password}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.confirm_password && touched.confirm_password && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.confirm_password}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                        <Button size="lg" type="submit">
                                            Confirm
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Right Side End */}
                </div>
            </div>
        </>
    );
};

export default UpdateForgotPassword;