import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Row,
  Modal,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API_STATUS, COURSE_STATUS } from "../utils/Constants";
import {
  StudentById,
  StudentSelector,
  fitmentProjectScheduler,
  MentorProjectFeedback,
  OverallFeedback,
} from "../store/reducer/StudentReducer";
import { Rating, Stack } from "@mui/material";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import {
  CreateFeedback,
  MentorSelector,
  clearmentorData,
} from "../store/reducer/MentorReducer";
import settingsReducer, {
  SkillList,
  settingSelector,
} from "../store/reducer/settingsSlice";
import {
  hiringmentorSelector,
  CreateCCFeedback,
  getMentor,
  clearData,
} from "../store/reducer/HiringMentorReducer";
import Swal from "sweetalert2";

const FitmentProjectsProgress = () => {
  const name = localStorage.getItem("username");
  const role = localStorage.getItem("role");
  const [course, setCourse] = useState({});
  const [isNew, setIsNew] = useState();
  const [navSprint, setNavSprint] = useState();
  const [feedback_show, setFeedbackShow] = useState();
  const [feedbackValue, setfeedbackValue] = useState("");
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("");
  const [sort, setSort] = useState("");
  const [query, setQuery] = useState("");
  const [rankingsValue, setRankingsValue] = useState({});
  const dispatch = useDispatch();
  const [errorMsg, seterrorMsg] = useState({
    feedbackError: false,
  });
  const [activityValues, setactivityValues] = useState();

  const navigate = useNavigate();
  const {
    studentDetailsDatas,
    fitmentData,
    mentorProjectFeedback,
    overallFeedbackData,
  } = useSelector(StudentSelector);
  const { skillDatas } = useSelector(settingSelector);
  const { student_id } = useParams();
  const { mentorFeedbackLoader } = useSelector(MentorSelector);
  const { mentorCCLoading, hiringMentorProfileDatas } =
    useSelector(hiringmentorSelector);
  useEffect(() => {
    dispatch(getMentor({}));
  }, []);
  useEffect(() => {
    const query =
      studentDetailsDatas?.hiring_partner_student &&
      studentDetailsDatas?.hiring_partner_student[0];
    console.log(studentDetailsDatas, "MENTORIDS");
    let payload = {
      mentor_id: query?.mentor_id,
      student_id: student_id,
      project_id: "",
    };
    dispatch(fitmentProjectScheduler({ payload }));
    dispatch(
      MentorProjectFeedback({
        page,
        limit,
        query: "",
        sortby: sort,
        order,
        status: "",
        student_id: studentDetailsDatas?.id,
        project_id: "",
        date: "",
      })
    );
    dispatch(
      SkillList({
        page,
        limit,
        query: studentDetailsDatas?.department,
        sortby: sort ?? "",
        order: order ?? "",
      })
    );
    dispatch(
      OverallFeedback({
        page,
        limit,
        query: "",
        sortby: sort,
        order,
        status: "",
        student_id: studentDetailsDatas?.id,
      })
    );
    console.log(studentDetailsDatas, "studentDetailsDatas");
  }, [studentDetailsDatas]);

  useEffect(() => {
    console.log(overallFeedbackData, "overallFeedbackData?");
  }, [overallFeedbackData]);

  useEffect(() => {
    let studentParam = {};
    studentParam.student_id = student_id;
    dispatch(StudentById({ studentParam }));
  }, []);

  const handleSprintNavigate = (projectId, mentorId) => {
    // let payload = {
    //     mentor_id: mentorId,
    //     student_id: studentDetailsDatas?.studentData?.id,
    //     project_id: projectId
    // }
    // dispatch(fitmentProjectScheduler({ payload }))
    navigate(`${projectId}`);
  };

  const CultureCatalystFeedback = () => {
    setFeedbackShow(true);
  };
  const MentorUpdate = (type) => {
    if (feedbackValue == "") {
      seterrorMsg({
        feedbackError: true,
      });
    } else {
      let formData = {};
      formData.feedback = feedbackValue;
      formData.student_id = studentDetailsDatas.id;
      formData.domain = studentDetailsDatas.department;
      formData.acceptance_status = type;
      formData.hiring_partner_id = hiringMentorProfileDatas?.company_id;
      formData.overall_rankings = overallFeedbackData?.overall_rankings;
      formData.rankings = overallFeedbackData?.rankings_by_id;
      console.log(formData, "DSFASOFD");
      dispatch(CreateCCFeedback({ formData }));
    }
  };
  useEffect(() => {
    if (mentorCCLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Feedback Submitted Successfully",
        icon: "success",
      });
      setFeedbackShow(false);
      dispatch(clearData());
    }
  }, [mentorCCLoading]);

  return (
    <div className="container-fluid mt-4">
      <div>
        <Row>
          <Col md={8}></Col>
          <Col md={4}>
            <div className="d-flex w-100 justify-content-end">
              <Button
                className="mb-3 mx-1"
                variant="success"
                onClick={(e) => {
                  role == 1
                    ? navigate("/admin/student_list")
                    : role == 2
                    ? navigate("/mentor/students")
                    : navigate("/student/list");
                }}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <Card>
          <div className="container">
            <Row className="mt-3">
              <Col md={6} className="pe-0 ps-5">
                <h1>
                  <label htmlFor="">
                    Culture Catalyst Name:{" "}
                    <strong>{studentDetailsDatas?.name}</strong>
                  </label>
                </h1>
              </Col>
              {role == 2 ? (
                <Col md={6} className="text-end pe-5 ps-0">
                  <Button
                    className="mx-1"
                    variant="warning"
                    onClick={(e) => {
                      CultureCatalystFeedback();
                    }}
                  >
                    Accept / Reject
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </Row>
            <Row className="">
              <Col md={8} className="pe-0 ps-5">
                <h1>
                  <strong>{course?.name}</strong>
                </h1>
              </Col>
              <Col md={4} className="text-end pe-5 ps-0"></Col>
            </Row>
            <div className="container-fluid">
              <hr />
              <h3>
                <strong>Total Projects({fitmentData?.data?.length})</strong>
              </h3>
              <div className="p-3">
                <Accordion defaultActiveKey="0">
                  {fitmentData?.data?.map((sprints, i) => {
                    const sprintStatus = "Show";
                    return (
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header className="w=100">
                          <div className="d-flex justify-content-between w-100">
                            <div>
                              <h6>{sprints?.project_fitment?.title}</h6>
                            </div>
                            <div className="d-flex align-items-center">
                              <Button
                                className="me-5"
                                onClick={() => {
                                  handleSprintNavigate(
                                    sprints?.project_fitment?.id,
                                    sprints?.mentor_id
                                  );
                                }}
                              >
                                <div style={{ fontSize: "15px" }}>
                                  {sprintStatus}
                                </div>
                              </Button>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <p>{sprints?.project_fitment?.description}</p>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </Card>
        {/* Culture Catalyst Feedback */}
        <Modal
          show={feedback_show}
          id="settings"
          dialogClassName="modal-100w"
          aria-labelledby="settings"
          tabIndex="-1"
          scrollable
          size="xl"
        >
          <div className="p-3 mt-2" style={{ overflow: "auto" }}>
            <>
              <h1>Culture Catalyst Feedback</h1>
              <Row>
                <Col md="6">
                  <h2>
                    <label htmlFor="">
                      Culture Catalyst Name:{" "}
                      <strong>{studentDetailsDatas?.name}</strong>
                    </label>
                  </h2>
                </Col>
                <Col md="6">
                  <h2>
                    <label htmlFor="">
                      Domain Name:{" "}
                      <strong>{studentDetailsDatas?.departments?.name}</strong>
                    </label>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col md="6"></Col>
              </Row>
              <Row className="">
                {skillDatas?.rows?.map((data, skill_index) => {
                  return (
                    <>
                      <Col md="6">
                        <h2>{data?.name}</h2>
                        <Stack spacing={1}>
                          <Rating
                            name="half-rating"
                            precision={0.5}
                            value={
                              overallFeedbackData?.rankings_by_id &&
                              overallFeedbackData?.rankings_by_id[skill_index]
                                ?.skill_id == data.id
                                ? overallFeedbackData?.rankings_by_id[
                                    skill_index
                                  ]?.rankings
                                : 0
                            }
                            disabled
                          />
                        </Stack>
                      </Col>
                    </>
                  );
                })}
              </Row>
              <Row>
                <Col md="6">
                  <h2 className="">
                    Overall Rating:{" "}
                    <strong>
                      {" "}
                      {overallFeedbackData?.overall_rankings?.toFixed(2)}{" "}
                    </strong>
                  </h2>
                </Col>
                <Col md="6">
                  <h5>
                    {" "}
                    <label htmlFor="">Feedback : </label>
                  </h5>
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="file-text" />
                    <div className="filled form-group tooltip-end-top mt-3">
                      {/* <CsLineIcons icon="user" /> */}
                      <Form.Control
                        as="textarea"
                        name="feedback"
                        placeholder="Feedback"
                        value={feedbackValue}
                        className="is-invalid"
                        onChange={(e) => {
                          setfeedbackValue(e.target.value);
                        }}
                      />
                      {errorMsg?.feedbackError && (
                        <p className="text-danger">Feedback is required</p>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="text-end">
                <LoadingButton
                  type="button"
                  variant="contained"
                  className="mx-1"
                  loading={
                    ![API_STATUS.FULFILLED, "initial"].includes(
                      mentorFeedbackLoader
                    )
                  }
                  onClick={() => MentorUpdate(1)}
                >
                  Accept
                </LoadingButton>
                <LoadingButton
                  type="button"
                  variant="contained"
                  loading={
                    ![API_STATUS.FULFILLED, "initial"].includes(
                      mentorFeedbackLoader
                    )
                  }
                  onClick={() => MentorUpdate(2)}
                >
                  Reject
                </LoadingButton>
                <Button
                  variant="primary"
                  className="mx-1"
                  onClick={() => setFeedbackShow(false)}
                >
                  Cancel
                </Button>
              </div>
              {/* </form> */}
            </>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default FitmentProjectsProgress;
