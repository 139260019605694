import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  mentor_list,
  mentor_add,
  mentor_delete,
  mentor_by_id,
  mentor_edit,
  getfeedbackList,
  mentor_by_user_id,
  createfeedback,
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "mentor";

const initialState = {
  loading: "initial",
  errorMessage: "",
  mentorListDatas: [],
  mentorLoading: "initial",
  mentorAddLoading: "initial",
  mentorDelLoading: "initial",
  MentorUpdateLoading: "initial",
  mentorDatas: [],
  mentorUserDatas: [],
  feedbackData: [],
  mentorFeedbackLoader: "initial",
  count: 0,
};

export const MentorDataList = createAsyncThunk(
  `${namespace}/MentorList`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      console.log("MentorList");
      const { data } = await mentor_list();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const MentorDataAdd = createAsyncThunk(
  `${namespace}/MentorDataAdd`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("MentorList");
      const { data } = await mentor_add(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const MentorDelete = createAsyncThunk(
  `${namespace}/MentorDelete`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("MentorList");
      const { data } = await mentor_delete(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const MentorById = createAsyncThunk(
  `${namespace}/MentorById`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("MentorById");
      const { data } = await mentor_by_id(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const MentorId = createAsyncThunk(
  `${namespace}/MentorId`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      console.log("MentorById");
      const { data } = await mentor_by_user_id();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const MentorUpdate = createAsyncThunk(
  `${namespace}/MentorUpdate`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("MentorUpdate");
      const { data } = await mentor_edit(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const MentorFeedback = createAsyncThunk(
  `${namespace}/MentorFeedback`,
  async (
    { feedbackpage, feedbacklimit, feedbackquery, student_id, sprint_id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("MentorUpdate");
      const { data } = await getfeedbackList(
        feedbackpage,
        feedbacklimit,
        feedbackquery,
        student_id,
        sprint_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CreateFeedback = createAsyncThunk(
  `${namespace}/CreateFeedback`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("CreateFeedback");
      const { data } = await createfeedback(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const MentorSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearmentorData: () => {
      return initialState;
    },
    clearData: (state) => {
      state.mentorLoading = "initial";
      state.mentorAddLoading = "initial";
      state.mentorDelLoading = "initial";
      state.mentorFeedbackLoader = "initial";
      state.MentorUpdateLoading = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(MentorDataList.pending, (state) => {
      state.mentorLoading = API_STATUS.PENDING;
    });
    builder.addCase(MentorDataList.fulfilled, (state, { payload }) => {
      state.mentorLoading = API_STATUS.FULFILLED;      
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.mentorListDatas = payloaddata;
      console.log(payload, "MentorList");
    });
    builder.addCase(MentorDataList.rejected, (state, action) => {
      state.mentorLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(MentorDataAdd.pending, (state) => {
      state.mentorAddLoading = API_STATUS.PENDING;
    });
    builder.addCase(MentorDataAdd.fulfilled, (state, { payload }) => {
      state.mentorAddLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(MentorDataAdd.rejected, (state, action) => {
      state.mentorAddLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(MentorDelete.pending, (state) => {
      state.mentorDelLoading = API_STATUS.PENDING;
    });
    builder.addCase(MentorDelete.fulfilled, (state, { payload }) => {
      state.mentorDelLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(MentorDelete.rejected, (state, action) => {
      state.mentorDelLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(MentorFeedback.pending, (state) => {
      state.mentorFeedbackLoader = API_STATUS.PENDING;
    });
    builder.addCase(MentorFeedback.fulfilled, (state, { payload }) => {
      state.mentorFeedbackLoader = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.feedbackData = payloaddata;
    });
    builder.addCase(MentorFeedback.rejected, (state, action) => {
      state.mentorFeedbackLoader = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(MentorById.pending, (state) => {
      state.mentorLoading = API_STATUS.PENDING;
    });
    builder.addCase(MentorById.fulfilled, (state, { payload }) => {
      state.mentorLoading = API_STATUS.FULFILLED;
      state.mentorDatas = payload?.data;
      console.log(payload, "mentorDatas");
    });
    builder.addCase(MentorById.rejected, (state, action) => {
      state.mentorLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(MentorUpdate.pending, (state) => {
      state.MentorUpdateLoading = API_STATUS.PENDING;
    });
    builder.addCase(MentorUpdate.fulfilled, (state, { payload }) => {
      state.MentorUpdateLoading = API_STATUS.FULFILLED;
      console.log(payload, "MentorList");
    });
    builder.addCase(MentorUpdate.rejected, (state, action) => {
      state.MentorUpdateLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(MentorId.pending, (state) => {
      state.mentorLoading = API_STATUS.PENDING;
    });
    builder.addCase(MentorId.fulfilled, (state, { payload }) => {
      state.mentorLoading = API_STATUS.FULFILLED;
      state.mentorUserDatas = payload?.data;
      console.log(payload, "MentorList");
    });
    builder.addCase(MentorId.rejected, (state, action) => {
      state.mentorLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(CreateFeedback.pending, (state) => {
      state.mentorFeedbackLoader = API_STATUS.PENDING;
    });
    builder.addCase(CreateFeedback.fulfilled, (state, { payload }) => {
      state.mentorFeedbackLoader = API_STATUS.FULFILLED;
      console.log(payload, "MentorList");
    });
    builder.addCase(CreateFeedback.rejected, (state, action) => {
      state.mentorFeedbackLoader = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearmentorData, clearData } = MentorSlice.actions;

export const MentorSelector = (state) => state.mentor;

export default MentorSlice.reducer;
