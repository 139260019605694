import React, { useDebugValue, useEffect, useState } from "react";
import { Card, Col, Row, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import {
  API_STATUS,
  STUDENT_ACCEPTANCE_STATUS,
  ADMIN_ACCEPTANCE_STATUS,
  ADMIN_ACCEPTANCE_COLOR,
} from "../../utils/Constants";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import { BsEye, BsActivity } from "react-icons/bs";
import settingsReducer, {
  SkillList,
  settingSelector,
} from "../../store/reducer/settingsSlice";
import {
  AdminStudentList,
  AdminSelector,
  clearAdminData,
  approveStudentCareer,
} from "../../store/reducer/AdminReducer";
import { LoadingButton } from "@mui/lab";
import {
  CC_MentorDataList,
  CC_MentorSelector,
  clearCCMentorData,
  CC_MentorDelete,
  assignStudenttoMentor,
} from "../../store/reducer/CCMentorReducer";
import {
  departmentLists,
  departmentUpdates,
  answerLists,
  placementSelector,
  testLists,
} from "../../store/reducer/PlacementReducer";
import { signUpSelector, collegeList } from "../../store/reducer/SignUpReducer";
import { FaUser } from "react-icons/fa";
import { TablePagination } from "@mui/material";
const Admin_StudentList = () => {
  const title = "Student List";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [mentorId, setmentorId] = useState();
  const [collegeId, setcollegeId] = useState();
  const [domainId, setdomainId] = useState();
  const [errorMsg, seterrorMsg] = useState({
    mentorError: false,
    collegeError: false,
    domainError: false,
  });
  const [show, setShow] = useState(false);
  const [showapprove, setShowApprove] = useState(false);
  const [studentDetails, setstudentDetails] = useState({});

  useEffect(() => {
    let mentorformData = {};
    mentorformData.query = "";
    mentorformData.limit = 0;
    mentorformData.page = 0;
    console.log(mentorformData,"ASDFASDFASDFasd");
    dispatch(CC_MentorDataList(mentorformData));
    let departmentQuery = {};
        departmentQuery.page = 0;
        departmentQuery.limit = 0;
        departmentQuery.query = "";        
        dispatch(departmentLists(departmentQuery ));
    let formData = {};
    formData.query = "";
    formData.limit = 0;
    formData.page = 0;
    dispatch(collegeList(formData));
  }, []);

  const { skillDatas } = useSelector(settingSelector);
  const {
    adminStudentListData,
    admin_student_approve_Loading,
    admin_errorMessage,
  } = useSelector(AdminSelector);
  const { ccMentorListData, cc_mentorAssignLoading } =
    useSelector(CC_MentorSelector);
  const { collegeData } = useSelector(signUpSelector);
  const { departmentData, answerDatas, answerLoading } =
    useSelector(placementSelector);

  useEffect(() => {
    console.log(answerDatas, "answerDatas");
  }, [answerDatas]);

  useEffect(() => {
    let order = "";
    let college_id = "";
    let domain = 0;
    dispatch(
      AdminStudentList({ query, limit, page, order, college_id, domain })
    );
  }, []);

  const MentorUpdate = () => {
    if (!mentorId || !collegeId || !domainId) {
      seterrorMsg({
        mentorError: !mentorId,
        collegeError: !collegeId,
        domainError: !domainId,
      });
    } else {
      let formData = {};
      formData.cc_mentor_id = mentorId;
      formData.college_id = collegeId;
      formData.domain = domainId;
      console.log(formData, "DSFASOFD");
      dispatch(assignStudenttoMentor({ formData }));
    }
  };

  const updateApprove = (answerDatas) => {
    let formData = {};
    formData.department_id = studentDetails?.departments?.id;
    formData.student_id = studentDetails?.id;
    dispatch(approveStudentCareer({ formData }));
  };

  const assignMentor = () => {
    setShow(true);
  };
  const approveStudent = (studentData) => {
    setShowApprove(true);
    let id = studentData.user_id;
    setstudentDetails(studentData);
    dispatch(answerLists({ id }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = adminStudentListData?.count % 10;
    let remainder = adminStudentListData?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [adminStudentListData]);

  useEffect(() => {
    const formData = {
      page,
      limit,
      query,
      order: "",
      college_id: "",
      domain: 0,
    };
    console.log(formData,"QUEWRWER");
    dispatch(AdminStudentList(formData));
  }, [page, limit, query]);

  useEffect(() => {
    if (cc_mentorAssignLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Mentor Update Successfully!",
        icon: "success",
      });
      dispatch(clearAdminData());
      
      dispatch(clearCCMentorData());
      let order = "";
      let college_id = "";
      let domain = 0;
      dispatch(
        AdminStudentList({ query, limit, page, order, college_id, domain })
      );
      setShow(false);
      setmentorId();
      setcollegeId();
      setdomainId();
    }
    if (admin_student_approve_Loading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Student Approved Successfully!",
        icon: "success",
      });
      let order = "";
      let college_id = "";
      let domain = 0;
      dispatch(
        AdminStudentList({ query, limit, page, order, college_id, domain })
      );
      setShowApprove(false);
      setstudentDetails({});
      dispatch(clearAdminData());
    }
    if (
      admin_student_approve_Loading === API_STATUS.REJECTED &&
      admin_errorMessage
    ) {
      Swal.fire({
        title: admin_errorMessage.message,
        icon: "error",
      });
      dispatch(clearAdminData());
    }
  }, [
    cc_mentorAssignLoading,
    admin_student_approve_Loading,
    admin_errorMessage,
  ]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <Row>
              <Col md={6}>
                <div className="page-title-container">
                  <h1 className="mb-0 pb-0 display-4">{title}</h1>
                  <Breadcrumb className="breadcrumb-container d-inline-block">
                    <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col md={6}>
                <div className="text-end">
                  <Button
                    variant="success"
                    className="btn-icon btn-icon-start hover-outline mt-1 mb-1"
                    onClick={assignMentor}
                  >
                    <CsLineIcons icon="user" /> <span>Assign CC Mentor</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </section>

          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Student Name</th>
                    <th>Email</th>
                    <th>College</th>
                    <th>Domain</th>
                    <th>Accepted Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adminStudentListData &&
                    adminStudentListData?.rows?.map((studentValues, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{studentValues?.name}</td>
                            <td>{studentValues?.email}</td>
                            <td>
                              {studentValues?.college == null
                                ? "Yet to Choose"
                                : studentValues?.college}
                            </td>
                            <td>
                              {studentValues?.departments?.name == null
                                ? "Yet to Choose"
                                : studentValues?.departments?.name}
                            </td>
                            <td>
                              {studentValues?.primarlimary_test_status ==
                              null ? (
                                <Button variant="danger">
                                  Not yet atttended
                                </Button>
                              ) : (
                                <Button
                                  variant={
                                    ADMIN_ACCEPTANCE_COLOR[
                                      studentValues?.primarlimary_test_status
                                    ]
                                  }
                                >
                                  {
                                    ADMIN_ACCEPTANCE_STATUS[
                                      studentValues?.primarlimary_test_status
                                    ]
                                  }
                                </Button>
                              )}
                            </td>
                            <td>
                              {studentValues?.primarlimary_test_status == 3 ? (
                                <>
                                  <button
                                    title="View Student Progress"
                                    className="btn btn-primary mx-1"
                                    onClick={() => {
                                      navigate(
                                        "/hiring_partner/" +
                                          studentValues?.id +
                                          "/course/" +
                                          studentValues?.department
                                      );
                                    }}
                                  >
                                    <BsEye />
                                  </button>
                                  <button
                                    title="View Fitment Progress"
                                    className="btn btn-primary mx-1"
                                    onClick={() => {
                                      navigate(
                                        "/student/" +
                                          studentValues?.id +
                                          "/project"
                                      );
                                    }}
                                  >
                                    <BsActivity />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    title="Approve Student"
                                    className="btn btn-primary mx-1"
                                    onClick={() =>
                                      approveStudent(studentValues)
                                    }
                                  >
                                    <FaUser size={18} />
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between px-2">
                <strong>Total count : {adminStudentListData?.count}</strong>
                <TablePagination
                  component="div"
                  count={adminStudentListData?.count}
                  page={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
      {/* Mentor Assign */}
      <Modal
        show={show}
        id="settings"
        dialogClassName="modal-100w"
        aria-labelledby="settings"
        tabIndex="-1"
        scrollable
        size="xl"
      >
        <div className="p-3 mt-2" style={{ overflow: "auto" }}>
          <>
            <h1>Assign Mentor</h1>
            <Row className="mt-2">
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">College List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setcollegeId(e.target.value);
                    }}
                    value={collegeId}
                    name="college_id"
                    className="is-invalid"
                    placeholder="College"
                  >
                    <option>Select College</option>
                    {collegeData &&
                      collegeData?.rows?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.collegeError && (
                    <p className="text-danger">Select the College</p>
                  )}
                </div>
              </Col>
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">Domain List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setdomainId(e.target.value);
                    }}
                    value={domainId}
                    name="domain"
                    className="is-invalid"
                    placeholder="Domain"
                  >
                    <option>Select Domain</option>
                    {departmentData &&
                      departmentData?.rows?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.domainError && (
                    <p className="text-danger">Select the Domain</p>
                  )}
                </div>
              </Col>
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">CC Mentor List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setmentorId(e.target.value);
                    }}
                    value={mentorId}
                    name="mentor_id"
                    className="is-invalid"
                    placeholder="CC Mentor"
                  >
                    <option>Select CC Mentor</option>
                    {ccMentorListData &&
                      ccMentorListData?.rows?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.mentorError && (
                    <p className="text-danger">Select the CC Mentor</p>
                  )}
                </div>
              </Col>
            </Row>
            <div className="text-end">
              <LoadingButton
                variant="contained"
                loading={
                  ![API_STATUS.FULFILLED, "initial"].includes(
                    cc_mentorAssignLoading
                  )
                }
                onClick={MentorUpdate}
              >
                Confirm
              </LoadingButton>
              <Button
                variant="primary"
                className="mx-1"
                onClick={() => setShow(false)}
              >
                Cancel
              </Button>
            </div>
          </>
        </div>
      </Modal>

      {/* Student Approve */}
      <Modal
        show={showapprove}
        id="studentApprove"
        dialogClassName="modal-100w"
        aria-labelledby="studentApprove"
        tabIndex="-1"
        scrollable
        size="xl"
      >
        <div className="p-3 mt-2" style={{ overflow: "auto" }}>
          <>
            <h1>Approve Student to Career Journey</h1>
            <Row>
              <Col md="6">
                <h5>
                  <label htmlFor="">
                    Student Name: <strong>{studentDetails?.name}</strong>
                  </label>
                </h5>
              </Col>
              <Col md="6">
                <h5>
                  <label htmlFor="">
                    Domain: <strong>{studentDetails?.departments?.name}</strong>
                  </label>
                </h5>
              </Col>
              <Col md="6">
                <h5>
                  <label htmlFor="">
                    College Name: <strong>{studentDetails?.college}</strong>
                  </label>
                </h5>
              </Col>
              <Col md="6">
                <h5>
                  <label htmlFor="">
                    Year: <strong>{studentDetails?.year}</strong>
                  </label>
                </h5>
              </Col>
            </Row>
            <Row>
              {answerDatas &&
                answerDatas.map((fields, i) => {
                  return (
                    <div class="form_group mt-1">
                      <div className="">
                        Question {i + 1}: <b>{fields?.questions?.question}</b>
                      </div>
                      <div>
                        Answer: <b>{fields?.answer}</b>
                      </div>
                      <hr />
                    </div>
                  );
                })}
            </Row>
            <div className="text-end">
              {studentDetails?.primarlimary_test_status == 1 ? (
                <>
                  <LoadingButton
                    variant="contained"
                    loading={
                      ![API_STATUS.FULFILLED, "initial"].includes(
                        admin_student_approve_Loading
                      )
                    }
                    onClick={() => updateApprove(answerDatas)}
                  >
                    Confirm
                  </LoadingButton>
                </>
              ) : (
                <></>
              )}

              <Button
                variant="primary"
                className="mx-1"
                onClick={() => {
                  setShowApprove(false);
                  setstudentDetails({});
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        </div>
      </Modal>
    </>
  );
};

export default Admin_StudentList;
