import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import Nav from "./components/layout/nav/Nav";
console.log("inasdsad");
const PrivateRoute = () => {
  const isAuth = sessionStorage.getItem("isAuthenticated");
  const isAuthenticated = 1;

  if (!isAuthenticated) {
    localStorage.clear();
  }
  return isAuthenticated ? (
    <>
      <Nav />
      <main>
        <Container>
          <Row className="h-100">
            <Col className="h-100" id="contentArea">
              <Outlet />
            </Col>
          </Row>
        </Container>
      </main>
    </>
  ) : (
    <Navigate to="/signin" />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PrivateRoute);
