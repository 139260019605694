import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form,FormGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { LoadingButton } from "@mui/lab";
import "react-datepicker/dist/react-datepicker.css";

import {
  CertificateSelector,
  certificateView,
  certificateclearData,
  certificateUpload
} from "../../store/reducer/CertificateReducer";

const UploadCertificate = () => {
  const title = "Certificate Upload";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** States */
  const [validated, setValidated] = useState(false);
  const [certificateFile, setcertificateFile] = useState();
  const [certificateerrorMsg, setcertificateerrorMsg] = useState(false);
  /** Functions */

  const handleActivity = (event) => {
    const { files } = event.target;
    setcertificateFile(files[0]);
  };

  /** Form Handling */
  const validationSchema = Yup.object().shape({
    certificate_id: Yup.string().matches(/^[a-zA-Z0-9]*$/, 'Certificate can only contain letters, numbers').required("Certificate Id is required"),
    email:Yup.string().email('Invalid email').required('Email is required'),
    certificate_course_details:Yup.string().required("Provide Certificate Details"),
  });
  const initialValues = { 
  certificate_id: "",
  email:"",
  certificate_course_details:"",

   };
  const onSubmit = (formData) => {
    formData.certificate = certificateFile;    
    if (certificateFile == null) {
      setcertificateerrorMsg(!certificateFile);
    }
   
      else {
    dispatch(certificateUpload({ formData }));
      }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const { certificateData, certificateDataLoading, certificateerrorMessage,certificateUploadLoading } =
    useSelector(CertificateSelector);

  useEffect(() => {
    console.log(certificateData, "certificateData");
  }, [certificateData]);

  useEffect(() => {
    if (certificateDataLoading === API_STATUS.FULFILLED) {
      dispatch(certificateclearData());
    }
    if(certificateUploadLoading=== API_STATUS.FULFILLED){
      Swal.fire({
        title:"Certicicate Uploaded Successfully",
        icon:"success"
      })
      dispatch(certificateclearData())
    }

    if (
      (certificateUploadLoading === API_STATUS.REJECTE || certificateDataLoading === API_STATUS.REJECTED) &&
      certificateerrorMessage
    ) {
      if (certificateerrorMessage.message)
        Swal.fire({
          title: certificateerrorMessage.message,
          icon: "error",
        });
      else if (certificateerrorMessage.errors) {
        let data = "";
        certificateerrorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        Swal.fire({
          title: data,
          icon: "error",
        });
      }
      dispatch(certificateclearData());
    }
  }, [certificateDataLoading, certificateerrorMessage, certificateUploadLoading]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <form
                id="mentorAddForm"
                className={
                  validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                }
                onSubmit={(e) => {
                  e.preventDefault();
                  setValidated(true);
                  handleSubmit();
                }}
              >
                <Row className="mb-4 g-4">

                <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="email" />
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Email ID"
                        value={values.email}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="diploma" />
                      <Form.Control
                        type="text"
                        name="certificate_id"
                        placeholder="Certificate ID"
                        value={values.certificate_id}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.certificate_id && touched.certificate_id && (
                        <Form.Control.Feedback type="invalid">
                          {errors.certificate_id}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="news" />
                      <Form.Control
                        as="textarea"
                        name="certificate_course_details"
                        placeholder="Certificate Course Detail"
                        value={values.certificate_course_details}
                        className="is-invalid"
                        onChange={handleChange}
                        />
                      {errors.certificate_course_details && touched.certificate_course_details && (
                        <Form.Control.Feedback type="invalid">
                          {errors.certificate_course_details}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file-image" />
                      <Form.Control
                        type="file"
                        
                        name="certificate_file"
                        onChange={(e) => handleActivity(e)}
                        placeholderText="Upload Certificate"
                        values={certificateFile}
                      />
                      {certificateerrorMsg && (
                        <p className="text-danger">File required</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="text-end">
                
                  <LoadingButton
                    variant="contained"
                    loading={
                      ![API_STATUS.FULFILLED, "initial"].includes(
                        certificateDataLoading
                      )
                    }
                    onClick={() => setcertificateerrorMsg(false)}
                    type="submit"
                  >
                    Upload
                  </LoadingButton>
                </div>
              </form>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default UploadCertificate;
