import { useEffect, useRef, useState } from "react";
import {
  JobListData,
  hiringpartnerSelector,
} from "../../store/reducer/HiringPartnerReducer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Table, Row, Modal, Col, Form } from "react-bootstrap";
import TablePagination from "@mui/material/TablePagination";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS, JOBSTATUS } from "../../utils/Constants";
import {
  Button as Buttoned,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MdPersonAdd } from "react-icons/md";
import _ from "lodash";
import Swal from "sweetalert2";
import {
  AdminSelector,
  clearAdminData,
  updateJobStatusScheme,
  AdminStudentList,
  approveStudentCareer,
  assignstudentTohiringpartner,
} from "../../store/reducer/AdminReducer";
import { signUpSelector, collegeList } from "../../store/reducer/SignUpReducer";
import { LoadingButton } from "@mui/lab";
import {
  departmentLists,
  departmentUpdates,
  answerLists,
  placementSelector,
  testLists,
} from "../../store/reducer/PlacementReducer";
const AdminJobList = () => {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const { jobListDetails, hiringPartnerListLoading } = useSelector(
    hiringpartnerSelector
  );
  const { collegeData } = useSelector(signUpSelector);
  const {
    adminStudentListData,
    updateJobStatusLoad,
    student_to_hp_loading,
    admin_errorMessage,
  } = useSelector(AdminSelector);
  const { departmentData, answerDatas, answerLoading } =
    useSelector(placementSelector);
  const [selectedId, setSelectedId] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [orgId, setOrgId] = useState(null);
  const [pageSize, setPageSize] = useState(1);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState();
  const variantColor = ["primary", "warning", "success", "danger"];
  const options = ["", "Hold", "Approve", "Remove"];
  const [show, setShow] = useState(false);
  const [domainId, setdomainId] = useState("");
  const [collegeId, setcollegeId] = useState();
  const [hiringPartnerId, sethiringPartnerId] = useState();
  const [jobId, setjobId] = useState();
  const [studentId, setstudentId] = useState();
  const [errorMsg, seterrorMsg] = useState({
    studentError: false,
    collegeError: false,
  });

  useEffect(() => {
    // let query = "";
    // let limit = 0;
    // let page = 0;
    // let org_id = hiring_partner_id;
    // dispatch(JobListData({ query, limit, page, org_id }));
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    dispatch(departmentLists(departmentQuery));
    let formData = {};
    formData.query = "";
    formData.limit = 0;
    formData.page = 0;
    console.log(formData, "JCOLLEGELIST");
    dispatch(collegeList(formData));
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleClick = () => {
    // updateJobStatusScheme
    setOpen(!open);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    const formData = {
      job_ids: selectedId,
      status: index,
    };
    dispatch(updateJobStatusScheme({ formData }));
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = jobListDetails?.count % 10;
    let remainder = jobListDetails?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [jobListDetails]);

  useEffect(() => {
    const formData = {
      page,
      limit,
      org_id: 0,
      query,
    };
    dispatch(JobListData(formData));
  }, [page, limit, orgId, query]);

  const assignModal = (jobData) => {
    setShow(true);
    setdomainId(jobData?.domain);
    setjobId(jobData?.id);
    sethiringPartnerId(jobData?.org_id);
  };

  const collegeStudents = (collegeid) => {
    setcollegeId(collegeid);
    let collegeName = collegeData?.rows?.find((ele) => {
      return ele.id == collegeid;
    });
    let query = "";
    let limit = "";
    let page = 0;
    let order = "";
    let college_id = collegeid;
    let domain = domainId;
    dispatch(
      AdminStudentList({ query, limit, page, order, college_id, domain })
    );
  };
  const AssignStudentToHP = () => {
    if (!collegeId || !studentId) {
      seterrorMsg({
        studentError: !studentId,
        collegeError: !collegeId,
      });
    } else {
      let formData = {};
      formData.org_id = hiringPartnerId;
      formData.job_id = jobId;
      formData.student_id = studentId;
      formData.department_id = domainId;
      formData.student_acceptance_status = 0;

      console.log(formData, "DSFASOFD");
      dispatch(assignstudentTohiringpartner({ formData }));
    }
  };

  useEffect(() => {
    if (updateJobStatusLoad === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Job Updated Successfully!",
        icon: "success",
      });
      const formData = {
        page,
        limit,
        org_id: orgId,
        query,
      };
      dispatch(JobListData(formData));
      dispatch(clearAdminData());
      setSelectedId([]);
    }
    if (student_to_hp_loading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Student Assigned to Hiring Partner Successfully!",
        icon: "success",
      });

      let query = "";
      let limit = 0;
      let page = 0;
      let org_id = 0;
      dispatch(JobListData({ query, limit, page, org_id }));
      setShow(false);
      setcollegeId();
      setdomainId("");
      setstudentId();
      setjobId();
      dispatch(clearAdminData());
    }
    if (student_to_hp_loading === API_STATUS.REJECTED && admin_errorMessage) {
      Swal.fire({
        title: admin_errorMessage.message,
        icon: "error",
      });
      dispatch(clearAdminData());
    }
  }, [updateJobStatusLoad, student_to_hp_loading, admin_errorMessage]);

  const resetDatas = () => {
    setShow(false);
    setcollegeId();
    setdomainId("");
    setstudentId();
    setjobId();
    sethiringPartnerId();
  };

  return (
    <>
      <h1>Job List</h1>
      <Card>
        <Card.Body>
          <div className="mt-2 mb-2 text-end">
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="Button group with a nested menu"
            >
              <Buttoned disabled={selectedId?.length <= 0} onClick={handleClick}>
                Actions
              </Buttoned>
              <Buttoned
                disabled={selectedId?.length <= 0}
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Buttoned>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {options.map((option, index) => {
                          if (index > 0)
                            return (
                              <MenuItem
                                key={option}
                                // disabled={index === 2}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            );
                        })}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={jobListDetails?.rows?.every((ele) =>
                      selectedId?.includes(ele?.id)
                    )}
                    onChange={(e) => {
                      const { checked } = e.target;
                      if (checked) {
                        setSelectedId(_.map(jobListDetails?.rows, "id"));
                      } else {
                        setSelectedId([]);
                      }
                    }}
                  />
                </th>
                <th>Hiring Partner</th>
                <th>Job Title</th>
                <th>Job Type</th>
                <th>Domain</th>
                <th>Total Openings</th>
                <th>Total Students Assigned</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {jobListDetails?.rows?.map((job, index) => {
                return (
                  <>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          name={job?.id}
                          checked={selectedId?.includes(job?.id)}
                          onClick={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              setSelectedId((prev) => [...prev, job?.id]);
                            } else {
                              setSelectedId(
                                _.filter(selectedId, function (o) {
                                  return o != job?.id;
                                })
                              );
                            }
                          }}
                        />
                      </td>
                      <td>{job?.hiringpartner?.name}</td>
                      <td>{job?.title}</td>
                      <td>{job?.job_type}</td>
                      <td>{job?.departments?.name}</td>
                      <td>{job?.vacancy_count}</td>
                      <td>{job?.hiring_partner_student?.length}</td>
                      <td>
                        <Button variant={variantColor[job?.status]}>
                          {JOBSTATUS[job?.status]}
                        </Button>
                      </td>
                      <td>
                        {job?.status == 2 ? (
                          <Button
                            title="Assign Students"
                            className="btn btn-primary"
                            onClick={(e) => {
                              assignModal(job);
                            }}
                          >
                            <MdPersonAdd size={18} />
                          </Button>
                        ) : (
                          <>
                            {" "}
                            <Button
                              title="Assign Students"
                              className="btn btn-primary"
                            >
                              <MdPersonAdd size={18} />
                            </Button>
                          </>
                        )}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between px-2">
            <strong>Total count : {jobListDetails?.count}</strong>
            <TablePagination
              component="div"
              count={jobListDetails?.count}
              page={rowsPerPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Card.Body>
      </Card>

      {/* Student Assign */}
      <Modal
        show={show}
        id="studentAssign"
        dialogClassName="modal-100w"
        aria-labelledby="studentAssign"
        tabIndex="-1"
        scrollable
        size="xl"
      >
        <div className="p-3 mt-2" style={{ overflow: "auto" }}>
          <>
            <h1>Assign Students to Job</h1>
            <Row className="mt-2">
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">College List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      collegeStudents(e.target.value);
                    }}
                    value={collegeId}
                    name="college_id"
                    className="is-invalid"
                    placeholder="College"
                  >
                    <option>Select College</option>
                    {collegeData &&
                      collegeData?.rows?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.collegeError && (
                    <p className="text-danger">Select the College</p>
                  )}
                </div>
              </Col>
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">Domain List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    value={domainId}
                    name="domain"
                    className="is-invalid"
                    placeholder="Domain"
                    disabled
                  >
                    <option>Select Domain</option>
                    {departmentData &&
                      departmentData?.rows?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.domainError && (
                    <p className="text-danger">Select the Domain</p>
                  )}
                </div>
              </Col>
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">Students List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setstudentId(e.target.value);
                    }}
                    value={studentId}
                    name="mentor_id"
                    className="is-invalid"
                    placeholder="CC Mentor"
                  >
                    <option>Select Student</option>
                    {adminStudentListData &&
                      adminStudentListData?.rows?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.studentError && (
                    <p className="text-danger">Select the Student</p>
                  )}
                </div>
              </Col>
            </Row>
            <div className="text-end">
              <LoadingButton
                variant="contained"
                loading={
                  ![API_STATUS.FULFILLED, "initial"].includes(
                    hiringPartnerListLoading
                  )
                }
                onClick={AssignStudentToHP}
              >
                Confirm
              </LoadingButton>
              <Button variant="primary" className="mx-1" onClick={resetDatas}>
                Cancel
              </Button>
            </div>
          </>
        </div>
      </Modal>
    </>
  );
};

export default AdminJobList;
