import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TablePagination } from "@mui/material";
import {
  EmptyCourseData,
  ProjectActivityList,
  courseDetails,
  courseSelector,
  ProjectActivityEODList,
  getActivitiesTypeScheduler,
  planDetail,
  postPlanDetails,
} from "../store/reducer/CourseReducer";
import {
  StudentById,
  MentorProjectFeedback,
  StudentSelector,
  clearData,
  createProjectEOD,
  createProjectPlan,
  fitmentProjectScheduler,
} from "../store/reducer/StudentReducer";
import {
  CreateFeedback,
  MentorSelector,
  clearmentorData,
} from "../store/reducer/MentorReducer";
import { useFormik } from "formik";
import { eodValidator, planValidator } from "../validators/Validators";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { API_STATUS, EODSTATUS, TYPE_OF_WORK } from "../utils/Constants";
import { API_BASE } from "../services/config";
import { LoadingButton } from "@mui/lab";
import { Rating, Stack } from "@mui/material";
import settingsReducer, {
  SkillList,
  settingSelector,
} from "../store/reducer/settingsSlice";

const ProjectActivityProgress = () => {
  //id, query, order, limit, sort, page
  const name = localStorage.getItem("username");
  const role = localStorage.getItem("role");
  const keysArray = [
    "activity_id",
    "planned_desc",
    "planned_hrs",
    "activity_type_id",
    "actual_duration",
    "comments",
    "attachment",
    "status",
  ];
  const [show, setShow] = useState({
    pfd: false,
    eod: false,
  });
  const [studentId, setstudentId] = useState();
  const dispatch = useDispatch();
  const [inProgressId, setInProgressId] = useState();
  const navigate = useNavigate();
  const { student_id, projectId } = useParams();
  const [order, setOrder] = useState("");
  const [disablePlan, setDisablePlan] = useState(false);
  const [keySet, setKeySet] = useState("pfd");
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [query, setQuery] = useState("");
  const [feedback_show, setFeedbackShow] = useState(false);
  const [feedbackValue, setfeedbackValue] = useState("");
  const [ratingsValues, setRatingsValues] = useState([]);
  const [DepartmentName, setDepartmentName] = useState();
  const [activityValues, setactivityValues] = useState();
  const [errorMsg, seterrorMsg] = useState({
    feedbackError: false,
  });
  const [currentActivityId, setCurrentActivityId] = useState();
  const {
    studentDetailsDatas,
    feedbackLoadings,
    createProjectPlanLoad,
    createProjectEODLoad,
    fitmentData,
    mentorProjectFeedback,
    fitmentActivity,
  } = useSelector(StudentSelector);
  const { projectActivitydata, activityTypes, projectActivityEODdata } =
    useSelector(courseSelector);
  const { skillDatas } = useSelector(settingSelector);
  const { mentorFeedbackLoader } = useSelector(MentorSelector);
  const [validated, setValidated] = useState(false);
  const [value, setValue] = useState(2);

  const [planpage, setplanPage] = useState(1);
  const [planlimit, setplanLimit] = useState(10);
  const [planquery, setplanQuery] = useState("");
  const [planpageSize, setplanPageSize] = useState(1);
  const [planrowsPerPage, setplanRowsPerPage] = useState(0);
  const [planDetailValues, setplanDetailValues] = useState([]);

  const [eodpage, seteodPage] = useState(1);
  const [eodlimit, seteodLimit] = useState(10);
  const [eodquery, seteodQuery] = useState("");
  const [eodpageSize, seteodPageSize] = useState(1);
  const [eodrowsPerPage, seteodRowsPerPage] = useState(0);
  const [eodDetailValues, seteodDetailValues] = useState([]);

  const [eods, setEods] = useState([
    {
      actual_duration: "",
      status: "",
      attachment: "",
      activity_type_id: "",
      activity_id: "",
      planned_hrs: "",
      comments: "",
      planned_desc: "",
    },
  ]);
  const handleActivity = (id) => {
    setCurrentActivityId(id);
  };
  useEffect(() => {
    console.log(mentorProjectFeedback, "mentorProjectFeedback");
  }, [mentorProjectFeedback]);

  const [tasks, setTasks] = useState([
    {
      student_id: "",
      project_id: "",
      activity_id: "",
      plan: "",
      plan_duration: "",
      activity_type_id: "",
    },
  ]);
  useEffect(() => {
    console.log(skillDatas, "skillDatas");
  }, [skillDatas]);
  useEffect(() => {
    let data = [];
    let inprogress = [];
    projectActivitydata?.map((ele) => {
      const updatedAtDateTime = dayjs(ele.updated_at);
      console.log(updatedAtDateTime, "updatedAtDateTime");
      const currentTime = dayjs();
      const isMoreThan31HoursAgo = updatedAtDateTime.isBefore(
        currentTime.subtract(31, "hour")
      );
      // console.log(first)
      // if (isMoreThan31HoursAgo && ele.status === 2) {
      //     setDisablePlan(true);
      //     Swal.fire({
      //         title: "Complete and fill In Progress Activity(which is mentioned in EOD Status) and then continue your today's activity",
      //         icon: "warning"
      //     })
      // }
      const isWithinLast24Hours =
        // updatedAtDateTime.isAfter(currentTime.subtract(24, 'hour')) &&
        updatedAtDateTime.isBefore(currentTime.subtract(8, "hour"));
      console.log(ele, "isWithinLast24Hours updatedAtDateTime");
      if (isMoreThan31HoursAgo && ele.status === 2) inprogress.push(ele.id);
      if (isWithinLast24Hours && ele.status !== 1)
        data.push({
          actual_duration: "",
          status: "",
          attachment: "",
          activity_type_id: ele?.activity_type_id,
          activity_id: ele?.activity_id,
          planned_hrs: ele?.plan_duration,
          comments: "",
          planned_desc: ele?.plan,
          plan_id: ele?.id,
        });
    });
    setInProgressId(inprogress);
    setEods(data);
  }, [projectActivitydata]);

  useEffect(() => {
    if (projectId) {
      const query =
        studentDetailsDatas?.hiring_partner_student &&
        studentDetailsDatas?.hiring_partner_student[0];
      let payload = {
        mentor_id: query?.mentor_id,
        student_id: student_id,
        project_id: projectId,
      };
      dispatch(fitmentProjectScheduler({ payload }));
      dispatch(
        ProjectActivityList({
          page: planpage,
          limit: planlimit,
          query: planquery,
          sortby: sort,
          order,
          status: "",
          student_id: studentDetailsDatas?.id,
          project_id: projectId,
          date: "",
        })
      );
      dispatch(
        ProjectActivityEODList({
          page: eodpage,
          limit: eodlimit,
          query: eodquery,
          sortby: sort,
          order,
          status: "",
          student_id: studentDetailsDatas?.id,
          project_id: projectId,
          date: "",
        })
      );
      dispatch(
        MentorProjectFeedback({
          page,
          limit,
          query: "",
          sortby: sort,
          order,
          status: "",
          student_id: studentDetailsDatas?.id,
          project_id: projectId,
          date: "",
        })
      );
    }
    setstudentId(studentDetailsDatas?.id);
    dispatch(
      SkillList({
        page,
        limit,
        query: studentDetailsDatas?.department,
        sortby: sort ?? "",
        order: order ?? "",
      })
    );
  }, [studentDetailsDatas, projectId]);

  useEffect(() => {
    let studentParam = {};
    studentParam.student_id = student_id;
    dispatch(StudentById({ studentParam }));
  }, []);

  const handleAddTask = () => {
    if (show.pfd)
      setTasks([
        ...tasks,
        { plan: "", plan_duration: "", activity_type_id: "", activity_id: "" },
      ]);
    else
      setEods([
        ...eods,
        {
          actual_duration: "",
          status: "",
          attachment: "",
          activity_type_id: "",
          activity_id: "",
          planned_hrs: "",
          comments: "",
          planned_desc: "",
        },
      ]);
  };

  const onSubmit = (formData) => {
    const data = show.pfd ? tasks : eods;
    console.log(data, "test error");
    const excludedKey = "attachment";
    const excluded = ["student_id", "project_id"];
    const hasEmptyFields = show.pfd
      ? tasks.some((ele) =>
          Object.entries(ele).some(
            ([key, value]) => !excluded.includes(key) && value === ""
          )
        )
      : eods.some((ele) =>
          Object.entries(ele).some(
            ([key, value]) => key !== excludedKey && value === ""
          )
        );

    if (hasEmptyFields) {
      Swal.fire({ title: "Fill all fields", icon: "error" });
    } else {
      data.forEach((ele) => {
        ele.project_id = projectId;
        ele.student_id = studentId;
        if (ele.status || ele.status == "") {
          ele["eod_status"] = ele.status;
          delete ele["status"];
        }
      });
      console.log(data, "formdata data");
      if (show.pfd) dispatch(createProjectPlan({ formData: data }));
      else dispatch(createProjectEOD({ formData: data }));
    }
  };

  const handleForm = (index, event) => {
    const { value, name } = event.target;
    let newEod = [...eods];
    let newTasks = [...tasks];

    if (!newTasks[index]) {
      newTasks[index] = {};
    }
    if (!newEod[index]) {
      newEod[index] = {};
    }

    if (show.pfd) {
      newTasks[index][name] = value;
      setTasks(newTasks);
    } else {
      newEod[index][name] =
        name === "attachment" ? event.target.files[0] : value;
      setEods(newEod);
    }
  };
  const ActivityFeedback = (activityData) => {
    setactivityValues(activityData);
    setFeedbackShow(true);
  };

  const starRatings = (startValue, skill_id) => {
    let ratings = [];
    ratings = ratingsValues.filter((record) => record.skill_id !== skill_id);
    ratings.push({ skill_id: skill_id, value: Number(startValue) });
    setRatingsValues([...ratings]);
  };
  const resetValues = () => {
    setFeedbackShow(false);
    setRatingsValues([]);
    setfeedbackValue("");
  };

  const MentorUpdate = () => {
    if (ratingsValues.length == skillDatas?.rows?.length) {
      if (feedbackValue == "") {
        seterrorMsg({
          feedbackError: true,
        });
      } else {
        const sortedRatings = ratingsValues
          .slice()
          .sort((a, b) => a.skill_id - b.skill_id);
        const sum = sortedRatings.reduce((accumulator, object) => {
          return parseFloat(accumulator) + parseFloat(object.value);
        }, 0);
        let formData = {};
        formData.feedback = feedbackValue;
        formData.activity_id = activityValues.id;
        formData.project_id = activityValues.project_id;
        formData.student_id = studentDetailsDatas.id;
        formData.domain = studentDetailsDatas.department;
        formData.activity_status = 1;
        formData.rankings = sortedRatings;
        formData.overall_rankings = sum;
        dispatch(CreateFeedback({ formData }));
      }
    } else {
      Swal.fire({
        title: "Please give the Ratings",
        icon: "error",
      });
    }
  };

  /** Table Pagination for Plan for the Day  */
  const planhandleChangePage = (event, newPage) => {
    setplanPage(newPage + 1);
    setplanRowsPerPage(newPage);
  };

  const planhandleChangeRowsPerPage = (event) => {
    setplanLimit(parseInt(event.target.value, 10));
    setplanPage(0);
  };

  useEffect(() => {
    let count = planDetailValues?.length % 10;
    let remainder = planDetailValues?.length / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setplanPageSize(pageLength);
  }, [planDetailValues]);

  useEffect(() => {
    dispatch(
      ProjectActivityList({
        page: planpage,
        limit: planlimit,
        query: planquery,
        sortby: sort,
        order,
        status: "",
        student_id: studentDetailsDatas?.id,
        project_id: projectId,
        date: "",
      })
    );
  }, [planpage, planlimit, planquery]);

  /** Table Pagination for EOD  */
  const eodhandleChangePage = (event, newPage) => {
    seteodPage(newPage + 1);
    seteodRowsPerPage(newPage);
  };

  const eodhandleChangeRowsPerPage = (event) => {
    seteodLimit(parseInt(event.target.value, 10));
    seteodPage(0);
  };

  useEffect(() => {
    console.log(eodDetailValues, "eodDetailValues1");
    let count = eodDetailValues?.length % 10;
    let remainder = eodDetailValues?.length / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    seteodPageSize(pageLength);
  }, [eodDetailValues]);

  useEffect(() => {
    dispatch(
      ProjectActivityEODList({
        page: eodpage,
        limit: eodlimit,
        query: eodquery,
        sortby: sort,
        order,
        status: "",
        student_id: studentDetailsDatas?.id,
        project_id: projectId,
        date: "",
      })
    );
  }, [eodpage, eodlimit, eodquery]);

  useEffect(() => {
    if (createProjectPlanLoad === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Plan Uploaded Successfully",
        icon: "success",
      });
      setShow({ pfd: false, eod: false });
      dispatch(clearData());
    }
    if (createProjectEODLoad === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Plan Uploaded Successfully",
        icon: "success",
      });
      setShow({ pfd: false, eod: false });
      dispatch(clearData());
    }
    if (mentorFeedbackLoader === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Feedback Submitted Successfully",
        icon: "success",
      });
      setFeedbackShow(false);
      setactivityValues({});
      setRatingsValues([]);
      setfeedbackValue("");
      dispatch(
        MentorProjectFeedback({
          page,
          limit,
          query: "",
          sortby: sort,
          order,
          status: "",
          student_id: studentDetailsDatas?.id,
          project_id: projectId,
          date: "",
        })
      );
      dispatch(clearmentorData());
    }
  }, [createProjectPlanLoad, createProjectEODLoad, mentorFeedbackLoader]);

  const initialValues = show.pfd ? tasks : eods;
  const validationSchema = show.pfd ? planValidator : eodValidator;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  return (
    <div className="container-fluid mt-4">
      <Row>
        <Row>
          <Col md={8}></Col>
          <Col md={4}>
            <div className="d-flex w-100 justify-content-end">
              <Button
                className="mb-3 mx-1"
                variant="success"
                onClick={(e) => {
                  navigate("/student/" + student_id + "/project");
                }}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <Col md={9}>
          <Card className="mb-3 p-4">
            <Row className="mt-3">
              <Col md={12} className="pe-0 ps-5">
                <h1>
                  <label htmlFor="">
                    Culture Catalyst Name:{" "}
                    <strong>{studentDetailsDatas?.name}</strong>
                  </label>
                </h1>
              </Col>
              <hr />
            </Row>
            <Row>
              <h1 className="mt-3">
                {" "}
                {fitmentData?.data &&
                  fitmentData?.data[0]?.project_fitment?.title}
              </h1>
              <div className="px-3">
                {fitmentData?.data &&
                  fitmentData?.data[0]?.project_fitment?.description}
              </div>
            </Row>
          </Card>
          <Card className="p-4">
            {fitmentActivity?.map((data) => {
              if (
                currentActivityId
                  ? data?.id == currentActivityId
                  : data?.id == (fitmentActivity && fitmentActivity[0]?.id)
              )
                return (
                  <>
                    <Row>
                      <Col md="10">
                        <h1 className="mt-3">{data?.title}</h1>
                        <div className="px-3">{data?.description}</div>
                      </Col>
                      <Col md="2" className="">
                        <Button
                          className="mt-3 mx-1 justify-content-end"
                          variant="primary"
                          onClick={(e) => {
                            ActivityFeedback(data);
                          }}
                        >
                          Feedback
                        </Button>
                      </Col>
                    </Row>
                    <Accordion defaultActiveKey="0" className="mt-3">
                      <Accordion.Item eventKey={0}>
                        <Accordion.Header className="w=100">
                          <div className="d-flex justify-content-between w-100">
                            <h6 className="">Plan for the day</h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Plan</th>
                                <th>Planned Date</th>
                                <th>Planned (Hrs)</th>
                                <th>Type of Work</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectActivitydata?.map((plans) => {
                                if (
                                  plans?.project_id == projectId &&
                                  data?.id == plans?.activity_id
                                )
                                  return (
                                    <tr>
                                      <td> {plans?.plan} </td>
                                      <td>
                                        {dayjs(plans?.updated_at).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </td>
                                      <td>{plans?.plan_duration}</td>
                                      <td>
                                        {TYPE_OF_WORK[plans?.activity_type_id]}
                                      </td>
                                      <td>
                                        {plans?.status != 0
                                          ? EODSTATUS[plans?.status]
                                          : "Yet To Update"}
                                      </td>
                                    </tr>
                                  );
                              })}
                            </tbody>
                          </Table>
                          <div className="d-flex justify-content-between px-2">
                            <strong>
                              Total count : {planDetailValues?.length}
                            </strong>
                            <TablePagination
                              component="div"
                              count={planDetailValues?.length}
                              page={planrowsPerPage}
                              onPageChange={planhandleChangePage}
                              rowsPerPage={planlimit}
                              onRowsPerPageChange={planhandleChangeRowsPerPage}
                            />
                          </div>
                          {/* {
                                                    projectActivitydata?.map((plans) => {
                                                        if (plans?.project_id == projectId && data?.id == plans?.activity_id)
                                                            return (
                                                                <Row className="g-0 mb-2">
                                                                    <Col xs="auto">
                                                                        <div className="sw-3 d-inline-block d-flex justify-content-start align-items-center h-100">
                                                                            <div className="sh-3">
                                                                                <CsLineIcons
                                                                                    icon="chevron-right"
                                                                                    className="text-secondary align-top"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className="d-flex flex-column pt-0 pb-0 ps-3 pe-4 h-100 justify-content-center">
                                                                            <div className="d-flex flex-column">
                                                                                <div className="text-alternate mt-n1 lh-1-25">
                                                                                    {plans?.plan} ({dayjs(plans?.updated_at).format("YYYY/MM/DD")})
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <div className="d-inline-block d-flex justify-content-end align-items-center h-100">
                                                                            <div className="text-muted ms-2 mt-n1 lh-1-25">
                                                                                {plans?.plan_duration} hrs
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                    })
                                                } */}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey={1}>
                        <Accordion.Header className="w=100">
                          <div className="d-flex justify-content-between w-100">
                            <h6 className="">EOD Status</h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Comment</th>
                                <th>Planned Date</th>
                                <th>Planned (Hrs)</th>
                                <th>Type of Work</th>
                                <th>Status</th>
                                <th>Attachment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectActivityEODdata?.map((eod) => {
                                console.log(eod, "returnn eod");
                                if (
                                  eod?.project_id == projectId &&
                                  data?.id == eod?.activity_id
                                )
                                  return (
                                    <tr>
                                      <td> {eod?.eod_status} </td>
                                      <td>
                                        {dayjs(eod?.updated_at).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </td>
                                      <td>{eod?.actual_duration}</td>
                                      <td>
                                        {TYPE_OF_WORK[eod?.activity_type_id]}
                                      </td>
                                      <td>
                                        {eod?.status != 0
                                          ? EODSTATUS[eod?.status]
                                          : "Yet To Update"}
                                      </td>
                                      <td>
                                        {" "}
                                        <div
                                          className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl"
                                          onClick={() =>
                                            window.open(
                                              `${API_BASE}/${eod?.attachment}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          <CsLineIcons
                                            icon="file-text"
                                            className="text-white"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                              })}
                            </tbody>
                          </Table>
                          <div className="d-flex justify-content-between px-2">
                            <strong>
                              Total count : {eodDetailValues?.length}
                            </strong>
                            <TablePagination
                              component="div"
                              count={eodDetailValues?.length}
                              page={eodrowsPerPage}
                              onPageChange={eodhandleChangePage}
                              rowsPerPage={eodlimit}
                              onRowsPerPageChange={eodhandleChangeRowsPerPage}
                            />
                          </div>
                          {/* {
                                                    projectActivityEODdata?.map((eod) => {
                                                        if (eod?.project_id == projectId && data?.id == eod?.activity_id)
                                                            return (
                                                                <Row className="g-0 mb-2">
                                                                    <Col xs="auto">
                                                                        <div className="sw-3 d-inline-block d-flex justify-content-start align-items-center h-100">
                                                                            <div className="sh-3">
                                                                                <CsLineIcons
                                                                                    icon="chevron-right"
                                                                                    className="text-secondary align-top"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className="d-flex flex-column pt-0 pb-0 ps-3 pe-4 h-100 justify-content-center">
                                                                            <div className="d-flex flex-column">
                                                                                <div className="text-alternate mt-n1 lh-1-25">
                                                                                    {eod?.eod_status}{" "} ({dayjs(eod.updated_at).format("YYYY/MM/DD")})
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    {
                                                                        eod?.attachment ?

                                                                            <Col md={1}>
                                                                                <Card className="sh-10 hover-border-primary border-primary mt-3 mb-3" onClick={() => window.open(`${API_BASE}/${eod?.attachment}`, "_blank")}>
                                                                                    <Card.Body className="p-3 text-center align-items-center d-flex flex-column justify-content-between">
                                                                                        <div className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl">
                                                                                            <CsLineIcons
                                                                                                icon="file-text"
                                                                                                className="text-white"
                                                                                            />
                                                                                        </div>
                                                                                    </Card.Body>
                                                                                </Card>
                                                                            </Col>
                                                                            :
                                                                            ""}
                                                                </Row>
                                                            )
                                                    })
                                                } */}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey={2}>
                        <Accordion.Header className="w=100">
                          <div className="d-flex justify-content-between w-100">
                            <h6 className="">Mentor feedback</h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {mentorProjectFeedback?.map((feedback) => {
                            if (
                              feedback?.project_id == projectId &&
                              data?.id == feedback?.activity_id
                            )
                              return (
                                <>
                                  <Row>
                                    <Col md="6">
                                      <h2 className="">
                                        Feedback On:{" "}
                                        <strong>
                                          {dayjs(feedback?.updated_at).format(
                                            "YYYY/MM/DD"
                                          )}{" "}
                                        </strong>
                                      </h2>
                                    </Col>
                                    <Col md="6">
                                      <h2 className="">
                                        Overall Rating:{" "}
                                        <strong>
                                          {" "}
                                          {feedback?.overall_rankings}{" "}
                                        </strong>
                                      </h2>
                                    </Col>
                                  </Row>
                                  <Row className="">
                                    {skillDatas?.rows?.map(
                                      (data, skill_index) => {
                                        let parent =
                                          feedback?.project_feedback_rankings;
                                        let skillrank = parent.find((ele) => {
                                          return ele.skill_id == data.id;
                                        });
                                        console.log(skillrank, "RANTINGS");

                                        return (
                                          <>
                                            <Col md="6">
                                              <h2>{data?.name}</h2>
                                              <Stack spacing={1}>
                                                <Rating
                                                  name="half-rating"
                                                  precision={0.5}
                                                  value={
                                                    skillrank
                                                      ? skillrank?.rankings
                                                      : 0
                                                  }
                                                  disabled
                                                />
                                              </Stack>
                                            </Col>
                                          </>
                                        );
                                      }
                                    )}
                                    <Col md="6">
                                      <h5>
                                        {" "}
                                        <label htmlFor="">Feedback : </label>
                                      </h5>
                                      <div className="mb-3 filled form-group tooltip-end-top">
                                        <CsLineIcons icon="file-text" />
                                        <div className="filled form-group tooltip-end-top mt-3">
                                          {/* <CsLineIcons icon="user" /> */}
                                          <Form.Control
                                            as="textarea"
                                            name="feedback"
                                            placeholder="Feedback"
                                            className="is-invalid"
                                            defaultValue={
                                              feedback?.feedback ?? ""
                                            }
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <hr />
                                </>
                              );
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <hr />
                  </>
                );
            })}
          </Card>
        </Col>
        <Col>
          <Card className="p-4">
            <h2 className="">Activities ({fitmentActivity?.length})</h2>
            <ListGroup>
              {fitmentActivity?.map((activities, i) => {
                console.log(activities, "hasdusygd");
                return (
                  <>
                    <ListGroup.Item
                      key={i}
                      className="p-2"
                      onClick={() => handleActivity(activities?.id)}
                    >
                      <a href={null} className="pe-auto cursor-pointer">
                        <div className="d-flex justify-content-between w-100 p-2">
                          <p>{activities?.title}</p>
                          {/* <p>{activities?.duration} hrs</p> */}
                        </div>
                      </a>
                    </ListGroup.Item>
                  </>
                );
              })}
            </ListGroup>
          </Card>
        </Col>
        {/* Activity Feedback */}
        <Modal
          show={feedback_show}
          id="settings"
          dialogClassName="modal-100w"
          aria-labelledby="settings"
          tabIndex="-1"
          scrollable
          size="xl"
        >
          <div className="p-3 mt-2" style={{ overflow: "auto" }}>
            <>
              {/* <form
                                id="activityFeedbackForm"
                                className={
                                    validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                                }
                                onFeedbackSubmit={(e) => {
                                    e.preventDefault();
                                    setFeedbackValidated(true);
                                    handleFeedbackSubmit();
                                }}
                            > */}
              <h1>Activity Feedback</h1>
              <Row>
                <Col md="6">
                  <h5>
                    <label htmlFor="">
                      Culture Catalyst Name:{" "}
                      <strong>{studentDetailsDatas?.name}</strong>
                    </label>
                  </h5>
                </Col>
                <Col md="6">
                  <h5>
                    <label htmlFor="">
                      Domain Name:{" "}
                      <strong>{studentDetailsDatas?.departments?.name}</strong>
                    </label>
                  </h5>
                </Col>
              </Row>
              <Row className="mt-2">
                <h5>
                  <label htmlFor="">
                    Activity: <strong>{activityValues?.title}</strong>
                  </label>
                </h5>
                {skillDatas?.rows?.map((data) => {
                  return (
                    <>
                      <Col md="6">
                        <h2>{data?.name}</h2>
                        <Stack spacing={1}>
                          <Rating
                            name="half-rating"
                            precision={0.5}
                            onChange={(e) => {
                              starRatings(e.target.value, data.id);
                            }}
                          />
                        </Stack>
                      </Col>
                    </>
                  );
                })}
                <Col md="6">
                  <h5>
                    {" "}
                    <label htmlFor="">Feedback : </label>
                  </h5>
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="file-text" />
                    <div className="filled form-group tooltip-end-top mt-3">
                      {/* <CsLineIcons icon="user" /> */}
                      <Form.Control
                        as="textarea"
                        name="feedback"
                        placeholder="Feedback"
                        value={feedbackValue}
                        className="is-invalid"
                        onChange={(e) => {
                          setfeedbackValue(e.target.value);
                        }}
                      />
                      {errorMsg?.feedbackError && (
                        <p className="text-danger">Feedback is required</p>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="text-end">
                <LoadingButton
                  type="button"
                  variant="contained"
                  loading={
                    ![API_STATUS.FULFILLED, "initial"].includes(
                      mentorFeedbackLoader
                    )
                  }
                  onClick={MentorUpdate}
                >
                  Confirm
                </LoadingButton>
                <Button
                  variant="primary"
                  className="mx-1"
                  onClick={resetValues}
                >
                  Cancel
                </Button>
              </div>
              {/* </form> */}
            </>
          </div>
        </Modal>
      </Row>
    </div>
  );
};

export default ProjectActivityProgress;
