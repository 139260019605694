import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CollegeAdd, GeneralSettingsSelector, Get_College_ByID, CollegeUpdate, clearGeneralSettingsData } from "../../store/reducer/GeneralSettingsReducer"
import { localFrontend, FRONTEND_BASE } from "../../services/config";
import { LoadingButton } from "@mui/lab";

const CollegeManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const title = id ? "College Edit" : "College Add";
    const [validated, setValidated] = useState(false);
    const [Loginerror, setLoginerror] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("College Name is required"),
        college_code: Yup.string().required("College Code Name is required"),
    })

    const [initialValues, setInitialValues] = useState({
        name: "", college_code: ""
    })
    const onSubmit = (formData) => {
        if (id) {
            dispatch(CollegeUpdate({ id, formData }))
        } else {
            dispatch(CollegeAdd({ formData }));
        }

    };

    const formik = useFormik({ initialValues, validationSchema, onSubmit });
    const { handleSubmit, handleChange, values, touched, errors } = formik;


    const { college_add_Loading, college_update_Loading, collegeIdData, genereal_settings_errorMessage } = useSelector(GeneralSettingsSelector)

    useEffect(() => {
        console.log(id, "collegeId");
        if (id)
            dispatch(Get_College_ByID({ id: id }))
    }, [id]);

    useEffect(() => {
        if (id && collegeIdData !== "") {
            formik.setValues({
                name: collegeIdData?.name,
                college_code: collegeIdData?.college_code,
            })
        } 
    }, [collegeIdData])

    useEffect(() => {
        if (college_add_Loading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: "College Added Successfully!",
                icon: 'success'
            });
            dispatch(clearGeneralSettingsData());
            navigate("/admin/college");
        }
        if (college_update_Loading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: "College Updated Successfully!",
                icon: 'success'
            }); dispatch(clearGeneralSettingsData());
            navigate("/admin/college");
        }
        if (((college_add_Loading === API_STATUS.REJECTED) || (college_update_Loading === API_STATUS.REJECTED)) && (genereal_settings_errorMessage != null)) {
            if (genereal_settings_errorMessage.message) Swal.fire({
                title: genereal_settings_errorMessage.message,
                icon: 'error'
            });
            else if (genereal_settings_errorMessage.errors) {
                let data = "";
                genereal_settings_errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                Swal.fire({
                    title: data,
                    icon: 'error'
                })
            }
            dispatch(clearGeneralSettingsData());
        }
    }, [college_add_Loading, college_update_Loading, genereal_settings_errorMessage]);

    return (
        <>
            <Row>
                <Col>
                    {/* Title Start */}
                    <section className="scroll-section" id="title">
                        <div className="page-title-container">
                            <h1 className="mb-0 pb-0 display-4">{title}</h1>
                            <Breadcrumb className="breadcrumb-container d-inline-block">
                                <Breadcrumb.Item key={`breadCrumb1`}>
                                </Breadcrumb.Item>
                            </Breadcrumb>

                        </div>
                        <Link to="/admin/college">
                            <div className="text-end mb-2">
                                <Button variant='success' type='submit'>Back</Button>
                            </div>
                        </Link>
                    </section>
                    {/* Title End */}

                    {/* Standard Start */}
                    <section className="scroll-section" id="standard">
                        {/* <h2 className="small-title">Standard</h2> */}
                        <Card body className="mb-5">
                            {/* List Header Start */}
                            <form
                                id="collegeForm"
                                className={
                                    validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                                }
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setValidated(true);
                                    handleSubmit();
                                }}
                            >
                                <div className="mb-3 filled form-group tooltip-end-top">
                                    {Loginerror && Loginerror.error && (
                                        <Form.Control.Feedback
                                            type="invalid"
                                            style={{ display: "block" }}
                                        >
                                            {Loginerror.error}
                                        </Form.Control.Feedback>
                                    )}
                                </div>
                                <Row>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file" />
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder="College Name"
                                                value={values.name}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.name && touched.name && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file" />
                                            <Form.Control
                                                type="text"
                                                name="college_code"
                                                placeholder="College Code"
                                                value={values.college_code}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.college_code && touched.college_code && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.college_code}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-end mt-2">

                                    <LoadingButton variant='contained' loading={![API_STATUS.FULFILLED, 'initial'].includes(college_add_Loading || college_update_Loading)} type="submit" >
                                        {id ? "Update" : "Create"}
                                    </LoadingButton>
                                </div>
                            </form>
                        </Card>
                    </section>

                    {/* Positions End */}
                </Col>
            </Row >
        </>
    );
};

export default CollegeManagement;
