import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  signInScheduler,
  signInSelector,
  clearData,
  signInSocial,
} from "../store/reducer/SignInReducer";
import { API_STATUS } from "../utils/Constants";
import "../assets/css/responsive.css";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);

  const { signInData, signInLoading, errorMessage } =
    useSelector(signInSelector);
  console.log(signInData, "signInData");

  useEffect(() => {
    document.body.classList.add("h-100");
    const root = document.getElementById("root");
    if (root) {
      root.classList.add("h-100");
    }
    return () => {
      document.body.classList.remove("h-100");
      if (root) {
        root.classList.remove("h-100");
      }
    };
  }, []);

  const [captcha, setCaptcha] = useState({
    callback: "not fired",
    value: "[empty]",
    load: false,
    expired: "false",
    recaptchaLoaded: false,
  });
  const recaptchaRef = React.createRef();

  const asyncScriptOnLoad = () => {
    setCaptcha({ callback: "called!", recaptchaLoaded: true });
  };

  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const initialValues = { email: "", password: "" };
  const onSubmit = (formData) => {
    console.log(formData, "STUDENTFORM");
    dispatch(signInScheduler({ formData }));
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  useEffect(() => {
    if (signInLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Logged in Successfully!",
        icon: "success",
      });
      dispatch(clearData());
      console.log(signInData, "signInData1");
      if (signInData?.student[0]?.certificate_status == 1) {
        navigate("/certificate/view/");
      } else {
        if (signInData?.student[0]?.primarlimary_test_status !== 0) {
          navigate("student/dashboard");
        } else {
          navigate("/welcome");
        }
      }
    }
    if (signInLoading === API_STATUS.REJECTED && errorMessage) {
      // toast.dismiss();
      // errors.password = errorMessage.error;
      setLoginerror(errorMessage);
      console.log(errorMessage.error, "ERRORS");
      dispatch(clearData());
      // toast.error(errorMessage.error);
    }
  }, [signInLoading, errorMessage]);

  return (
    <>
      {/* Background Start */}
      <div className="fixed-background" />
      {/* Background End */}

      <div className="container-fluid p-0 h-100 position-relative login_pages">
        <div className="row g-0 h-100">
          {/* Left Side Start */}
          <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
            <div className="min-h-100 d-flex align-items-center">
              <div className="w-100 w-lg-75 w-xxl-50">
                <div>
                  <div className="mb-5">
                    {/* <div class="logo "><div class="img" style={{width:"100%",height:"90px"}}></div></div> */}
                    <div
                      className="logo-default h-100 mb-5"
                      style={{ width: "100%", minHeight: "90px" }}
                    />
                    <h1 className="display-3 text-white">
                      Create a Workplace Culture that Inspires and Innovates.
                    </h1>
                    {/* <h1 className="display-3 text-white">
                      Ready for Your Project
                    </h1> */}
                  </div>
                  {/* <p className="h6 text-white lh-1-5 mb-5">
                    Dynamically target high-payoff intellectual capital for
                    customized technologies. Objectively integrate emerging core
                    competencies before process-centric communities...
                  </p>
                  <div className="mb-5">
                    <Button size="lg" variant="outline-white" href="/">
                      Learn More
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Left Side End */}

          {/* Right Side Start */}
          <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
            <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
              <div className="sw-lg-50 px-5">
                {/* <div className="sh-11">
                  <NavLink to="/">
                    <div className="logo-default" />
                  </NavLink>
                </div> */}
                <div className="mb-5">
                  <h2 className="cta-1 mb-0 text-primary">Welcome,</h2>
                  <h2 className="cta-1 text-primary">let's get started!</h2>
                </div>
                <div className="mb-5">
                  <p className="h6">Please use your credentials to login.</p>
                  <p className="h6">
                    If you are not a member, please{" "}
                    <NavLink
                      to="/signup"
                      className="h5"
                      style={{ textDecoration: "underline", color: "red" }}
                    >
                      Register
                    </NavLink>
                    .
                  </p>
                </div>
                <div>
                  <form
                    id="loginForm"
                    className={
                      validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                    }
                    onSubmit={(e) => {
                      e.preventDefault();
                      setValidated(true);
                      handleSubmit();
                    }}
                  >
                    <div className="mb-3 filled form-group tooltip-end-top">
                      {Loginerror && Loginerror.error && (
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: "block" }}
                        >
                          {Loginerror.error}
                        </Form.Control.Feedback>
                      )}
                    </div>
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="email" />
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </div>
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="lock-off" />
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        // value={values.password}
                        className="is-invalid"
                        placeholder="Password"
                      />
                      <NavLink
                        className="text-small position-absolute t-3 e-3"
                        to="/forgot_password"
                      >
                        Forgot?
                      </NavLink>
                      {errors.password && touched.password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                      {/* <a href="#">Forgot Password?</a>  */}
                      <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        theme="dark"
                        size="invisible"
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_G_CAPTCHA || ""}
                        onChange={handleChange}
                        asyncScriptOnLoad={asyncScriptOnLoad}
                      />
                    </div>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={
                        ![API_STATUS.FULFILLED, "initial"].includes(
                          signInLoading
                        )
                      }
                    >
                      Login
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Right Side End */}
        </div>
      </div>
    </>
  );
};

export default SignIn;
