import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS, TYPE_OF_ACTIVITY } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsPencil, BsCardList } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { TablePagination } from "@mui/material";
import {
  courseSelector,
  getActivitiesTypeScheduler,
  clearCourseLoadings,
  clearCourseData,
} from "../../store/reducer/CourseReducer";
import _ from "lodash";
const ActivityTypeList = () => {
  const title = "Activity Type List";

  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);

  useEffect(() => {
    let type = 1;
    dispatch(getActivitiesTypeScheduler({ page, limit, query, type }));
  }, []);

  const { activityTypes, errorMessage } = useSelector(courseSelector);

  useEffect(() => {
    console.log(activityTypes, "activityTypes");
  }, [activityTypes]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = activityTypes?.count % 10;
    let remainder = activityTypes?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [activityTypes]);

  useEffect(() => {
    let type = '1';
    dispatch(getActivitiesTypeScheduler({ page, limit, query, type }));
  }, [page, limit, query]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Link to="/activity/add/">
              <div className="text-end mb-2">
                <Button variant="success" type="submit">
                  <CsLineIcons icon="plus" />
                  Add Activity Type
                </Button>
              </div>
            </Link>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Activity</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {activityTypes &&
                    activityTypes?.rows?.map((typeValues, index) => {
                      let Type = _.split(typeValues?.type, ",");
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{typeValues?.name}</td>
                            <td>
                              {Type?.length == 1
                                ? TYPE_OF_ACTIVITY[Type[0]]
                                : TYPE_OF_ACTIVITY[Type[1]]}
                            </td>
                            <td>
                              {typeValues?.status == 1 ? "In-Active" : "Active"}
                            </td>
                            <td>
                              <Link
                                to={`/activity/edit/${typeValues.id}`}
                                title="Edit"
                                className="btn btn-primary mx-1 "
                              >
                                <BsPencil />
                              </Link>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between px-2">
                <strong>Total count : {activityTypes?.count}</strong>
                <TablePagination
                  component="div"
                  count={activityTypes?.count}
                  page={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default ActivityTypeList;
