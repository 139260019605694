import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsPencil, BsCardList } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { TablePagination } from "@mui/material";
import {
  departmentLists,
  placementSelector,
} from "../../store/reducer/PlacementReducer";

const DomainList = () => {
  const title = "Domain Lists";
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);

  useEffect(() => {    
    dispatch(departmentLists({ page, limit, query }));
  }, []);

  const {
    testData,
    testLoading,
    departmentData,
    errorMessage,
    departupdateLoading,
  } = useSelector(placementSelector);
  useEffect(() => {
    console.log(departmentData, "departmentData");
  }, [departmentData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = departmentData?.count % 10;
    let remainder = departmentData?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [departmentData]);

  useEffect(() => {
    dispatch(departmentLists({ query, limit, page }));
  }, [page, limit, query]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Link to="/domain/add/">
              <div className="text-end mb-2">
                <Button variant="success" type="submit">
                  <CsLineIcons icon="plus" />
                  Add Domain
                </Button>
              </div>
            </Link>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Domain Name</th>
                    <th>Total Sprints</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {departmentData &&
                    departmentData?.rows?.map((departmentValues, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{departmentValues?.name}</td>
                            <td>{departmentValues?.sprint?.length}</td>
                            <td>
                              {departmentValues?.status == 1
                                ? "Active"
                                : "In-Active"}
                            </td>
                            <td>
                              <Link
                                to={`/domain/edit/${departmentValues.id}`}
                                title="Edit"
                                className="btn btn-primary mx-1 "
                              >
                                <BsPencil />
                              </Link>
                              <Link
                                to={`/domain/sprint/${departmentValues.id}`}
                                title="View Sprints"
                                className="btn btn-primary mx-1 "
                              >
                                <BsCardList />
                              </Link>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between px-2">
                <strong>Total count : {departmentData?.count}</strong>
                <TablePagination
                  component="div"
                  count={departmentData?.count}
                  page={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default DomainList;
