import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS, TYPE_OF_ACTIVITY } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  TypeActivityAdd,
  GeneralSettingsSelector,
  TypeActivity_ByID,
  TypeActivityUpdate,
  clearGeneralSettingsData,
  SoftSkillAdd,
  SoftSkill_ByID,
  SoftSkillUpdate,
} from "../../store/reducer/GeneralSettingsReducer";
import {
  departmentLists,
  departmentUpdates,
  placementSelector,
  testLists,
} from "../../store/reducer/PlacementReducer";

import { localFrontend, FRONTEND_BASE } from "../../services/config";
import { LoadingButton } from "@mui/lab";
import _, { uniq } from "lodash";

const SoftSkillManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const title = id ? "Soft Skill Edit" : "Soft Skill Add";
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [errorTypeMsg, seterrorTypeMsg] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [assignstudents, setAssignstudents] = useState([]);

  useEffect(() => {
    let departmentQuery = {};
        departmentQuery.page = 0;
        departmentQuery.limit = 0;
        departmentQuery.query = "";        
        dispatch(departmentLists(departmentQuery ));
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Soft Skill is required"),
  });

  const [initialValues, setInitialValues] = useState({
    name: "",
    type: "",
  });
  const onSubmit = (formData) => {
    console.log(assignstudents[0], assignstudents, "ASDFASDF");
    if (_.isEmpty(assignstudents[0])) {
      seterrorTypeMsg(true);
      setValidated(false);
    } else {
      formData.type = assignstudents.toString();
      console.log(formData, "FORMDATAADD");
      if (id) {
        dispatch(SoftSkillUpdate({ id, formData }));
      } else {
        dispatch(SoftSkillAdd({ formData }));
      }
    }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const {
    genereal_settings_errorMessage,
    softskillIdData,
    soft_skill_add_Loading,
    type_activity_Loading,
    soft_skill_update_Loading,
  } = useSelector(GeneralSettingsSelector);

  const { departmentData } = useSelector(placementSelector);
  useEffect(() => {
    if (id) dispatch(SoftSkill_ByID({ id: id }));
  }, [id]);

  useEffect(() => {
    if (id && softskillIdData !== "") {
      formik.setValues({
        name: softskillIdData?.name,
      });
      let values = softskillIdData?.type?.split(",");
      values = values?.filter((val) => {        
        return val != "";
      });      
      setAssignstudents(values);
    }
  }, [softskillIdData]);

  useEffect(() => {
    if (soft_skill_add_Loading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Soft Skill Added Successfully!",
        icon: "success",
      });
      dispatch(clearGeneralSettingsData());
      navigate("/admin/soft_skill");
    }

    if (soft_skill_update_Loading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Soft Skill Updated Successfully!",
        icon: "success",
      });
      dispatch(clearGeneralSettingsData());
      navigate("/admin/soft_skill");
    }
    if (
      (soft_skill_add_Loading === API_STATUS.REJECTED ||
        soft_skill_update_Loading === API_STATUS.REJECTED) &&
      genereal_settings_errorMessage != null
    ) {
      if (genereal_settings_errorMessage.message)
        Swal.fire({
          title: genereal_settings_errorMessage.message,
          icon: "error",
        });
      else if (genereal_settings_errorMessage.errors) {
        let data = "";
        genereal_settings_errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        Swal.fire({
          title: data,
          icon: "error",
        });
      }
      dispatch(clearGeneralSettingsData());
    }
  }, [
    soft_skill_add_Loading,
    soft_skill_update_Loading,
    genereal_settings_errorMessage,
  ]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Link to="/admin/soft_skill">
              <div className="text-end mb-2">
                <Button variant="success" type="submit">
                  Back
                </Button>
              </div>
            </Link>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <form
                id="collegeForm"
                className={
                  validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                }
                onSubmit={(e) => {
                  e.preventDefault();
                  setValidated(true);
                  handleSubmit();
                }}
              >
                <div className="mb-3 filled form-group tooltip-end-top">
                  {Loginerror && Loginerror.error && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {Loginerror.error}
                    </Form.Control.Feedback>
                  )}
                </div>
                <Row>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file" />
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Soft Skill"
                        value={values.name}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.name && touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <></>
                  </Col>
                  <Row className="mt-2">
                    <h4>
                      <label htmlFor="">Domain List</label>
                    </h4>
                    {departmentData &&
                      departmentData?.rows?.map((department) => {
                        console.log(
                          assignstudents?.includes(department.id.toString()) ==
                            true,
                          "FSDFASFD"
                        );
                        return (
                          <>
                            <Col md="3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`formikTermsCheck_${department.id}`}
                                checked={assignstudents?.includes(
                                  department?.id?.toString()
                                )}
                                onClick={(e) => {
                                  const { checked } = e.target;
                                  if (checked) {
                                    setAssignstudents((prev) => [
                                      ...prev,
                                      department?.id?.toString(),
                                    ]);
                                  } else {
                                    setAssignstudents(
                                      _.filter(assignstudents, function (o) {
                                        return o != department?.id;
                                      })
                                    );
                                  }
                                }}
                              />
                              <label
                                className="form-check-label align-middle mx-1"
                                htmlFor={`formikTermsCheck_${department.id}`}
                              >
                                {department?.name}
                              </label>
                            </Col>
                          </>
                        );
                      })}
                  </Row>
                  {errorTypeMsg && (
                    <p className="text-danger">Domain is required</p>
                  )}
                </Row>
                <div className="text-end mt-2">
                  <LoadingButton
                    variant="contained"
                    loading={
                      ![API_STATUS.FULFILLED, "initial"].includes(
                        soft_skill_add_Loading || soft_skill_update_Loading
                      )
                    }
                    type="submit"
                    onClick={() => {
                      if (assignstudents?.length == 0) {
                        seterrorTypeMsg(true);
                        setValidated(false);
                      }
                    }}
                  >
                    {id ? "Update" : "Create"}
                  </LoadingButton>
                </div>
              </form>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default SoftSkillManagement;
