import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  hiring_partner_list,
  add_hiring_partner,
  get_hiring_partner,
  edit_hiring_partner,
  mentor_edit,
  createProject,
  ViewProject,
  mentor_by_user_id,
  updateProject,
  project_list,
  studentsByList,
  studentsByProject,
  studentsassign,
  mentor_activity_list,
  create_overall_feedback,
  mentor_cc_feedbacks,
} from "../../services/api";

const namespace = "hiringMentor";

const initialState = {
  loading: "initial",
  errorMessage: "",
  projectListDetails: null,
  hiringMentorDatas: null,
  hiringMentorEditData: null,
  hiringMentorProfileDatas: null,
  hiringMentorLoading: "initial",
  hiringMentorListLoading: "initial",
  hiringMentorStudentLoading: "initial",
  mentorProfileUpdateLoading: "initial",
  CreateJobSchedulerLoading: "initial",
  ViewProjectSchedulerLoading: "initial",
  UpdateJobScehdulerLoading: "initial",
  hiringMentorStudentProjLoading: "initial",
  assignstudentLoading: "initial",
  mentorCCLoading: "initial",
  studentsListProject: null,
  MentorCCFeedbackDatas: [],
  count: 0,
  projectDetails: "",
  studentsList: null,
};

export const ProjectListData = createAsyncThunk(
  `${namespace}/ProjectListData`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      // console.log(formData,id, "UpdateJob formdata");
      const { data } = await project_list();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const StudentListByMentor = createAsyncThunk(
  `${namespace}/StudentListByMentor`,
  async ({ page, limit, query}, { rejectWithValue, dispatch }) => {
    try {
      // console.log(formData,id, "UpdateJob formdata");
      const { data } = await studentsByList(page, limit, query);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const AssignedStudentsByProject = createAsyncThunk(
  `${namespace}/AssignedStudentsByProject`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      // console.log(formData,id, "UpdateJob formdata");
      const { data } = await studentsByProject(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const AssignStudentsadd = createAsyncThunk(
  `${namespace}/AssignStudentsadd`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      // console.log(formData,id, "UpdateJob formdata");
      const { data } = await studentsassign(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const UpdateProjectScehduler = createAsyncThunk(
  `${namespace}/UpdateProjectScehduler`,
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, id, "UpdateJob formdata");
      const { data } = await updateProject(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CreateProjectScheduler = createAsyncThunk(
  `${namespace}/CreateProjectScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "CreateJob formdata");
      const { data } = await createProject(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ViewProjectScheduler = createAsyncThunk(
  `${namespace}/ViewProjectScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "CreateJob formdata");
      const { data } = await ViewProject(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ActivityByMentor = createAsyncThunk(
  `${namespace}/ActivityByMentor`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await mentor_activity_list(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const UpdateProfileData = createAsyncThunk(
  `${namespace}/UpdateProfileData`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "UpdateProfileData");
      const { data } = await mentor_edit(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const getMentor = createAsyncThunk(
  `${namespace}/getMentor`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await mentor_by_user_id();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CreateCCFeedback = createAsyncThunk(
  `${namespace}/CreateCCFeedback`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await create_overall_feedback(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const MentorFeedbackbyStudent = createAsyncThunk(
  `${namespace}/MentorFeedbackbyStudent`,
  async ({ student_id }, { rejectWithValue, dispatch }) => {
    try {
      console.log(student_id, "MentorFeedbackbyStudent");
      const { data } = await mentor_cc_feedbacks(student_id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const hiringMentorSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearHiringMentorData: () => {
      return initialState;
    },
    clearData: (state) => {
      state.hiringMentorLoading = "initial";
      state.hiringMentorListLoading = "initial";
      state.mentorProfileUpdateLoading = "initial";
      state.CreateJobSchedulerLoading = "initial";
      state.UpdateJobScehdulerLoading = "initial";
      state.ViewProjectSchedulerLoading = "initial";
      state.hiringMentorStudentProjLoading = "initial";
      state.assignstudentLoading = "initial";
      state.mentorCCLoading = "initial";
      state.studentsListProject = null;
      state.projectDetails = "";
      state.studentsList = null;
      state.ActivityDetails = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(CreateProjectScheduler.pending, (state) => {
      state.CreateJobSchedulerLoading = API_STATUS.PENDING;
    });
    builder.addCase(CreateProjectScheduler.fulfilled, (state, { payload }) => {
      state.CreateJobSchedulerLoading = API_STATUS.FULFILLED;
      // state.hiringMentorEditData = payload?.data;
      console.log(payload, "hiringMentorEditData");
    });
    builder.addCase(CreateProjectScheduler.rejected, (state, action) => {
      state.CreateJobSchedulerLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(UpdateProjectScehduler.pending, (state) => {
      state.UpdateJobScehdulerLoading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateProjectScehduler.fulfilled, (state, { payload }) => {
      state.UpdateJobScehdulerLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateProjectScehduler.rejected, (state, action) => {
      state.UpdateJobScehdulerLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(ViewProjectScheduler.pending, (state) => {
      state.ViewProjectSchedulerLoading = API_STATUS.PENDING;
    });
    builder.addCase(ViewProjectScheduler.fulfilled, (state, { payload }) => {
      state.ViewProjectSchedulerLoading = API_STATUS.FULFILLED;
      state.projectDetails = payload?.data;
      console.log(payload, "hiringMentorEditData");
    });
    builder.addCase(ViewProjectScheduler.rejected, (state, action) => {
      state.ViewProjectSchedulerLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(ProjectListData.pending, (state) => {
      state.hiringMentorLoading = API_STATUS.PENDING;
    });
    builder.addCase(ProjectListData.fulfilled, (state, { payload }) => {
      state.hiringMentorLoading = API_STATUS.FULFILLED;
      state.projectListDetails = payload;
      console.log(state.projectListDetails, "JUDJDJ");
    });
    builder.addCase(ProjectListData.rejected, (state, action) => {
      state.hiringMentorLoading = API_STATUS.REJECTED;
      console.log(action.payload, "HiringMentorProfile");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(StudentListByMentor.pending, (state) => {
      state.hiringMentorStudentLoading = API_STATUS.PENDING;
    });
    builder.addCase(StudentListByMentor.fulfilled, (state, { payload }) => {
      state.hiringMentorStudentLoading = API_STATUS.FULFILLED;
      state.studentsList = payload;
      // console.log(state.projectListDetails, "JUDJDJ");
    });
    builder.addCase(StudentListByMentor.rejected, (state, action) => {
      state.hiringMentorStudentLoading = API_STATUS.REJECTED;
      console.log(action.payload, "HiringMentorProfile");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(AssignedStudentsByProject.pending, (state) => {
      state.hiringMentorStudentProjLoading = API_STATUS.PENDING;
    });
    builder.addCase(
      AssignedStudentsByProject.fulfilled,
      (state, { payload }) => {
        state.hiringMentorStudentProjLoading = API_STATUS.FULFILLED;
        state.studentsListProject = payload?.rows;
        // console.log(state.projectListDetails, "JUDJDJ");
      }
    );
    builder.addCase(AssignedStudentsByProject.rejected, (state, action) => {
      state.hiringMentorStudentProjLoading = API_STATUS.REJECTED;
      console.log(action.payload, "HiringMentorProfile");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(AssignStudentsadd.pending, (state) => {
      state.assignstudentLoading = API_STATUS.PENDING;
    });
    builder.addCase(AssignStudentsadd.fulfilled, (state, { payload }) => {
      state.assignstudentLoading = API_STATUS.FULFILLED;
      // state.hiringMentorEditData = payload?.data;
      console.log(payload, "hiringMentorEditData");
    });
    builder.addCase(AssignStudentsadd.rejected, (state, action) => {
      state.assignstudentLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(ActivityByMentor.pending, (state) => {
      state.hiringMentorLoading = API_STATUS.PENDING;
    });
    builder.addCase(ActivityByMentor.fulfilled, (state, { payload }) => {
      state.hiringMentorLoading = API_STATUS.FULFILLED;
      state.ActivityDetails = payload?.count;
      console.log(payload, "MentorList");
    });
    builder.addCase(ActivityByMentor.rejected, (state, action) => {
      state.hiringMentorLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getMentor.pending, (state) => {
      state.hiringMentorLoading = API_STATUS.PENDING;
    });
    builder.addCase(getMentor.fulfilled, (state, { payload }) => {
      state.hiringMentorLoading = API_STATUS.FULFILLED;
      state.hiringMentorProfileDatas = payload?.data;
      console.log(payload, "MentorList");
    });
    builder.addCase(getMentor.rejected, (state, action) => {
      state.hiringMentorLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(UpdateProfileData.pending, (state) => {
      state.mentorProfileUpdateLoading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateProfileData.fulfilled, (state, { payload }) => {
      state.mentorProfileUpdateLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateProfileData.rejected, (state, action) => {
      state.mentorProfileUpdateLoading = API_STATUS.REJECTED;
      console.log(action.payload, "EditHiringPartnerData");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(CreateCCFeedback.pending, (state) => {
      state.mentorCCLoading = API_STATUS.PENDING;
    });
    builder.addCase(CreateCCFeedback.fulfilled, (state, { payload }) => {
      state.mentorCCLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(CreateCCFeedback.rejected, (state, action) => {
      state.mentorCCLoading = API_STATUS.REJECTED;
      console.log(action.payload, "EditHiringPartnerData");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(MentorFeedbackbyStudent.pending, (state) => {
      state.hiringMentorLoading = API_STATUS.PENDING;
    });
    builder.addCase(MentorFeedbackbyStudent.fulfilled, (state, { payload }) => {
      state.hiringMentorLoading = API_STATUS.FULFILLED;
      state.MentorCCFeedbackDatas = payload?.datas;
      console.log(state.MentorCCFeedbackDatas, "MentorCCFeedbackDatas");
    });
    builder.addCase(MentorFeedbackbyStudent.rejected, (state, action) => {
      state.hiringMentorLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearHiringMentorData, clearData } = hiringMentorSlice.actions;

export const hiringmentorSelector = (state) => state.hiringMentor;

export default hiringMentorSlice.reducer;
