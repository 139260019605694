import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TablePagination } from "@mui/material";
import {
  EmptyCourseData,
  ProjectActivityEODList,
  ProjectActivityList,
  courseDetails,
  courseSelector,
  getActivitiesTypeScheduler,
  planDetail,
  postPlanDetails,
} from "../../store/reducer/CourseReducer";
import {
  MentorProjectFeedback,
  ProfileStudent,
  StudentSelector,
  clearData,
  createProjectEOD,
  createProjectPlan,
  fitmentProjectScheduler,
} from "../../store/reducer/StudentReducer";
import { useFormik } from "formik";
import { eodValidator, planValidator } from "../../validators/Validators";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { API_STATUS, EODSTATUS, TYPE_OF_WORK } from "../../utils/Constants";
import { API_BASE } from "../../services/config";

const ProjectActivity = () => {
  //id, query, order, limit, sort, page
  const name = localStorage.getItem("username");
  const keysArray = [
    "activity_id",
    "planned_desc",
    "planned_hrs",
    "activity_type_id",
    "actual_duration",
    "comments",
    "attachment",
    "status",
  ];
  const [show, setShow] = useState({
    pfd: false,
    eod: false,
  });
  const [studentId, setstudentId] = useState();
  const dispatch = useDispatch();
  const [inProgressId, setInProgressId] = useState();
  const [currentActivityId, setCurrentActivityId] = useState();
  const navigate = useNavigate();
  const { hiring_partner_id, projectId } = useParams();
  const [order, setOrder] = useState("");
  const [disablePlan, setDisablePlan] = useState(false);
  const [keySet, setKeySet] = useState("pfd");
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [query, setQuery] = useState("");

  const [planpage, setplanPage] = useState(1);
  const [planlimit, setplanLimit] = useState(10);
  const [planquery, setplanQuery] = useState("");
  const [planpageSize, setplanPageSize] = useState(1);
  const [planrowsPerPage, setplanRowsPerPage] = useState(0);
  const [planDetailValues, setplanDetailValues] = useState([]);

  const [eodpage, seteodPage] = useState(1);
  const [eodlimit, seteodLimit] = useState(10);
  const [eodquery, seteodQuery] = useState("");
  const [eodpageSize, seteodPageSize] = useState(1);
  const [eodrowsPerPage, seteodRowsPerPage] = useState(0);
  const [eodDetailValues, seteodDetailValues] = useState([]);

  const [feedbackpage, setfeedbackPage] = useState(1);
  const [feedbacklimit, setfeedbackLimit] = useState(10);
  const [feedbackquery, setfeedbackQuery] = useState("");
  const [feedbackpageSize, setfeedbackPageSize] = useState(1);
  const [feedbackrowsPerPage, setfeedbackRowsPerPage] = useState(0);
  const [feedbackDetailValues, setfeedbackDetailValues] = useState([]);

  const {
    studentProfileDatas,
    createProjectPlanLoad,
    createProjectEODLoad,
    fitmentData,
    mentorProjectFeedback,
    fitmentActivity,
  } = useSelector(StudentSelector);
  const { projectActivitydata, activityTypes, projectActivityEODdata } =
    useSelector(courseSelector);
  const [validated, setValidated] = useState(false);

  const [eods, setEods] = useState([
    {
      actual_duration: "",
      status: "",
      attachment: "",
      activity_type_id: "",
      activity_id: "",
      planned_hrs: "",
      comments: "",
      planned_desc: "",
    },
  ]);
  const handleActivity = (id) => {
    setCurrentActivityId(id);
  };
  useEffect(() => {
    let planData = [];
    planData = projectActivitydata?.filter((ele) => {
      return ele.activity_id == currentActivityId;
    });
    setplanDetailValues(planData);

    let eodData = [];
    eodData = projectActivityEODdata?.filter((ele) => {
      return ele.activity_id == currentActivityId;
    });
    seteodDetailValues(eodData);

    let feedbackDatas = [];
    feedbackDatas = mentorProjectFeedback?.filter((ele) => {
      return ele.activity_id == currentActivityId;
    });
    setfeedbackDetailValues(feedbackDatas);
  }, [currentActivityId]);
  const [tasks, setTasks] = useState([
    {
      student_id: "",
      project_id: "",
      activity_id: "",
      plan: "",
      plan_duration: "",
      activity_type_id: "",
    },
  ]);

  useEffect(() => {
    let data = [];
    let inprogress = [];
    projectActivitydata?.map((ele) => {
      const updatedAtDateTime = dayjs(ele.updated_at);
      console.log(
        dayjs(updatedAtDateTime).format("hh:mm"),
        "updatedAtDateTime"
      );
      const currentTime = dayjs();
      const isMoreThan31HoursAgo = updatedAtDateTime.isBefore(
        currentTime.subtract(31, "hour")
      );
      // console.log(first)
      if (isMoreThan31HoursAgo && ele.status === 2) {
        setDisablePlan(true);
        Swal.fire({
          title:
            "Complete and fill In Progress Activity(which is mentioned in EOD Status) and then continue your today's activity",
          icon: "warning",
        });
      }
      const isWithinLast24Hours = updatedAtDateTime.isAfter(
        currentTime.subtract(8, "hour")
      );
      // updatedAtDateTime.isBefore(currentTime.add(24, 'hour'));
      console.log(isWithinLast24Hours, "isWithinLast24Hours updatedAtDateTime");
      if (isMoreThan31HoursAgo && ele.status === 2) inprogress.push(ele.id);
      if (isWithinLast24Hours && ele.status !== 1)
        data.push({
          actual_duration: "",
          status: "",
          attachment: "",
          activity_type_id: ele?.activity_type_id,
          activity_id: ele?.activity_id,
          planned_hrs: ele?.plan_duration,
          comments: "",
          planned_desc: ele?.plan,
          plan_id: ele?.id,
        });
    });
    setInProgressId(inprogress);
    setEods(data);
  }, [projectActivitydata]);

  useEffect(() => {
    if (projectId) {
      // const query =
      //   studentProfileDatas?.studentData?.hiring_partner_students &&
      //   studentProfileDatas?.studentData?.hiring_partner_students[0];
      let query =
        studentProfileDatas?.studentData?.hiring_partner_student?.find(
          (ele) => {
            return ele?.org_id == hiring_partner_id;
          }
        );
      let payload = {
        mentor_id: query?.mentor_id,
        student_id: studentProfileDatas?.studentData?.id,
        project_id: projectId,
      };
      dispatch(fitmentProjectScheduler({ payload }));
      dispatch(
        ProjectActivityList({
          page: planpage,
          limit: planlimit,
          query: planquery,
          sortby: sort,
          order,
          status: "",
          student_id: studentProfileDatas?.studentData?.id,
          project_id: projectId,
          date: "",
        })
      );
      dispatch(
        ProjectActivityEODList({
          page: eodpage,
          limit: eodlimit,
          query: eodquery,
          sortby: sort,
          order,
          status: "",
          student_id: studentProfileDatas?.studentData?.id,
          project_id: projectId,
          date: "",
        })
      );
      dispatch(
        MentorProjectFeedback({
          page,
          limit,
          query: "",
          sortby: sort,
          order,
          status: "",
          student_id: studentProfileDatas?.studentData?.id,
          project_id: projectId,
          date: "",
        })
      );
    }
    setstudentId(studentProfileDatas?.studentData?.id);
  }, [studentProfileDatas, projectId]);

  useEffect(() => {
    dispatch(ProfileStudent({}));
  }, []);

  const handleAddTask = () => {
    if (show.pfd)
      setTasks([
        ...tasks,
        { plan: "", plan_duration: "", activity_type_id: "", activity_id: "" },
      ]);
    else
      setEods([
        ...eods,
        {
          actual_duration: "",
          status: "",
          attachment: "",
          activity_type_id: "",
          activity_id: "",
          planned_hrs: "",
          comments: "",
          planned_desc: "",
        },
      ]);
  };

  const onSubmit = (formData) => {
    const data = show.pfd ? tasks : eods;
    console.log(data, "test error");
    const excludedKey = "attachment";
    const excluded = ["student_id", "project_id"];
    const hasEmptyFields = show.pfd
      ? tasks.some((ele) =>
          Object.entries(ele).some(
            ([key, value]) => !excluded.includes(key) && value === ""
          )
        )
      : eods.some((ele) =>
          Object.entries(ele).some(
            ([key, value]) => key !== excludedKey && value === ""
          )
        );

    if (hasEmptyFields) {
      Swal.fire({ title: "Fill all fields", icon: "error" });
    } else {
      data.forEach((ele) => {
        ele.project_id = projectId;
        ele.student_id = studentId;
        if (ele.status || ele.status == "") {
          ele["eod_status"] = ele.status;
          delete ele["status"];
        }
      });
      console.log(data, "formdata data");
      if (show.pfd) dispatch(createProjectPlan({ formData: data }));
      else dispatch(createProjectEOD({ formData: data }));
    }
  };

  const handleForm = (index, event) => {
    const { value, name } = event.target;
    let newEod = [...eods];
    let newTasks = [...tasks];

    if (!newTasks[index]) {
      newTasks[index] = {};
    }
    if (!newEod[index]) {
      newEod[index] = {};
    }

    if (show.pfd) {
      newTasks[index][name] = value;
      setTasks(newTasks);
    } else {
      newEod[index][name] =
        name === "attachment" ? event.target.files[0] : value;
      setEods(newEod);
    }
  };

  const handleEODShow = () => {
    setShow({ pfd: false, eod: true });
    setKeySet("eod");
    let activityQuery = {};
    activityQuery.page = 0;
    activityQuery.limit = 0;
    activityQuery.query = "";
    activityQuery.type = 1;
    dispatch(getActivitiesTypeScheduler(activityQuery));
  };

  const handlePlan = () => {
    if (!disablePlan) {
      dispatch(
        ProjectActivityList({
          page: planpage,
          limit: planlimit,
          query: planquery,
          sortby: sort,
          order,
          status: "",
          student_id: studentId,
          project_id: projectId,
          date: "",
        })
      );
      let activityQuery = {};
      activityQuery.page = 0;
      activityQuery.limit = 0;
      activityQuery.query = "";
      activityQuery.type = 1;
      dispatch(getActivitiesTypeScheduler(activityQuery));
      setKeySet("pfd");
      setShow({ pfd: true, eod: false });
    } else {
      Swal.fire({
        title: "",
        icon: "warning",
      });
    }
  };

  useEffect(() => {
    if (createProjectPlanLoad === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Plan Updated Successfully!",
        icon: "success",
      });
      setShow({ pfd: false, eod: false });
      dispatch(clearData());
      dispatch(
        ProjectActivityList({
          page: planpage,
          limit: planlimit,
          query: planquery,
          sortby: sort,
          order,
          status: "",
          student_id: studentProfileDatas?.studentData?.id,
          project_id: projectId,
          date: "",
        })
      );
    }
    if (createProjectEODLoad === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "EOD Updated Successfully!",
        icon: "success",
      });
      setShow({ pfd: false, eod: false });
      dispatch(
        ProjectActivityEODList({
          page: eodpage,
          limit: eodlimit,
          query: eodquery,
          sortby: sort,
          order,
          status: "",
          student_id: studentProfileDatas?.studentData?.id,
          project_id: projectId,
          date: "",
        })
      );
      dispatch(clearData());
      setEods([]);
    }
  }, [createProjectPlanLoad, createProjectEODLoad]);

  const initialValues = show.pfd ? tasks : eods;
  const validationSchema = show.pfd ? planValidator : eodValidator;
console.log(eods,"INTIAL");
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  /** Table Pagination for Plan for the Day  */
  const planhandleChangePage = (event, newPage) => {
    setplanPage(newPage + 1);
    setplanRowsPerPage(newPage);
  };

  const planhandleChangeRowsPerPage = (event) => {
    setplanLimit(parseInt(event.target.value, 10));
    setplanPage(0);
  };

  useEffect(() => {
    let count = planDetailValues?.length % 10;
    let remainder = planDetailValues?.length / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setplanPageSize(pageLength);
  }, [planDetailValues]);

  useEffect(() => {
    dispatch(
      ProjectActivityList({
        page: planpage,
        limit: planlimit,
        query: planquery,
        sortby: sort,
        order,
        status: "",
        student_id: studentProfileDatas?.studentData?.id,
        project_id: projectId,
        date: "",
      })
    );
  }, [planpage, planlimit, planquery]);

  /** Table Pagination for EOD  */
  const eodhandleChangePage = (event, newPage) => {
    seteodPage(newPage + 1);
    seteodRowsPerPage(newPage);
  };

  const eodhandleChangeRowsPerPage = (event) => {
    seteodLimit(parseInt(event.target.value, 10));
    seteodPage(0);
  };

  useEffect(() => {
    console.log(eodDetailValues, "eodDetailValues1");
    let count = eodDetailValues?.length % 10;
    let remainder = eodDetailValues?.length / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    seteodPageSize(pageLength);
  }, [eodDetailValues]);

  useEffect(() => {
    dispatch(
      ProjectActivityEODList({
        page: eodpage,
        limit: eodlimit,
        query: eodquery,
        sortby: sort,
        order,
        status: "",
        student_id: studentProfileDatas?.studentData?.id,
        project_id: projectId,
        date: "",
      })
    );
  }, [eodpage, eodlimit, eodquery]);

  /** Table Pagination for Feedback  */
  const feedbackhandleChangePage = (event, newPage) => {
    setfeedbackPage(newPage + 1);
    setfeedbackRowsPerPage(newPage);
  };

  const feedbackhandleChangeRowsPerPage = (event) => {
    setfeedbackLimit(parseInt(event.target.value, 10));
    setfeedbackPage(0);
  };

  useEffect(() => {
    console.log(feedbackDetailValues, "feedbackDetailValues1");
    let count = feedbackDetailValues?.length % 10;
    let remainder = feedbackDetailValues?.length / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setfeedbackPageSize(pageLength);
  }, [feedbackDetailValues]);

  useEffect(() => {
    dispatch(
      MentorProjectFeedback({
        page: feedbackpage,
        limit: feedbacklimit,
        query: feedbackquery,
        sortby: sort,
        order,
        status: "",
        student_id: studentProfileDatas?.studentData?.id,
        project_id: projectId,
        date: "",
      })
    );
  }, [feedbackpage, feedbacklimit, feedbackquery]);

  return (
    <div className="container-fluid mt-4">
      <div className="d-flex justify-content-between">
        <h1>Hi {name}, </h1>
        <div className="d-flex">
          <Button
            className="w-100 mb-3 me-3"
            variant="success"
            onClick={handlePlan}
          >
            Plan for the Day
          </Button>
          <Button
            className="w-100 mb-3"
            disabled={!eods?.length > 0}
            variant="danger"
            onClick={handleEODShow}
          >
            EOD Status
          </Button>
        </div>
      </div>

      <Row>
        <Col md={9}>
          <Card className="mb-3 p-4">
            <Row>
              <h1 className="mt-3">
                {" "}
                {fitmentData?.data &&
                  fitmentData?.data[0]?.project_fitment?.title}
              </h1>
              <div className="px-3">
                {fitmentData?.data &&
                  fitmentData?.data[0]?.project_fitment?.description}
              </div>
            </Row>
          </Card>
          <Card className="p-4">
            {fitmentActivity?.map((data) => {
              if (
                currentActivityId
                  ? data?.id == currentActivityId
                  : data?.id == (fitmentActivity && fitmentActivity[0]?.id)
              )
                return (
                  <Card className="p-3 mb-4 shadow-lg">
                    <Row>
                      <h1 className="mt-3">{data?.title}</h1>
                      <div className="px-3">{data?.description}</div>
                    </Row>
                    <Accordion defaultActiveKey="0" className="mt-3">
                      <Accordion.Item eventKey={0}>
                        <Accordion.Header className="w=100">
                          <div className="d-flex justify-content-between w-100">
                            <h6 className="">Plan for the day</h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Plan</th>
                                <th>Planned Date</th>
                                <th>Planned (Hrs)</th>
                                <th>Type of Work</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectActivitydata?.map((plans) => {
                                if (
                                  plans?.project_id == projectId &&
                                  data?.id == plans?.activity_id
                                )
                                  return (
                                    <tr>
                                      <td> {plans?.plan} </td>
                                      <td>
                                        {dayjs(plans?.updated_at).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </td>
                                      <td>{plans?.plan_duration}</td>
                                      <td>
                                        {TYPE_OF_WORK[plans?.activity_type_id]}
                                      </td>
                                      <td>
                                        {plans?.status != 0
                                          ? EODSTATUS[plans?.status]
                                          : "Yet To Update"}
                                      </td>
                                    </tr>
                                  );
                              })}
                            </tbody>
                          </Table>
                          <div className="d-flex justify-content-between px-2">
                            <strong>
                              Total count : {planDetailValues?.length}
                            </strong>
                            <TablePagination
                              component="div"
                              count={planDetailValues?.length}
                              page={planrowsPerPage}
                              onPageChange={planhandleChangePage}
                              rowsPerPage={planlimit}
                              onRowsPerPageChange={planhandleChangeRowsPerPage}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey={1}>
                        <Accordion.Header className="w=100">
                          <div className="d-flex justify-content-between w-100">
                            <h6 className="">EOD Status</h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Comment</th>
                                <th>Planned Date</th>
                                <th>Planned (Hrs)</th>
                                <th>Type of Work</th>
                                <th>Status</th>
                                <th>Attachment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectActivityEODdata?.map((eod) => {
                                console.log(eod, "returnn eod");
                                if (
                                  eod?.project_id == projectId &&
                                  data?.id == eod?.activity_id
                                )
                                  return (
                                    <tr>
                                      <td> {eod?.eod_status} </td>
                                      <td>
                                        {dayjs(eod?.updated_at).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </td>
                                      <td>{eod?.actual_duration}</td>
                                      <td>
                                        {TYPE_OF_WORK[eod?.activity_type_id]}
                                      </td>
                                      <td>
                                        {eod?.status != 0
                                          ? EODSTATUS[eod?.status]
                                          : "Yet To Update"}
                                      </td>
                                      <td>
                                        {" "}
                                        <div
                                          className="d-flex sh-6 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl"
                                          onClick={() =>
                                            window.open(
                                              `${API_BASE}/${eod?.attachment}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          <CsLineIcons
                                            icon="file-text"
                                            className="text-white"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                              })}
                            </tbody>
                          </Table>
                          <div className="d-flex justify-content-between px-2">
                            <strong>
                              Total count : {eodDetailValues?.length}
                            </strong>
                            <TablePagination
                              component="div"
                              count={eodDetailValues?.length}
                              page={eodrowsPerPage}
                              onPageChange={eodhandleChangePage}
                              rowsPerPage={eodlimit}
                              onRowsPerPageChange={eodhandleChangeRowsPerPage}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey={2}>
                        <Accordion.Header className="w=100">
                          <div className="d-flex justify-content-between w-100">
                            <h6 className="">Mentor feedback</h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Feedback</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {mentorProjectFeedback?.map((feedbacks) => {
                                if (
                                  feedbacks?.project_id == projectId &&
                                  data?.id == feedbacks?.activity_id
                                )
                                  return (
                                    <tr>
                                      <td> {feedbacks?.feedback} </td>
                                      <td>
                                        {dayjs(feedbacks?.updated_at).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </td>
                                    </tr>
                                  );
                              })}
                            </tbody>
                          </Table>
                          <div className="d-flex justify-content-between px-2">
                            <strong>
                              Total count : {feedbackDetailValues?.length}
                            </strong>
                            <TablePagination
                              component="div"
                              count={feedbackDetailValues?.length}
                              page={feedbackrowsPerPage}
                              onPageChange={feedbackhandleChangePage}
                              rowsPerPage={feedbacklimit}
                              onRowsPerPageChange={
                                feedbackhandleChangeRowsPerPage
                              }
                            />
                          </div>
                          {/* {
                                                        mentorProjectFeedback?.map((feedbacks) => {
                                                            if (feedbacks?.project_id == projectId && data?.id == feedbacks?.activity_id)
                                                                return (
                                                                    <Row className="g-0 mb-2">
                                                                        <Col xs="auto">
                                                                            <div className="sw-3 d-inline-block d-flex justify-content-start align-items-center h-100">
                                                                                <div className="sh-3">
                                                                                    <CsLineIcons
                                                                                        icon="chevron-right"
                                                                                        className="text-secondary align-top"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div className="d-flex flex-column pt-0 pb-0 ps-3 pe-4 h-100 justify-content-center">
                                                                                <div className="d-flex flex-column">
                                                                                    <div className="text-alternate mt-n1 lh-1-25">
                                                                                        {feedbacks?.feedback}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            <div className="d-inline-block d-flex justify-content-end align-items-center h-100">
                                                                                <div className="text-muted ms-2 mt-n1 lh-1-25">
                                                                                    {dayjs(feedbacks.updated_at).format("YYYY/MM/DD")}
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                        })
                                                    } */}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Card>
                );
            })}
          </Card>
        </Col>
        <Col>
          <Card className="p-4">
            <h2 className="">Activities ({fitmentActivity?.length})</h2>
            <ListGroup>
              {fitmentActivity?.map((activities, i) => {
                console.log(activities, "hasdusygd");
                return (
                  <>
                    <ListGroup.Item
                      key={i}
                      className="p-2"
                      onClick={() => handleActivity(activities?.id)}
                    >
                      <a href={null} className="pe-auto cursor-pointer">
                        <div className="d-flex justify-content-between w-100 p-2">
                          <p>{activities?.title}</p>
                          {/* <p>{activities?.duration} hrs</p> */}
                        </div>
                      </a>
                    </ListGroup.Item>
                  </>
                );
              })}
            </ListGroup>
          </Card>
        </Col>
        <Modal
          show={show.eod || show.pfd}
          id={keySet}
          onHide={() => {
            let data = tasks.filter(
              (ele) =>
                ele.plan !== "" &&
                ele.plan_duration !== "" &&
                ele.activity_type_id !== "" &&
                ele.activity_id !== ""
            );
            data = data.length === 0 && [
              {
                plan: "",
                plan_duration: "",
                activity_type_id: "",
                activity_id: "",
              },
            ];
            setTasks(data);
            setShow({ pfd: false, eod: false });
          }}
          dialogClassName="modal-100w"
          aria-labelledby={keySet}
          tabIndex="-1"
          scrollable
          size="xl"
        >
          <div className="p-3 mt-2" style={{ overflow: "auto" }}>
            <>
              <h1>{show?.pfd ? "Plan for the day" : "EOD Status"}</h1>
              <Form noValidate onSubmit={handleSubmit}>
                {show?.pfd ? (
                  <Table responsive striped bordered hover>
                    <thead>
                      <tr>
                        <th>Activity</th>
                        <th>Task Description</th>
                        <th>Planned Hrs.</th>
                        <th>Type of work</th>
                      </tr>
                    </thead>
                    <tbody className="mb-3 filled form-group">
                      {tasks.map((task, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              as="select"
                              name="activity_id"
                              value={task.activity_id}
                              className="is-invalid"
                              onChange={(e) => handleForm(index, e)}
                            >
                              <option>Select Activity</option>
                              {fitmentData?.data &&
                                fitmentData?.data[0]?.project_fitment?.project_activity?.map(
                                  (item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.title}
                                    </option>
                                  )
                                )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                          </td>
                          <td>
                            <Form.Control
                              as="textarea"
                              rows={1}
                              name="plan"
                              placeholder="Plan"
                              value={task.plan}
                              className="is-invalid"
                              onChange={(e) => handleForm(index, e)}
                            />
                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              rows={6}
                              name="plan_duration"
                              placeholder="Plan Duration in Hrs."
                              value={task.plan_duration}
                              className="is-invalid"
                              onChange={(e) => handleForm(index, e)}
                            />
                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                          </td>
                          <td>
                            <Form.Control
                              as="select"
                              name="activity_type_id"
                              value={task.activity_type_id}
                              className="is-invalid"
                              onChange={(e) => handleForm(index, e)}
                            >
                              <option>Select Type</option>
                              {activityTypes?.rows?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  show?.eod && (
                    <Table responsive striped bordered hover>
                      <thead>
                        <tr>
                          {keysArray.map((key) => (
                            <th key={key}>
                              {key.replace("_", " ").toUpperCase()}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {eods.map((eod, index) => (
                          <tr key={index}>
                            {keysArray.map((key) => {
                              console.log(eod, "habsjbdaijsh");
                              let progress = inProgressId.includes(eod?.plan_id)
                                ? [1]
                                : [2, 1];
                              return (
                                <td key={key} className="tooltip-end-top">
                                  {key === "attachment" ? (
                                    <Form.Control
                                      type="file"
                                      name={key}
                                      onChange={(e) => handleForm(index, e)}
                                    />
                                  ) : (
                                    <InputGroup>
                                      {key === "activity_id" ||
                                      key === "activity_type_id" ||
                                      key === "status" ? (
                                        <Form.Control
                                          as="select"
                                          name={key}
                                          value={eod[key]}
                                          onChange={(e) => handleForm(index, e)}
                                          disabled={key !== "status"}
                                        >
                                          <option>
                                            Select {key.replace("_", " ")}
                                          </option>
                                          {key === "activity_id" ? (
                                            <>
                                              {fitmentData?.data &&
                                                fitmentData?.data[0]?.project_fitment?.project_activity?.map(
                                                  (item) => (
                                                    <option
                                                      key={item.id}
                                                      value={item.id}
                                                    >
                                                      {item.title}
                                                    </option>
                                                  )
                                                )}
                                            </>
                                          ) : key === "status" ? (
                                            <>
                                              {progress?.map((item) => (
                                                <option key={item} value={item}>
                                                  {EODSTATUS[item]}
                                                </option>
                                              ))}
                                            </>
                                          ) : (
                                            <>
                                              {activityTypes?.rows?.map(
                                                (item) => (
                                                  <option
                                                    key={item.id}
                                                    value={item.id}
                                                  >
                                                    {item?.name}
                                                  </option>
                                                )
                                              )}
                                            </>
                                          )}
                                        </Form.Control>
                                      ) : (
                                        <FormControl
                                          type={
                                            key === "actual_duration"
                                              ? "number"
                                              : "text"
                                          }
                                          name={key}
                                          placeholder={key
                                            .replace("_", " ")
                                            .toUpperCase()}
                                          value={eod[key]}
                                          onChange={(e) => handleForm(index, e)}
                                          disabled={
                                            key === "planned_desc" ||
                                            key === "planned_hrs"
                                          }
                                        />
                                      )}
                                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                    </InputGroup>
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )
                )}

                <div className="d-flex justify-content-around">
                  {show.pfd ? (
                    <Button
                      variant="primary"
                      className="w-100 mx-5"
                      onClick={handleAddTask}
                    >
                      Add Task
                    </Button>
                  ) : (
                    ""
                  )}

                  <Button
                    variant="success"
                    className="w-100 mx-5"
                    type="submit"
                  >
                    Confirm
                  </Button>
                </div>
              </Form>
            </>
          </div>
        </Modal>
      </Row>
    </div>
  );
};

export default ProjectActivity;
