import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Card,
  Col,
  Row,
  Container,
  Modal,
  Tab,
  Tabs,
  ProgressBar,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faFire,
  faIdBadge,
  faPlus,
  faSuitcase,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faStackOverflow,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
// import { faGithub } from "@fortawesome/free-brands-svg-icons";
import profileimg from "../../assets/img/profileimg.jpg";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import NODATA from "../../../src/assets/img/nojobs.jpg";
import NOMATCH from "../../../src/assets/images/nomatch.jpg";
import { FcRating } from "react-icons/fc";
import {
  GetCertData,
  GetEduData,
  OverallFeedback,
  OverallSprintFeedback,
  ProfileStudent,
  RegisteredSessionList,
  StudentCertScheduler,
  StudentEduScheduler,
  StudentSelector,
  updateSessions,
  clearData,
} from "../../store/reducer/StudentReducer";
import "../../assets/css/test.css";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { GiSprint } from "react-icons/gi";
import { RxActivityLog } from "react-icons/rx";
import { TbCurrentLocation } from "react-icons/tb";
import { BiUserCheck } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import {
  courseDetails,
  courseSelector,
  planDetail,
} from "../../store/reducer/CourseReducer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SkillList, settingSelector } from "../../store/reducer/settingsSlice";
import { API_BASE, gradeCalc } from "../../services/config";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { Button, TextField } from "@mui/material";
import { certificateScheme, educationalScheme } from "../../utils/Validation";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import WebcamRecorder from "./Video";
import {
  DashboardSelector,
  SessionList,
} from "../../store/reducer/DashboardReducer";
import _ from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StudentDashboard = () => {
  const name = localStorage.getItem("username");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [actCount, setActCount] = useState(0);
  const [VideOn, setVideeoOn] = useState(false);
  const videoRef = useRef(null);
  const [educationData, setEducationData] = useState({
    educations: [
      {
        education: "",
        institution: "",
        percentage: "",
        from_date: "",
        to_date: "",
      },
    ],
  });
  const [certificatData, setCertificateData] = useState({
    certificates: [
      {
        name: "",
        description: "",
        conducted_by: "",
        grade: "",
        from_date: "",
        to_date: "",
      },
    ],
  });
  const {
    studentDetailsDatas,
    studentProfileDatas,
    overallFeedbackData,
    studentEduLoad,
    EduDetails,
    CertDetails,
    studentCertLoad,
    GetEduDataLoad,
    GetCertDataLoad,
    updateVideoResumeLoad,
    OverallSprintFeedbackData,
    registeredSessionData,
  } = useSelector(StudentSelector);
  console.log(
    OverallSprintFeedbackData,
    overallFeedbackData,
    "overallFeedbackDataoverallFeedbackData"
  );
  const [averages, setAverages] = useState({});
  const [overallAverage, setOverallAverage] = useState(0);
  console.log(averages, "averagesaverages");
  const { skillDatas } = useSelector(settingSelector);
  const { courseData, planDetails } = useSelector(courseSelector);
  const { sessionDetails } = useSelector(DashboardSelector);
  const currentSprint = courseData?.rows?.find((ele) => {
    return ele.id === studentProfileDatas?.studentData?.current_sprint;
  });
  const now = 60;
  const [show, setShow] = useState(false);
  const [certshow, setCertShow] = useState(false);
  const [sessionData, setSessionDatas] = useState([]);
  const [sessionAttened, setsessionAttedned] = useState(0);
  console.log(sessionData, "sessionData");
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.stopPropagation();
    setShow(true);
  };

  const handleCertClose = () => setCertShow(false);
  const handleCertShow = (e) => {
    e.stopPropagation();
    setCertShow(true);
  };
  const handleJoinMeet = (data) => {
    setsessionAttedned(1);
    let formData = {};
    formData.id = data?.id;
    formData.status = 2;
    console.log(formData,data, "SEssionDATa1");
    dispatch(updateSessions({formData}));
    window.open(data?.meeting_link, "_blank", "noreferrer");
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const courserank = OverallSprintFeedbackData?.rankings_by_id;
    const projectrank = overallFeedbackData?.rankings_by_id;
    console.log(courserank, "courserankcourserank");
    let combined;
    if (courserank && projectrank) combined = [...courserank, ...projectrank];
    else if (courserank) combined = [...courserank];
    const course = OverallSprintFeedbackData?.overall_rankings ?? 0;
    const project = overallFeedbackData?.overall_rankings ?? 0;
    let avg;
    if (project > 0) avg = (parseFloat(course) + parseFloat(project)) / 2;
    else avg = course;
    const grouped = _.groupBy(combined, "skill_id");
    const average = _.mapValues(grouped, (group) =>
      _.meanBy(group, "rankings")
    );
    setAverages(average);
    setOverallAverage(avg);
  }, [OverallSprintFeedbackData, overallFeedbackData]);

  useEffect(() => {
    let formData = {};
    dispatch(ProfileStudent(formData));
    var video = videoRef.current;

    video?.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });

    video?.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    let live = [];

    live = registeredSessionData?.filter((status) => {
      if (status?.status === 0) {
        return status;
      }
    });
    setSessionDatas(live);
    console.log(registeredSessionData, "registeredSessionData");
  }, [registeredSessionData]);

  // useEffect(() => {
  //     let past = []
  //     let upcoming = []
  //     let live = [];

  //     sessionDetails?.map((sessions) => {
  //         const currentDate = dayjs();
  //         const inputDate = dayjs(sessions?.date_time);

  //         if (inputDate.isBefore(currentDate)) {
  //             past.push(sessions)
  //         } else if (inputDate.isSame(currentDate, 'second')) {
  //             live.push(sessions)
  //         } else {
  //             upcoming.push(sessions)
  //         }

  //         setSessionDatas({
  //             past,
  //             upcoming,
  //             live
  //         })
  //     })
  // }, [sessionDetails])

  useEffect(() => {
    var video = videoRef.current;

    video?.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });

    video?.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
    dispatch(
      RegisteredSessionList({
        student_id: studentProfileDatas?.studentData?.id,
      })
    );
    // dispatch(SessionList({ page: "", limit: "", query: "", sortby: "", order: "", domain: studentProfileDatas?.studentData?.department, guest_speaker_id: "" }))
    dispatch(
      courseDetails({
        department_id: studentProfileDatas?.studentData?.department,
        id: "",
        order: "",
        limit: "",
        sort: "",
        page: "",
        query: "",
      })
    );
    dispatch(
      OverallFeedback({
        page: "",
        limit: "",
        query: "",
        sortby: "",
        order: "",
        status: "",
        student_id: studentProfileDatas?.studentData?.id,
      })
    );
    dispatch(
      OverallSprintFeedback({
        page: "",
        limit: "",
        query: "",
        sortby: "",
        order: "",
        status: "",
        student_id: studentProfileDatas?.studentData?.id,
      })
    );
    dispatch(
      SkillList({
        page: "",
        limit: "",
        query: studentProfileDatas?.studentData?.department,
        sortby: "",
        order: "",
      })
    );
  }, [studentProfileDatas]);

  useEffect(() => {
    dispatch(
      planDetail({
        order: "",
        planlimit: "",
        sortby: "",
        planpage: "",
        planquery: "",
        status: "",
        student_id: studentProfileDatas?.studentData?.id,
        sprint_id: currentSprint?.id,
        date: "",
      })
    );
    dispatch(GetEduData({ student_id: studentProfileDatas?.studentData?.id }));
    dispatch(GetCertData({ student_id: studentProfileDatas?.studentData?.id }));
  }, [currentSprint, studentProfileDatas]);

  useEffect(() => {
    let counter = 0;
    courseData?.rows?.map((activities) => {
      counter += activities?.sprintactivity.length;
    });
    setActCount(counter);
  }, [courseData]);

  const handleVideoShow = async () => {
    setVideeoOn(true);
  };

  const hitEducation = () => {
    dispatch(GetEduData({ student_id: studentProfileDatas?.studentData?.id }));
  };

  const hitCertificates = () => {
    dispatch(GetCertData({ student_id: studentProfileDatas?.studentData?.id }));
  };

  const handleModalHide = () => {
    setVideeoOn(false);
  };

  useEffect(() => {
    if (studentEduLoad === API_STATUS.FULFILLED) {
      handleClose();
      dispatch(clearData());
      Swal.fire({
        title: "Added Succesfully!",
        icon: "success",
      });
      hitEducation();
      setEducationData({
        educations: [
          {
            education: "",
            institution: "",
            percentage: "",
            from_date: "",
            to_date: "",
          },
        ],
      });
    }
    if (studentCertLoad === API_STATUS.FULFILLED) {
      handleCertClose();
      dispatch(clearData());
      Swal.fire({
        title: "Added Succesfully!",
        icon: "success",
      });
      hitCertificates();
      setCertificateData({
        certificates: [
          {
            name: "",
            description: "",
            conducted_by: "",
            grade: "",
            from_date: "",
            to_date: "",
          },
        ],
      });
    }
    if (GetCertDataLoad === API_STATUS.FULFILLED) {
      dispatch(clearData());
    }
    if (GetEduDataLoad === API_STATUS.FULFILLED) {
      dispatch(clearData());
    }
    if (updateVideoResumeLoad === API_STATUS.FULFILLED) {
      let formData = {};
      dispatch(ProfileStudent(formData));
      dispatch(clearData());
      Swal.fire({
        title: "Added Succesfully!",
        icon: "success",
      });
      setVideeoOn(false);
    }
  }, [
    studentEduLoad,
    studentCertLoad,
    GetEduDataLoad,
    GetCertDataLoad,
    updateVideoResumeLoad,
  ]);

  return (
    <div className="container-fluid mt-4">
      <div className="d-flex justify-content-between">
        <h1>Dashboard</h1>
        {studentProfileDatas?.studentData?.primarlimary_test_status == 2 ||
        studentProfileDatas?.studentData?.primarlimary_test_status == 3 ? (
          <Button
            variant="contained"
            color="success"
            onClick={(e) => {
              navigate(
                "/student/course/" +
                  studentProfileDatas?.studentData?.department
              );
            }}
          >
            {planDetails?.rows?.length > 0 ? "Continue Sprint" : "Start Sprint"}
          </Button>
        ) : (
          <></>
        )}
      </div>
      {studentProfileDatas?.studentData?.primarlimary_test_status == 2 ? (
        <Row className="g-2 mb-2 mt-2">
          <Col sm="6" lg="3">
            <Card className="hover-scale-up cursor-pointer p-3">
              <div className="d-flex flex-column justify-content-between align-items-center">
                <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                  <GiSprint color="white" />
                </div>
                <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                  Total Sprints
                </div>
                <div className="text-small text-primary">
                  <h3>{courseData?.rows?.length}</h3>
                </div>
              </div>
            </Card>
          </Col>
          <Col sm="6" lg="3">
            <Card className="hover-scale-up cursor-pointer p-3">
              <div className="d-flex flex-column justify-content-between align-items-center">
                <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                  <RxActivityLog color="white" />
                </div>
                <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                  Total Activities
                </div>
                <div className="text-small text-primary">
                  <h3>{actCount}</h3>
                </div>
              </div>
            </Card>
          </Col>
          <Col sm="6" lg="3">
            <Card className="hover-scale-up cursor-pointer p-3">
              <div className="d-flex flex-column justify-content-between align-items-center">
                <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                  <TbCurrentLocation color="white" />
                </div>
                <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                  Current Sprint
                </div>
                <div className="text-small text-primary">
                  <h3>{currentSprint?.name}</h3>
                </div>
              </div>
            </Card>
          </Col>
          <Col sm="6" lg="3">
            <Card className="hover-scale-up cursor-pointer p-3">
              <div className="d-flex flex-column justify-content-between align-items-center">
                <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                  <BiUserCheck color="white" />
                </div>
                <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                  Completed Sprint(s)
                </div>
                <div className="text-small text-primary">
                  <h3>
                    {studentProfileDatas?.studentSprintsCount[0]?.count ?? 0}
                  </h3>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row className="mb-2">
          <Col>
            <div className="card mt-2">
              <div className="w-100">
                <div className="card-body">
                  <div>
                    <hr />
                    <div className="text-center">
                      <p>
                        {" "}
                        Thank you for submitting your answers! We appreciate
                        your effort and contribution. Your answer on{" "}
                        <strong className="text-success">
                          {" "}
                          {
                            studentProfileDatas?.studentData?.departments?.name
                          }{" "}
                        </strong>{" "}
                        will validate by our
                        <strong className="text-primary"> AI Model </strong>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {/* <Row className="mt-5">
                <Col md={3}>
                    <Card className='mb-1'>
                        <div className="profile-container">
                            <div className="bg-gradient-light avatar-image rounded-xl d-flex justify-content-center align-items-center mb-2">
                                <FaUser size={30} color='white' />
                            </div>

                            <fieldset className="user-details">
                                <legend>User Details</legend>

                                <div className="field">
                                    <label htmlFor="username">Username:</label>
                                    <span id="username">{studentProfileDatas?.studentData?.name}</span>
                                </div>
                                <div className="field">
                                    <label htmlFor="email">Email id:</label>
                                    <span id="email">{studentProfileDatas?.studentData?.email}</span>
                                </div>
                                <div className="field">
                                    <label htmlFor="fullName">Domain:</label>
                                    <span id="fullName">{studentProfileDatas?.studentData?.departments?.name}</span>
                                </div>
                            </fieldset>
                        </div>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='mb-1'>
                        <div className="profile-container">
                                Focus Area
                        </div>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='mb-1'>
                        <div className="profile-container">
                                Roles and responsibilities
                        </div>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='mb-1'>
                        <div className="profile-container">
                                Work life balance
                        </div>
                    </Card>
                </Col>
            </Row> */}
      <div>
        <div>
          <section className="profile-head">
            <Container>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={1} sm={2}>
                      <div className="profile-img">
                        <div className="bg-gradient-light user-image rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <FaUser size={60} color="white" />
                        </div>
                      </div>
                    </Col>
                    <Col md={7} sm={6}>
                      <div className="profile-content mt-3">
                        <h3 className="text-capitalize mb-0">
                          {studentProfileDatas?.studentData?.name}
                        </h3>

                        <p className="mt-0">
                          {studentProfileDatas?.studentData?.specialization}
                        </p>
                        <p>
                          <span className="loc">
                            {studentProfileDatas?.studentData?.address ? (
                              <FontAwesomeIcon
                                icon={faLocationDot}
                                style={{ marginRight: "7px" }}
                              />
                            ) : (
                              <></>
                            )}
                            {studentProfileDatas?.studentData?.address}
                          </span>
                        </p>
                      </div>
                    </Col>
                    <Col className="align-items-center">
                      <Row className="h-100 align-items-center">
                        <Col sm={6}>
                          <FontAwesomeIcon
                            className="font"
                            icon={faBolt}
                            size="3x"
                            style={{ color: "#FFD43B" }}
                          />
                          <b>
                            <h3 className="number">
                              {(overallAverage * 4)?.toFixed(2)} Points
                            </h3>{" "}
                          </b>
                        </Col>
                        <Col sm={6}>
                          <FontAwesomeIcon
                            icon={faIdBadge}
                            size="3x"
                            style={{ color: "#990000" }}
                          />
                          <h3 className="level">
                            {gradeCalc(overallAverage * 4)} Grade
                          </h3>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </section>

          <section className="tab mt-2">
            <Container>
              <Card>
                <Card.Body>
                  <Tabs
                    defaultActiveKey="sessions"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey={"sessions"} title="Sessions">
                      <Card>
                        <Card.Body className="slider-container bg-light">
                          <h3>
                            <strong>Upcoming Sessions</strong>
                          </h3>
                          <Slider {...settings}>
                            {sessionData?.length > 0 ? (
                              sessionData?.map((datas) => {                             
                                const data = datas?.guest_speaker;
                                return (
                                  <>
                                    <Card>
                                      <Card.Body className="d-flex justify-content-between align-items-center w-100">
                                        <div>
                                          <h4 className="text-uppercase">
                                            {data?.topic}
                                          </h4>
                                          <p>
                                            {dayjs(data?.date_time).format(
                                              "DD/MM/YYYY - hh:mm:ss"
                                            )}
                                          </p>
                                        </div>
                                        <div>
                                          {dayjs().isSame(
                                            dayjs(data?.date_time),
                                            "date"
                                          ) &&
                                          dayjs().isSame(
                                            dayjs(data?.date_time),
                                            "month"
                                          ) &&
                                          dayjs().isSame(
                                            dayjs(data?.date_time),
                                            "year"
                                          ) ? (
                                            <Button
                                              color="success"
                                              variant="contained"
                                              onClick={() => {
                                                handleJoinMeet(datas);
                                              }}
                                              role="link"
                                            >
                                              Join Now
                                            </Button>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </>
                                );
                              })
                            ) : (
                              <>No Data's</>
                            )}
                          </Slider>
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="home" title="Stats">
                      <Row>
                        <Col md={6}>
                          <Card className="home-tab">
                            <Card.Header>LEVELUP STATS</Card.Header>
                            <Card.Body>
                              <Card.Text>
                                <Row className="just">
                                  <Col md={6} sm={6}>
                                    <Row>
                                      <Col md={3} sm={3}>
                                        <FontAwesomeIcon
                                          className="font"
                                          icon={faBolt}
                                          size="3x"
                                          style={{ color: "#FFD43B" }}
                                        />
                                      </Col>
                                      <Col md={6} sm={6}>
                                        <b>
                                          <h3 className="number">
                                            {(overallAverage * 4)?.toFixed(2)}{" "}
                                            Points
                                          </h3>{" "}
                                        </b>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={3} sm={3}>
                                        <FontAwesomeIcon
                                          icon={faIdBadge}
                                          size="3x"
                                          style={{ color: "#990000" }}
                                        />
                                      </Col>
                                      <Col md={6} sm={6}>
                                        <b>
                                          <h3 className="level">
                                            {gradeCalc(
                                              parseInt(overallAverage * 4)
                                            )}{" "}
                                            Grade
                                          </h3>
                                        </b>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <p className="mt-4">
                                    Your Profile Level evaluates your overall
                                    technical mastery and success techical
                                    interviews. It updates after each technical
                                    round.
                                  </p>
                                </Row>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card className="home-tab">
                            <Card.Header>TECHNICAL SCORECARD</Card.Header>
                            <Card.Body>
                              <p className="skill">
                                We assess your DSA skills on the following
                                criteria, view scores for each category.
                              </p>
                              {skillDatas?.rows?.map((skills) => {
                                const keys = Object.keys(averages);
                                const _target = keys.find((ele) => {
                                  return ele == skills?.id;
                                });
                                // const target = overallFeedbackData?.rankings_by_id?.find((ele) => { return ele?.skill_id == skills?.id })
                                const value = averages[_target] * 20;
                                return (
                                  <div className="m-1">
                                    <p>
                                      {skills?.name} ({value?.toFixed(2)}%)
                                    </p>
                                    <ProgressBar now={value} />
                                  </div>
                                );
                              })}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="profile" title="Profile">
                      <Card className="home-tab record personality">
                        <Card.Header className="text-uppercase">
                          Video Resume
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <h2>Introduction Video Guidelines</h2>

                            <p>
                              We understand that you're more than just a resume.
                              Your voice matters, and your individuality sets
                              you apart. Please respond to the following
                              questions:
                            </p>

                            <ol>
                              <li>
                                <b>Introduction: </b> Share a brief introduction
                                about yourself, including your academic
                                background and qualifications.
                              </li>
                              <li>
                                <b>Technical Projects:</b> Discuss key technical
                                projects you've worked on, your role in them,
                                and the technologies you've used.
                              </li>
                              <li>
                                <b>Future Vision:</b> Share your vision for your
                                tech career and where you see yourself heading
                                in the future.
                              </li>
                            </ol>

                            <h2>
                              Top Tips for Recording Your Video Responses:
                            </h2>
                            <ol>
                              <li>
                                <b>Quiet Environment:</b> Find a quiet,
                                distraction-free area to record your video.
                              </li>
                              <li>
                                <b>Practice:</b> Practice your responses to feel
                                comfortable with the process.
                              </li>
                              <li>
                                <b>Eye Contact:</b> Make eye contact with the
                                camera whenever possible to connect with your
                                audience.
                              </li>
                              <li>
                                <b>Grant Access:</b> Remember to grant access to
                                your camera and microphone when requested.
                              </li>
                              <li>
                                <b>Timer:</b> Remember that the timer will show
                                you the maximum time limit of your video resume.
                                Try to use the maximum time to effectively
                                showcase your aspects to recruiters.
                              </li>
                            </ol>
                            {studentProfileDatas?.studentData?.video_resume ? (
                              <video
                                width="300"
                                height="200"
                                autoplay="autoplay"
                              >
                                <source
                                  src={
                                    API_BASE +
                                    "/" +
                                    studentProfileDatas?.studentData
                                      ?.video_resume
                                  }
                                  type="video/*"
                                />
                              </video>
                            ) : (
                              <></>
                            )}
                            {studentProfileDatas?.studentData?.video_resume ? (
                              <video
                                src={
                                  API_BASE +
                                  "/" +
                                  studentProfileDatas?.studentData?.video_resume
                                }
                                ref={videoRef}
                                width="600"
                                height="300"
                                autoplay={true}
                                controls="controls"
                                controlsList="nodownload"
                              />
                            ) : (
                              <Button
                                variant="contained"
                                onClick={handleVideoShow}
                              >
                                Start Recording
                              </Button>
                            )}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Modal size="l" show={VideOn} onHide={handleModalHide}>
                        <Modal.Header closeButton>
                          <Modal.Title>Record Your Video Resume</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {/* <Modal.Title> */}
                          <p>
                            Personality is determined through a video resume
                            that enables recruiters to gain insight into your
                            behavioural aspects.
                          </p>
                          {/* </Modal.Title> */}
                          <WebcamRecorder
                            props={{
                              name: studentProfileDatas?.studentData?.id,
                              modal: VideOn,
                            }}
                          />
                        </Modal.Body>
                      </Modal>
                      <Modal
                        size="xl"
                        id={"eod"}
                        show={show}
                        onHide={handleClose}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Education Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Formik
                            initialValues={educationData}
                            validationSchema={educationalScheme}
                            onSubmit={(values) => {
                              console.log(values);
                              values.educations?.map(
                                (ele) =>
                                  (ele.student_id =
                                    studentProfileDatas?.studentData?.id)
                              );
                              dispatch(
                                StudentEduScheduler({
                                  payload: values.educations,
                                })
                              );
                            }}
                          >
                            {({ values }) => (
                              <Form>
                                <FieldArray
                                  name="educations"
                                  render={({ push, remove }) => (
                                    <div className="d-block">
                                      {values.educations.map((_, index) => (
                                        <div key={index}>
                                          <Row className="mt-2">
                                            <Col md={2}>
                                              <Field
                                                name={`educations[${index}].education`}
                                                as={TextField}
                                                placeholder="Education"
                                              />
                                              <ErrorMessage
                                                name={`educations[${index}].education`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                            <Col md={2}>
                                              <Field
                                                name={`educations[${index}].institution`}
                                                as={TextField}
                                                placeholder="Institute"
                                              />
                                              <ErrorMessage
                                                name={`educations[${index}].institution`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                            <Col md={2}>
                                              <Field
                                                name={`educations[${index}].percentage`}
                                                as={TextField}
                                                placeholder="Percentage"
                                              />
                                              <ErrorMessage
                                                name={`educations[${index}].percentage`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                            <Col md={2} className="me-4">
                                              <Field
                                                name={`educations[${index}].from_date`}
                                                className="d-inline"
                                              >
                                                {({ field, form, meta }) => (
                                                  <DatePicker
                                                    {...field}
                                                    placeholderText="From Date"
                                                    inputVariant="outlined"
                                                    format="MM/dd/yyyy"
                                                    name={`educations[${index}].from_date`}
                                                    className="pt-3 pb-3 px-2"
                                                    selected={
                                                      educationData.educations &&
                                                      educationData.educations[
                                                        index
                                                      ]?.from_date
                                                    }
                                                    onChange={(date) => {
                                                      if (
                                                        educationData.educations &&
                                                        educationData
                                                          .educations[index]
                                                      ) {
                                                        educationData.educations[
                                                          index
                                                        ].from_date = date;
                                                        setEducationData({
                                                          ...educationData,
                                                        });
                                                      }
                                                      form.setFieldValue(
                                                        `educations[${index}].from_date`,
                                                        date
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </Field>
                                              <ErrorMessage
                                                name={`educations[${index}].from_date`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                            <Col md={2}>
                                              <Field
                                                name={`educations[${index}].to_date`}
                                              >
                                                {({ field, form, meta }) => (
                                                  <DatePicker
                                                    {...field}
                                                    placeholderText="End Date"
                                                    className="pt-3 pb-3 px-2"
                                                    inputVariant="outlined"
                                                    format="MM/dd/yyyy"
                                                    name={`educations[${index}].to_date`}
                                                    selected={
                                                      educationData.educations &&
                                                      educationData.educations[
                                                        index
                                                      ]?.to_date
                                                    }
                                                    onChange={(date) => {
                                                      if (
                                                        educationData.educations &&
                                                        educationData
                                                          .educations[index]
                                                      ) {
                                                        educationData.educations[
                                                          index
                                                        ].to_date = date;
                                                        setEducationData({
                                                          ...educationData,
                                                        });
                                                      }
                                                      form.setFieldValue(
                                                        `educations[${index}].to_date`,
                                                        date
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </Field>
                                              <ErrorMessage
                                                name={`educations[${index}].to_date`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}

                                      <div className="d-flex justify-content-between mt-2">
                                        <Button
                                          type="button"
                                          onClick={() => {
                                            educationData.educations.push({
                                              education: "",
                                              institution: "",
                                              percentage: "",
                                              from_date: null,
                                              to_date: null,
                                            });

                                            setEducationData({
                                              ...educationData,
                                            });
                                          }}
                                        >
                                          Add Education
                                        </Button>
                                        <Button type="submit">Submit</Button>
                                      </div>
                                    </div>
                                  )}
                                />
                              </Form>
                            )}
                          </Formik>
                        </Modal.Body>
                      </Modal>

                      <Modal
                        size="xl"
                        id={"dashboardmodal"}
                        show={certshow}
                        onHide={handleCertClose}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Certificate Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Formik
                            initialValues={certificatData}
                            validationSchema={certificateScheme}
                            onSubmit={(values) => {
                              values.certificates?.map(
                                (ele) =>
                                  (ele.student_id =
                                    studentProfileDatas?.studentData?.id)
                              );
                              console.log(values);
                              dispatch(
                                StudentCertScheduler({
                                  payload: values.certificates,
                                })
                              );
                            }}
                          >
                            {({ values }) => (
                              <Form>
                                <FieldArray
                                  name="certificates"
                                  render={({ push, remove }) => (
                                    <div className="d-block">
                                      {values.certificates.map((_, index) => (
                                        <div key={index}>
                                          <Row className="mt-2">
                                            <Col md={2}>
                                              <Field
                                                name={`certificates[${index}].name`}
                                                as={TextField}
                                                placeholder="Certificate Name"
                                              />
                                              <ErrorMessage
                                                name={`certificates[${index}].name`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                            <Col md={2}>
                                              <Field
                                                name={`certificates[${index}].conducted_by`}
                                                as={TextField}
                                                placeholder="Conducted By"
                                              />
                                              <ErrorMessage
                                                name={`certificates[${index}].conducted_by`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                            <Col md={2}>
                                              <Field
                                                name={`certificates[${index}].description`}
                                                as={TextField}
                                                placeholder="description"
                                              />
                                              <ErrorMessage
                                                name={`certificates[${index}].description`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                            <Col md={2}>
                                              <Field
                                                name={`certificates[${index}].grade`}
                                                as={TextField}
                                                placeholder="grade"
                                              />
                                              <ErrorMessage
                                                name={`certificates[${index}].grade`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                            <Col md={2} className="me-2">
                                              <Field
                                                name={`certificates[${index}].from_date`}
                                                className="d-inline"
                                              >
                                                {({ field, form, meta }) => (
                                                  <DatePicker
                                                    {...field}
                                                    placeholderText="From Date"
                                                    inputVariant="outlined"
                                                    format="MM/dd/yyyy"
                                                    name={`certificates[${index}].from_date`}
                                                    className="pt-3 pb-3 px-2"
                                                    selected={
                                                      certificatData.certificates &&
                                                      certificatData
                                                        .certificates[index]
                                                        ?.from_date
                                                    }
                                                    onChange={(date) => {
                                                      if (
                                                        certificatData.certificates &&
                                                        certificatData
                                                          .certificates[index]
                                                      ) {
                                                        certificatData.certificates[
                                                          index
                                                        ].from_date = date;
                                                        setEducationData({
                                                          ...certificatData,
                                                        });
                                                      }
                                                      form.setFieldValue(
                                                        `certificates[${index}].from_date`,
                                                        date
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </Field>
                                              <ErrorMessage
                                                name={`certificates[${index}].from_date`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                            <Col md={1}>
                                              <Field
                                                name={`certificates[${index}].to_date`}
                                              >
                                                {({ field, form, meta }) => (
                                                  <DatePicker
                                                    {...field}
                                                    placeholderText="End Date"
                                                    className="pt-3 pb-3 px-2 me-1"
                                                    inputVariant="outlined"
                                                    format="MM/dd/yyyy"
                                                    name={`certificates[${index}].to_date`}
                                                    selected={
                                                      certificatData.certificates &&
                                                      certificatData
                                                        .certificates[index]
                                                        ?.to_date
                                                    }
                                                    onChange={(date) => {
                                                      if (
                                                        certificatData.certificates &&
                                                        certificatData
                                                          .certificates[index]
                                                      ) {
                                                        certificatData.certificates[
                                                          index
                                                        ].to_date = date;
                                                        setCertificateData({
                                                          ...certificatData,
                                                        });
                                                      }
                                                      form.setFieldValue(
                                                        `certificates[${index}].to_date`,
                                                        date
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </Field>
                                              <ErrorMessage
                                                name={`certificates[${index}].to_date`}
                                                className="text-danger"
                                                component="div"
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}

                                      <div className="d-flex justify-content-between mt-2">
                                        <Button
                                          type="button"
                                          onClick={() => {
                                            certificatData.certificates.push({
                                              name: "",
                                              description: "",
                                              conducted_by: "",
                                              grade: "",
                                              from_date: "",
                                              to_date: "",
                                            });

                                            setCertificateData({
                                              ...certificatData,
                                            });
                                          }}
                                        >
                                          Add Certificate
                                        </Button>
                                        <Button type="submit">Submit</Button>
                                      </div>
                                    </div>
                                  )}
                                />
                              </Form>
                            )}
                          </Formik>
                        </Modal.Body>
                      </Modal>
                      <Card className="home-tab record">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="d-flex justify-content-between me-3 w-100">
                                <h5>EDUCATION</h5>
                                <Button onClick={handleShow}>Add</Button>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              {EduDetails.map((edu) => {
                                return (
                                  <>
                                    <hr />
                                    <div className="education">
                                      <p className="degree">
                                        {edu?.education} -{" "}
                                        <span>{edu?.percentage}%</span>
                                      </p>
                                      <p className="school">
                                        {edu?.institution}{" "}
                                      </p>
                                      <p className="location">
                                        {dayjs(edu?.from_date).format(
                                          "DD/MM/YYYY"
                                        )}{" "}
                                        -{" "}
                                        {dayjs(edu?.to_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Card>

                      <Card className="home-tab record">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="d-flex justify-content-between me-3 w-100">
                                <h5>CERTIFICATE</h5>
                                <Button onClick={handleCertShow}>Add</Button>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              {CertDetails?.map((certificates) => {
                                return (
                                  <>
                                    <hr />
                                    <div className="certificate">
                                      <p className="name">
                                        {certificates?.name} -{" "}
                                        <span>{certificates?.grade} Grade</span>
                                      </p>
                                      <p className="name">
                                        {certificates?.description}
                                      </p>
                                      <p className="org">
                                        {certificates?.conducted_by}
                                      </p>
                                      <div className="date-link">
                                        <p className="date">
                                          {dayjs(
                                            certificates?.from_date
                                          ).format("DD/MM/YYYY")}
                                        </p>
                                        <p className="date">
                                          {dayjs(certificates?.to_date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Card>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Container>
          </section>
        </div>
        <div className="mt-5 d-none">
          <Row>
            <Col>
              <div className="card mt-2">
                <div className="w-100">
                  <div className="card-body">
                    <div>
                      <hr />
                      <div className="text-center">
                        <p>
                          {" "}
                          Thank you for submitting your answers! We appreciate
                          your effort and contribution. Your answer on{" "}
                          <strong className="text-success">
                            {" "}
                            {
                              studentProfileDatas?.studentData?.departments
                                ?.name
                            }{" "}
                          </strong>{" "}
                          will validate by our
                          <strong className="text-primary"> AI Model </strong>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ display: "none" }}>
          <Row>
            <Col md={7}>
              <div className="card">
                <div className="p-4 pb-0">
                  <h2 className="header">Jobs</h2>
                </div>
                <div className="text-center pb-3">
                  <img
                    src={NODATA}
                    alt=""
                    draggable={false}
                    className="w-100"
                  />
                  <p>You have not applied to any jobs yet</p>
                  <Button variant="primary">Apply Jobs</Button>
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="d-flex flex-column">
                <div className="card">
                  <div className="p-4 pb-0">
                    <h3 className="header">Matching Jobs</h3>
                  </div>
                  <div className="w-100 text-center">
                    <img
                      src={NOMATCH}
                      alt=""
                      draggable={false}
                      className="w-50"
                    />
                  </div>
                </div>
                <div className="card mt-2">
                  <div className="p-4 pb-0">
                    <h3 className="header">Loyalty Points</h3>
                  </div>
                  <div className="w-100">
                    <div className="card-body">
                      <h4 className="header">Recently Gained Point</h4>
                      <div>
                        <h3>
                          <FcRating size={24} /> 0
                        </h3>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <p>Total Points Gained</p>
                          <b>0</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
