import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import CsLineIcons from './cs-line-icons/CsLineIcons'
import { useDispatch, useSelector } from 'react-redux'
import { CC_MentorById, CC_MentorSelector, CC_MentorStatScheduler } from '../store/reducer/CCMentorReducer'
import _ from "lodash"
import { FaUser } from 'react-icons/fa'
import Table from 'react-bootstrap/Table';
import { BsActivity, BsEye } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

const CCMentorDashboard = () => {
    const [activityCount, setActivityCount] = useState(0)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { cc_mentorDatas, ccStatData } = useSelector(CC_MentorSelector);

    useEffect(() => {
        dispatch(CC_MentorById({}))
    }, []);

    useEffect(() => {
        if (ccStatData?.data) {
            let count = _.reduce(ccStatData?.data?.departments?.sprint, function (sum, n) {
                return sum + n?.sprintactivity?.length;
            }, 0);
            setActivityCount(count)
        }
    }, [ccStatData])

    useEffect(() => {
        if (cc_mentorDatas?.id) {
            const formData = {
                id: cc_mentorDatas?.id
            }
            dispatch(CC_MentorStatScheduler(formData))
        }
        console.log(cc_mentorDatas, 'cc_mentorDatas')
    }, [cc_mentorDatas])

    return (
        <div>
            <Row className="g-2">
                <Col sm="6" md="3">
                    <Card className="hover-scale-up cursor-pointer sh-19">
                        <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
                            <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                                <CsLineIcons icon="form" className="text-white" />
                            </div>
                            <div className="heading text-center mb-0 d-flex align-items-center lh-1">No of College Assigned</div>
                            <div className="text-small text-primary"><h3>{ccStatData?.collegecount}</h3></div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="6" md="3">
                    <Card className="hover-scale-up cursor-pointer sh-19">
                        <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
                            <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                                <CsLineIcons icon="user" className="text-white" />
                            </div>
                            <div className="heading text-center mb-0 d-flex align-items-center lh-1">No of Culture Catalyst Assigned</div>
                            <div className="text-small text-primary"><h3>{ccStatData?.ccCount}</h3></div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="6" md="3">
                    <Card className="hover-scale-up cursor-pointer sh-19">
                        <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
                            <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                                <CsLineIcons icon="activity" className="text-white" />
                            </div>
                            <div className="heading text-center mb-0 d-flex align-items-center lh-1">Total Sprints</div>
                            <div className="text-small text-primary"><h3>{ccStatData?.data?.departments?.sprint?.length}</h3></div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="6" md="3">
                    <Card className="hover-scale-up cursor-pointer sh-19">
                        <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
                            <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                                <CsLineIcons icon="activity" className="text-white" />
                            </div>
                            <div className="heading text-center mb-0 d-flex align-items-center lh-1">Total Activities</div>
                            <div className="text-small text-primary"><h3>{activityCount}</h3></div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={3} className='mt-2'>
                    <Card className='mb-1'>
                        <div className="profile-container">
                            <div className="bg-gradient-light avatar-image rounded-xl d-flex justify-content-center align-items-center mb-2">
                                <FaUser size={30} color='white' />
                                {/* <img src={user.avatar} alt="User Avatar" className="avatar-image" /> */}
                            </div>

                            <fieldset className="user-details">
                                <legend>User Details</legend>

                                <div className="field">
                                    <label htmlFor="username">Username:</label>
                                    <span id="username">{ccStatData?.data?.name}</span>
                                </div>
                                <div className="field">
                                    <label htmlFor="email">Email id:</label>
                                    <span id="email">{ccStatData?.data?.email}</span>
                                </div>
                                <div className="field">
                                    <label htmlFor="fullName">Domain:</label>
                                    <span id="fullName">{ccStatData?.data?.departments?.name}</span>
                                </div>
                            </fieldset>
                        </div>
                    </Card>
                </Col>
                <Col>
                    <Card body className="mb-5 mt-2">
                        <h2 className='text-muted'>Recent Culture Catalyst</h2>
                        {/* List Header Start */}
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>S.NO</th>
                                    <th>Culture Catalyst Name</th>
                                    <th>Email</th>
                                    <th>Domain</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {
                                5 == 5 ?

                                    <tbody>
                                        {ccStatData &&
                                            ccStatData?.recentStudents?.map((studentValues, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{studentValues?.name}</td>
                                                            <td>{studentValues?.email}</td>
                                                            <td>{studentValues?.departments?.name}</td>
                                                            <td>{studentValues?.status == 1 ? "Active" : "In-Active"}</td>
                                                            <td>
                                                                <button
                                                                    title="View Progress"
                                                                    className="btn btn-primary mx-1"
                                                                    onClick={(e) => {
                                                                        navigate(
                                                                            `/student/dashboard/${studentValues?.id}`
                                                                        );
                                                                    }}
                                                                >
                                                                    <FaUser size={18} />
                                                                </button>
                                                                <button
                                                                    title="View Sprint Progress"
                                                                    className="btn btn-primary mx-1"
                                                                    onClick={(e) => {
                                                                        navigate(
                                                                            "/hiring_partner/" +
                                                                            studentValues?.id +
                                                                            "/course/" +
                                                                            studentValues?.department
                                                                        );
                                                                    }}
                                                                >
                                                                    <BsEye size={18} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </tbody>

                                    :
                                    <tbody>
                                        {ccStatData &&
                                            ccStatData?.recentStudents?.map((studentValues, index) => {
                                                if (studentValues?.student_acceptance_status == 3)
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{studentValues?.student?.name}</td>
                                                                <td>{studentValues?.student?.email}</td>
                                                                <td>{studentValues?.departments?.name}</td>
                                                                <td>{studentValues?.status == 1 ? "Active" : "In-Active"}</td>
                                                                <td>
                                                                    <button
                                                                        title="View Progress"
                                                                        className="btn btn-primary mx-1"
                                                                        onClick={(e) => {
                                                                            navigate(
                                                                                `/student/dashboard/${studentValues?.id}`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <FaUser size={18} />
                                                                    </button>
                                                                    <button
                                                                        title="View Sprint Progress"
                                                                        className="btn btn-primary mx-1"
                                                                        onClick={(e) => {
                                                                            navigate(
                                                                                "/hiring_partner/" +
                                                                                studentValues?.student_id +
                                                                                "/course/" +
                                                                                studentValues?.department_id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <BsEye size={18} />
                                                                    </button>
                                                                    <button
                                                                        title="View Fitment Progress"
                                                                        className="btn btn-primary mx-1"
                                                                        onClick={(e) => {
                                                                            navigate(
                                                                                "/student/" +
                                                                                studentValues?.student_id + '/project');
                                                                        }}
                                                                    >
                                                                        <BsActivity size={18} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                            })}
                                    </tbody>
                            }

                        </Table>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default CCMentorDashboard