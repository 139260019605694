import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  sprint_Lists,
  CreateActivity,
  Get_Activity_ByID,
  UpdateActivity,
  SprintSelector,
  clearSprintData,
} from "../../store/reducer/SprintReducer";
import {
  departmentLists,
  departmentUpdates,
  placementSelector,
  testLists,
} from "../../store/reducer/PlacementReducer";
import { localFrontend, FRONTEND_BASE } from "../../services/config";
import { LoadingButton } from "@mui/lab";
const ActivityManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { domain_id, sprint_id, id } = useParams();
  const title = id ? "Activity Edit" : "Activity Add";
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [departmentID, setDepartmentID] = useState(domain_id);
  const [sprintId, setSprintId] = useState(sprint_id);
  const [isClicked, setIsClicked] = useState(false);
  const [errorMsg, seterrorMsg] = useState({
    domainerror: false,
    sprinterror: false,
  });

  useEffect(() => {
    let page = 0;
    let limit = 0;
    let query = "";
    let order = "";
    let department_id = domain_id;
    dispatch(sprint_Lists({ page, limit, query, order, department_id }));
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    dispatch(departmentLists(departmentQuery));
  }, []);

  const { departmentData } = useSelector(placementSelector);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Activity Name is required"),
    description: Yup.string().required("Description is required"),
    duration: Yup.string().required("Duration is required"),
  });

  const [initialValues, setInitialValues] = useState({
    name: "",
    duration: "",
    description: "",
  });
  const onSubmit = (formData) => {
    if (!departmentID || !sprintId) {
      setValidated(false);
    } else {
      formData.sprint_id = sprintId;
      console.log(id, "FORMDATA");
      if (id) {
        dispatch(UpdateActivity({ id, formData }));
      } else {
        dispatch(CreateActivity({ formData }));
      }
    }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const {
    sprintListData,
    getActivityData,
    update_activity_Loading,
    create_activity_Loading,
    sprint_errorMessage,
  } = useSelector(SprintSelector);

  useEffect(() => {
    if (id) dispatch(Get_Activity_ByID({ id: id }));
  }, [id]);
  useEffect(() => {
    if (getActivityData !== "") {
      formik.setValues({
        name: getActivityData?.name,
        description: getActivityData?.description,
        duration: getActivityData?.duration,
      });
    }

    dispatch(clearSprintData());
  }, [getActivityData]);

  useEffect(() => {
    if (create_activity_Loading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Activity Added Successfully!",
        icon: "success",
      });
      dispatch(clearSprintData());
      navigate("/domain/sprint/" + domain_id);
    }
    console.log(update_activity_Loading, "update_activity_Loading");
    if (update_activity_Loading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Activity Updated Successfully!",
        icon: "success",
      });
      dispatch(clearSprintData());
      navigate("/domain/sprint/" + domain_id);
    }

    if (
      (create_activity_Loading === API_STATUS.REJECTED ||
        update_activity_Loading === API_STATUS.REJECTED) &&
      sprint_errorMessage != null
    ) {
      if (sprint_errorMessage.message)
        Swal.fire({
          title: sprint_errorMessage.message,
          icon: "error",
        });
      else if (sprint_errorMessage.errors) {
        let data = "";
        sprint_errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        Swal.fire({
          title: data,
          icon: "error",
        });
      }
      dispatch(clearSprintData());
    }
  }, [create_activity_Loading, update_activity_Loading, sprint_errorMessage]);
  console.log(update_activity_Loading, "update_activity_Loading");
  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Link to={`/domain/sprint/${domain_id}`}>
              <div className="text-end mb-2">
                <Button variant="success" type="submit">
                  Back
                </Button>
              </div>
            </Link>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <form
                id="mentorAddForm"
                className={
                  validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                }
                onSubmit={(e) => {
                  e.preventDefault();
                  setValidated(true);
                  handleSubmit();
                }}
              >
                <div className="mb-3 filled form-group tooltip-end-top">
                  {Loginerror && Loginerror.error && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {Loginerror.error}
                    </Form.Control.Feedback>
                  )}
                </div>
                <Row>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file-text" />
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setDepartmentID(e.target.value);
                        }}
                        value={departmentID}
                        className="is-invalid"
                        placeholder="Domain"
                        disabled
                      >
                        {!isClicked ? <option>Select Domain</option> : ""}
                        {departmentData &&
                          departmentData?.rows?.map((fields) => {
                            return (
                              <option value={fields.id}>{fields.name}</option>
                            );
                          })}
                      </Form.Control>
                      {errorMsg?.domainerror && (
                        <p className="text-danger">Domain is required</p>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file-text" />
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setSprintId(e.target.value);
                        }}
                        value={sprintId}
                        className="is-invalid"
                        placeholder="Sprint"
                      >
                        {!isClicked ? <option>Select Sprint</option> : ""}
                        {sprintListData &&
                          sprintListData?.rows?.map((fields) => {
                            return (
                              <option value={fields.id}>{fields.name}</option>
                            );
                          })}
                      </Form.Control>
                      {errorMsg?.sprinterror && (
                        <p className="text-danger">Sprint is required</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3 g-3">
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file" />
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Activity Name"
                        value={values.name}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.name && touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="time" />
                      <Form.Control
                        type="number"
                        name="duration"
                        onChange={handleChange}
                        value={values.duration}
                        className="is-invalid"
                        placeholder="Duration"
                      />
                      {errors.duration && touched.duration && (
                        <Form.Control.Feedback type="invalid">
                          {errors.duration}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="filled form-group tooltip-end-top">
                      {/* <CsLineIcons icon="user" /> */}
                      <Form.Control
                        as="textarea"
                        name="description"
                        placeholder="Activity Description"
                        value={values.description}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.description && touched.description && (
                        <Form.Control.Feedback type="invalid">
                          {errors.description}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="text-end mt-2">
                  <LoadingButton
                    variant="contained"
                    loading={
                      ![API_STATUS.FULFILLED, "initial"].includes(
                        create_activity_Loading || update_activity_Loading
                      )
                    }
                    type="submit"
                    onClick={() => {
                      if (!departmentID || !sprintId) {
                        seterrorMsg({
                          domainerror: !departmentID,
                          sprinterror: !sprintId,
                        });
                        setValidated(false);
                      }
                    }}
                  >
                    {id ? "Update" : "Create"}
                  </LoadingButton>
                </div>
              </form>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default ActivityManagement;
