import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Form, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik, Field } from "formik";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  signUpScheduler,
  signUpSelector,
  collegeList,
  clearErrormsg,
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import {
  clearData,
  placementRegisterScheduler,
  signInSelector,
} from "../store/reducer/SignInReducer";

const PlacementRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { placementRegisterLoader } = useSelector(signInSelector);

  const placementValidator = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string().email().required("Email is required"),
    dob: Yup.date().required(),
    college: Yup.string().required(),
    course: Yup.string().required(),
    specialization: Yup.string().required(),
    year: Yup.string().required(),
  });

  const initialValues = {
    name: "",
    dob: "",
    email: "",
    phone: "",
    college: "",
    course: "",
    specialization: "",
    year: "",
  };

  const onSubmit = (formData) => {
    dispatch(placementRegisterScheduler({ formData }));
  };

  useEffect(() => {
    document.body.classList.add("h-100");
    const root = document.getElementById("root");
    if (root) {
      root.classList.add("h-100");
    }
    return () => {
      document.body.classList.remove("h-100");
      if (root) {
        root.classList.remove("h-100");
      }
    };
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: placementValidator,
    onSubmit,
  });

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    setValues,
    resetForm,
  } = formik;
  const handleDateChange = (date) => {
    setValues({ ...values, dob: date });
  };

  const setSelectedValues = (e, type) => {
    setValues({ ...values, [type]: e });
  };

  useEffect(() => {
    if (placementRegisterLoader === API_STATUS.FULFILLED) {
      Swal.fire({
        title: `
        🎉 Thank you for Registering on Culture Catalyst! 🚀        
        Stay tuned for exciting updates on the latest job openings and cutting-edge technologies. `,
        icon: "success",
      });
      dispatch(clearData());
      resetForm();
    }
  }, [placementRegisterLoader]);

  return (
    <>
      <div className="fixed-background" />

      <div className="container-fluid p-0 h-100 position-relative login_pages">
        <div className="row g-0 h-100">
          {/* Left Side Start */}
          <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg-4 h-lg-100">
            <div className="min-h-100 d-flex align-items-center">
              <div className="w-100 w-lg-50 w-xxl-50">
                <div>
                  <div className="mb-5">
                    {/* <div class="logo "><div class="img" style={{width:"100%",height:"90px"}}></div></div> */}
                    <div
                      className="logo-default h-100 mb-5"
                      style={{ width: "100%", minHeight: "90px" }}
                    />
                    <h1 className="display-3 text-white">
                      Create a Workplace Culture that Inspires and Innovates.
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side Start */}
          <div className="col-12 col-lg-7 h-100 pb-4 px-4 pt-0 p-lg-0">
            <div className="sw-lg-100 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
              <div className="sw-lg-100 px-5">
                <div className="mb-5">
                  <h2 className="cta-1 mb-0 text-primary">
                    Welcome to Culture Catalyst,
                  </h2>
                </div>
                <div>
                  <form
                    id="loginForm"
                    className={
                      true ? "tooltip-end-bottom" : "tooltip-end-bottom "
                    }
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <Row className="mb-3 g-3">
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="user" />
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={values.name}
                            className="is-invalid"
                            onChange={handleChange}
                          />
                          {errors.name && touched.name && (
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="calendar" />
                          <Form.Group controlId="formDate">
                            <DatePicker
                              name="dob"
                              selected={values.dob}
                              placeholderText="Date of Birth"
                              onChange={handleDateChange}
                              dateFormat="dd/MM/yyyy" // Customize the date format
                              className="form-control" // Use Bootstrap styling
                            />
                          </Form.Group>
                          {errors.dob && touched.dob && (
                            <Form.Control.Feedback type="invalid">
                              {errors.dob}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="mobile" />
                          <Form.Control
                            type="number"
                            name="phone"
                            onChange={handleChange}
                            value={values.phone}
                            className="is-invalid"
                            placeholder="Phone"
                          />
                          {errors.phone && touched.phone && (
                            <Form.Control.Feedback type="invalid">
                              {errors.phone}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="email" />
                          <Form.Control
                            type="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            className="is-invalid"
                            placeholder="Email"
                          />
                          {errors.email && touched.email && (
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="pen" />
                          <Form.Control
                            type="text"
                            name="college"
                            onChange={handleChange}
                            value={values.college}
                            className="is-invalid"
                            placeholder="College"
                          />
                          {errors.college && touched.college && (
                            <Form.Control.Feedback type="invalid">
                              {errors.college}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="graduation" />
                          <Form.Control
                            type="text"
                            name="course"
                            value={values.course}
                            onChange={handleChange}
                            className="is-invalid"
                            placeholder="Course"
                          ></Form.Control>
                          {errors.course && touched.course && (
                            <Form.Control.Feedback type="invalid">
                              {errors.course}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="diploma" />
                          <Form.Control
                            type="text"
                            name="specialization"
                            placeholder="Specialization"
                            value={values.specialization}
                            className="is-invalid"
                            onChange={handleChange}
                          />
                          {errors.specialization && touched.specialization && (
                            <Form.Control.Feedback type="invalid">
                              {errors.specialization}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="board-2" />
                          <Form.Control
                            as="select"
                            value={values.year}
                            onChange={(e) =>
                              setSelectedValues(e.target.value, "year")
                            }
                            className="is-invalid"
                            placeholder="Year"
                            name="year"
                          >
                            <option value="">Choose Year</option>
                            <option value="1st Year">1st Year</option>
                            <option value="2nd Year">2nd Year</option>
                            <option value="3rd Year">3rd Year</option>
                            <option value="4th Year">4th Year</option>
                          </Form.Control>
                          {errors.year && touched.year && (
                            <Form.Control.Feedback type="invalid">
                              {errors.year}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="text-end">
                      {/* <Button size="lg" type="submit"> */}
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={false}
                      >
                        Register
                      </LoadingButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlacementRegister;
