import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Form, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik, Field } from "formik";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  signUpScheduler,
  signUpSelector,
  collegeList,
  clearErrormsg,
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";
import Swal from "sweetalert2";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [dobValue, setDobValue] = useState("");
  const [Loginerror, setLoginerror] = useState(false);
  const [collegeValue, setCollgeValue] = useState();
  const [courseValue, setCourseValues] = useState();
  const [yearValue, setYearValues] = useState();
  const [errorMsg, seterrorMsg] = useState({
    dobError: false,
    collegeError: false,
    courseError: false,
    yearError: false,
  });

  useEffect(() => {
    document.body.classList.add("h-100");
    const root = document.getElementById("root");
    if (root) {
      root.classList.add("h-100");
    }
    return () => {
      document.body.classList.remove("h-100");
      if (root) {
        root.classList.remove("h-100");
      }
    };
  }, []);

  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone_no: Yup.string().required("Phone is required"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/[@$!%*?&]/, getCharacterValidationError("special")),
    roll_no: Yup.string().required("Roll Number is required"),
    college_code: Yup.string().required("College / Academy Code is required"),
  });
  const initialValues = {
    name: "",
    dob: dobValue,
    email: "",
    password: "",
    phone_no: "",
    college: collegeValue,
    course: "",
    specialization: "",
    year: "",
    roll_no: "",
    college_code: "",
  };
  const onSubmit = (formData) => {
    if (!dobValue || !collegeValue || !yearValue || !courseValue) {
      setValidated(false);
    } else {
      formData.dob = dobValue;
      formData.college = collegeValue;
      formData.course = courseValue;
      formData.year = yearValue;
      formData.certificate_status = 0;
      dispatch(signUpScheduler({ formData }));
    }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const {
    signUpData,
    signUpLoading,
    collegeData,
    collegeLoading,
    errorMessage,
  } = useSelector(signUpSelector);

  useEffect(() => {
    let formData = {};
    formData.query = "";
    formData.limit = 0;
    formData.page = 0;
    dispatch(collegeList(formData));
  }, []);

  const handleDateChange = (date) => {
    console.log(date, "SETDOBDATE");
    setDobValue(date);
  };

  const setSelectedValues = (e, type) => {
    if (type == "college") setCollgeValue(e);
    else if (type == "course") setCourseValues(e);
    else setYearValues(e);
  };

  useEffect(() => {
    console.log(signUpLoading, "signInLoading");
    if (signUpLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Registerd Successfully!",
        icon: "success",
      });
      dispatch(clearErrormsg());
      navigate("/");
    }
    if (signUpLoading === API_STATUS.REJECTED && errorMessage) {
      Swal.fire({
        title: errorMessage,
        icon: "error",
      });
      setLoginerror(errorMessage);
      dispatch(clearErrormsg());
      // toast.error(errorMessage.error);
    }
  }, [signUpLoading, errorMessage]);

  return (
    <>
      {/* Background Start */}
      <div className="fixed-background" />
      {/* Background End */}

      <div className="container-fluid p-0 h-100 position-relative login_pages">
        <div className="row g-0 h-100">
          {/* Left Side Start */}
          <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg-4 h-lg-100">
            <div className="min-h-100 d-flex align-items-center">
              <div className="w-100 w-lg-50 w-xxl-50">
                <div>
                  <div className="mb-5">
                    {/* <div class="logo "><div class="img" style={{width:"100%",height:"90px"}}></div></div> */}
                    <div
                      className="logo-default h-100 mb-5"
                      style={{ width: "100%", minHeight: "90px" }}
                    />
                    <h1 className="display-3 text-white">
                      Create a Workplace Culture that Inspires and Innovates.
                    </h1>
                    {/* <h1 className="display-3 text-white">
                      Ready for Your Project
                    </h1> */}
                  </div>
                  {/* <p className="h6 text-white lh-1-5 mb-5">
                    Dynamically target high-payoff intellectual capital for
                    customized technologies. Objectively integrate emerging core
                    competencies before process-centric communities...
                  </p>
                  <div className="mb-5">
                    <Button size="lg" variant="outline-white" href="/">
                      Learn More
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Left Side End */}

          {/* Right Side Start */}
          <div className="col-12 col-lg-7 h-100 pb-4 px-4 pt-0 p-lg-0">
            <div className="sw-lg-100 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
              <div className="sw-lg-100 px-5">
                <div className="mb-5">
                  <h2 className="cta-1 mb-0 text-primary">
                    Welcome to Culture Catalyst,
                  </h2>
                  <h2 className="cta-1 text-primary">let's get started!</h2>
                </div>
                <div className="mb-5">
                  <p className="h6">
                    Already have an account?{" "}
                    <NavLink to="/" className="login_txt">
                      Signin
                    </NavLink>
                    .
                  </p>
                </div>
                <div>
                  <form
                    id="loginForm"
                    className={
                      validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                    }
                    onSubmit={(e) => {
                      e.preventDefault();
                      setValidated(true);
                      handleSubmit();
                    }}
                  >
                    <div className="mb-3 filled form-group tooltip-end-top">
                      {Loginerror && Loginerror.error && (
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: "block" }}
                        >
                          {Loginerror.error}
                        </Form.Control.Feedback>
                      )}
                    </div>
                    <Row className="mb-3 g-3">
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="user" />
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={values.name}
                            className="is-invalid"
                            onChange={handleChange}
                          />
                          {errors.name && touched.name && (
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="calendar" />
                          <Form.Group controlId="formDate">
                            <DatePicker
                              name="dob"
                              selected={dobValue}
                              placeholderText="Date of Birth"
                              onChange={handleDateChange}
                              dateFormat="dd/MM/yyyy" // Customize the date format
                              className="form-control" // Use Bootstrap styling
                            />
                          </Form.Group>
                          {errorMsg?.dobError && (
                            <p className="text-danger">
                              Date of Birth is required
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="mobile" />
                          <Form.Control
                            type="text"
                            name="phone_no"
                            onChange={handleChange}
                            value={values.phone_no}
                            className="is-invalid"
                            placeholder="Phone"
                          />
                          {errors.phone_no && touched.phone_no && (
                            <Form.Control.Feedback type="invalid">
                              {errors.phone_no}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="email" />
                          <Form.Control
                            type="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            className="is-invalid"
                            placeholder="Email"
                          />
                          {errors.email && touched.email && (
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="lock-off" />
                          <Form.Control
                            type="password"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                            className="is-invalid"
                            placeholder="Create your own Password"
                          />
                          {errors.password && touched.password && (
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          )}
                          <p className="py-1">
                            Use Upper case, Lower case, Numbers &
                            Symbols.Password length should be minimum 8
                            characters.
                          </p>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="building-large" />
                          <Form.Control
                            as="select"
                            onChange={(e) =>
                              setSelectedValues(e.target.value, "college")
                            }
                            value={collegeValue}
                            className="is-invalid"
                            placeholder="College"
                          >
                            <option value="">Choose College / Academy</option>
                            {collegeData &&
                              collegeData?.rows?.map((collegeValue) => {
                                return (
                                  <>
                                    <option value={collegeValue?.name}>
                                      {collegeValue?.name}
                                    </option>
                                  </>
                                );
                              })}
                          </Form.Control>

                          {errorMsg?.collegeError && (
                            <p className="text-danger">College / Academy is required</p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="pen" />
                          <Form.Control
                            type="text"
                            name="college_code"
                            onChange={handleChange}
                            value={values.college_code}
                            className="is-invalid"
                            placeholder="College / Academy Code"
                          />
                          {errors.college_code && touched.college_code && (
                            <Form.Control.Feedback type="invalid">
                              {errors.college_code}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="graduation" />
                          <Form.Control
                            as="select"
                            value={courseValue}
                            onChange={(e) =>
                              setSelectedValues(e.target.value, "course")
                            }
                            className="is-invalid"
                            placeholder="Course"
                          >
                            <option value="">Choose Course</option>
                            <option value="Arts and Science">
                              Arts and Science
                            </option>
                            <option value="BE">B.E</option>
                            <option value="B.Tech">B.Tech</option>
                            <option value="MBA">MBA</option>
                          </Form.Control>
                          {errorMsg?.courseError && (
                            <p className="text-danger">Course is required</p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="diploma" />
                          <Form.Control
                            type="text"
                            name="specialization"
                            placeholder="Specialization"
                            value={values.specialization}
                            className="is-invalid"
                            onChange={handleChange}
                          />
                          {errors.specialization && touched.specialization && (
                            <Form.Control.Feedback type="invalid">
                              {errors.specialization}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="board-2" />
                          <Form.Control
                            as="select"
                            value={yearValue}
                            onChange={(e) =>
                              setSelectedValues(e.target.value, "year")
                            }
                            className="is-invalid"
                            placeholder="Year"
                          >
                            <option value="">Choose Year</option>
                            <option value="1st Year">1st Year</option>
                            <option value="2nd Year">2nd Year</option>
                            <option value="3rd Year">3rd Year</option>
                            <option value="4th Year">4th Year</option>
                          </Form.Control>
                          {errorMsg?.yearError && (
                            <p className="text-danger">Year is required</p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="form" />
                          <Form.Control
                            type="text"
                            name="roll_no"
                            placeholder="Roll Number"
                            value={values.roll_no}
                            className="is-invalid"
                            onChange={handleChange}
                          />
                          {errors.roll_no && touched.roll_no && (
                            <Form.Control.Feedback type="invalid">
                              {errors.roll_no}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="text-end">
                      {/* <Button size="lg" type="submit"> */}
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={
                          ![API_STATUS.FULFILLED, "initial"].includes(
                            signUpLoading
                          )
                        }
                        onClick={() => {
                          if (
                            !dobValue ||
                            !collegeValue ||
                            !yearValue ||
                            !courseValue
                          ) {
                            seterrorMsg({
                              dobError: !dobValue,
                              collegeError: !collegeValue,
                              yearError: !yearValue,
                              courseError: !courseValue,
                            });
                            setValidated(false);
                          }
                        }}
                      >
                        Register
                      </LoadingButton>
                      {/* </Button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Right Side End */}
        </div>
      </div>
    </>
  );
};

export default SignUp;
