import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import { signUp, checkUser, college_list } from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "signUpConfiguration";

const initialState = {
  loading: "initial",
  errorMessage: "",
  signUpData: null,
  signUpLoading: "initial",
  userExistLoading: "initial",
  collegeLoading: "initial",
  collegeData: null,
  count: 0,
};

export const signUpScheduler = createAsyncThunk(
  `${namespace}/signUpScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "signUpScheduler");
      const { data } = await signUp(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const checkUserExist = createAsyncThunk(
  `${namespace}/checkUserExist`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await checkUser(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const collegeList = createAsyncThunk(
  `${namespace}/collegeList`,
  async ({ page, limit, query }, { rejectWithValue, dispatch }) => {
    try {
      console.log(page,"COLLEGELIST");
      const { data } = await college_list(page, limit, query);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const schedulerConfigSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearSignUpData: () => {
      return initialState;
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
      state.signUpLoading = "initial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUpScheduler.pending, (state) => {
      state.signUpLoading = API_STATUS.PENDING;
    });
    builder.addCase(signUpScheduler.fulfilled, (state, { payload }) => {
      state.signUpLoading = API_STATUS.FULFILLED;
      state.signUpData = payload;
    });
    builder.addCase(signUpScheduler.rejected, (state, action) => {
      state.signUpLoading = API_STATUS.REJECTED;
      console.log(action?.payload, "payload");
      state.errorMessage = action?.payload?.data?.message;
      console.log(state.errorMessage, "state.errorMessage");
    });
    builder.addCase(checkUserExist.pending, (state) => {
      state.userExistLoading = API_STATUS.PENDING;
    });
    builder.addCase(checkUserExist.fulfilled, (state, { payload }) => {
      state.userExistLoading = API_STATUS.FULFILLED;
      state.errorMessage = "success";
    });
    builder.addCase(checkUserExist.rejected, (state, action) => {
      state.userExistLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(collegeList.pending, (state) => {
      state.collegeLoading = API_STATUS.PENDING;
    });
    builder.addCase(collegeList.fulfilled, (state, { payload }) => {
      state.collegeLoading = API_STATUS.FULFILLED;
      state.errorMessage = "success";
      console.log(payload,"SFASDFSDFASD");
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.collegeData = payloaddata;      
      console.log(payloaddata,"COLLEGEDSSDF");
    });
    builder.addCase(collegeList.rejected, (state, action) => {
      state.collegeLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearSignUpData, clearErrormsg } = schedulerConfigSlice.actions;

export const signUpSelector = (state) => state.signUpConfiguration;

export default schedulerConfigSlice.reducer;
