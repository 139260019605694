import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  hiring_partner_list,
  add_hiring_partner,
  edit_hiring_partner,
  get_hiring_partner,
  profile_update_hiring_partner,
  createJobOpening,
  ViewJobOpening,
  hiring_partner_profile,
  updateJobOpening,
  job_list,
  in_active_hp,
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "hiringPartner";

const initialState = {
  loading: "initial",
  errorMessage: "",
  jobListDetails: null,
  hiringPartnerDatas: null,
  hiringPartnerEditData: null,
  hiringPartnerProfileDatas: null,
  hiringPartnerLoading: "initial",
  hiringPartnerListLoading: "initial",
  hiringPartnerEditLoading: "initial",
  CreateJobSchedulerLoading: "initial",
  ViewJobSchedulerLoading: "initial",
  UpdateJobScehdulerLoading: "initial",
  hpInActiveLoading: "initial",
  count: 0,
  jobDetails: {},
};

export const hiring_partnerLists = createAsyncThunk(
  `${namespace}/hiring_partnerLists`,
  async ({ query, limit = "", page = "" }, { rejectWithValue, dispatch }) => {
    try {
      console.log(query, limit, page, "query,limit,page");
      const { data } = await hiring_partner_list(query, limit, page);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const addHiringPartner = createAsyncThunk(
  `${namespace}/addHiringPartner`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await add_hiring_partner(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getHiringPartner = createAsyncThunk(
  `${namespace}/getHiringPartner`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      // console.log(formData, "formData");
      const { data } = await get_hiring_partner(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const EditHiringPartnerData = createAsyncThunk(
  `${namespace}/EditHiringPartnerData`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "EditHiringPartnerDataformData");
      const { data } = await profile_update_hiring_partner(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const JobListData = createAsyncThunk(
  `${namespace}/JobListData`,
  async ({ query, limit, page, org_id }, { rejectWithValue, dispatch }) => {
    try {
      // console.log(formData,id, "UpdateJob formdata");
      const { data } = await job_list(query, limit, page, org_id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const UpdateJobScehduler = createAsyncThunk(
  `${namespace}/UpdateJobScehduler`,
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, id, "UpdateJob formdata");
      const { data } = await updateJobOpening(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CreateJobScheduler = createAsyncThunk(
  `${namespace}/CreateJobScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "CreateJob formdata");
      const { data } = await createJobOpening(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const ProfileHiringPartner = createAsyncThunk(
  `${namespace}/ProfileHiringPartner`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      console.log("ProfileHiringPartner");
      const { data } = await hiring_partner_profile();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ViewJobScheduler = createAsyncThunk(
  `${namespace}/ViewJobScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "CreateJob formdata");
      const { data } = await ViewJobOpening(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const InActiveHP = createAsyncThunk(
  `${namespace}/InActiveHP`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("InActiveHP");
      const { data } = await in_active_hp(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const hiringPartnerSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearHiringPartnerData: () => {
      return initialState;
    },
    clearHiringPartnerLoadingsData: (state) => {
      state.hiringPartnerLoading = "initial";
      state.hiringPartnerListLoading = "initial";
      state.hiringPartnerEditLoading = "initial";
      state.CreateJobSchedulerLoading = "initial";
      state.UpdateJobScehdulerLoading = "initial";
      state.ViewJobSchedulerLoading = "initial";
      state.hpInActiveLoading = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(hiring_partnerLists.pending, (state) => {
      state.hiringPartnerListLoading = API_STATUS.PENDING;
    });
    builder.addCase(hiring_partnerLists.fulfilled, (state, { payload }) => {
      state.hiringPartnerListLoading = API_STATUS.FULFILLED;
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.hiringPartnerDatas = data;
      console.log(data, "hiringPartnerDatas");
    });
    builder.addCase(hiring_partnerLists.rejected, (state, action) => {
      state.hiringPartnerListLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(addHiringPartner.pending, (state) => {
      state.hiringPartnerLoading = API_STATUS.PENDING;
    });
    builder.addCase(addHiringPartner.fulfilled, (state, { payload }) => {
      state.hiringPartnerLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(addHiringPartner.rejected, (state, action) => {
      state.hiringPartnerLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getHiringPartner.pending, (state) => {
      state.hiringPartnerListLoading = API_STATUS.PENDING;
    });
    builder.addCase(getHiringPartner.fulfilled, (state, { payload }) => {
      state.hiringPartnerListLoading = API_STATUS.FULFILLED;
      state.hiringPartnerEditData = payload?.data;
      console.log(payload, "hiringPartnerEditData");
    });
    builder.addCase(getHiringPartner.rejected, (state, action) => {
      state.hiringPartnerListLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(CreateJobScheduler.pending, (state) => {
      state.CreateJobSchedulerLoading = API_STATUS.PENDING;
    });
    builder.addCase(CreateJobScheduler.fulfilled, (state, { payload }) => {
      state.CreateJobSchedulerLoading = API_STATUS.FULFILLED;
      // state.hiringPartnerEditData = payload?.data;
      console.log(payload, "hiringPartnerEditData");
    });
    builder.addCase(CreateJobScheduler.rejected, (state, action) => {
      state.CreateJobSchedulerLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(UpdateJobScehduler.pending, (state) => {
      state.UpdateJobScehdulerLoading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateJobScehduler.fulfilled, (state, { payload }) => {
      state.UpdateJobScehdulerLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateJobScehduler.rejected, (state, action) => {
      state.UpdateJobScehdulerLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(ViewJobScheduler.pending, (state) => {
      state.ViewJobSchedulerLoading = API_STATUS.PENDING;
    });
    builder.addCase(ViewJobScheduler.fulfilled, (state, { payload }) => {
      state.ViewJobSchedulerLoading = API_STATUS.FULFILLED;
      state.jobDetails = payload?.data;
      console.log(payload, "hiringPartnerEditData");
    });
    builder.addCase(ViewJobScheduler.rejected, (state, action) => {
      state.ViewJobSchedulerLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(EditHiringPartnerData.pending, (state) => {
      state.hiringPartnerEditLoading = API_STATUS.PENDING;
    });
    builder.addCase(EditHiringPartnerData.fulfilled, (state, { payload }) => {
      state.hiringPartnerEditLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(EditHiringPartnerData.rejected, (state, action) => {
      state.hiringPartnerEditLoading = API_STATUS.REJECTED;
      console.log(action.payload, "EditHiringPartnerData");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(ProfileHiringPartner.pending, (state) => {
      state.hiringPartnerLoading = API_STATUS.PENDING;
    });
    builder.addCase(ProfileHiringPartner.fulfilled, (state, { payload }) => {
      state.hiringPartnerLoading = API_STATUS.FULFILLED;
      state.hiringPartnerProfileDatas = payload?.data;
      console.log(payload, "JUDJDJ");
    });
    builder.addCase(ProfileHiringPartner.rejected, (state, action) => {
      state.hiringPartnerLoading = API_STATUS.REJECTED;
      console.log(action.payload, "HiringPartnerProfile");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(JobListData.pending, (state) => {
      state.hiringPartnerLoading = API_STATUS.PENDING;
    });
    builder.addCase(JobListData.fulfilled, (state, { payload }) => {
      state.hiringPartnerLoading = API_STATUS.FULFILLED;
      state.jobListDetails = payload;
      console.log(state.jobListDetails, "JUDJDJ");
    });
    builder.addCase(JobListData.rejected, (state, action) => {
      state.hiringPartnerLoading = API_STATUS.REJECTED;
      console.log(action.payload, "HiringPartnerProfile");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(InActiveHP.pending, (state) => {
      state.hpInActiveLoading = API_STATUS.PENDING;
    });
    builder.addCase(InActiveHP.fulfilled, (state, { payload }) => {
      state.hpInActiveLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(InActiveHP.rejected, (state, action) => {
      state.hpInActiveLoading = API_STATUS.REJECTED;
      console.log(action.payload, "hpInActiveLoading");
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearHiringPartnerData, clearHiringPartnerLoadingsData } =
  hiringPartnerSlice.actions;

export const hiringpartnerSelector = (state) => state.hiringPartner;

export default hiringPartnerSlice.reducer;
