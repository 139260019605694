import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {  CC_MentorSelector, clearCCMentorData, CC_MentorById, CC_MentorUpdate } from "../../store/reducer/CCMentorReducer";
import { departmentLists, departmentUpdates, placementSelector, testLists } from '../../store/reducer/PlacementReducer';
import { LoadingButton } from "@mui/lab";

const CC_MentorEdit = () => {
    const title = "CC Mentor Edit";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { id } = useParams();
    const [validated, setValidated] = useState(false);
    const [Loginerror, setLoginerror] = useState(false);
    const [departmentID, setDepartmentID] = useState("");
    const [isClicked, setIsClicked] = useState(false)
    const [errorMsg, seterrorMsg] = useState({
        doj: false,
    });

    useEffect(() => {
        let formData = {};
        formData.id = id;
        dispatch(CC_MentorById({ formData }));
    }, [])


    useEffect(() => {
        let departmentQuery = {};
        departmentQuery.page = 0;
        departmentQuery.limit = 0;
        departmentQuery.query = "";        
        dispatch(departmentLists(departmentQuery ));
    }, [])


    const {
        departmentData,
    } = useSelector(placementSelector);

    useEffect(() => {
        console.log(departmentData, "departmentData");
    }, [departmentData])

    const {  cc_mentorDatas, cc_mentorUpdateLoading, cc_mentor_errorMessage } = useSelector(CC_MentorSelector);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email().required("Email is required"),
        avaiablity: Yup.number().required("Availability Hours is required"),
    })
    const initialValues = { name: "", phone_no: "", email: "", avaiablity: "" };
    useEffect(() => {
        if (cc_mentorDatas != null) {
            formik.setValues(
                {
                    name: cc_mentorDatas?.name || '', phone_no: cc_mentorDatas?.phone_no, email: cc_mentorDatas?.email || '', avaiablity: cc_mentorDatas?.avaiablity || ''
                })
            const JobValue = (cc_mentorDatas?.domain)
            setDepartmentID(JobValue)

        }
    }, [cc_mentorDatas]);
    const onSubmit = (formData) => {
        formData.id = cc_mentorDatas.id;
        formData.domain = departmentID;
        dispatch(CC_MentorUpdate({ formData }));
    };

    const formik = useFormik({ initialValues, validationSchema, onSubmit });
    const { handleSubmit, handleChange, values, touched, errors } = formik;


    useEffect(() => {
        console.log(cc_mentorDatas, "cc_mentorDatas");
    }, [cc_mentorDatas])

    useEffect(() => {
        console.log(cc_mentorUpdateLoading, "cc_mentorUpdateLoading");
        if (cc_mentorUpdateLoading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: "Mentor Updated Successfully!",
                icon: 'success'
            });
            dispatch(clearCCMentorData());
            navigate("/cc_mentor");
        }
        if (cc_mentorUpdateLoading === API_STATUS.REJECTED && cc_mentor_errorMessage) {
            if (cc_mentor_errorMessage.message) Swal.fire({
                title: cc_mentor_errorMessage.message,
                icon: 'error'
            });
            else if (cc_mentor_errorMessage.errors) {
                let data = "";
                cc_mentor_errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                Swal.fire({
                    title: data,
                    icon: 'error'
                })
            }
            dispatch(clearCCMentorData());
        }
    }, [cc_mentorUpdateLoading, cc_mentor_errorMessage]);

    return (
        <>
            <Row>
                <Col>
                    {/* Title Start */}
                    <section className="scroll-section" id="title">
                        <div className="page-title-container">
                            <h1 className="mb-0 pb-0 display-4">{title}</h1>
                            <Breadcrumb className="breadcrumb-container d-inline-block">
                                <Breadcrumb.Item key={`breadCrumb1`}>
                                </Breadcrumb.Item>
                            </Breadcrumb>

                        </div>
                        <Link to="/cc_mentor" >
                            <div className="text-end mb-2">
                                <Button variant='success' type='submit'>CC Mentor List</Button>
                            </div>
                        </Link>
                    </section>
                    {/* Title End */}

                    {/* Standard Start */}
                    <section className="scroll-section" id="standard">
                        {/* <h2 className="small-title">Standard</h2> */}
                        <Card body className="mb-5">
                            {/* List Header Start */}
                            <form
                                id="mentorEditForm"
                                className={
                                    validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                                }
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setValidated(true);
                                    handleSubmit();
                                }}
                            >
                                <div className="mb-3 filled form-group tooltip-end-top">
                                    {Loginerror && Loginerror.error && (
                                        <Form.Control.Feedback
                                            type="invalid"
                                            style={{ display: "block" }}
                                        >
                                            {Loginerror.error}
                                        </Form.Control.Feedback>
                                    )}
                                </div>
                                <Row className="mb-3 g-3">
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="user" />
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder="Mentor Name"
                                                value={values.name}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.name && touched.name && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file-text" />
                                            <Form.Control
                                                as="select"
                                                onChange={(e) => {
                                                    setDepartmentID(e.target.value);
                                                }}
                                                value={departmentID}
                                                className="is-invalid"
                                                placeholder="Domain">
                                                {
                                                    !isClicked ? <option>Select Domain</option> : ""
                                                }
                                                {departmentData &&
                                                    departmentData?.rows?.map((fields) => {
                                                        return (
                                                            <option value={fields.id}>{fields.name}</option>
                                                        );
                                                    })}
                                            </Form.Control>
                                            {errorMsg?.doj &&
                                                (
                                                    <p className='text-danger'>Domain is required</p>
                                                )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="email" />
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                onChange={handleChange}
                                                value={values.email}
                                                disabled
                                                className="is-invalid"
                                                placeholder="Email"
                                            />
                                            {errors.email && touched.email && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="clock" />
                                            <Form.Control
                                                type="text"
                                                name="avaiablity"
                                                placeholder="Availability Hours"
                                                value={values.avaiablity}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.avaiablity && touched.avaiablity && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.avaiablity}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-end">
                                <LoadingButton variant='contained' loading={![API_STATUS.FULFILLED, 'initial'].includes(cc_mentorUpdateLoading)} type="submit" onClick={() => {
                                        if (!departmentID) {
                                            seterrorMsg({
                                                doj: !departmentID
                                            });
                                            setValidated(false)
                                        }
                                    }}>
                                        Update
                                    </LoadingButton>
                                </div>
                            </form>
                        </Card>
                    </section>

                    {/* Positions End */}
                </Col>
            </Row >
        </>
    );
};

export default CC_MentorEdit;
