import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdDelete } from "react-icons/md";
import {
  JobListData,
  CreateJobScheduler,
  UpdateJobScehduler,
  ViewJobScheduler,
  clearHiringPartnerData,
  hiringpartnerSelector,
} from "../../store/reducer/HiringPartnerReducer";
import {
  StudentListData,
  clearStudentData,
  StudentAcceptanceUpdate,
  StudentSelector,
} from "../../store/reducer/StudentReducer";
import {
  ProjectListData,
  clearHiringMentorData,
  ActivityByMentor,
  hiringmentorSelector,
} from "../../store/reducer/HiringMentorReducer";
import {
  HiringPartnerDashboard,
  DashboardSelector,
  clearDataLoadings,
} from "../../store/reducer/DashboardReducer";
import {
  MentorId,
  MentorSelector,
  clearData,
} from "../../store/reducer/MentorReducer";
import { BsEye, BsActivity, BsPencil } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import TablePagination from "@mui/material/TablePagination";
const MentorDashboard = () => {
  const title = "Mentor Dashboard";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState();
  useEffect(() => {
    dispatch(HiringPartnerDashboard({}));
    let projectpage = 0;
    let projectlimit = 0;
    let projectquery = "";
    dispatch(
      ProjectListData({
        page: projectpage,
        limit: projectlimit,
        query: projectquery,
      })
    );
    dispatch(StudentListData({ query, limit, page, org_id: 0 }));
    dispatch(MentorId({}));
    dispatch(ActivityByMentor({}));
  }, []);
  useEffect(() => {}, []);

  const { projectListDetails, ActivityDetails } =
    useSelector(hiringmentorSelector);
  const {
    jobListDetails,
    jobDetails,
    UpdateJobScehdulerLoading,
    CreateJobSchedulerLoading,
    ViewJobSchedulerLoading,
  } = useSelector(hiringpartnerSelector);
  const {
    hiringpartnerdashboardDatas,
    hiringpartnerdashboardLoading,
    dashboarderrorMessage,
  } = useSelector(DashboardSelector);

  const { studentDatas, studentLoading } = useSelector(StudentSelector);
  const { mentorUserDatas } = useSelector(MentorSelector);
  useEffect(() => {
    console.log(hiringpartnerdashboardDatas, "hiringpartnerdashboardDatas");
  }, [hiringpartnerdashboardDatas]);

  useEffect(() => {
    console.log(ActivityDetails, "ActivityDetails");
  }, [ActivityDetails]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Row className="g-2">
        <Col sm="6" md="4">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="user" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Total Assigned Culture Catalyst(s)
              </div>
              <div className="text-small text-primary">
                <h3>{hiringpartnerdashboardDatas?.assigned_student_count}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" md="4">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="form" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Project(s) Created
              </div>
              <div className="text-small text-primary">
                <h3>{projectListDetails?.length}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" md="4">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="activity" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Activities Created
              </div>
              <div className="text-small text-primary">
                <h3>{ActivityDetails}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={3}>
          <Card className="mb-1">
            <div className="profile-container">
              <div className="bg-gradient-light avatar-image rounded-xl d-flex justify-content-center align-items-center mb-2">
                <FaUser size={30} color="white" />
                {/* <img src={user.avatar} alt="User Avatar" className="avatar-image" /> */}
              </div>

              <fieldset className="user-details">
                <legend>User Details</legend>

                <div className="field">
                  <label htmlFor="username">Username:</label>
                  <span id="username">{mentorUserDatas?.name}</span>
                </div>
                <div className="field">
                  <label htmlFor="email">Email id:</label>
                  <span id="email">{mentorUserDatas?.email}</span>
                </div>
                <div className="field">
                  <label htmlFor="fullName">Domain:</label>
                  <span id="fullName">
                    {mentorUserDatas?.departments?.name}
                  </span>
                </div>
              </fieldset>
            </div>
          </Card>
        </Col>
        <Col md={9}>
          <Card body>
            <Card.Title>
              <h3>Recent Culture Catalyst(s)</h3>
            </Card.Title>
            {/* List Header Start */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Culture Catalyst Name</th>
                  <th>Email</th>
                  <th>Domain</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {studentDatas &&
                  studentDatas?.rows?.map((studentValues, index) => {
                    if (studentValues?.student_acceptance_status != 2)
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{studentValues?.student?.name}</td>
                            <td>{studentValues?.student?.email}</td>
                            <td>{studentValues?.departments?.name}</td>
                            <td>
                              <button
                                title="View Progress"
                                className="btn btn-primary mx-1"
                                onClick={(e) => {
                                  navigate(
                                    `/student/dashboard/${studentValues?.id}`
                                  );
                                }}
                              >
                                <FaUser size={18} />
                              </button>
                              <button
                                title="View Culture Catalyst"
                                className="btn btn-primary mx-1"
                                onClick={(e) => {
                                  navigate(
                                    "/hiring_partner/" +
                                      studentValues?.student_id +
                                      "/course/" +
                                      studentValues?.department_id
                                  );
                                }}
                              >
                                <BsEye />
                              </button>
                              <button
                                title="View Fitment Progress"
                                className="btn btn-primary mx-1"
                                onClick={(e) => {
                                  navigate(
                                    "/student/" +
                                      studentValues?.student_id +
                                      "/project"
                                  );
                                }}
                              >
                                <BsActivity />
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                  })}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end px-2">
              <TablePagination
                component="div"
                options={{
                  paging: false,
                }}
                count={studentDatas?.count}
                page={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeRowsPerPage}
                pageSize={limit}
                rowsPerPageOptions={[limit]}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MentorDashboard;
