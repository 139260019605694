import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  getActivityList,
  getActivityTypes,
  getAllEod,
  getAllPlanDetails,
  getCourseDetails,
  getEod,
  getPlanDetails,
  getProjectEOD,
  getProjectPlan,
  getSprintStatus,
  submitEOD,
  submitPlan,
  updateStudentSprint,  
} from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "course";

const initialState = {
  corseDetailsLoading: "initial",
  planLoader: "initial",
  planDetails: [],
  errorMessage: "",
  planDetailLoader: "initial",
  EODDetailLoader: "initial",
  checkSprintStatusLoader: "initial",
  allPlanDetailLoad: "initial",
  courseData: [],
  sprintstatusDetails: [],
  activityData: [],
  activityTypes: [],
  ActivityListLoader: "initial",
  updateSprintLoader: "initial",
  activitiesTypeLoader: "initial",
  getEodDetailseLoader: "initial",
  ProjectActivityListLoader: "initial",
  ProjectActivityEODListLoader: "initial",
  AllEodLoad: "initial",
  eodDatas: [],
  projectActivitydata: [],
  projectActivityEODdata: [],
  allPlanDetails: [],
  AllEODdata: [],
};

export const courseDetails = createAsyncThunk(
  `${namespace}/courseDetails`,
  async (
    { id, query, order, limit, sort, page, department_id },
    { rejectWithValue, dispatch }
  ) => {
    console.log(
      id,
      query,
      order,
      limit,
      sort,
      page,
      department_id,
      "id, query, order, limit, sort, page, department_id"
    );
    try {
      const { data } = await getCourseDetails({
        department_id,
        id,
        query,
        order,
        limit,
        sort,
        page,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const planDetail = createAsyncThunk(
  `${namespace}/planDetail`,
  async (
    {
      planpage,
      planlimit,
      planquery,
      sortby,
      order,
      status,
      id,
      student_id,
      sprint_id,
      date,
      activity_id,
    },
    { rejectWithValue, dispatch }
  ) => {
    console.log(date, "datedate");
    try {
      const { data } = await getPlanDetails(
        planpage,
        planlimit,
        planquery,
        sortby,
        order,
        status,
        id,
        student_id,
        sprint_id,
        date,
        activity_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const allPlanDetail = createAsyncThunk(
  `${namespace}/allPlanDetail`,
  async (
    { status, id, student_id, sprint_id, date },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await getAllPlanDetails(
        status,
        id,
        student_id,
        sprint_id,
        date
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const checkSprintStatus = createAsyncThunk(
  `${namespace}/checkSprintStatus`,
  async (
    {
      page,
      limit,
      query,
      sortby,
      order,
      status,
      id,
      student_id,
      sprint_id,
      activity_id,
    },
    { rejectWithValue, dispatch }
  ) => {
    console.log(student_id, "studieni");
    try {
      const { data } = await getSprintStatus(
        page,
        limit,
        query,
        sortby,
        order,
        status,
        id,
        student_id,
        sprint_id,
        activity_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ActivityList = createAsyncThunk(
  `${namespace}/ActivityList`,
  async (
    { page, limit, query, sortby, order, status, id, sprint_id, activity_id },
    { rejectWithValue, dispatch }
  ) => {
    console.log(id, "studieni");
    try {
      const { data } = await getActivityList({
        page,
        limit,
        query,
        sortby,
        order,
        status,
        id,
        sprint_id,
        activity_id,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ProjectActivityList = createAsyncThunk(
  `${namespace}/ProjectActivityList`,
  async (
    { page, limit, query, sortby, order, status, student_id, project_id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await getProjectPlan(
        page,
        limit,
        query,
        sortby,
        order,
        status,
        student_id,
        project_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ProjectActivityEODList = createAsyncThunk(
  `${namespace}/ProjectActivityEODList`,
  async (
    { page, limit, query, sortby, order, status, student_id, project_id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await getProjectEOD(
        page,
        limit,
        query,
        sortby,
        order,
        status,
        student_id,
        project_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const postPlanDetails = createAsyncThunk(
  `${namespace}/postPlanDetails`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await submitPlan({ formData });
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getEodDetails = createAsyncThunk(
  `${namespace}/getEodDetails`,
  async (
    {
      eodpage,
      eodlimit,
      eodquery,
      sortby,
      order,
      activity_id,
      student_id,
      sprint_id,
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await getEod(
        eodpage,
        eodlimit,
        eodquery,
        sortby,
        order,
        activity_id,
        student_id,
        sprint_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getAllEodDetails = createAsyncThunk(
  `${namespace}/getAllEodDetails`,
  async (
    { activity_id, student_id, sprint_id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await getAllEod(activity_id, student_id, sprint_id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const postEODDetails = createAsyncThunk(
  `${namespace}/postEODDetails`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    console.log(formData, "test err ss");
    try {
      const { data } = await submitEOD({ formData });
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateSprint = createAsyncThunk(
  `${namespace}/updateSprint`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await updateStudentSprint({ formData });
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getActivitiesTypeScheduler = createAsyncThunk(
  `${namespace}/getActivitiesTypeScheduler`,
  async ({ page, limit, query, type }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getActivityTypes(page, limit, query, type);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const courseReducer = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearCourseData: () => {
      return initialState;
    },
    clearCorseErrMsg: (state) => {
      state.errorMessage = null;
    },
    EmptyCourseData: (state) => {
      state.courseData = [];
    },
    clearCourseLoadings: (state) => {
      state.ActivityListLoader = "initial";
      state.checkSprintStatusLoader = "initial";
      state.corseDetailsLoading = "initial";
      state.planDetailLoader = "initial";
      state.updateSprintLoader = "initial";
      state.activitiesTypeLoader = "initial";
      state.EODDetailLoader = "initial";
      state.allPlanDetailLoad = "initial";
      state.getEodDetailseLoader = "initial";
      state.ProjectActivityListLoader = "initial";
      state.ProjectActivityEODListLoader = "initial";
      state.AllEodLoad="initial";
      state.planLoader = "initial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(courseDetails.pending, (state) => {
      state.corseDetailsLoading = API_STATUS.PENDING;
    });
    builder.addCase(courseDetails.fulfilled, (state, { payload }) => {
      state.corseDetailsLoading = API_STATUS.FULFILLED;
      console.log(payload, secretKey);
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.courseData = data;
    });
    builder.addCase(courseDetails.rejected, (state, action) => {
      state.corseDetailsLoading = API_STATUS.REJECTED;
      console.log(action?.payload, "payload");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(planDetail.pending, (state) => {
      state.planLoader = API_STATUS.PENDING;
    });
    builder.addCase(planDetail.fulfilled, (state, { payload }) => {
      state.planLoader = API_STATUS.FULFILLED;
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.planDetails = data;
      console.log(data, "ASDFSGFSDG");
    });
    builder.addCase(planDetail.rejected, (state, action) => {
      state.planLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(postPlanDetails.pending, (state) => {
      state.planDetailLoader = API_STATUS.PENDING;
    });
    builder.addCase(postPlanDetails.fulfilled, (state, { payload }) => {
      state.planDetailLoader = API_STATUS.FULFILLED;
    });
    builder.addCase(postPlanDetails.rejected, (state, action) => {
      state.planDetailLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(postEODDetails.pending, (state) => {
      state.EODDetailLoader = API_STATUS.PENDING;
    });
    builder.addCase(postEODDetails.fulfilled, (state, { payload }) => {
      state.EODDetailLoader = API_STATUS.FULFILLED;
    });
    builder.addCase(postEODDetails.rejected, (state, action) => {
      state.EODDetailLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(getAllEodDetails.pending, (state) => {
      state.AllEodLoad = API_STATUS.PENDING;
    });
    builder.addCase(getAllEodDetails.fulfilled, (state, { payload }) => {
      state.AllEodLoad = API_STATUS.FULFILLED;
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.AllEODdata = data;;
    });
    builder.addCase(getAllEodDetails.rejected, (state, action) => {
      state.AllEodLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(allPlanDetail.pending, (state) => {
      state.allPlanDetailLoad = API_STATUS.PENDING;
    });
    builder.addCase(allPlanDetail.fulfilled, (state, { payload }) => {
      state.allPlanDetailLoad = API_STATUS.FULFILLED;
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.allPlanDetails = data;
      console.log(data, "ASDFSGFSDG");
    });
    builder.addCase(allPlanDetail.rejected, (state, action) => {
      state.allPlanDetailLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(checkSprintStatus.pending, (state) => {
      state.checkSprintStatusLoader = API_STATUS.PENDING;
    });
    builder.addCase(checkSprintStatus.fulfilled, (state, { payload }) => {
      state.checkSprintStatusLoader = API_STATUS.FULFILLED;
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      console.log(data, "djafvdhfvbsdfj");
      state.sprintstatusDetails = data?.data;
    });
    builder.addCase(checkSprintStatus.rejected, (state, action) => {
      state.checkSprintStatusLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(ActivityList.pending, (state) => {
      state.ActivityListLoader = API_STATUS.PENDING;
    });
    builder.addCase(ActivityList.fulfilled, (state, { payload }) => {
      state.ActivityListLoader = API_STATUS.FULFILLED;
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      state.activityData = data?.data;
    });
    builder.addCase(ActivityList.rejected, (state, action) => {
      state.ActivityListLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(ProjectActivityList.pending, (state) => {
      state.ProjectActivityListLoader = API_STATUS.PENDING;
    });
    builder.addCase(ProjectActivityList.fulfilled, (state, { payload }) => {
      state.ProjectActivityListLoader = API_STATUS.FULFILLED;
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.projectActivitydata = data?.data;
    });
    builder.addCase(ProjectActivityList.rejected, (state, action) => {
      state.ProjectActivityListLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(ProjectActivityEODList.pending, (state) => {
      state.ProjectActivityEODListLoader = API_STATUS.PENDING;
    });
    builder.addCase(ProjectActivityEODList.fulfilled, (state, { payload }) => {
      state.ProjectActivityEODListLoader = API_STATUS.FULFILLED;
      // const data = JSON.parse(EncryptDecrypt.decryptdata(payload?.datas, "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"))
      state.projectActivityEODdata = payload?.datas?.data;
    });
    builder.addCase(ProjectActivityEODList.rejected, (state, action) => {
      state.ProjectActivityEODListLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(updateSprint.pending, (state) => {
      state.updateSprintLoader = API_STATUS.PENDING;
    });
    builder.addCase(updateSprint.fulfilled, (state, { payload }) => {
      state.updateSprintLoader = API_STATUS.FULFILLED;
    });
    builder.addCase(updateSprint.rejected, (state, action) => {
      state.updateSprintLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(getActivitiesTypeScheduler.pending, (state) => {
      state.activitiesTypeLoader = API_STATUS.PENDING;
    });
    builder.addCase(
      getActivitiesTypeScheduler.fulfilled,
      (state, { payload }) => {
        state.activitiesTypeLoader = API_STATUS.FULFILLED;
        // const payloaddata = JSON.parse(
        //   EncryptDecrypt.decryptdata(
        //     payload?.datas,
        //     "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        //   )
        // );
        // state.activityTypes = payloaddata;
        state.activityTypes = payload?.datas;

        console.log(state.activityTypes, "state.activityTypes");
      }
    );
    builder.addCase(getActivitiesTypeScheduler.rejected, (state, action) => {
      state.activitiesTypeLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getEodDetails.pending, (state) => {
      state.getEodDetailseLoader = API_STATUS.PENDING;
    });
    builder.addCase(getEodDetails.fulfilled, (state, { payload }) => {
      state.getEodDetailseLoader = API_STATUS.FULFILLED;
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.eodDatas = data;
      console.log(data, "activisty type reeducer");
    });
    builder.addCase(getEodDetails.rejected, (state, action) => {
      state.getEodDetailseLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const {
  clearCourseData,
  clearCorseErrMsg,
  EmptyCourseData,
  clearCourseLoadings,
} = courseReducer.actions;

export const courseSelector = (state) => state.course;

export default courseReducer.reducer;
