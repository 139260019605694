import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { LoadingButton } from "@mui/lab";
import "react-datepicker/dist/react-datepicker.css";

import {
  CertificateSelector,
  certificateView,
  certificateclearData,
  downloadCertificate,
} from "../../store/reducer/CertificateReducer";
import { API_BASE } from "../../services/config";

const View_Download = () => {
  const title = "Certificate";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** States */
  const [validated, setValidated] = useState(false);
  const validationSchema = Yup.object().shape({
    certificate_id: Yup.string().required("Certificate Id is required"),
  });
  const initialValues = { certificate_id: "" };
  const onSubmit = (formData) => {
    dispatch(certificateView({ formData }));
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const { certificateData, certificateDataLoading, certificateerrorMessage } =
    useSelector(CertificateSelector);

  useEffect(() => {
    if (certificateDataLoading === API_STATUS.FULFILLED) {
      dispatch(certificateclearData());
    }
    if (
      certificateDataLoading === API_STATUS.REJECTED &&
      certificateerrorMessage
    ) {
      if (certificateerrorMessage.message)
        Swal.fire({
          title: certificateerrorMessage.message,
          icon: "error",
        });
      else if (certificateerrorMessage.errors) {
        let data = "";
        certificateerrorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        Swal.fire({
          title: data,
          icon: "error",
        });
      }
      dispatch(certificateclearData());
    }
  }, [certificateDataLoading, certificateerrorMessage]);

  /** Functions */
  const onButtonClick = () => {
    const pdfUrl = API_BASE + "/" + certificateData.certificate;
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = pdfUrl;
    link.download = "document.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <form
                id="mentorAddForm"
                className={
                  validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                }
                onSubmit={(e) => {
                  e.preventDefault();
                  setValidated(true);
                  handleSubmit();
                }}
              >
                <Row className="mb-3 g-3">
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="diploma" />
                      <Form.Control
                        type="text"
                        name="certificate_id"
                        placeholder="Certificate ID"
                        value={values.certificate_id}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.certificate_id && touched.certificate_id && (
                        <Form.Control.Feedback type="invalid">
                          {errors.certificate_id}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>

                  {certificateData?.certificate_id != null ? (
                    <>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="user" />
                          <Form.Control
                            type="text"
                            name="user"
                            readOnly
                            placeholder="Name"
                            value={certificateData.name}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="email" />
                          <Form.Control
                            type="text"
                            name="email"
                            readOnly
                            placeholder="Email"
                            value={certificateData?.email}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="file" />
                          <Form.Control
                            as="textarea"
                            disabled={true}
                            name="course_details"
                            placeholder="Certification Details"
                            value={certificateData?.certificate_course_details}
                          />
                        </div>
                      </Col>
                      <div className="text-end">
                        <LoadingButton
                          variant="contained"
                          loading={
                            ![API_STATUS.FULFILLED, "initial"].includes(
                              certificateDataLoading
                            )
                          }
                          type="button"
                          onClick={onButtonClick}
                        >
                          Download Certificate
                        </LoadingButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-end">
                        <LoadingButton
                          variant="contained"
                          loading={
                            ![API_STATUS.FULFILLED, "initial"].includes(
                              certificateDataLoading
                            )
                          }
                          type="submit"
                        >
                          Get Certificate
                        </LoadingButton>
                      </div>
                    </>
                  )}
                </Row>
              </form>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default View_Download;
