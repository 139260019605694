import React, { useDebugValue, useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Modal,
  Button,
  Form,
  Breadcrumb,
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import {
  API_STATUS,
  STUDENT_ACCEPTANCE_STATUS,
  FEEDBACK_STATUS,
} from "../../utils/Constants";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import { BsEye, BsActivity } from "react-icons/bs";
import { MdClose, MdCheck, MdPersonPin } from "react-icons/md";
import { TablePagination } from "@mui/material";
import {
  MentorDataList,
  MentorSelector,
  clearData,
  MentorDelete,
} from "../../store/reducer/MentorReducer";
import {
  StudentListData,
  clearStudentData,
  StudentAcceptanceUpdate,
  StudentSelector,
} from "../../store/reducer/StudentReducer";
import settingsReducer, {
  SkillList,
  settingSelector,
} from "../../store/reducer/settingsSlice";
import {
  hiringmentorSelector,
  MentorFeedbackbyStudent,
  getMentor,
} from "../../store/reducer/HiringMentorReducer";
import { LoadingButton } from "@mui/lab";
import { FaUser } from "react-icons/fa";
import { Rating, Stack } from "@mui/material";
const HiringPartnerStudents = () => {
  const title = "Hiring Partner Students List";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [mentorId, setmentorId] = useState();
  const [errorMsg, seterrorMsg] = useState({
    mentorError: false,
  });
  const [show, setShow] = useState(false);
  const [studentName, setstudentName] = useState();
  const [DepartmentName, setDepartmentName] = useState();
  const [ID, setID] = useState();
  const [reject_show, setRejectShow] = useState(false);
  const [feedback_show, setFeedbackShow] = useState();
  const [feedbackValue, setfeedbackValue] = useState("");
  const [order, setOrder] = useState("");
  const [sort, setSort] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  useEffect(() => {
    dispatch(MentorDataList({}));
  }, []);

  const {
    mentorListDatas,
    mentorLoading,
    mentorDelLoading,
    errorMessage,
    mentorFeedbackLoader,
  } = useSelector(MentorSelector);
  const {
    studentDatas,
    studentLoading,
    studentAcceptanceLoading,
    studenterrorMessage,
    studentAcceptanceMessage,
    overallFeedbackData,
  } = useSelector(StudentSelector);
  const { skillDatas } = useSelector(settingSelector);
  const { mentorCCLoading, hiringMentorProfileDatas, MentorCCFeedbackDatas } =
    useSelector(hiringmentorSelector);
  useEffect(() => {
    let org_id = id;
    dispatch(StudentListData({ query, limit, page, org_id }));
  }, []);

  useEffect(() => {
    console.log(studentDatas, "studentDatas");
  }, [studentDatas]);

  useEffect(() => {
    console.log(skillDatas, "skillDatas");
  }, [skillDatas]);

  const UpdateMentor = (studentValues) => {
    setShow(true);
    setstudentName(studentValues?.student?.name);
    setDepartmentName(studentValues?.departments?.name);
    setID(studentValues?.id);
  };
  const MentorUpdate = () => {
    if (!mentorId) {
      seterrorMsg({
        mentorError: !mentorId,
      });
    } else {
      let formData = {};
      formData.id = ID;
      formData.mentor_id = mentorId;
      formData.student_acceptance_status = 3;
      console.log(formData, "DSFASOFD");
      dispatch(StudentAcceptanceUpdate({ formData }));
    }
  };

  const showFeedback = (studentDetails) => {
    let student_id = studentDetails?.student?.id;
    dispatch(MentorFeedbackbyStudent({ student_id }));
    dispatch(
      SkillList({
        page,
        limit,
        query: studentDetails?.departments?.id,
        sortby: sort ?? "",
        order: order ?? "",
      })
    );
    setFeedbackShow(true);
    setstudentName(studentDetails?.student?.name);
    setDepartmentName(studentDetails?.departments?.name);
    setID(studentDetails?.student?.id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = studentDatas?.count % 10;
    let remainder = studentDatas?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [studentDatas]);

  useEffect(() => {
    let org_id = id;
    dispatch(StudentListData({ query, limit, page, org_id }));
  }, [page, limit, query]);

  useEffect(() => {
    console.log(MentorCCFeedbackDatas, "MentorCCFeedbackDatasu");
  }, [MentorCCFeedbackDatas]);
  useEffect(() => {
    if (mentorDelLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Mentor Deleted Successfully!",
        icon: "success",
      });
      dispatch(clearData());
      dispatch(MentorDataList({}));
    }

    if (studentAcceptanceLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: studentAcceptanceMessage?.message,
        icon: studentAcceptanceMessage?.type,
      });     
      let org_id = id;
      dispatch(StudentListData({ query, limit, page, org_id }));
      dispatch(clearStudentData());
      setShow(false);
      setRejectShow(false);
    }
  }, [mentorDelLoading, studentAcceptanceLoading]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="d-flex w-100 justify-content-end">
              <Button
                className="mb-3 mx-1"
                variant="success"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            </div>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Culture Catalyst Name</th>
                    <th>Email</th>
                    <th>Domain</th>
                    <th>Job Title</th>
                    <th>Mentor</th>
                    <th>Status</th>
                    <th>Mentor Feedback</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {studentDatas &&
                    studentDatas?.rows?.map((studentValues, index) => {
                      let parent = studentValues?.student?.mentor_feedback;
                      // studentValues?.student?.mentor_feedback[0]?.acceptance_status
                      let statusValue = parent?.find((ele) => {
                        return ele.student_id == studentValues?.student_id;
                      });
                      if (studentValues?.student_acceptance_status != 2)
                        return (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{studentValues?.student?.name}</td>
                              <td>{studentValues?.student?.email}</td>
                              <td>{studentValues?.departments?.name}</td>
                              <td>{studentValues?.job_description?.title}</td>
                              {studentValues?.mentor ? (
                                <td>{studentValues?.mentor?.name}</td>
                              ) : (
                                <td>Yet to Assign Mentor</td>
                              )}
                              <td>
                                {
                                  STUDENT_ACCEPTANCE_STATUS[
                                    studentValues?.student_acceptance_status
                                  ]
                                }
                              </td>
                              <td>
                                {statusValue?.acceptance_status ? (
                                  <button
                                    title="Mentor Status"
                                    className="btn btn-success mx-1"
                                    onClick={() => showFeedback(studentValues)}
                                  >
                                    {
                                      FEEDBACK_STATUS[
                                        statusValue?.acceptance_status
                                      ]
                                    }{" "}
                                  </button>
                                ) : (
                                  <button
                                    title="Mentor Status"
                                    className="btn btn-warning mx-1"
                                  >
                                    Yet To Update
                                  </button>
                                )}
                              </td>
                              <td>
                                <button
                                  title="View Progress"
                                  className="btn btn-primary mx-1"
                                  onClick={(e) => {
                                    navigate(
                                      `/student/dashboard/${studentValues?.student_id}`
                                    );
                                  }}
                                >
                                  <FaUser size={18} />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                    })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between px-2">
                <strong>Total count : {studentDatas?.count}</strong>
                <TablePagination
                  component="div"
                  count={studentDatas?.count}
                  page={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
      {/* Mentor Assign */}
      <Modal
        show={show}
        id="mentorAssign"
        dialogClassName="modal-100w"
        aria-labelledby="mentorAssign"
        tabIndex="-1"
        scrollable
        size="xl"
      >
        <div className="p-3 mt-2" style={{ overflow: "auto" }}>
          <>
            <h1>Assign Mentor</h1>
            <Row>
              <Col md="6">
                <h5>
                  <label htmlFor="">
                    Culture Catalyst Name: <strong>{studentName}</strong>
                  </label>
                </h5>
              </Col>
              <Col md="6">
                <h5>
                  <label htmlFor="">
                    Domain Name: <strong>{DepartmentName}</strong>
                  </label>
                </h5>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">Mentor List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setmentorId(e.target.value);
                    }}
                    value={mentorId}
                    name="mentor_id"
                    className="is-invalid"
                    placeholder="Domain"
                  >
                    <option>Select Mentor</option>
                    {mentorListDatas &&
                      mentorListDatas.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.mentorError && (
                    <p className="text-danger">Select the Mentor</p>
                  )}
                </div>
              </Col>
            </Row>
            <div className="text-end">
              <LoadingButton
                variant="contained"
                loading={
                  ![API_STATUS.FULFILLED, "initial"].includes(
                    studentAcceptanceLoading
                  )
                }
                onClick={MentorUpdate}
              >
                Confirm
              </LoadingButton>
              <Button
                variant="primary"
                className="mx-1"
                onClick={() => setShow(false)}
              >
                Cancel
              </Button>
            </div>
          </>
        </div>
      </Modal>

      {/* Culture Catalyst Feedback */}
      <Modal
        show={feedback_show}
        id="feedback"
        dialogClassName="modal-100w"
        aria-labelledby="feedback"
        tabIndex="-1"
        scrollable
        size="xl"
      >
        <div className="p-3 mt-2" style={{ overflow: "auto" }}>
          <>
            <h1>Culture Catalyst Feedback</h1>
            <Row>
              <Col md="6">
                <h2>
                  <label htmlFor="">
                    Culture Catalyst Name: <strong>{studentName}</strong>
                  </label>
                </h2>
              </Col>
              <Col md="6">
                <h2>
                  <label htmlFor="">
                    Domain Name: <strong>{DepartmentName}</strong>
                  </label>
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md="6"></Col>
            </Row>
            <Row className="">
              {skillDatas?.rows?.map((data, skill_index) => {
                return (
                  <>
                    <Col md="6">
                      <h2>{data?.name}</h2>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating"
                          precision={0.5}
                          value={
                            MentorCCFeedbackDatas?.mentor_feedback_rankings &&
                            MentorCCFeedbackDatas?.mentor_feedback_rankings[
                              skill_index
                            ]?.skill_id == data.id
                              ? MentorCCFeedbackDatas?.mentor_feedback_rankings[
                                  skill_index
                                ]?.rankings
                              : 0
                          }
                          disabled
                        />
                      </Stack>
                    </Col>
                  </>
                );
              })}
            </Row>
            <Row>
              <Col md="6">
                <h2 className="">
                  Overall Rating:{" "}
                  <strong>
                    {" "}
                    {MentorCCFeedbackDatas?.overall_rankings?.toFixed(2)}{" "}
                  </strong>
                </h2>
              </Col>
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">Feedback : </label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <div className="filled form-group tooltip-end-top mt-3">
                    {/* <CsLineIcons icon="user" /> */}
                    <Form.Control
                      as="textarea"
                      name="feedback"
                      placeholder="Feedback"
                      value={MentorCCFeedbackDatas?.feedback}
                      className="is-invalid"
                      readOnly
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <div className="text-end">
              <LoadingButton
                type="button"
                variant="contained"
                className="mx-1"
                loading={
                  ![API_STATUS.FULFILLED, "initial"].includes(
                    mentorFeedbackLoader
                  )
                }
              >
                Send Offer Letter
              </LoadingButton>
              <LoadingButton
                type="button"
                variant="contained"
                loading={
                  ![API_STATUS.FULFILLED, "initial"].includes(
                    mentorFeedbackLoader
                  )
                }
              >
                Reject
              </LoadingButton>
              <Button
                variant="primary"
                className="mx-1"
                onClick={() => setFeedbackShow(false)}
              >
                Cancel
              </Button>
            </div>
            {/* </form> */}
          </>
        </div>
      </Modal>
    </>
  );
};

export default HiringPartnerStudents;
