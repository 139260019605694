import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearErrormsg,
  signUpScheduler,
  signUpSelector,
} from "../../store/reducer/SignUpReducer";
import { UserSelector, myAccount } from "../../store/reducer/UserReducer";
import {
  departmentLists,
  departmentUpdates,
  placementSelector,
  testLists,
} from "../../store/reducer/PlacementReducer";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import LOGO from "../../assets/img/favicon.png";

const WelcomPage = () => {
  const name = localStorage.getItem("username");
  const title = "Choose Career";
  const recaptchaRef = React.createRef();
  const [initialValues, setInitialValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const { signUpData, signUpLoading } = useSelector(signUpSelector);
  const { UserDatas, clearData } = useSelector(UserSelector);
  const {
    testData,
    testLoading,
    departmentData,
    errorMessage,
    departupdateLoading,
  } = useSelector(placementSelector);
  const [departmentID, setDepartmentID] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const onSubmit = (formData) => {
    // formData.expires_on = selectedDate;
    // let data = Object.keys(formData);
    // data.forEach((items) => {
    //     if (!formData[items])
    //         delete formData[items]
    // })
    // if (id) {
    //     dispatch(UpdateJobScehduler({ id, formData }))
    // } else {
    //     dispatch(CreateJobScheduler({ formData }));
    // }
  };
  useEffect(() => {
    dispatch(myAccount({}));
  }, []);
  useEffect(() => {
    console.log(UserDatas, "UserDatas");
  }, [UserDatas]);
  // const {
  //     register,
  //     handleSubmit,
  //     formState: { errors },
  //     reset,
  // } = useForm({
  //     // resolver: yupResolver(signUpValidationSchema),
  // });
  const REDIRECT_URI = "http://localhost:3000/account/login";
  const onSubmitHandler = (formData) => {
    console.log({ formData });
    dispatch(signUpScheduler({ formData }));
  };
  const formik = useFormik({ initialValues, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const DELAY = 1500;
  const [captcha, setCaptcha] = useState({
    callback: "not fired",
    value: "[empty]",
    load: false,
    expired: "false",
    recaptchaLoaded: false,
  });

  // const onLoginStart = useCallbac(() => {
  //     // alert("login start");
  // }, []);

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    }
    dispatch(testLists({}));
    // dispatch(completedLists({}));
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    dispatch(departmentLists(departmentQuery ));
  }, []);

  const handleChanges = (value) => {
    console.log("onChange prop - Captcha value:", value);
    setCaptcha({ value });
    if (value === null) setCaptcha({ expired: "true" });
  };

  const asyncScriptOnLoad = () => {
    setCaptcha({ callback: "called!", recaptchaLoaded: true });
    console.log("scriptLoad - reCaptcha Ref-", recaptchaRef);
  };
  const startTest = () => {
    if (departmentID !== "") {
      localStorage.setItem("departmentID", departmentID);
      localStorage.setItem("question_group_id", 1);
      let formData = { department: departmentID };
      dispatch(departmentUpdates({ formData }));
    } else {
      Swal.fire({
        title: "Select Career to continue",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (departupdateLoading === API_STATUS.FULFILLED) {
      navigate("/questions");
    }
    if (errorMessage) {
      if (errorMessage.message)
        Swal.fire({
          title: errorMessage.message,
          icon: "error",
        });
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        Swal.fire({
          title: data,
          icon: "error",
        });
      }
      dispatch(clearErrormsg());
    }
  }, [departupdateLoading, errorMessage]);
  return (
    <div>
      <header className="header-page">
        <img src={LOGO} alt="" style={{ height: "70px" }} />
        <h2 className="header">Hello {name}</h2>
      </header>
      <div className="container" style={{ marginTop: "310px" }}>
        <section className="scroll-section" id="title">
          <div className="page-title-container">
            <h1 className="mb-0 pb-0 display-4">{title}</h1>
            <Breadcrumb className="breadcrumb-container d-inline-block">
              <Breadcrumb.Item key={`breadCrumb1`}>
                Select Career type
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </section>

        <div className="d-flex flex-column justify-content-center">
          <Card className="p-4 w-100">
            <h2 class="sub_heading">
              Select the profile of your choice and proceed with the test
            </h2>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              {/* <Row>
                        <Col md="6"> */}

              <div
                className="mb-3 filled form-group tooltip-end-top"
                onClick={() => setIsClicked(true)}
              >
                {/* <CsLineIcons icon="user" /> */}
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setDepartmentID(e.target.value);
                  }}
                  value={departmentID}
                  // onChange={(e) => setSelectedValues(e.target.value, 'year')}
                  className="is-invalid"
                  placeholder="Department"
                >
                  {!isClicked ? <option>SELECT</option> : ""}
                  {departmentData &&
                    departmentData?.rows?.map((fields) => {
                      return <option value={fields.id}>{fields.name}</option>;
                    })}
                </Form.Control>
                {/* {errors.department && touched.department && (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.department}
                                            </Form.Control.Feedback>
                                        )} */}
              </div>
            </Form>
            {/* <div class="form_group">
                                    <label>
                                    </label>
                                    <div class="select">
                                        <select
                                            name="department"
                                            class="optionType"
                                            id="opt"
                                            onChange={(e) => {
                                                setDepartmentID(e.target.value);
                                            }}
                                        >
                                            <option value=""> Choose Profile</option>
                                            {departmentData &&
                                                departmentData.map((fields) => {
                                                    return (
                                                        <option value={fields.id}>{fields.name}</option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div> */}
            <div className="text-end">
              <Button
                name="question_submit"
                class="submit_btn"
                onClick={(e) => {
                  startTest();
                }}
              >
                <span class="btnText">Start Test</span>
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default WelcomPage;
