import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Webcam from 'react-webcam';
import RecordRTC from 'recordrtc';
import { updateVideoResume } from '../../store/reducer/StudentReducer';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const WebcamRecorder = ({ props }) => {
    const dispatch = useDispatch()
    const webcamRef = useRef(null);
    const [recording, setRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [recorder, setRecorder] = useState(null);
    const currentTime = useRef(0);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);
    const [recordTime, setRecordTime] = useState(120)


    useEffect(() => {
        const disableRightClick = (e) => {
            e.preventDefault();
        };

        document?.addEventListener('contextmenu', disableRightClick);

        return () => {
            document?.removeEventListener('contextmenu', disableRightClick);
        };
    }, []);

    const renderTime = ({ remainingTime }) => {
        currentTime.current = remainingTime
        if (currentTime.current !== remainingTime) {
            isNewTimeFirstTick.current = true;
            prevTime.current = currentTime.current;
            currentTime.current = remainingTime;
        } else {
            isNewTimeFirstTick.current = false;
        }

        // force one last re-render when the time is over to tirgger the last animation
        if (remainingTime === 0) {
            setTimeout(() => {
                setOneLastRerender((val) => val + 1);
            }, 20);
        }

        const isTimeUp = isNewTimeFirstTick.current;

        return (
            <div className="time-wrapper">
                <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
                    {remainingTime}
                </div>
                {prevTime.current !== null && (
                    <div
                        key={prevTime.current}
                        className={`time ${!isTimeUp ? "down" : ""}`}
                    >
                        {prevTime.current}
                    </div>
                )}
            </div>
        );
    };


    const stopRecording = () => {
        if (recorder) {
            recorder.stopRecording(() => {
                const blob = recorder.getBlob();
                const url = URL.createObjectURL(blob);
                setRecordedChunks(recordedChunks.concat(blob));
                setRecording(false);
            });
        }
    };
    const startRecording = async () => {
        const stream = webcamRef.current.video.srcObject;
        const newRecorder = new RecordRTC(stream, {
            type: 'video',
        });
        newRecorder.startRecording();
        setRecording(true);
        setRecorder(newRecorder);
        const time = recordTime * 1000
        setTimeout(() => {
            newRecorder?.stopRecording(() => {
                const blob = newRecorder.getBlob();
                const url = URL.createObjectURL(blob);
                setRecordedChunks(recordedChunks.concat(blob));
                setRecording(false);
            });
        }, time);
    };

    const clearRecordedChunks = () => {
        setRecordedChunks([]);
    };

    const handleSubmit = () => {
        const payload = {
            student_id: props?.name,
            video_resume: recordedChunks[0]
        }
        dispatch(updateVideoResume({ payload }))
    }

    useEffect(() => {
        if (!props.modal) {
            stopRecording()
        }
    }, [props.modal])

    return (
        <div>
            <div className='position-relative'>
                {
                    recordedChunks.length == 0 ?
                        <div className="timer-wrapper position-absolute p-2" style={{ right: "0%" }}>
                            <CountdownCircleTimer
                                strokeWidth={5}
                                // isSmoothColorTransition
                                isPlaying={recording}
                                size={50}
                                duration={recordTime}
                                colors={["#0ec94d", "#F7B801", "#A30000", "#A30000"]}
                                colorsTime={[recordTime, 30, 10, 0]}
                            >
                                {renderTime}
                            </CountdownCircleTimer>
                        </div>
                        :
                        <></>
                }

                {recordedChunks.length > 0 ? (
                    <div>
                        <h2>Recorded Video</h2>
                        <video controls style={{ width: '100%' }}>
                            {recordedChunks.map((chunk, index) => {
                                return (
                                    <source key={index} src={URL.createObjectURL(chunk)} />
                                )
                            })}
                        </video>
                    </div>
                )
                    :
                    <>
                        <Webcam style={{ width: '100%' }} audio={true} ref={webcamRef} aria-readonly />
                    </>
                }
                <div className="d-flex justify-content-between me-2">
                    {
                        recordedChunks.length > 0 ?
                            <>
                                <Button onClick={clearRecordedChunks}>Clear</Button>
                                <Button onClick={handleSubmit}>Submit</Button>
                            </> : !recording ? (
                                <Button onClick={startRecording}>Start Recording</Button>
                            ) : (
                                <Button onClick={stopRecording}>Stop Recording</Button>
                            )
                    }



                </div>
            </div>
        </div>
    );
};

export default WebcamRecorder;
