import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { sprint_Lists, CreateAttachment, Domain_ActivityList, Get_Activity_ByID, UpdateActivity, SprintSelector, clearSprintData, Get_Attachment_ByID, UpdateAttachment } from "../../store/reducer/SprintReducer";
import { departmentLists, departmentUpdates, placementSelector, testLists } from '../../store/reducer/PlacementReducer';
import { localFrontend, FRONTEND_BASE } from "../../services/config";
import { LoadingButton } from "@mui/lab";

const AttachmentManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { domain_id, id } = useParams();
    const title = id ? "Attachment Edit" : "Attachment Add";
    const [validated, setValidated] = useState(false);
    const [Loginerror, setLoginerror] = useState(false);
    const [departmentID, setDepartmentID] = useState(domain_id);
    const [sprintId, setSprintId] = useState();
    const [activityId, setactivityId] = useState();
    const [isClicked, setIsClicked] = useState(false)
    const [errorMsg, seterrorMsg] = useState({
        domainerror: false,
        sprinterror: false,
        activityerror: false
    });

    useEffect(() => {
        let page = 0;
        let limit = 0;
        let query = "";
        let order = "";
        let department_id = domain_id;
        dispatch(sprint_Lists({ page, limit, query, order, department_id }));
        let departmentQuery = {};
        departmentQuery.page = 0;
        departmentQuery.limit = 0;
        departmentQuery.query = "";        
        dispatch(departmentLists(departmentQuery ));
    }, [])
    const selectSprint = (sId) => {
        setSprintId(sId)
        let page = 0;
        let limit = 0;
        let query = "";
        let order = "";
        let id = 0;
        let sprint_id = []
        sprint_id = sId
        dispatch(Domain_ActivityList({ page, limit, query, order, sprint_id, id }));
    }


    const {
        departmentData,
    } = useSelector(placementSelector);

    const validationSchema = Yup.object().shape({
        url: Yup.string().required("URL Name is required")
    })

    const [initialValues, setInitialValues] = useState({
        url: ""
    })

    const onSubmit = (formData) => {
        if ((!departmentID) || (!sprintId) || (!activityId)) {
            setValidated(false)
        } else {
            formData.activity_id = activityId;
            console.log(formData, "formDataformData");
            if (id) {
                dispatch(UpdateAttachment({ id, formData }))
            } else {
                dispatch(CreateAttachment({ formData }));
            }
        }
    };

    const formik = useFormik({ initialValues, validationSchema, onSubmit });
    const { handleSubmit, handleChange, values, touched, errors } = formik;
    const { sprintListData, activityListData, getAttachmentData, create_attachment_Loading, update_attachment_Loading, sprint_errorMessage } = useSelector(SprintSelector);
    useEffect(() => {
        console.log(activityListData, "activityListData");
    }, [activityListData])
    useEffect(() => {
        if (id)
            dispatch(Get_Attachment_ByID({ id: id }))
    }, [id]);
    useEffect(() => {
        if (getAttachmentData !== "") {
            let page = 0;
            let limit = 0;
            let query = "";
            let order = "";
            let id = 0;
            let sprint_id = []
            sprint_id = getAttachmentData?.sprintactivity?.sprint_id
            dispatch(Domain_ActivityList({ page, limit, query, order, sprint_id, id }));

            formik.setValues({
                url: getAttachmentData?.file,
            })
            setSprintId(getAttachmentData?.sprintactivity?.sprint_id)
            setactivityId(getAttachmentData?.sprintactivity?.id)
        }
    }, [getAttachmentData])



    useEffect(() => {
        if (create_attachment_Loading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: "Attachment Added Successfully!",
                icon: 'success'
            });
            dispatch(clearSprintData());
            navigate("/domain/sprint/" + domain_id);
        }
        console.log(update_attachment_Loading, "update_attachment_Loading");
        if (update_attachment_Loading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: "Attachment Updated Successfully!",
                icon: 'success'
            });
            dispatch(clearSprintData());
            navigate("/domain/sprint/" + domain_id);
        }
        if (((create_attachment_Loading === API_STATUS.REJECTED) || (update_attachment_Loading === API_STATUS.REJECTED)) && sprint_errorMessage != null) {
            if (sprint_errorMessage.message) Swal.fire({
                title: sprint_errorMessage.message,
                icon: 'error'
            });
            else if (sprint_errorMessage.errors) {
                let data = "";
                sprint_errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                Swal.fire({
                    title: data,
                    icon: 'error'
                })
            }
            dispatch(clearSprintData());
        }
    }, [create_attachment_Loading, update_attachment_Loading, sprint_errorMessage]);

    return (
        <>
            <Row>
                <Col>
                    {/* Title Start */}
                    <section className="scroll-section" id="title">
                        <div className="page-title-container">
                            <h1 className="mb-0 pb-0 display-4">{title}</h1>
                            <Breadcrumb className="breadcrumb-container d-inline-block">
                                <Breadcrumb.Item key={`breadCrumb1`}>
                                </Breadcrumb.Item>
                            </Breadcrumb>

                        </div>
                        <Link to={`/domain/sprint/${domain_id}`} >
                            <div className="text-end mb-2">
                                <Button variant='success' type='submit'>Back</Button>
                            </div>
                        </Link>
                    </section>
                    {/* Title End */}

                    {/* Standard Start */}
                    <section className="scroll-section" id="standard">
                        {/* <h2 className="small-title">Standard</h2> */}
                        <Card body className="mb-5">
                            {/* List Header Start */}
                            <form
                                id="AttachmentAddForm"
                                className={
                                    validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                                }
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setValidated(true);
                                    handleSubmit();
                                }}
                            >
                                <div className="mb-3 filled form-group tooltip-end-top">
                                    {Loginerror && Loginerror.error && (
                                        <Form.Control.Feedback
                                            type="invalid"
                                            style={{ display: "block" }}
                                        >
                                            {Loginerror.error}
                                        </Form.Control.Feedback>
                                    )}
                                </div>
                                <Row>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file-text" />
                                            <Form.Control
                                                as="select"
                                                onChange={(e) => {
                                                    setDepartmentID(e.target.value);
                                                }}
                                                value={departmentID}
                                                className="is-invalid"
                                                placeholder="Domain"
                                                disabled>
                                                {
                                                    !isClicked ? <option>Select Domain</option> : ""
                                                }
                                                {departmentData &&
                                                    departmentData?.rows?.map((fields) => {
                                                        return (
                                                            <option value={fields.id}>{fields.name}</option>
                                                        );
                                                    })}
                                            </Form.Control>
                                            {errorMsg?.domainerror &&
                                                (
                                                    <p className='text-danger'>Domain is required</p>
                                                )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file-text" />
                                            <Form.Control
                                                as="select"
                                                onChange={(e) => {
                                                    selectSprint(e.target.value)
                                                }}
                                                value={sprintId}
                                                className="is-invalid"
                                                placeholder="Sprint">
                                                {
                                                    !isClicked ? <option>Select Sprint</option> : ""
                                                }
                                                {
                                                    sprintListData &&
                                                    sprintListData?.rows?.map((fields) => {
                                                        return (
                                                            <option value={fields.id}>{fields.name}</option>
                                                        );
                                                    })}
                                            </Form.Control>
                                            {errorMsg?.sprinterror &&
                                                (
                                                    <p className='text-danger'>Sprint is required</p>
                                                )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file-text" />
                                            <Form.Control
                                                as="select"
                                                onChange={(e) => {
                                                    setactivityId(e.target.value);
                                                }}
                                                value={activityId}
                                                className="is-invalid"
                                                placeholder="Activity">
                                                {
                                                    !isClicked ? <option>Select Activity</option> : ""
                                                }
                                                {
                                                    activityListData &&
                                                    activityListData?.rows?.map((fields) => {
                                                        return (
                                                            <option value={fields.id}>{fields.name}</option>
                                                        );
                                                    })}
                                            </Form.Control>
                                            {errorMsg?.activityerror &&
                                                (
                                                    <p className='text-danger'>Activity is required</p>
                                                )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="filled form-group tooltip-end-top">
                                            {/* <CsLineIcons icon="user" /> */}
                                            <Form.Control
                                                as="textarea"
                                                name="url"
                                                placeholder="URL"
                                                value={values.url}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {console.log(touched.url, "ERROE")}
                                            {errors.url && touched.url && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.url}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-end mt-2">

                                    <LoadingButton variant='contained' loading={![API_STATUS.FULFILLED, 'initial'].includes(create_attachment_Loading || update_attachment_Loading)} type="submit" onClick={() => {
                                        if ((!departmentID) || (!sprintId) || (!activityId)) {
                                            seterrorMsg({
                                                domainerror: !departmentID,
                                                sprinterror: !sprintId,
                                                activityerror: !activityId,
                                            });
                                            setValidated(false)
                                        }
                                    }}>
                                        {id ? "Update" : "Create"}
                                    </LoadingButton>
                                </div>
                            </form>
                        </Card>
                    </section>

                    {/* Positions End */}
                </Col>
            </Row >
        </>
    );
};

export default AttachmentManagement;
