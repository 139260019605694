import * as Yup from "yup";
export const createJobValidator = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    key_skills: Yup.string().required('Key Skills is required'),
    salary_min: Yup.string().required('Minimum amount is required'),
    salary_max: Yup.string().required('Maximum amount is required'),
    vacancy_count: Yup.string().required('Number Of Positions is required'),
    roles_responsiablity: Yup.string().required('Roles & Responsiablity is required'),   
});
export const createProjectValidator = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
});

export const planValidator = Yup.object().shape({
    plan: Yup.string().required('Plan is required'),
    plan_duration: Yup.string().required('Plan Duration is required'),
});

export const eodValidator = Yup.object().shape({
    actual_duration: Yup.number(),
    eod_status: Yup.string(),
    attachment: Yup.mixed(),
    activity_type_id: Yup.string()
});