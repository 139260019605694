import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import {
  JobListData,
  ProfileHiringPartner,
  hiringpartnerSelector,
  clearData,
} from "../store/reducer/HiringPartnerReducer";
import {
  HiringPartnerDashboard,
  DashboardSelector,
  clearDataLoadings,
} from "../store/reducer/DashboardReducer";
import {
  departmentLists,
  departmentUpdates,
  placementSelector,
  testLists,
} from "../store/reducer/PlacementReducer";
import TablePagination from "@mui/material/TablePagination";
const Dashboard = () => {
  const title = "Dashboard";
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    dispatch(HiringPartnerDashboard({}));
    dispatch(departmentLists(departmentQuery));
    dispatch(ProfileHiringPartner({}));
  }, []);

  const {
    jobListDetails,
    hiringPartnerLoading,
    hiringPartnerProfileDatas,
    errorMessage,
  } = useSelector(hiringpartnerSelector);
  const {
    hiringpartnerdashboardDatas,
    hiringpartnerdashboardLoading,
    dashboarderrorMessage,
  } = useSelector(DashboardSelector);
  const { departmentData } = useSelector(placementSelector);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    console.log(hiringPartnerProfileDatas, "hiringPartnerProfileDatas");
    let org_id = hiringPartnerProfileDatas?.id;
    dispatch(JobListData({ query, limit, page, org_id }));
  }, [hiringPartnerProfileDatas]);
  useEffect(() => {
    console.log(jobListDetails, "jobListDetails");
    console.log(hiringpartnerdashboardDatas, "hiringpartnerdashboardDatas");
  }, [jobListDetails, hiringpartnerdashboardDatas]);

  return (
    <div>
      {/* Total Counts of Student, Mentor and Jobs */}
      <Row className="g-2">
        <Col sm="6" lg="3">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="user" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Total Culture Catalyst(s)
              </div>
              <div className="text-small text-primary">
                <h3>{hiringpartnerdashboardDatas?.total_students_count}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="3">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="check" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Total Assigned Culture Catalyst(s)
              </div>
              <div className="text-small text-primary">
                <h3>{hiringpartnerdashboardDatas?.assigned_student_count}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="3">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="book" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Total Mentors
              </div>
              <div className="text-small text-primary">
                <h3>{hiringpartnerdashboardDatas?.total_mentors_count}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="3">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="list" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Total Jobs
              </div>
              <div className="text-small text-primary">
                <h3>{hiringpartnerdashboardDatas?.total_jobs_count}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Student, Mentor and Jobs Counts By Department */}
      <Row className="mx-2 mt-2">
        <Col md={4}>
          <Card className="mb-2 p-4">
            <Row className="h-100 align-content-center">
              {departmentData &&
                departmentData?.rows?.map((deparmentValues, index) => {
                  return (
                    <>
                      <Col md="6" className="mb-2">
                        <Card className="text-center p-2 m-1 shadow-lg bg-light">
                          {deparmentValues?.name}
                          <div className="text-muted text-medium text-center">
                            <h3>
                              {" "}
                              {hiringpartnerdashboardDatas?.student_by_domain?.[
                                deparmentValues.id
                              ]?.student_count ?? 0}{" "}
                              CC
                            </h3>
                          </div>
                        </Card>
                      </Col>
                    </>
                  );
                })}
            </Row>
          </Card>
        </Col>
        <Col md={8}>
          {/* Jobs Details */}
          <Card body className="mb-5 mt-2">
            <Card.Title>
              <h3>Recent Job(s)</h3>
            </Card.Title>
            {/* List Header Start */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Job Title</th>
                  <th>Domain</th>
                  <th>No of Positions</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {jobListDetails &&
                  jobListDetails?.rows?.map((jobValues, index) => {
                    return (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{jobValues?.title}</td>
                          <td>{jobValues?.departments?.name}</td>
                          <td>{jobValues?.vacancy_count}</td>
                          <td>
                            {jobValues?.status == 1 ? "Active" : "In-Active"}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end px-2">
              <TablePagination
                component="div"
                options={{
                  paging: false,
                }}
                count={jobListDetails?.length}
                page={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeRowsPerPage}
                pageSize={limit}
                rowsPerPageOptions={[limit]}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
