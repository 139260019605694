import React, { useEffect, useState } from "react";
import { Card, Col, Row, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS, STUDENT_ACCEPTANCE_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import { BsEye, BsActivity } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { TablePagination } from "@mui/material";
import { MdClose, MdCheck, MdPersonPin } from "react-icons/md";
import {
  MentorDataList,
  MentorSelector,
  clearData,
  MentorDelete,
} from "../../store/reducer/MentorReducer";
import {
  StudentListData,
  clearStudentData,
  StudentAcceptanceUpdate,
  StudentSelector,
} from "../../store/reducer/StudentReducer";
import {
  StudentListByMentor,
  clearHiringMentorData,
  hiringmentorSelector,
} from "../../store/reducer/HiringMentorReducer";
import {
  CCMentorCCList,
  CC_MentorById,
  CC_MentorSelector,
  clearCCMentorData,
} from "../../store/reducer/CCMentorReducer";

const MentorStudent = () => {
  const title = "Culture Catalyst List";
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [mentorId, setmentorId] = useState();
  const [errorMsg, seterrorMsg] = useState({
    mentorError: false,
  });
  const [show, setShow] = useState(false);
  const [studentName, setstudentName] = useState();
  const [DepartmentName, setDepartmentName] = useState();
  const [ID, setID] = useState();
  const [students, setStudents] = useState();
  const { studentsList } = useSelector(hiringmentorSelector);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);  

  const { cc_mentorDatas, cc_mentorStudentDatas, CCMentorCCLoader } =
    useSelector(CC_MentorSelector);

  useEffect(() => {
    if (role == 5) {
      dispatch(CCMentorCCList({ page, limit, query }));
    } else {
      dispatch(StudentListByMentor({ page, limit, query }));
    }
  }, []);

  useEffect(() => {
    console.log(cc_mentorStudentDatas, "cc_mentorStudentDatas1");
    if (role == 5) {
      setStudents(cc_mentorStudentDatas);
      let count = cc_mentorStudentDatas?.count % 10;
      let remainder = cc_mentorStudentDatas?.count / 10;
      let pageLength = remainder > 0 ? count + 1 : count;
      setPageSize(pageLength);
    } else {
      setStudents(studentsList);
      let count = studentsList?.count % 10;
      let remainder = studentsList?.count / 10;
      let pageLength = remainder > 0 ? count + 1 : count;
      setPageSize(pageLength);
    }
  }, [studentsList, cc_mentorStudentDatas]);

  // useEffect(() => {
  //   if (role == 5) {
  //     const formData = cc_mentorDatas?.id;
  //     dispatch(CCMentorCCList(formData));
  //   }
  // }, [cc_mentorDatas]);

  const { mentorListDatas, mentorLoading, mentorDelLoading, errorMessage } =
    useSelector(MentorSelector);
  const {
    studentDatas,
    studentLoading,
    studentAcceptanceLoading,
    studenterrorMessage,
    studentAcceptanceMessage,
  } = useSelector(StudentSelector);

  useEffect(() => {
    console.log(studentDatas, "studentDatas");
  }, [studentDatas]);

  useEffect(() => {
    console.log(mentorListDatas, "mentorListDatas");
  }, [mentorListDatas]);

  const acceptStudent = (id, type) => {
    let formData = {};
    formData.id = id;
    formData.student_acceptance_status = type;
    dispatch(StudentAcceptanceUpdate({ formData }));
  };
  const UpdateMentor = (studentValues) => {
    setShow(true);
    setstudentName(studentValues?.student?.name);
    setDepartmentName(studentValues?.departments?.name);
    setID(studentValues?.id);
  };
  const MentorUpdate = () => {
    if (!mentorId) {
      seterrorMsg({
        mentorError: !mentorId,
      });
    } else {
      let formData = {};
      formData.id = ID;
      formData.mentor_id = mentorId;
      formData.student_acceptance_status = 3;
      console.log(formData, "DSFASOFD");
      dispatch(StudentAcceptanceUpdate({ formData }));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (role == 5) {
      setStudents(cc_mentorStudentDatas);
      let count = cc_mentorStudentDatas?.count % 10;
      let remainder = cc_mentorStudentDatas?.count / 10;
      let pageLength = remainder > 0 ? count + 1 : count;
      setPageSize(pageLength);
      console.log(count, "COUNTS");
    } else {
      setStudents(studentsList);
      let count = studentsList?.count % 10;
      let remainder = studentsList?.count / 10;
      let pageLength = remainder > 0 ? count + 1 : count;
      setPageSize(pageLength);
    }
  }, [studentsList, cc_mentorStudentDatas]);

  useEffect(() => {
    dispatch(StudentListByMentor({ page, limit, query }));
  }, [page, limit, query]);

  useEffect(() => {
    if (mentorDelLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Mentor Deleted Successfully!",
        icon: "success",
      });
      dispatch(clearData());
      dispatch(MentorDataList({}));
    }

    if (studentAcceptanceLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: studentAcceptanceMessage?.message,
        icon: studentAcceptanceMessage?.type,
      });
      let query = "";
      let limit = "";
      let page = 0;
      dispatch(StudentListData({ query, limit, page }));
      dispatch(clearStudentData());
      setShow(false);
    }
    if (CCMentorCCLoader === API_STATUS.FULFILLED) {
      dispatch(clearCCMentorData());
    }
  }, [mentorDelLoading, studentAcceptanceLoading, CCMentorCCLoader]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Culture Catalyst Name</th>
                    <th>Email</th>
                    <th>Domain</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {role == 5 ? (
                  <tbody>
                    {cc_mentorStudentDatas &&
                      cc_mentorStudentDatas?.data?.map(
                        (studentValues, index) => {
                          return (
                            <>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{studentValues?.name}</td>
                                <td>{studentValues?.email}</td>
                                <td>{studentValues?.departments?.name}</td>
                                <td>
                                  {studentValues?.status == 1
                                    ? "Active"
                                    : "In-Active"}
                                </td>
                                <td>
                                  <button
                                    title="View Progress"
                                    className="btn btn-primary mx-1"
                                    onClick={(e) => {
                                      navigate(
                                        `/student/dashboard/${studentValues?.id}`
                                      );
                                    }}
                                  >
                                    <FaUser size={18} />
                                  </button>
                                  <button
                                    title="View Sprint Progress"
                                    className="btn btn-primary mx-1"
                                    onClick={(e) => {
                                      navigate(
                                        "/hiring_partner/" +
                                          studentValues?.id +
                                          "/course/" +
                                          studentValues?.department
                                      );
                                    }}
                                  >
                                    <BsEye size={18} />
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                ) : (
                  <tbody>
                    {studentsList &&
                      studentsList?.rows?.map((studentValues, index) => {
                        if (studentValues?.student_acceptance_status == 3)
                          return (
                            <>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{studentValues?.student?.name}</td>
                                <td>{studentValues?.student?.email}</td>
                                <td>{studentValues?.departments?.name}</td>
                                <td>
                                  {studentValues?.status == 1
                                    ? "Active"
                                    : "In-Active"}
                                </td>
                                <td>
                                  <button
                                    title="View Progress"
                                    className="btn btn-primary mx-1"
                                    onClick={(e) => {
                                      navigate(
                                        `/student/dashboard/${studentValues?.id}`
                                      );
                                    }}
                                  >
                                    <FaUser size={18} />
                                  </button>
                                  <button
                                    title="View Sprint Progress"
                                    className="btn btn-primary mx-1"
                                    onClick={(e) => {
                                      navigate(
                                        "/hiring_partner/" +
                                          studentValues?.student_id +
                                          "/course/" +
                                          studentValues?.department_id
                                      );
                                    }}
                                  >
                                    <BsEye size={18} />
                                  </button>
                                  <button
                                    title="View Fitment Progress"
                                    className="btn btn-primary mx-1"
                                    onClick={(e) => {
                                      navigate(
                                        "/student/" +
                                          studentValues?.student_id +
                                          "/project"
                                      );
                                    }}
                                  >
                                    <BsActivity size={18} />
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                      })}
                  </tbody>
                )}
              </Table>
              {console.log(students,"ASDFFGFDGHYFH")}
              <div className="d-flex justify-content-between px-2">
                <strong>Total count : {students?.data?.length}</strong>
                <TablePagination
                  component="div"
                  count={students?.data?.length}
                  page={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
      {/* Mentor Assign */}
      <Modal
        show={show}
        id="settings"
        dialogClassName="modal-100w"
        aria-labelledby="settings"
        tabIndex="-1"
        scrollable
        size="xl"
      >
        <div className="p-3 mt-2" style={{ overflow: "auto" }}>
          <>
            <h1>Assign Mentor</h1>
            <Row>
              <Col md="6">
                <h5>
                  <label htmlFor="">
                    Culture Catalyst Name: <strong>{studentName}</strong>
                  </label>
                </h5>
              </Col>
              <Col md="6">
                <h5>
                  <label htmlFor="">
                    Domain Name: <strong>{DepartmentName}</strong>
                  </label>
                </h5>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">Mentor List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setmentorId(e.target.value);
                    }}
                    value={mentorId}
                    name="mentor_id"
                    className="is-invalid"
                    placeholder="Domain"
                  >
                    <option>Select Mentor</option>
                    {mentorListDatas &&
                      mentorListDatas?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.mentorError && (
                    <p className="text-danger">Select the Mentor</p>
                  )}
                </div>
              </Col>
            </Row>
            <div className="text-end">
              <Button
                variant="success"
                className="mx-1"
                type="submit"
                onClick={MentorUpdate}
              >
                Confirm
              </Button>
              <Button
                variant="primary"
                className="mx-1"
                onClick={() => setShow(false)}
              >
                Cancel
              </Button>
            </div>
          </>
        </div>
      </Modal>
    </>
  );
};

export default MentorStudent;
