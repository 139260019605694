import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  cc_mentor_list,
  cc_mentor_delete,
  cc_mentor_add,
  cc_mentor_by_id,
  cc_mentor_edit,
  cc_mentor_assign_student,
  ccmentorStudents,
  updateSprintStatus,
  getccmentorStat,
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "cc_mentor";

const initialState = {
  loading: "initial",
  cc_mentor_errorMessage: "",
  ccMentorListData: [],
  cc_mentorList_Loading: "initial",
  cc_mentorLoading: "initial",
  cc_mentorStatLoading: "initial",
  cc_mentorDelLoading: "initial",
  CCMentorCCLoader: "initial",
  cc_mentorUpdateLoading: "initial",
  cc_mentorAddLoading: "initial",
  cc_mentorAssignLoading: "initial",
  ApproveRejectActivityLoad: "initial",
  cc_mentorDatas: [],
  cc_mentorStudentDatas: [],
  ccStatData: {},
};

export const CC_MentorDataList = createAsyncThunk(
  `${namespace}/CC_MentorDataList`,
  async ({ page, limit, query }, { rejectWithValue, dispatch }) => {
    try {
      console.log(page, limit, query, "CC_MentorDataListQ");
      const { data } = await cc_mentor_list(page, limit, query);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CC_MentorStatScheduler = createAsyncThunk(
  `${namespace}/CC_MentorStatScheduler`,
  async ({ page, limit, query, id }, { rejectWithValue, dispatch }) => {
    try {
      console.log("CC_MentorStatScheduler");
      const { data } = await getccmentorStat(id, page, limit, query);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CC_MentorDelete = createAsyncThunk(
  `${namespace}/CC_MentorDelete`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("CC_MentorDelete");
      const { data } = await cc_mentor_delete(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CC_MentorDataAdd = createAsyncThunk(
  `${namespace}/CC_MentorDataAdd`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("CC_MentorDataAdd");
      const { data } = await cc_mentor_add(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CC_MentorById = createAsyncThunk(
  `${namespace}/CC_MentorById`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("CC_MentorById");
      const { data } = await cc_mentor_by_id(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CCMentorCCList = createAsyncThunk(
  `${namespace}/CCMentorCCList`,
  async ({page,query,limit}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await ccmentorStudents(page,query,limit);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const CC_MentorUpdate = createAsyncThunk(
  `${namespace}/CC_MentorUpdate`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("CC_MentorUpdate");
      const { data } = await cc_mentor_edit(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const assignStudenttoMentor = createAsyncThunk(
  `${namespace}/assignStudenttoMentor`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("assignStudenttoMentor");
      const { data } = await cc_mentor_assign_student(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ApproveRejectActivity = createAsyncThunk(
  `${namespace}/ApproveRejectActivity`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("ApproveRejectActivity");
      const { data } = await updateSprintStatus(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const CC_MentorSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearmentorData: () => {
      return initialState;
    },
    clearCCMentorData: (state) => {
      state.cc_mentorList_Loading = "initial";
      state.cc_mentorDelLoading = "initial";
      state.cc_mentorLoading = "initial";
      state.cc_mentorUpdateLoading = "initial";
      state.cc_mentorAssignLoading = "initial";
      state.CCMentorCCLoader = "initial";
      state.cc_mentorStatLoading = "initial";
      state.ApproveRejectActivityLoad = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(CC_MentorDataList.pending, (state) => {
      state.cc_mentorList_Loading = API_STATUS.PENDING;
    });
    builder.addCase(CC_MentorDataList.fulfilled, (state, { payload }) => {
      state.cc_mentorList_Loading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.ccMentorListData = payloaddata;
      console.log(state.ccMentorListData, "sC_MentorDataList");
    });
    builder.addCase(CC_MentorDataList.rejected, (state, action) => {
      state.cc_mentorList_Loading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.cc_mentor_errorMessage = action?.payload?.data;
    });
    builder.addCase(CC_MentorDelete.pending, (state) => {
      state.cc_mentorDelLoading = API_STATUS.PENDING;
    });
    builder.addCase(CC_MentorDelete.fulfilled, (state, { payload }) => {
      state.cc_mentorDelLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(CC_MentorDelete.rejected, (state, action) => {
      state.cc_mentorDelLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.cc_mentor_errorMessage = action?.payload?.data;
    });

    builder.addCase(CC_MentorStatScheduler.pending, (state) => {
      state.cc_mentorStatLoading = API_STATUS.PENDING;
    });
    builder.addCase(CC_MentorStatScheduler.fulfilled, (state, { payload }) => {
      state.cc_mentorStatLoading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.ccStatData = payloaddata;
    });
    builder.addCase(CC_MentorStatScheduler.rejected, (state, action) => {
      state.cc_mentorStatLoading = API_STATUS.REJECTED;
      state.cc_mentor_errorMessage = action?.payload?.data;
    });
    builder.addCase(CC_MentorDataAdd.pending, (state) => {
      state.cc_mentorAddLoading = API_STATUS.PENDING;
    });
    builder.addCase(CC_MentorDataAdd.fulfilled, (state, { payload }) => {
      state.cc_mentorAddLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(CC_MentorDataAdd.rejected, (state, action) => {
      state.cc_mentorAddLoading = API_STATUS.REJECTED;
      console.log(action.payload, "CC_MentorDataAddErrorr");
      state.cc_mentor_errorMessage = action?.payload?.data;
    });
    builder.addCase(ApproveRejectActivity.pending, (state) => {
      state.ApproveRejectActivityLoad = API_STATUS.PENDING;
    });
    builder.addCase(ApproveRejectActivity.fulfilled, (state, { payload }) => {
      state.ApproveRejectActivityLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(ApproveRejectActivity.rejected, (state, action) => {
      state.ApproveRejectActivityLoad = API_STATUS.REJECTED;
      console.log(action.payload, "ApproveRejectActivityErrorr");
      state.cc_mentor_errorMessage = action?.payload?.data;
    });
    builder.addCase(CC_MentorById.pending, (state) => {
      state.cc_mentorLoading = API_STATUS.PENDING;
    });
    builder.addCase(CC_MentorById.fulfilled, (state, { payload }) => {
      state.cc_mentorLoading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.cc_mentorDatas = payloaddata?.data;
      console.log(payloaddata, "CC_MentorByIdpayloaddata");
    });
    builder.addCase(CC_MentorById.rejected, (state, action) => {
      state.cc_mentorLoading = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.cc_mentor_errorMessage = action?.payload?.data;
    });
    builder.addCase(CCMentorCCList.pending, (state) => {
      state.CCMentorCCLoader = API_STATUS.PENDING;
    });
    builder.addCase(CCMentorCCList.fulfilled, (state, { payload }) => {
      state.CCMentorCCLoader = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.cc_mentorStudentDatas = payloaddata;
      // state.cc_mentorStudentDatas = payload?.datas
      console.log(payload?.datas, "CCMentorCCListpayloaddata");
    });
    builder.addCase(CCMentorCCList.rejected, (state, action) => {
      state.CCMentorCCLoader = API_STATUS.REJECTED;
      console.log(action.payload, "MentorListError");
      state.cc_mentor_errorMessage = action?.payload?.data;
    });
    builder.addCase(CC_MentorUpdate.pending, (state) => {
      state.cc_mentorUpdateLoading = API_STATUS.PENDING;
    });
    builder.addCase(CC_MentorUpdate.fulfilled, (state, { payload }) => {
      state.cc_mentorUpdateLoading = API_STATUS.FULFILLED;
      console.log(payload, "cc_mentorUpdateLoading");
    });
    builder.addCase(CC_MentorUpdate.rejected, (state, action) => {
      state.cc_mentorUpdateLoading = API_STATUS.REJECTED;
      console.log(action.payload, "cc_mentorUpdateLoading");
      state.cc_mentor_errorMessage = action?.payload?.data;
    });
    builder.addCase(assignStudenttoMentor.pending, (state) => {
      state.cc_mentorAssignLoading = API_STATUS.PENDING;
    });
    builder.addCase(assignStudenttoMentor.fulfilled, (state, { payload }) => {
      state.cc_mentorAssignLoading = API_STATUS.FULFILLED;
      console.log(payload, "cc_mentorUpdateLoading");
    });
    builder.addCase(assignStudenttoMentor.rejected, (state, action) => {
      state.cc_mentorAssignLoading = API_STATUS.REJECTED;
      console.log(action.payload, "cc_mentorUpdateLoading");
      state.cc_mentor_errorMessage = action?.payload?.data;
    });
  },
});

export const { clearCCMentorData, clearmentorData } = CC_MentorSlice.actions;

export const CC_MentorSelector = (state) => state.cc_mentor;

export default CC_MentorSlice.reducer;
