import React, { useEffect, useState } from "react";
import { Card, Col, Row, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS, JOB_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsPencil } from "react-icons/bs";
import { MdPersonAdd } from "react-icons/md";
import { TablePagination } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  JobListData,
  getHiringPartner,
  CreateJobScheduler,
  UpdateJobScehduler,
  ViewJobScheduler,
  clearHiringPartnerData,
  hiringpartnerSelector,
} from "../../store/reducer/HiringPartnerReducer";
import {
  departmentLists,
  departmentUpdates,
  answerLists,
  placementSelector,
  testLists,
} from "../../store/reducer/PlacementReducer";
import { signUpSelector, collegeList } from "../../store/reducer/SignUpReducer";
import {
  AdminStudentList,
  AdminSelector,
  clearAdminData,
  approveStudentCareer,
  assignstudentTohiringpartner,
} from "../../store/reducer/AdminReducer";
const AssignStudents = () => {
  const title = "Hiring Partner Jobs";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hiring_partner_id } = useParams();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [show, setShow] = useState(false);
  const [studentId, setstudentId] = useState();
  const [collegeId, setcollegeId] = useState();
  const [jobId, setjobId] = useState();
  const [domainId, setdomainId] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [errorMsg, seterrorMsg] = useState({
    studentError: false,
    collegeError: false,
  });
  useEffect(() => {
    let org_id = hiring_partner_id;
    dispatch(JobListData({ query, limit, page, org_id }));
    let id = hiring_partner_id;
    dispatch(getHiringPartner({ id }));
    let departmentQuery = {};
        departmentQuery.page = 0;
        departmentQuery.limit = 0;
        departmentQuery.query = "";        
        dispatch(departmentLists(departmentQuery ));
    let formData = {};
    formData.query = "";
    formData.limit = 0;
    formData.page = 0;
    dispatch(collegeList(formData));
  }, []);

  const {
    jobListDetails,
    hiringPartnerEditData,
    errorMessage,
    hiringPartnerListLoading,
  } = useSelector(hiringpartnerSelector);
  const { collegeData } = useSelector(signUpSelector);
  const { departmentData, answerDatas, answerLoading } =
    useSelector(placementSelector);
  const {
    adminStudentListData,
    admin_student_approve_Loading,
    admin_errorMessage,
    student_to_hp_loading,
  } = useSelector(AdminSelector);
  useEffect(() => {
    console.log(adminStudentListData, "adminStudentListData");
  }, [adminStudentListData]);

  const assignModal = (jobData) => {
    setShow(true);
    setdomainId(jobData?.domain);
    setjobId(jobData?.id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = jobListDetails?.count % 10;
    let remainder = jobListDetails?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [jobListDetails]);

  useEffect(() => {
    let org_id = hiring_partner_id;
    dispatch(JobListData({ query, limit, page, org_id }));
  }, [page, limit, query]);

  const collegeStudents = (collegeid) => {
    setcollegeId(collegeid);
    let collegeName = collegeData.find((ele) => {
      return ele.id == collegeid;
    });
    let query = "";
    let limit = "";
    let page = 0;
    let order = "";
    let college_id = collegeid;
    let domain = domainId;
    dispatch(
      AdminStudentList({ query, limit, page, order, college_id, domain })
    );
  };
  const AssignStudentToHP = () => {
    if (!collegeId || !studentId) {
      seterrorMsg({
        studentError: !studentId,
        collegeError: !collegeId,
      });
    } else {
      let formData = {};
      formData.org_id = hiring_partner_id;
      formData.job_id = jobId;
      formData.student_id = studentId;
      formData.department_id = domainId;
      formData.student_acceptance_status = 0;

      console.log(formData, "DSFASOFD");
      dispatch(assignstudentTohiringpartner({ formData }));
    }
  };
  const resetDatas = () => {
    setShow(false);
    setcollegeId();
    setdomainId("");
    setstudentId();
    setjobId();
  };

  useEffect(() => {
    if (student_to_hp_loading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Student Assigned to Hiring Partner Successfully!",
        icon: "success",
      });

      let query = "";
      let limit = 0;
      let page = 0;
      let org_id = hiring_partner_id;
      dispatch(JobListData({ query, limit, page, org_id }));
      setShow(false);
      setcollegeId();
      setdomainId("");
      setstudentId();
      setjobId();
      dispatch(clearAdminData());
    }
    if (student_to_hp_loading === API_STATUS.REJECTED && admin_errorMessage) {
      Swal.fire({
        title: admin_errorMessage.message,
        icon: "error",
      });
      dispatch(clearAdminData());
    }
  }, [student_to_hp_loading, admin_errorMessage]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div>
              <h4>
                {" "}
                Company Name : <b>{hiringPartnerEditData?.name}</b>
              </h4>
            </div>
            <Link to="/admin/hiring_partner">
              <div className="text-end mb-2">
                <Button variant="success" type="button">
                  Back
                </Button>
              </div>
            </Link>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Job Title</th>
                    <th>Domain</th>
                    <th>Job Type</th>
                    <th>Number Of Positions</th>
                    <th>Students Assigned </th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {jobListDetails &&
                    jobListDetails?.rows?.map((jobValues, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{jobValues?.title}</td>
                            <td>{jobValues?.departments?.name}</td>
                            <td>{jobValues?.job_type}</td>
                            <td>{jobValues?.vacancy_count}</td>
                            <td>{jobValues?.hiring_partner_student?.length}</td>
                            <td>{JOB_STATUS[jobValues?.status]}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between px-2">
                <strong>Total count : {jobListDetails?.count}</strong>
                <TablePagination
                  component="div"
                  count={jobListDetails?.count}
                  page={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
      {/* Student Assign */}
      <Modal
        show={show}
        id="studentAssign"
        dialogClassName="modal-100w"
        aria-labelledby="studentAssign"
        tabIndex="-1"
        scrollable
        size="xl"
      >
        <div className="p-3 mt-2" style={{ overflow: "auto" }}>
          <>
            <h1>Assign Students to Job</h1>
            <Row className="mt-2">
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">College List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      collegeStudents(e.target.value);
                    }}
                    value={collegeId}
                    name="college_id"
                    className="is-invalid"
                    placeholder="College"
                  >
                    <option>Select College</option>
                    {collegeData &&
                      collegeData?.rows?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.collegeError && (
                    <p className="text-danger">Select the College</p>
                  )}
                </div>
              </Col>
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">Domain List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    value={domainId}
                    name="domain"
                    className="is-invalid"
                    placeholder="Domain"
                    disabled
                  >
                    <option>Select Domain</option>
                    {departmentData &&
                      departmentData?.rows?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.domainError && (
                    <p className="text-danger">Select the Domain</p>
                  )}
                </div>
              </Col>
              <Col md="6">
                <h5>
                  {" "}
                  <label htmlFor="">Students List</label>
                </h5>
                <div className="mb-3 filled form-group tooltip-end-top">
                  <CsLineIcons icon="file-text" />
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setstudentId(e.target.value);
                    }}
                    value={studentId}
                    name="mentor_id"
                    className="is-invalid"
                    placeholder="CC Mentor"
                  >
                    <option>Select Student</option>
                    {adminStudentListData &&
                      adminStudentListData?.rows?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  {errorMsg?.studentError && (
                    <p className="text-danger">Select the Student</p>
                  )}
                </div>
              </Col>
            </Row>
            <div className="text-end">
              <LoadingButton
                variant="contained"
                loading={
                  ![API_STATUS.FULFILLED, "initial"].includes(
                    hiringPartnerListLoading
                  )
                }
                onClick={AssignStudentToHP}
              >
                Confirm
              </LoadingButton>
              <Button variant="primary" className="mx-1" onClick={resetDatas}>
                Cancel
              </Button>
            </div>
          </>
        </div>
      </Modal>
    </>
  );
};

export default AssignStudents;
