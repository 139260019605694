import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createJobValidator } from "../../validators/Validators";
import {
  CreateJobScheduler,
  UpdateJobScehduler,
  ViewJobScheduler,
  clearHiringPartnerData,
  hiringpartnerSelector,
  clearHiringPartnerLoadingsData,
} from "../../store/reducer/HiringPartnerReducer";
import {
  departmentLists,
  departmentUpdates,
  placementSelector,
  testLists,
} from "../../store/reducer/PlacementReducer";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { API_STATUS } from "../../utils/Constants";
import Feedback from "react-bootstrap/esm/Feedback";
import { LoadingButton } from "@mui/lab";
const CreateJob = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobTypeValue, setjobTypeValues] = useState();
  const [salaryLable, setsalaryLable] = useState("Salary");
  const [validated, setValidated] = useState(false);
  const [dojValue, setDojValue] = useState();
  const [departmentID, setDepartmentID] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [errorMsg, seterrorMsg] = useState({
    doj: false,
    dom: false,
    jobType: false,
  });
  console.log(errorMsg, "errorMsg");
  const title = "Hiring Partner";

  const {
    jobDetails,
    UpdateJobScehdulerLoading,
    CreateJobSchedulerLoading,
    ViewJobSchedulerLoading,
  } = useSelector(hiringpartnerSelector);
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    key_skills: "",
    salary_min: "",
    salary_max: "",
    vacancy_count: "",
    roles_responsiablity: "",
  });
  const onSubmit = (formData) => {
    if (!dojValue || !jobTypeValue || !departmentID) {
      setValidated(false);
    } else {
      formData.domain = departmentID;
      formData.job_type = jobTypeValue;
      formData.date_of_joining = dojValue;
      let data = Object.keys(formData);
      data.forEach((items) => {
        if (!formData[items]) delete formData[items];
      });
      console.log(formData, "SDFSDF");

      if (id) {
        dispatch(UpdateJobScehduler({ id, formData }));
      } else {
        dispatch(CreateJobScheduler({ formData }));
      }
    }
  };
  useEffect(() => {
    if (id) dispatch(ViewJobScheduler({ formData: id }));
  }, [id]);
  useEffect(() => {
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    dispatch(departmentLists(departmentQuery));
  }, []);

  const { departmentData } = useSelector(placementSelector);

  const formik = useFormik({
    initialValues,
    validationSchema: createJobValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  useEffect(() => {
    if (id && jobDetails !== "") {
      formik.setValues({
        title: jobDetails?.title,
        description: jobDetails?.description,
        key_skills: jobDetails?.key_skills,
        salary_min: jobDetails?.salary_min,
        salary_max: jobDetails?.salary_max,
        vacancy_count: jobDetails?.vacancy_count,
        roles_responsiablity: jobDetails?.roles_responsiablity,
      });
      const JobValue = jobDetails?.job_type;
      setjobTypeValues(JobValue);
      if (jobDetails?.date_of_joining)
        setDojValue(new Date(jobDetails?.date_of_joining));
      else {
        setDojValue("");
      }
      setsalaryLable("Stipend");
      const deptValue = jobDetails?.domain;
      setDepartmentID(deptValue);
    } else {
      dispatch(clearHiringPartnerData());
    }
  }, [jobDetails]);

  const handleJobType = (e) => {
    setjobTypeValues(e);
    if (e == "Intership") setsalaryLable("Stipend");
    else setsalaryLable("Salary");
  };

  const handleDateChange = (date) => {
    setDojValue(date);
  };

  useEffect(() => {
    if (CreateJobSchedulerLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Job Created Successfully!",
        icon: "success",
      });
      dispatch(clearHiringPartnerData());
      navigate("/hiring_partner/job");
    }
    if (UpdateJobScehdulerLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Job Updated Successfully!",
        icon: "success",
      });
      dispatch(clearHiringPartnerData());
      navigate("/hiring_partner/job");
    }
    if (ViewJobSchedulerLoading === API_STATUS.REJECTED) {
      navigate("/hiring_partner");
      dispatch(clearHiringPartnerData());
    }
  }, [
    CreateJobSchedulerLoading,
    UpdateJobScehdulerLoading,
    ViewJobSchedulerLoading,
  ]);
  return (
    <div className="container">
      <section className="scroll-section" id="title">
        <div className="page-title-container">
          <h1 className="mb-0 pb-0 display-4">{title}</h1>
          <Breadcrumb className="breadcrumb-container d-inline-block">
            <Breadcrumb.Item key={`breadCrumb1`}>
              {id ? "Edit Job" : "Create Job"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Link to="/hiring_partner/job">
          <div className="text-end mb-2">
            <Button variant="success" type="submit">
              Back
            </Button>
          </div>
        </Link>
      </section>
      <Row>
        <Col>
          <Card className="p-3 create_job">
            <h1> {id ? "Edit Job" : "Create Job"}</h1>
            <Form
              id="jobForm"
              className={
                validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
              }
              onSubmit={(e) => {
                e.preventDefault();
                setValidated(true);
                handleSubmit();
              }}
            >
              <Row>
                <Col md="6">
                  <div className="filled form-group tooltip-end-top">
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Title"
                      value={values.title}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.title && touched.title && (
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    {/* <CsLineIcons icon="file-text" /> */}
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        setDepartmentID(e.target.value);
                      }}
                      value={departmentID}
                      className="is-invalid"
                      placeholder="Domain"
                    >
                      {!isClicked ? <option>Select Domain</option> : ""}
                      {departmentData &&
                        departmentData?.rows?.map((fields) => {
                          return (
                            <option value={fields.id}>{fields.name}</option>
                          );
                        })}
                    </Form.Control>
                    {errorMsg?.dom && (
                      <p className="text-danger">Domain is required</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className=" filled form-group tooltip-end-top">
                    {/* <CsLineIcons icon="user" /> */}
                    <Form.Control
                      type="text"
                      name="key_skills"
                      placeholder="Key Skills"
                      value={values.key_skills}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.key_skills && touched.key_skills && (
                      <Form.Control.Feedback type="invalid">
                        {errors.key_skills}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="filled form-group tooltip-end-top">
                    {/* <CsLineIcons icon="user" /> */}
                    <Form.Control
                      type="text"
                      name="vacancy_count"
                      placeholder="Number Of Positions"
                      value={values.vacancy_count}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.vacancy_count && touched.vacancy_count && (
                      <Form.Control.Feedback type="invalid">
                        {errors.vacancy_count}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mt-3 filled form-group tooltip-end-top">
                    <Form.Control
                      as="select"
                      value={jobTypeValue}
                      onChange={(e) => handleJobType(e.target.value)}
                      className="is-invalid"
                      placeholder="Job Type"
                    >
                      <option value="">Job Type</option>
                      <option value="Full Time">Full Time</option>
                      <option value="Part Time">Part Time</option>
                      <option value="Intership">Intership</option>
                    </Form.Control>
                    {/* {errors.job_type && touched.job_type && (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.job_type}
                                            </Form.Control.Feedback>
                                        )} */}
                    {errorMsg?.jobType && (
                      <p className="text-danger">Job Type is required</p>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="filled form-group tooltip-end-top mt-3">
                    <Form.Group controlId="formDate">
                      <DatePicker
                        name="date_of_joining"
                        selected={dojValue}
                        placeholderText="Date of Joining"
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        minDate={new Date()}
                      />
                    </Form.Group>
                    {errorMsg?.doj && (
                      <p className="text-danger">
                        {" "}
                        Date of Joining is required
                      </p>
                    )}{" "}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Row>
                    <Col md="3">
                      <div className="mt-4 mx-5 filled form-group tooltip-end-top">
                        <p>{salaryLable} per Month</p>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mt-3 filled form-group tooltip-end-top">
                        {/* <CsLineIcons icon="user" /> */}
                        <Form.Control
                          type="text"
                          name="salary_min"
                          placeholder="Minimum"
                          value={values.salary_min}
                          className="is-invalid"
                          onChange={handleChange}
                        />
                        {errors.salary_min && touched.salary_min && (
                          <Form.Control.Feedback type="invalid">
                            {errors.salary_min}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mt-3 filled form-group tooltip-end-top">
                        {/* <CsLineIcons icon="user" /> */}
                        <Form.Control
                          type="text"
                          name="salary_max"
                          placeholder="Maximum"
                          value={values.salary_max}
                          className="is-invalid"
                          onChange={handleChange}
                        />
                        {errors.salary_max && touched.salary_max && (
                          <Form.Control.Feedback type="invalid">
                            {errors.salary_max}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <div className="filled form-group tooltip-end-top mt-3">
                    {/* <CsLineIcons icon="user" /> */}
                    <Form.Control
                      as="textarea"
                      name="description"
                      placeholder="Job Description"
                      value={values.description}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.description && touched.description && (
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="filled form-group tooltip-end-top mt-3">
                    {/* <CsLineIcons icon="user" /> */}
                    <Form.Control
                      as="textarea"
                      name="roles_responsiablity"
                      placeholder="Roles & Responsiablity"
                      value={values.roles_responsiablity}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.roles_responsiablity &&
                      touched.roles_responsiablity && (
                        <Form.Control.Feedback type="invalid">
                          {errors.roles_responsiablity}
                        </Form.Control.Feedback>
                      )}
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-4">
                <LoadingButton
                  variant="contained"
                  loading={
                    ![API_STATUS.FULFILLED, "initial"].includes(
                      CreateJobSchedulerLoading || UpdateJobScehdulerLoading
                    )
                  }
                  type="submit"
                  onClick={() => {
                    if (!dojValue || !jobTypeValue || !departmentID) {
                      seterrorMsg({
                        doj: !dojValue,
                        dom: !departmentID,
                        jobType: !jobTypeValue,
                      });
                      setValidated(false);
                    }
                  }}
                >
                  {id ? "Update" : "Create"}
                </LoadingButton>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateJob;
