import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  ClipboardEvent,
} from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import {
  signUpScheduler,
  signUpSelector,
  clearErrormsg,
} from "../../store/reducer/SignUpReducer";
import { API_STATUS } from "../../utils/Constants";
import {
  testLists,
  questionLists,
  placementSelector,
  departmentLists,
  answerAdds,
  clearData,
} from "../../store/reducer/PlacementReducer";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Breadcrumb, Button, Card, Form, Modal, Row } from "react-bootstrap";
import LOGO from "../../assets/img/favicon.png";
import { LoadingButton } from "@mui/lab";
const Question = () => {
  const name = localStorage.getItem("username");
  const dispatch = useDispatch();
  const title = "Questions";
  const description = "";
  const navigate = useNavigate();
  const [words, setWords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [answerCount, setAnswerCount] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [timer, setTimer] = useState("00:00");
  let [answers, setAnswers] = useState([]);
  const { questionData, answerLoading, errorMessage } =
    useSelector(placementSelector);
  const [disableText, setDisableText] = useState(false);
  let submitted = false;

  let classType = timer.split(":")[0] > 19 ? "green" : "red";
  const onSubmit = (formData) => {
    // formData.expires_on = selectedDate;
    // let data = Object.keys(formData);
    // data.forEach((items) => {
    //     if (!formData[items])
    //         delete formData[items]
    // })
    // if (id) {
    //     dispatch(UpdateJobScehduler({ id, formData }))
    // } else {
    //     dispatch(CreateJobScheduler({ formData }));
    // }
  };

  // const {
  //     register,
  //     handleSubmit,
  //     formState: { errors },
  //     reset,
  // } = useForm({
  //     //   resolver: yupResolver(myAnswerSchema),
  // });
  const [validated, setValidated] = useState(false);

  const preventCopyPaste = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Drag, Drop, Copy, Paste events are not allowed !",
      icon: "warning",
    });
  };
  const preventQuestions = (e) => {
    e.preventDefault();
  };
  const formik = useFormik({ initialValues, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      clearInterval(Ref.current);
      navigate("/");
    } else {
      let department_id = localStorage.getItem("departmentID") ?? 1;
      let question_group_id = localStorage.getItem("question_group_id");
      dispatch(
        questionLists({ department_id: department_id, question_group_id: "1" })
      );
      // dispatch(departmentLists({}));
    }
  }, []);

  const handleAnswerChange = (event) => {
    setUserAnswer(event.target.value);
  };

  const handleNextWord = (questionDatas, questionIndex) => {
    let qindex = questionIndex + 1;
    if (qindex < questionData?.rows?.length) {
      setCurrentIndex(qindex);
      if (answers[qindex] && answers[qindex]?.answer != "") {
        return true;
      } else {
        answers[qindex] = {
          question_id: questionDatas[qindex]?.id,
          answer: "",
        };
      }
    } else {
      submitAnswer(1);
    }
  };
  const handlePrevWord = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const resetQuiz = () => {
    setCurrentIndex(0);
    setScore(0);
    setUserAnswer("");
    setShowResult(false);
  };
  useEffect(() => {
    if (localStorage.getItem("answers")) {
      let storageAnswer = JSON.parse(localStorage.getItem("answers"));

      storageAnswer.map((answerValues, indexs) => {
        if (answerValues)
          answers[indexs] = {
            question_id: answerValues.question_id,
            answer: answerValues.answer,
          };
      });
    }
  }, []);

  const submitAnswer = (type) => {
    let submit = true;
    const all_datas = questionData?.rows?.map(
      (question, questionIndexSubmit) => {
        if (typeof answers[questionIndexSubmit] === "undefined") {
          submit = false;
          // answers[questionIndexSubmit] = { question_id: question.id, answer: "" };
          // setAnswers([...answers]);
        } else if (
          typeof answers[questionIndexSubmit] !== "undefined" &&
          answers[questionIndexSubmit]?.answer == ""
        ) {
          submit = false;
        }
      }
    );

    Promise.all(all_datas).then(() => {
      if (type == 2) {
        submit = true;
      }
      if (type == 1 && submit == false) {
        Swal.fire({
          title: "Answer all the question to submit!",
          icon: "error",
        });
        return false;
      }
      let ansData = JSON.parse(localStorage.getItem("answers"));
      let datas = ansData?.filter(function (element) {
        return element !== undefined;
      });

      let formData = { answers: datas ?? "" };
      console.log(formData, "formData");
      dispatch(answerAdds({ formData }));
    });
  };

  const updateAnswers = (question, e) => {
    let answer = e.target.value;
    answers[currentIndex] = { question_id: question.id, answer: answer };
    setAnswers([...answers]);
    localStorage.setItem("answers", JSON.stringify(answers));
    handleChange(e);
  };
  useEffect(() => {
    let data_count = 0;
    answers.map((ans) => {
      if (
        typeof ans !== "undefined" &&
        typeof ans.answer !== "undefined" &&
        ans.answer &&
        ans.answer !== ""
      ) {
        data_count = parseInt(data_count) + parseInt(1);
      }
    });
    setAnswerCount(data_count);
  }, [answers]);

  useEffect(() => {
    if (questionData && questionData?.count > 0) {
      setQuestions(questionData?.rows);
      let startTime = !localStorage.getItem("timerVal")
        ? 1800
        : localStorage.getItem("timerVal");
      clearTimer(getDeadTime(startTime));
    }
  }, [questionData]);

  useEffect(() => {
    if (answerLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Your Answer Submitted Successfully!",
        icon: "success",
      }).then(() => {
        // setIsSubmitted(true);
        // setIsSubmitted(false);
        dispatch(clearData());
        localStorage.clear();
        window.location.href = "/";
        // navigate('/')
      });

      // navigate('/thankyou')

      // reset();

      // if (Ref.current) {
      //   clearInterval(Ref.current);
      // }
      // navigate('/thankyou');
      // window.location.href = "/thankyou";
    }
    if (errorMessage) {
      if (errorMessage.message)
        Swal.fire({
          title: errorMessage.message,
          icon: "success",
        });
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        Swal.fire({
          title: data,
          icon: "success",
        });
      }
      dispatch(clearErrormsg());
    }
  }, [answerLoading, errorMessage]);

  /** Timer Settings */

  const Ref = useRef(0);
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);

    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
      let sec = parseFloat(minutes * 60) + parseFloat(seconds);
      localStorage.setItem("timerVal", sec);
      // if (total <= 5) {
      //   setDisableText(true);
      // }
    } else {
      localStorage.removeItem("timerVal");
      setDisableText(true);
      if (submitted === false) {
        submitted = true;
        submitAnswer(2);
      }
    }
  };
  // Clear The Timer
  const clearTimer = (e) => {
    setTimer("00:00");
    if (submitted === false) {
      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
        startTimer(e);
      }, 1000);
      Ref.current = id;
    } else {
      if (Ref.current) {
        clearInterval(Ref.current);
        Ref.current = null;
      }
    }
  };

  const getDeadTime = (startTime) => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + parseInt(startTime));
    return deadline;
  };
  const [state, setState] = useState({ modal_fullscreen: false });

  const tog_fullscreen = () => {
    setState((prevState) => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }));
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  return (
    <>
      <header className="header-page">
        <img src={LOGO} alt="" style={{ height: "70px" }} />
        <h2 className="header">Hello {name}</h2>
      </header>
      <div className="container" style={{ marginTop: "100px" }}>
        <section className="scroll-section" id="title">
          <div className="page-title-container">
            <h1 className="mb-0 pb-0 display-4">{title}</h1>
            <Breadcrumb className="breadcrumb-container d-inline-block">
              <Breadcrumb.Item key={`breadCrumb1`}>
                Pre-Placemet Assessment
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </section>
        <Card className="p-3" style={{ marginTop: "40px" }}>
          <Row>
            <div className="progressbar-div">
              <div className="row">
                <div className="col-lg-6">
                  <p>
                    {" "}
                    Question {currentIndex + 1} of {questionData?.count}{" "}
                  </p>
                </div>
                <div className="col-lg-6 text-end">
                  <div className="space-h3">
                    {/* <p>Hello {localStorage.getItem("username")}</p> */}
                    <h3>
                      Time left -{" "}
                      <span className={`timer_${classType}`}>{timer}</span>{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col-md-12">
                <ProgressBar
                  now={(answerCount / questionData?.count) * 100}
                  animated
                  variant="progress-wrap"
                />
              </div>
            </div>
          </Row>
          {isSubmitted ? (
            <Row className="text-center"></Row>
          ) : (
            <Row className="mt-2">
              {questionData &&
                questionData?.rows?.map((collection, questionIndex) => {
                  if (questionIndex === currentIndex)
                    return (
                      <>
                        <div className="d-flex flex-column">
                          <h5
                            className="question-p user-select-none"
                            onDrag={preventQuestions}
                            onCopy={preventQuestions}
                          >
                            {" "}
                            {collection?.question}
                          </h5>
                          <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                          >
                            <div className="mb-3 filled form-group tooltip-end-top">
                              {collection.type === 1 ? (
                                <>
                                  <Form.Control
                                    as={"textarea"}
                                    rows={5}
                                    name="answers"
                                    placeholder="Write your answer"
                                    value={answers[questionIndex]?.answer}
                                    className="is-invalid"
                                    onPaste={preventCopyPaste}
                                    onCopy={preventCopyPaste}
                                    onCut={preventCopyPaste}
                                    onDrop={preventCopyPaste}
                                    disabled={disableText}
                                    onChange={(e) =>
                                      updateAnswers(collection, e)
                                    }
                                  />
                                  <>
                                    {errors.department &&
                                      touched.department && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.department}
                                        </Form.Control.Feedback>
                                      )}
                                  </>
                                </>
                              ) : collection.type === 4 ? (
                                <>
                                  <Form.Control
                                    type="text"
                                    name="answers"
                                    placeholder="Write your answer"
                                    value={
                                      answers[questionIndex]?.answer == " "
                                        ? ""
                                        : answers[questionIndex]?.answer
                                    }
                                    className="is-invalid"
                                    disabled={disableText}
                                    onPaste={preventCopyPaste}
                                    onCopy={preventCopyPaste}
                                    onCut={preventCopyPaste}
                                    onDrop={preventCopyPaste}
                                    onChange={(e) =>
                                      updateAnswers(collection, e)
                                    }
                                  />
                                  <>
                                    {errors.department &&
                                      touched.department && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.department}
                                        </Form.Control.Feedback>
                                      )}
                                  </>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </Form>
                        </div>
                        <div className="row">
                          <div
                            style={{ textAlign: "left", margin: "auto" }}
                            className="col-md-6"
                          >
                            {questionIndex > 0 ? (
                              <Button
                                variant="primary"
                                name="question_submit"
                                className="ques_prev"
                                onClick={handlePrevWord}
                              >
                                Back
                              </Button>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div
                            style={{ textAlign: "right", margin: "auto" }}
                            className="col-md-6"
                          >
                            {questionIndex + 1 == questionData?.count ? (
                              <>
                                <LoadingButton
                                  // type="submit"
                                  variant="contained"
                                  // className="w-100 mx-5 rounded-3"
                                  loading={isSubmitted}
                                  onClick={() => {
                                    setIsSubmitted(true);
                                    handleNextWord(
                                      questionData?.rows,
                                      questionIndex
                                    );
                                  }}
                                  style={{ float: "right" }}
                                >
                                  Submit
                                </LoadingButton>
                              </>
                            ) : (
                              <>
                                <Button
                                  variant="primary"
                                  name="question_submit"
                                  onClick={() =>
                                    handleNextWord(
                                      questionData?.rows,
                                      questionIndex
                                    )
                                  }
                                  style={{ float: "right" }}
                                >
                                  Next
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    );
                })}
            </Row>
          )}
        </Card>
      </div>
    </>
  );
};

export default Question;
