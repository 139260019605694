import { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkSprintStatus,
  clearCourseLoadings,
  courseDetails,
  courseSelector,
  updateSprint,
} from "../../store/reducer/CourseReducer";
import {
  departmentLists,
  placementSelector,
} from "../../store/reducer/PlacementReducer";
import { API_STATUS, COURSE_STATUS } from "../../utils/Constants";
import {
  ProfileStudent,
  StudentSelector,
} from "../../store/reducer/StudentReducer";
import { LoadingButton } from "@mui/lab";

const Course = () => {
  const name = localStorage.getItem("username");
  const [course, setCourse] = useState({});
  const [order, setOrder] = useState("");
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [isNew, setIsNew] = useState();
  const [query, setQuery] = useState("");
  const [navSprint, setNavSprint] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { departmentData } = useSelector(placementSelector);
  const { studentProfileDatas } = useSelector(StudentSelector);
  const {
    courseData,
    sprintstatusDetails,
    updateSprintLoader,
    checkSprintStatusLoader,
  } = useSelector(courseSelector);
  console.log(sprintstatusDetails, "studentProfileDatas course");
  useEffect(() => {
    if (id) {
      dispatch(
        courseDetails({
          department_id: id,
          id: "",
          order,
          limit,
          sort,
          page,
          query,
        })
      );
      dispatch(
        checkSprintStatus({
          page: 1,
          limit: "",
          query: "",
          sortby: "",
          order: "",
          status: "",
          student_id: studentProfileDatas?.studentData?.id,
          sprint_id: "",
        })
      );
    }
    console.log(studentProfileDatas);
  }, [id, studentProfileDatas]);

  useEffect(() => {
    const data = departmentData?.rows?.find((items) => {
      return items.id == id;
    });
    setCourse(data);
  }, [departmentData]);

  useEffect(() => {
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    dispatch(departmentLists(departmentQuery));
    dispatch(ProfileStudent({}));
  }, []);

  useEffect(() => {
    if (sprintstatusDetails?.length == 0 && courseData?.count > 0) {
      setIsNew(0);
    } else {
      setIsNew("");
    }
    console.log(
      sprintstatusDetails?.length == 0 && courseData?.count > 0,
      sprintstatusDetails,
      isNew,
      "ISNEW"
    );
  }, [sprintstatusDetails, courseData]);

  const handleSprintNavigate = (Sprintid) => {
    const act_id = courseData?.rows.find((ele) => {
      return ele.id == Sprintid;
    });
    let actId = act_id.sprintactivity && act_id?.sprintactivity[0].id;
    const formData = {
      domain: course?.id,
      sprint_id: Sprintid,
      activity_id: sprintstatusDetails[0]?.activity_id ?? actId,
      status: 0,
      student_id: studentProfileDatas?.studentData?.id,
    };
    dispatch(updateSprint({ formData }));
    setNavSprint(Sprintid);
  };

  useEffect(() => {
    if (updateSprintLoader === API_STATUS.FULFILLED) {
      navigate(`/student/course/${id}/sprint/` + navSprint);
      dispatch(clearCourseLoadings());
    }
    if (checkSprintStatusLoader === API_STATUS.FULFILLED) {
      dispatch(clearCourseLoadings());
    }
  }, [updateSprintLoader, checkSprintStatusLoader]);

  return (
    <div className="container-fluid mt-4">
      <h1>Hi {name}, </h1>
      <div>
        <Card>
          <div className="container">
            <Row className="mt-5">
              <Col md={8} className="pe-0 ps-5">
                <h1>
                  <strong>{course?.name}</strong>
                </h1>
              </Col>
              <Col md={4} className="text-end pe-5 ps-0"></Col>
            </Row>
            <div className="container-fluid">
              <hr />
              <h3>
                <strong>Total Sprints({courseData?.rows?.length})</strong>
              </h3>
              <div className="p-3">
                <Accordion defaultActiveKey="0">
                  {courseData?.rows?.map((sprints, i) => {
                    const stat = sprintstatusDetails.find((ele) => {
                      return ele.sprint_id == sprints?.id;
                    });
                    const setStat =
                      isNew === i
                        ? COURSE_STATUS[0]
                        : COURSE_STATUS[stat?.status];
                    const sprintStatus = setStat ? setStat : "Not yet Started";
                    console.log(sprintStatus, setStat, "sprintStatus");
                    return (
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header className="w=100">
                          <div className="d-flex justify-content-between w-100">
                            <h6>{sprints?.name}</h6>
                            <div className="d-flex align-items-center">
                              <strong
                                className="me-3"
                                style={{ fontSize: "15px" }}
                              >
                                Duration : <span>{sprints.duration}</span>
                              </strong>
                              <LoadingButton
                                variant="contained"
                                loading={
                                  ![API_STATUS.FULFILLED, "initial"].includes(
                                    updateSprintLoader
                                  )
                                }
                                className="me-5"
                                onClick={() => {
                                  sprintStatus !== "Not yet Started" &&
                                    handleSprintNavigate(sprints?.id);
                                }}
                              >
                                <div style={{ fontSize: "15px" }}>
                                  {sprintStatus}
                                </div>
                              </LoadingButton>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <ListGroup>
                              {sprints?.sprintactivity?.map((activities) => {
                                return (
                                  <ListGroup.Item key={activities?.id}>
                                    <div className="d-flex justify-content-between w-100">
                                      <p>{activities?.name}</p>
                                      <p>{activities?.duration}</p>
                                    </div>
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Course;
