import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  signIn,
  hiringpartnerIn,
  signInsocial,
  profile,
  forgotPasswordMail,
  resetPasswordByMail,
  mentorsignIn,
  adminsingIn,
  ccMentorSignin,
  registerPlacement,
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";

const namespace = "signInConfiguration";

const initialState = {
  loading: "initial",
  errorMessage: "",
  signInData: null,
  mentorsignInData: null,
  adminsiginInData: null,
  hiringPartnersignInData: null,
  ccmentorSigninData: [],
  signInLoading: "initial",
  profileLoading: "initial",
  mentorsignInLoading: "initial",
  hiringPartnersignInLoading: "initial",
  ccmentorSignInLoad: "initial",
  updateSchedulerLoading: "initial",
  forgotPasswordLoading: "initial",
  adminsignInLoading: "initial",
  placementRegisterLoader: "initial",
  profileData: null,
  count: 0,
};

export const signInScheduler = createAsyncThunk(
  `${namespace}/signInScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await signIn(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const mentorsignInScheduler = createAsyncThunk(
  `${namespace}/mentorsignInScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await mentorsignIn(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const adminsignInScheduler = createAsyncThunk(
  `${namespace}/adminsignInScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await adminsingIn(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const signInSocial = createAsyncThunk(
  `${namespace}/signInSocial`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await signInsocial(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const userProfile = createAsyncThunk(
  `${namespace}/userProfile`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await profile(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  `${namespace}/forgotPassword`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "EmailformaData");
      const { data } = await forgotPasswordMail(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateforgotPassword = createAsyncThunk(
  `${namespace}/updateforgotPassword`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "updateforgotPassword");
      const { data } = await resetPasswordByMail(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const hiringPartnersignInScheduler = createAsyncThunk(
  `${namespace}/hiringPartnersignInScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await hiringpartnerIn(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const placementRegisterScheduler = createAsyncThunk(
  `${namespace}/placementRegisterScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await registerPlacement(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ccmentorSignInScheme = createAsyncThunk(
  `${namespace}/ccmentorSignInScheme`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await ccMentorSignin(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const signInConfigSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearSignInData: () => {
      return initialState;
    },
    clearData: (state) => {
      state.signInData = null;
      state.signInLoading = "initial";
      state.mentorsignInData = null;
      state.adminsiginInData = null;
      state.mentorsignInLoading = "initial";
      state.adminsignInLoading = "initial";
      state.placementRegisterLoader = "initial";
      state.errorMessage = null;
      state.hiringPartnersignInData = null;
      state.hiringPartnersignInLoading = "initial";
      state.ccmentorSignInLoad = "initial";
      state.forgotPasswordLoading = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(signInScheduler.pending, (state) => {
      state.signInLoading = API_STATUS.PENDING;
    });
    builder.addCase(signInScheduler.fulfilled, (state, { payload }) => {
      state.signInLoading = API_STATUS.FULFILLED;
      // state.signInData = payload;
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.signInData = payloadData;
      console.log(payloadData, "PAYSDFD");
      localStorage.setItem("authToken", state.signInData?.accessToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", state.signInData?.name);
      localStorage.setItem("role", payloadData.role);
      localStorage.removeItem("timerVal");
    });
    builder.addCase(signInScheduler.rejected, (state, action) => {
      state.signInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(mentorsignInScheduler.pending, (state) => {
      state.mentorsignInLoading = API_STATUS.PENDING;
    });
    builder.addCase(mentorsignInScheduler.fulfilled, (state, { payload }) => {
      state.mentorsignInLoading = API_STATUS.FULFILLED;
      // state.signInData = payload;
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.mentorsignInData = payloadData;
      console.log(payloadData, "PAYSDFD");
      localStorage.setItem("authToken", state.mentorsignInData?.accessToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", state.mentorsignInData?.name);
      localStorage.setItem("role", payloadData.role);
    });
    builder.addCase(mentorsignInScheduler.rejected, (state, action) => {
      state.mentorsignInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(signInSocial.pending, (state) => {
      state.signInLoading = API_STATUS.PENDING;
    });
    builder.addCase(signInSocial.fulfilled, (state, { payload }) => {
      state.signInLoading = API_STATUS.FULFILLED;
      // state.signInData = payload;
      state.signInData = JSON.parse(
        EncryptDecrypt.decryptdata(payload, secretKey)
      );
      console.log(payload, "signInDatad");
      localStorage.setItem("authToken", payload?.accessToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", payload?.name);
      localStorage.setItem("role", payload?.role_id);
    });
    builder.addCase(signInSocial.rejected, (state, action) => {
      state.signInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(userProfile.pending, (state) => {
      state.profileLoading = API_STATUS.PENDING;
    });
    builder.addCase(userProfile.fulfilled, (state, action) => {
      state.profileLoading = API_STATUS.FULFILLED;
      state.profileData = action?.payload?.data;
    });
    builder.addCase(userProfile.rejected, (state, action) => {
      state.profileLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(placementRegisterScheduler.pending, (state) => {
      state.placementRegisterLoader = API_STATUS.PENDING;
    });
    builder.addCase(placementRegisterScheduler.fulfilled, (state, action) => {
      state.placementRegisterLoader = API_STATUS.FULFILLED;
    });
    builder.addCase(placementRegisterScheduler.rejected, (state, action) => {
      state.placementRegisterLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.forgotPasswordLoading = API_STATUS.PENDING;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotPasswordLoading = API_STATUS.FULFILLED;
      // state.profileData = action?.payload?.data;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.forgotPasswordLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(updateforgotPassword.pending, (state) => {
      state.forgotPasswordLoading = API_STATUS.PENDING;
    });
    builder.addCase(updateforgotPassword.fulfilled, (state, action) => {
      state.forgotPasswordLoading = API_STATUS.FULFILLED;
      // state.profileData = action?.payload?.data;
    });
    builder.addCase(updateforgotPassword.rejected, (state, action) => {
      state.forgotPasswordLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(hiringPartnersignInScheduler.pending, (state) => {
      state.hiringPartnersignInLoading = API_STATUS.PENDING;
    });
    builder.addCase(
      hiringPartnersignInScheduler.fulfilled,
      (state, { payload }) => {
        state.hiringPartnersignInLoading = API_STATUS.FULFILLED;
        // state.signInData = payload; ccmentorSignInScheme
        let payloadData = JSON.parse(
          EncryptDecrypt.decryptdata(payload?.datas, secretKey)
        );
        state.hiringPartnersignInData = payloadData;
        console.log(payloadData, "PAYSDFD");
        localStorage.setItem(
          "authToken",
          state.hiringPartnersignInData?.accessToken
        );
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("username", state.hiringPartnersignInData?.name);
        localStorage.setItem("role", payloadData.role);
      }
    );
    builder.addCase(hiringPartnersignInScheduler.rejected, (state, action) => {
      state.hiringPartnersignInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(ccmentorSignInScheme.pending, (state) => {
      state.ccmentorSignInLoad = API_STATUS.PENDING;
    });
    builder.addCase(ccmentorSignInScheme.fulfilled, (state, { payload }) => {
      state.ccmentorSignInLoad = API_STATUS.FULFILLED;
      // state.signInData = payload;
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.ccmentorSigninData = payloadData;
      localStorage.setItem("authToken", payloadData?.accessToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", payloadData?.name);
      localStorage.setItem("role", payloadData.role);
    });
    builder.addCase(ccmentorSignInScheme.rejected, (state, action) => {
      state.ccmentorSignInLoad = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(adminsignInScheduler.pending, (state) => {
      state.adminsignInLoading = API_STATUS.PENDING;
    });
    builder.addCase(adminsignInScheduler.fulfilled, (state, { payload }) => {
      state.adminsignInLoading = API_STATUS.FULFILLED;
      // state.signInData = payload;
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.adminsiginInData = payloadData;
      console.log(payloadData, "PAYSDFD");
      localStorage.setItem("authToken", state.adminsiginInData?.accessToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", state.adminsiginInData?.name);
      localStorage.setItem("role", payloadData.role);
    });
    builder.addCase(adminsignInScheduler.rejected, (state, action) => {
      state.adminsignInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearSignInData, clearData } = signInConfigSlice.actions;

export const signInSelector = (state) => state.signInConfiguration;

export default signInConfigSlice.reducer;
