import React, { useEffect, useState } from "react";
import { Button, Form, Breadcrumb, Card, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { TablePagination } from "@mui/material";
import { useFormik } from "formik";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdListAlt, MdOutlinePerson } from "react-icons/md";
import {
  hiring_partnerLists,
  addHiringPartner,
  hiringpartnerSelector,
  clearHiringPartnerLoadingsData,
  InActiveHP,
} from "../../store/reducer/HiringPartnerReducer";

const HiringPartnerList = () => {
  const title = "Hiring Partner List";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);

  useEffect(() => {
    dispatch(hiring_partnerLists({ query, limit, page }));
  }, []);

  const {
    hiringPartnerDatas,
    hiringPartnerListLoading,
    hpInActiveLoading,
    errorMessage,
  } = useSelector(hiringpartnerSelector);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = hiringPartnerDatas?.count % 10;
    let remainder = hiringPartnerDatas?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [hiringPartnerDatas]);

  useEffect(() => {
    dispatch(hiring_partnerLists({ query, limit, page }));
  }, [page, limit, query]);
  useEffect(() => {
    console.log(hiringPartnerDatas, "hiringPartnerDatas");
  }, [hiringPartnerDatas]);
  // const HiringPartnerInactive = (id) => {
  //     let formData = {};
  //     formData.id = id
  //     Swal.fire({
  //         title: "In-active the Hiring Partner?",
  //         icon: "warning",
  //         showCancelButton: true,
  //         focusConfirm: false,
  //         confirmButtonText: "Ok",
  //         cancelButtonText: "Cancel",
  //     }).then((result) => {
  //         /* Read more about isConfirmed, isDenied below */
  //         if (result.isConfirmed) {
  //             dispatch(InActiveHP({ formData }));
  //         }
  //     });
  // };

  // useEffect(() => {
  //     if (hpInActiveLoading === API_STATUS.FULFILLED) {
  //         Swal.fire({
  //             title: 'Hiring Partner In-activated Successfully!',
  //             icon: 'success'
  //         });
  //         let query = "";
  //         let limit = "";
  //         let page = 0;
  //         dispatch(hiring_partnerLists({ query, limit, page }));
  //         dispatch(clearHiringPartnerLoadingsData())
  //     }
  //     if (hpInActiveLoading === API_STATUS.REJECTED && errorMessage) {
  //         Swal.fire({
  //             title: errorMessage.message,
  //             icon: 'error'
  //         });
  //         dispatch(clearHiringPartnerLoadingsData())
  //     }
  // }, [hpInActiveLoading, errorMessage])

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Company Name</th>
                    <th>Contact Person</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Total Students</th>
                    <th>Total Jobs</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {hiringPartnerDatas &&
                    hiringPartnerDatas?.rows?.map((hpValues, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{hpValues?.name}</td>
                            <td>{hpValues?.contact_person}</td>
                            <td>{hpValues?.phone_no}</td>
                            <td>{hpValues?.email}</td>
                            <td>
                              {hpValues?.hiring_partner_student?.length ?? 0}
                            </td>
                            <td>{hpValues?.job_description?.length ?? 0}</td>
                            <td>
                              {hpValues?.status == 1 ? "Active" : "In-Active"}
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                title="Jobs List"
                                className="btn btn-success mx-1"
                                onClick={(e) => {
                                  navigate("/assign_student/" + hpValues?.id);
                                }}
                              >
                                <MdListAlt size={18} />
                              </Button>
                              <Button
                                variant="primary"
                                title="Students List"
                                className="mx-1"
                                onClick={(e) => {
                                  navigate(
                                    "/hiring_partner/student/list/" +
                                      hpValues?.id
                                  );
                                }}
                              >
                                <MdOutlinePerson size={18} />
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between px-2">
                <strong>Total count : {hiringPartnerDatas?.count}</strong>
                <TablePagination
                  component="div"
                  count={hiringPartnerDatas?.count}
                  page={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default HiringPartnerList;
