import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import {
    ProfileHiringPartner,
    hiringpartnerSelector,
    EditHiringPartnerData,
    clearHiringPartnerLoadingsData,
} from "../../store/reducer/HiringPartnerReducer";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";


const HiringPartnerProfile = () => {
    const title = "Hiring Partner Profile";
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [Loginerror, setLoginerror] = useState(false);
    const [filedValue, setFieldValue] = useState({});
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Organization Name is required"),
        phone_no: Yup.string().required("Phone Number is required"),
        contact_person: Yup.string().required("Contact Person Name is required"),
    });
    useEffect(() => {
        dispatch(ProfileHiringPartner({}));
    }, []);

    const { hiringPartnerProfileDatas, hiringPartnerEditLoading, errorMessage } = useSelector(hiringpartnerSelector);

    const [initialValues, setInitState] = useState({
        name: "",
        phone_no: "",
        contact_person: "",
        website: "",
        address: "",
        email: "",
        about_us: "",
        profile_image: null
    });

    useEffect(() => {
        formik.setValues(
            {
                name: hiringPartnerProfileDatas?.name || '', contact_person: hiringPartnerProfileDatas?.contact_person, phone_no: hiringPartnerProfileDatas?.phone_no || '', website: hiringPartnerProfileDatas?.website || '', email: hiringPartnerProfileDatas?.email || '', address: hiringPartnerProfileDatas?.address || '', about_us: hiringPartnerProfileDatas?.about_us || '', profile_image: ""
            })
    }, [hiringPartnerProfileDatas]);

    const fileValue = (event) => {
        console.log(event.currentTarget.files, "event.currentTarget.files[0]");
        setFieldValue(event.currentTarget.files);
    };

    const onSubmit = (formData) => {
        // let formData = new FormData();
        // formData.append("name",formValues.name)
        // formData.append("phone_no",formValues.phone_no)
        // formData.append("email",formValues.email)
        // formData.append("about_us",formValues.about_us)
        // formData.append("website",formValues.website)
        // formData.append("address",formValues.address)
        // formData.append("profile_image",filedValue)
        // formData.append("id",hiringPartnerProfileDatas.id)
        // formData.append("user_id",hiringPartnerProfileDatas.user_id)
        // formData.id = hiringPartnerProfileDatas.id;
        // formData.user_id = hiringPartnerProfileDatas.user_id;
        // formData.profile_image = filedValue;
        dispatch(EditHiringPartnerData({ formData }));
    };


    const formik = useFormik({ initialValues, validationSchema, onSubmit });
    const { handleSubmit, handleChange, values, touched, errors } = formik;
    console.log(values, "Sdfsdfsda");

    useEffect(() => {
        console.log(hiringPartnerEditLoading, "hiringPartnerEditLoading");
        if (hiringPartnerEditLoading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: 'Profile Updated Successfully!',
                icon: 'success'
            });
            dispatch(clearHiringPartnerLoadingsData())
        }
        if (hiringPartnerEditLoading === API_STATUS.REJECTED && errorMessage) {
            Swal.fire({
                title: errorMessage.error,
                icon: 'error'
            });
            setLoginerror(errorMessage.error);
            dispatch(clearHiringPartnerLoadingsData())
        }
    }, [hiringPartnerEditLoading, errorMessage]);

    return (
        <>
            <Row>
                <Col>
                    {/* Title Start */}
                    <section className="scroll-section" id="title">
                        <div className="page-title-container">
                            <h1 className="mb-0 pb-0 display-4">{title}</h1>
                            <Breadcrumb className="breadcrumb-container d-inline-block">
                                <Breadcrumb.Item key={`breadCrumb1`}>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        {/* <Card className="mb-5" body>
                            <Card.Text>{description}</Card.Text>
                        </Card> */}
                    </section>
                    {/* Title End */}

                    {/* Standard Start */}
                    <section className="scroll-section" id="standard">
                        {/* <h2 className="small-title">Standard</h2> */}
                        <Card body className="mb-5">
                            <form
                                initialValues={initialValues}
                                id="loginForm"
                                className={
                                    validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                                }
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setValidated(true);
                                    handleSubmit();
                                }}
                            >
                                <div className="mb-3 filled form-group tooltip-end-top">
                                    {Loginerror && Loginerror.error && (
                                        <Form.Control.Feedback
                                            type="invalid"
                                            style={{ display: "block" }}
                                        >
                                            {Loginerror.error}
                                        </Form.Control.Feedback>
                                    )}
                                </div>
                                <Row className="mb-3 g-3">
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="building-large" />
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder="Name"
                                                defaultValue={(values.name)}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.name && touched.name && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="user" />
                                            <Form.Control
                                                type="text"
                                                name="contact_person"
                                                placeholder="Contact Person Name"
                                                value={values.contact_person}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.contact_person && touched.contact_person && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.contact_person}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="email" />
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                defaultValue={(values.email)}
                                                onChange={handleChange}
                                                className="is-invalid"
                                                placeholder="Email"
                                                readOnly
                                            />
                                            {errors.email && touched.email && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="mobile" />
                                            <Form.Control
                                                type="text"
                                                name="phone_no"
                                                defaultValue={(values.phone_no)}
                                                onChange={handleChange}
                                                className="is-invalid"
                                                placeholder="Phone"
                                            />
                                            {errors.phone_no && touched.phone_no && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.phone_no}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="web" />
                                            <Form.Control
                                                type="text"
                                                name="website"
                                                defaultValue={(values.website)}
                                                onChange={handleChange}
                                                className="is-invalid"
                                                placeholder="Website"
                                            />
                                            {errors.website && touched.website && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.website}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file-text" />
                                            <Form.Control
                                                as="textarea" rows={3}
                                                name="about_us"
                                                defaultValue={(values.about_us)}
                                                onChange={handleChange}
                                                className="is-invalid"
                                                placeholder="About Us"
                                            />
                                            {errors.about_us && touched.about_us && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.about_us}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="news" />
                                            <Form.Control
                                                as="textarea" rows={3}
                                                name="address"
                                                defaultValue={(values.address)}
                                                onChange={handleChange}
                                                className="is-invalid"
                                                placeholder="Address"
                                            />
                                            {errors.address && touched.address && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    {/* <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="" />
                                            <Form.Control
                                                name="file" type="file"
                                                onChange={fileValue}
                                                className="is-invalid"
                                            />
                                            {errors.address && touched.address && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col> */}
                                </Row>
                                <div className="text-end">
                                    <LoadingButton variant="contained" type="submit" loading={![API_STATUS.FULFILLED, 'initial'].includes(hiringPartnerEditLoading)} >
                                        Submit
                                    </LoadingButton>
                                </div>
                            </form>
                        </Card>
                    </section>

                    {/* Positions End */}
                </Col>
            </Row>
        </>
    );
};

export default HiringPartnerProfile;
