import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import PrivateRoute from "./privateRoute";
import useLayout from "./components/layout/Layout";
import Validation from "./components/Validation";
import Dashboard from "./components/Dashboard";
import StudentDashboard from "./components/students/StudentDashboard";
import ForgotPassword from "./components/Password/ForgotPassword";
import UpdateForgotPassword from "./components/Password/UpdateForgotPassword";
import CreateJob from "./components/hiringPartners/CreateJob";
import HiringPartnerProfile from "./components/hiringPartners/HiringPartnerProfile";
import StudentProfile from "./components/students/StudentProfile";
import "./App.css";
import Question from "./components/students/Questions";
import WelcomPage from "./components/students/WelcomPage";
import Course from "./components/students/Course";
import Sprint from "./components/students/Sprint";
import RegisterHiringPartner from "./components/hiringPartners/RegisterHiringPartner";
import MentorList from "./components/mentor/MentorList";
import MentorAdd from "./components/mentor/MentorAdd";
import MentorEdit from "./components/mentor/MentorEdit";
import Sprint1 from "./components/students/Sprint1";
import JobList from "./components/hiringPartners/JobList";
import Test from "./components/Test";
import StudentList from "./components/students/StudentList";
import FitmentProject from "./components/mentor/FitmentProject";
import MentorSignIn from "./components/MentorSignIn";
import HiringPartnerSignIn from "./components/HiringPartnerSignIn";
import MentorDashboard from "./components/mentor/MentorDashboard";
import CreateProject from "./components/mentor/CreateProject";
import StudentProgress from "./components/hiringPartners/StudentProgress";
import StudentCourse from "./components/hiringPartners/StudentCourse";
import MentorStudent from "./components/mentor/MentorStudent";
import AssignedFitment from "./components/students/FitmentProject";
import Projects from "./components/students/Projects";
import ProjectActivity from "./components/students/ProjectActivity";
import MentorProfile from "./components/MentorProfile";
import FitmentProjectsProgress from "./components/FitmentProjectsProgress";
import ProjectActivityProgress from "./components/ProjectActivityProgress";
import AdminSignIn from "./components/AdminSignIn";
import AdminDashboard from "./components/admin/AdminDashboard";
import CC_MentorList from "./components/admin/CC_Mentor_List";
import CC_MentorAdd from "./components/admin/CC_MentorAdd";
import CC_MentorEdit from "./components/admin/CC_MentorEdit";
import Admin_StudentList from "./components/admin/Admin_StudentList";
import HiringPartnerList from "./components/admin/HiringPartnerList";
import Sessions from "./components/students/Sessions";
import AdminJobList from "./components/admin/JobList";
import AssignStudents from "./components/admin/AssignStudents";
import DomainList from "./components/admin/DomainList";
import SprintDetails from "./components/admin/SprintDetails";
import SprintManagement from "./components/admin/SprintManagement";
import ActivityManagement from "./components/admin/ActivityManagement";
import CCMentorSignin from "./components/CCMentorSignin";
import ViewStudent from "./components/hiringPartners/ViewStudent";
import AttachmentManagement from "./components/admin/AttachmentManagement";
import GuestSpeakerList from "./components/admin/GuestSpeakerList";
import GuestSpeakerManagement from "./components/admin/GuestSpeakerManagement";
import CCMentorDashboard from "./components/CCMentorDashboard";
import DomainManagement from "./components/admin/DomainManagement";
import CollegeList from "./components/admin/CollegeList";
import CollegeManagement from "./components/admin/CollegeManagement";
import ActivityTypeList from "./components/admin/ActivityTypeList";
import ActivityTypeManagement from "./components/admin/ActivityTypeManagement";
import HiringPartnerStudents from "./components/admin/HiringPartnerStudents";
import SoftSkillList from "./components/admin/SoftSkillList";
import SoftSkillManagement from "./components/admin/SoftSkillManagement";
import QuestionManagement from "./components/admin/QuestionManagement";
import PlacementRegister from "./components/PlacementRegister";
import PlacementRegisterList from "./components/admin/PlacementRegisterList";
import StudentSignUp from "./components/certificate/StudentSignUp";
import View_Download from "./components/certificate/View_Download";
import UploadCertificate from "./components/certificate/UploadCertificate";
import HiringPartnerSignUp from "./components/certificate/HiringPartnerSignUp";
function App() {
  const role = localStorage.getItem("role");
  console.log(role, "ASDFASDFASDF");
  useLayout();
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/hiring_partner" element={<RegisterHiringPartner />} />
          <Route path="/signin" element={<SignUp />} />
          <Route path="/admin_signin" element={<AdminSignIn />} />
          <Route path="/mentor_signin" element={<MentorSignIn />} />
          <Route path="/ccmentor_signin" element={<CCMentorSignin />} />
          <Route
            path="/hiring_partner_signin"
            element={<HiringPartnerSignIn />}
          />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route
            path="/update_forgot_password/:email"
            element={<UpdateForgotPassword />}
          />
          <Route path="/welcome" element={<WelcomPage />} />
          <Route path="/questions" element={<Question />} />
          <Route path="/test" element={<Test />} />
          <Route path="/placement/register" element={<PlacementRegister />} />
          <Route
            path="/certificate/student/register"
            element={<StudentSignUp />}
          />
          <Route
            path="/certificate/hiring_partner/register"
            element={<HiringPartnerSignUp />}
          />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/validation" element={<Validation />} />
            {role == 1 ? (
              <>
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route path="/cc_mentor" element={<CC_MentorList />} />
                <Route path="/cc_mentor/add" element={<CC_MentorAdd />} />
                <Route path="/cc_mentor/edit/:id" element={<CC_MentorEdit />} />
                <Route
                  path="/admin/student_list"
                  element={<Admin_StudentList />}
                />
                <Route
                  path="/hiring_partner/:student_id/course/:id"
                  element={<StudentCourse />}
                />
                <Route
                  path="/hiring_partner/:student_id/course/:id/sprint/:qweryId"
                  element={<StudentProgress />}
                />
                <Route
                  path="/student/:student_id/project"
                  element={<FitmentProjectsProgress />}
                />
                <Route
                  path="/student/:student_id/project/:projectId"
                  element={<ProjectActivityProgress />}
                />
                <Route
                  path="/admin/hiring_partner"
                  element={<HiringPartnerList />}
                />
                <Route path="/admin/joblist" element={<AdminJobList />} />
                <Route
                  path="/assign_student/:hiring_partner_id"
                  element={<AssignStudents />}
                />
                <Route path="/admin/domain" element={<DomainList />} />
                <Route
                  path="/domain/sprint/:domain_id"
                  element={<SprintDetails />}
                />
                <Route
                  path="/sprint/add/:domain_id"
                  element={<SprintManagement />}
                />
                <Route
                  path="/sprint/edit/:domain_id/:id"
                  element={<SprintManagement />}
                />
                <Route
                  path="/activity/add/:domain_id"
                  element={<ActivityManagement />}
                />
                <Route
                  path="/activity/:domain_id/edit/:sprint_id/:id"
                  element={<ActivityManagement />}
                />
                <Route
                  path="/attachment/add/:domain_id"
                  element={<AttachmentManagement />}
                />
                <Route
                  path="/attachment/:domain_id/edit/:id"
                  element={<AttachmentManagement />}
                />
                <Route
                  path="/admin/guest_speaker"
                  element={<GuestSpeakerList />}
                />
                <Route
                  path="/guest_speaker/add/"
                  element={<GuestSpeakerManagement />}
                />
                <Route
                  path="/guest_speaker/edit/:id"
                  element={<GuestSpeakerManagement />}
                />
                <Route path="/domain/add/" element={<DomainManagement />} />
                <Route path="/domain/edit/:id" element={<DomainManagement />} />
                <Route path="/admin/college" element={<CollegeList />} />
                <Route path="/college/add/" element={<CollegeManagement />} />
                <Route
                  path="/college/edit/:id"
                  element={<CollegeManagement />}
                />
                <Route path="/admin/activity" element={<ActivityTypeList />} />
                <Route
                  path="/activity/add/"
                  element={<ActivityTypeManagement />}
                />
                <Route
                  path="/activity/edit/:id"
                  element={<ActivityTypeManagement />}
                />
                <Route
                  path="/hiring_partner/student/list/:id"
                  element={<HiringPartnerStudents />}
                />
                <Route
                  path="/student/dashboard/:studentId"
                  element={<ViewStudent />}
                />
                <Route path="/admin/soft_skill" element={<SoftSkillList />} />
                <Route
                  path="/soft_skill/add/"
                  element={<SoftSkillManagement />}
                />
                <Route
                  path="/soft_skill/edit/:id"
                  element={<SoftSkillManagement />}
                />
                <Route
                  path="/question/add/:domain_id"
                  element={<QuestionManagement />}
                />
                <Route
                  path="/question/edit/:domain_id/:id"
                  element={<QuestionManagement />}
                />
                <Route
                  path="admin/placement/list"
                  element={<PlacementRegisterList />}
                />
                <Route
                  path="/admin/certificate"
                  element={<UploadCertificate />}
                />
              </>
            ) : role == 2 ? (
              <>
                <Route path="/mentor/dashboard" element={<MentorDashboard />} />
                <Route path="/mentor/profile" element={<MentorProfile />} />
                <Route
                  path="/mentor/projectfitment"
                  element={<FitmentProject />}
                />
                <Route
                  path="/mentor/createproject"
                  element={<CreateProject />}
                />
                <Route
                  path="/mentor/editproject/:id"
                  element={<CreateProject />}
                />
                <Route path="/student/list" element={<StudentList />} />
                <Route
                  path="/hiring_partner/:student_id/course/:id"
                  element={<StudentCourse />}
                />
                <Route
                  path="/hiring_partner/:student_id/course/:id/sprint/:qweryId"
                  element={<StudentProgress />}
                />
                <Route path="/mentor/students" element={<MentorStudent />} />
                <Route
                  path="/student/:student_id/project"
                  element={<FitmentProjectsProgress />}
                />
                <Route
                  path="/student/:student_id/project/:projectId"
                  element={<ProjectActivityProgress />}
                />
                <Route
                  path="/student/dashboard"
                  element={<StudentDashboard />}
                />
                <Route
                  path="/student/dashboard/:studentId"
                  element={<ViewStudent />}
                />
              </>
            ) : role == 3 ? (
              <>
                <Route
                  path="/student/dashboard"
                  element={<StudentDashboard />}
                />
                <Route path="/student/course/:id" element={<Course />} />
                <Route
                  path="/student/course/:id/sprint/:qweryId"
                  element={<Sprint />}
                />
                <Route
                  path="/student/course/:id/sprint1/:qweryId"
                  element={<Sprint1 />}
                />
                <Route path="/student/profile" element={<StudentProfile />} />
                <Route
                  path="/student/projectfitment"
                  element={<AssignedFitment />}
                />
                <Route
                  path="/student/projectfitment/project/:hiring_partner_id"
                  element={<Projects />}
                />
                <Route
                  path="/student/projectfitment/project/:hiring_partner_id/:projectId"
                  element={<ProjectActivity />}
                />
                <Route path="/student/sessions" element={<Sessions />} />
                <Route path="/certificate/view" element={<View_Download />} />
              </>
            ) : role == 4 ? (
              <>
                <Route
                  path="/hiring_partner/dashboard"
                  element={<Dashboard />}
                />
                <Route
                  path="/hiring_partner/profile"
                  element={<HiringPartnerProfile />}
                />
                <Route path="/hiring_partner/job" element={<JobList />} />
                <Route path="/hiring_partner/create" element={<CreateJob />} />
                <Route
                  path="/hiring_partner/edit/:id"
                  element={<CreateJob />}
                />
                <Route path="/mentor" element={<MentorList />} />
                <Route path="/mentor/add" element={<MentorAdd />} />
                <Route path="/mentor/edit/:id" element={<MentorEdit />} />
                <Route path="/student/list" element={<StudentList />} />
                <Route
                  path="/hiring_partner/:student_id/course/:id"
                  element={<StudentCourse />}
                />
                <Route
                  path="/hiring_partner/:student_id/course/:id/sprint/:qweryId"
                  element={<StudentProgress />}
                />
                <Route
                  path="/student/:student_id/project"
                  element={<FitmentProjectsProgress />}
                />
                <Route
                  path="/student/:student_id/project/:projectId"
                  element={<ProjectActivityProgress />}
                />
                <Route
                  path="/student/dashboard/:studentId"
                  element={<ViewStudent />}
                />
                <Route path="/certificate/view" element={<View_Download />} />
              </>
            ) : role == 5 ? (
              <>
                <Route
                  path="/ccmentor/dashboard"
                  element={<CCMentorDashboard />}
                />
                <Route
                  path="/hiring_partner/:student_id/course/:id"
                  element={<StudentCourse />}
                />
                <Route
                  path="/hiring_partner/:student_id/course/:id/sprint/:qweryId"
                  element={<StudentProgress />}
                />
                <Route path="/ccmentor/students" element={<MentorStudent />} />
                <Route
                  path="/student/dashboard/:studentId"
                  element={<ViewStudent />}
                />
              </>
            ) : (
              <></>
            )}
            <Route path="/*" element={<>Page Not Found</>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
