import React, { useEffect, useState } from "react";
import { Card, Col, Row, Container, Modal, Tab, Tabs } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import {
  API_STATUS,
  QUESTION_TYPE,
  QUESTION_GROUP,
} from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsPencil, BsBarChartSteps } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { TablePagination } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  sprint_Lists,
  Domain_ActivityList,
  Domain_AttachmentList,
  SprintSelector,
  clearSprintData,
  clearsprintAllData,
  UploadSprint,
  UploadActivity,
  UploadAttachment,
} from "../../store/reducer/SprintReducer";
import {
  placementSelector,
  questionByDepartment,
} from "../../store/reducer/PlacementReducer";
import _ from "lodash";

const SprintDetails = () => {
  const title = "Sprint Details";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { domain_id } = useParams();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [sprintShow, setsprintShow] = useState(false);
  const [fileSprint, setfileSprint] = useState({});
  const [sprinterrorMsg, setsprinterrorMsg] = useState(false);
  const [actvityShow, setactvityShow] = useState(false);
  const [fileActivity, setfileActivity] = useState({});
  const [activityerrorMsg, setactivityerrorMsg] = useState(false);
  const [attachmentShow, setattachmentShow] = useState(false);
  const [fileAttachment, setfileAttachment] = useState({});
  const [attachmenterrorMsg, setattachmenterrorMsg] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [activitypage, setActivityPage] = useState(1);
  const [activitylimit, setActivityLimit] = useState(10);
  const [activityquery, setActivityQuery] = useState("");
  const [activitypageSize, setActivityPageSize] = useState(1);
  const [activityrowsPerPage, setActivityRowsPerPage] = useState(0);
  const [attachmentpage, setAttachmentPage] = useState(1);
  const [attachmentlimit, setAttachmentLimit] = useState(10);
  const [attachmentquery, setAttachmentQuery] = useState("");
  const [attachmentpageSize, setAttachmentPageSize] = useState(1);
  const [attachmentrowsPerPage, setAttachmentRowsPerPage] = useState(0);

  const [questionpage, setQuestionPage] = useState(1);
  const [questionlimit, setQuestionLimit] = useState(10);
  const [questionquery, setQuestionQuery] = useState("");
  const [questionpageSize, setQuestionPageSize] = useState(1);
  const [questionrowsPerPage, setQuestionRowsPerPage] = useState(0);

  const [sprintIDs, setsprintIDs] = useState([]);

  useEffect(() => {
    let order = "";
    let department_id = domain_id;
    dispatch(sprint_Lists({ page, limit, query, order, department_id }));
    dispatch(
      questionByDepartment({
        page: questionpage,
        limit: questionlimit,
        query: questionquery,
        department_id,
      })
    );
  }, []);

  const {
    sprintListData,
    activityListData,
    attachmentListData,
    sprint_upload_Loading,
    activity_upload_Loading,
    attachment_upload_Loading,
    sprint_errorMessage,
  } = useSelector(SprintSelector);
  const { questionByDepartmentData, questionsByDepartmentLoading } =
    useSelector(placementSelector);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = sprintListData?.count % 10;
    let remainder = sprintListData?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [sprintListData]);

  useEffect(() => {
    let order = "";
    let department_id = domain_id;
    dispatch(sprint_Lists({ page, limit, query, order, department_id }));
  }, [page, limit, query]);

  const activityhandleChangePage = (event, newPage) => {
    setActivityPage(newPage + 1);
    setActivityRowsPerPage(newPage);
  };

  const activityhandleChangeRowsPerPage = (event) => {
    setActivityLimit(parseInt(event.target.value, 10));
    // setActivityPage(0);
  };

  useEffect(() => {
    let count = activityListData?.count % 10;
    let remainder = activityListData?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setActivityPageSize(pageLength);
  }, [activityListData]);

  useEffect(() => {
    let order = "";
    let id = 0;
    let sprint_id = [];
    sprint_id = sprintListData?.rows?.map((vals) => {
      return vals.id;
    });
    dispatch(
      Domain_ActivityList({
        activitypage,
        activitylimit,
        activityquery,
        order,
        sprint_id,
        id,
      })
    );
    console.log(activitypage, ":SDAGFD");
  }, [activitypage, activitylimit, activityquery]);

  const attachmenthandleChangePage = (event, newPage) => {
    setAttachmentPage(newPage + 1);
    setAttachmentRowsPerPage(newPage);
  };

  const attachmenthandleChangeRowsPerPage = (event) => {
    setAttachmentLimit(parseInt(event.target.value, 10));
    setAttachmentPage(0);
  };

  useEffect(() => {
    let count = attachmentListData?.count % 10;
    let remainder = attachmentListData?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setAttachmentPageSize(pageLength);
  }, [attachmentListData]);

  useEffect(() => {
    let order = "";
    let id = 0;
    let activity_id = [];
    activity_id = activityListData?.rows?.map((vals) => {
      return vals.id;
    });
    dispatch(
      Domain_AttachmentList({
        attachmentpage,
        attachmentlimit,
        attachmentquery,
        order,
        activity_id,
        sprint_id: sprintIDs,
        id,
      })
    );
    console.log(attachmentlimit, "attachmentlimit1");
  }, [attachmentpage, attachmentlimit, attachmentquery]);

  const questionhandleChangePage = (event, newPage) => {
    setQuestionPage(newPage + 1);
    setQuestionRowsPerPage(newPage);
  };

  const questionhandleChangeRowsPerPage = (event) => {
    setQuestionLimit(parseInt(event.target.value, 10));
    setQuestionPage(0);
  };

  useEffect(() => {
    let count = questionByDepartmentData?.count % 10;
    let remainder = questionByDepartmentData?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setQuestionPageSize(pageLength);
  }, [questionByDepartmentData]);

  useEffect(() => {
    questionByDepartment({
      page: questionpage,
      limit: questionlimit,
      query: questionquery,
      department_id: domain_id,
    });
  }, [questionpage, questionlimit, questionquery]);

  useEffect(() => {
    let order = "";
    let id = 0;
    let sprint_id = [];
    sprint_id = sprintListData?.rows?.map((vals) => {
      return vals.id;
    });
    dispatch(
      Domain_ActivityList({
        activitypage,
        activitylimit,
        activityquery,
        order,
        sprint_id,
        id,
      })
    );
    setsprintIDs(sprint_id);
    console.log(sprintListData,"sprintListData1")
  }, [sprintListData]);

  useEffect(() => {
    let order = "";
    let id = 0;
    let activity_id = [];
    activity_id = activityListData?.rows?.map((vals) => {
      return vals.id;
    });
    dispatch(
      Domain_AttachmentList({
        attachmentpage,
        attachmentlimit,
        attachmentquery,
        order,
        activity_id,
        sprint_id: sprintIDs,
        id,
      })
    );
  }, [activityListData]);

  useEffect(() => {
    console.log(sprintListData, "sprintListData");
  }, [sprintListData]);

  const handleSprint = (event) => {
    const { files } = event.target;
    setfileSprint(files[0]);
  };

  const handleActivity = (event) => {
    const { files } = event.target;
    setfileActivity(files[0]);
  };

  const handleAttachment = (event) => {
    const { files } = event.target;
    setfileAttachment(files[0]);
  };

  const sprintUpload = () => {
    if (!fileSprint) {
      setsprinterrorMsg(!fileSprint);
    } else {
      let formData = {};
      formData.department_id = domain_id;
      formData.sprint_file = fileSprint;

      console.log(formData, "DSFASOFD");
      dispatch(UploadSprint({ formData }));
    }
  };

  const actvityUpload = () => {
    if (!fileActivity) {
      setactivityerrorMsg(!fileActivity);
    } else {
      let formData = {};
      formData.department_id = domain_id;
      formData.activity_file = fileActivity;
      console.log(formData, "ActivityForm");
      dispatch(UploadActivity({ formData }));
    }
  };

  const attachmentUpload = () => {
    if (!fileAttachment) {
      setattachmenterrorMsg(!fileAttachment);
    } else {
      let formData = {};
      formData.attachment_file = fileAttachment;
      console.log(formData, "AttachmentForm");
      dispatch(UploadAttachment({ formData }));
    }
  };

  useEffect(() => {
    if (sprint_upload_Loading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Sprint Upload Successfully!",
        icon: "success",
      });
      let order = "";
      let department_id = domain_id;
      dispatch(sprint_Lists({ page, limit, query, order, department_id }));
      setsprintShow(false);
      dispatch(clearsprintAllData());
    }
    if (activity_upload_Loading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Activity Upload Successfully!",
        icon: "success",
      });
      let order = "";
      let id = 0;
      let sprint_id = [];
      sprint_id = sprintListData?.rows?.map((vals) => {
        return vals.id;
      });
      dispatch(
        Domain_ActivityList({
          activitypage,
          activitylimit,
          activityquery,
          order,
          sprint_id,
          id,
        })
      );
      setactvityShow(false);
      dispatch(clearsprintAllData());
    }
    if (attachment_upload_Loading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Attachment Upload Successfully!",
        icon: "success",
      });
      let order = "";
      let id = 0;
      let activity_id = [];
      activity_id = activityListData?.rows?.map((vals) => {
        return vals.id;
      });
      dispatch(
        Domain_AttachmentList({
          attachmentpage,
          attachmentlimit,
          attachmentquery,
          order,
          activity_id,
          sprint_id: sprintIDs,
          id,
        })
      );
      setattachmentShow(false);
      dispatch(clearsprintAllData());
    }

    if (
      (sprint_upload_Loading ||
        activity_upload_Loading ||
        attachment_upload_Loading) === API_STATUS.REJECTED &&
      sprint_errorMessage
    ) {
      if (sprint_errorMessage.message)
        Swal.fire({
          title: sprint_errorMessage.message,
          icon: "error",
        });
      else if (sprint_errorMessage.errors) {
        let data = "";
        sprint_errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        Swal.fire({
          title: data,
          icon: "error",
        });
      }
      dispatch(clearsprintAllData());
    }
  }, [
    sprint_upload_Loading,
    activity_upload_Loading,
    attachment_upload_Loading,
    sprint_errorMessage,
  ]);

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Row>
              <Col md={6}>
                <h3>
                  Domain : <b>{sprintListData?.rows?.[0]?.department?.name}</b>
                </h3>
              </Col>
              <Col md={6}>
                <Link to="/admin/domain">
                  <div className="text-end mb-2">
                    <Button variant="success" type="submit">
                      Back
                    </Button>
                  </div>
                </Link>
              </Col>
            </Row>
          </section>
          {/* Title End */}
          {/* Standard Start */}
          <section className="tab mt-2">
            <Container>
              <Card>
                <Card.Body>
                  <Tabs
                    defaultActiveKey="sprint"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey={"sprint"} title="Sprint">
                      <div className="text-end mb-2">
                        <Link to={`/sprint/add/${domain_id}`}>
                          <Button
                            className="mx-1"
                            variant="success"
                            type="submit"
                          >
                            <CsLineIcons icon="plus" />
                            Add Sprint
                          </Button>
                        </Link>
                        <Button
                          className="mx-2"
                          variant="success"
                          type="button"
                          onClick={() => setsprintShow(true)}
                        >
                          <CsLineIcons icon="file-text" />
                          Upload Sprints
                        </Button>
                      </div>

                      <Card body className="mb-5">
                        {/* List Header Start */}
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>S.NO</th>
                              <th>Sprint Name</th>
                              <th>Activity Count</th>
                              <th>Duration (Hrs)</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sprintListData &&
                              sprintListData?.rows?.map(
                                (sprintValues, index) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{sprintValues?.name}</td>
                                        <td>
                                          {sprintValues?.sprintactivity?.length}
                                        </td>
                                        <td>{sprintValues?.duration}</td>
                                        <td>
                                          {sprintValues?.status == 1
                                            ? "Active"
                                            : "In-Active"}
                                        </td>
                                        <td>
                                          <Link
                                            to={`/sprint/edit/${domain_id}/${sprintValues.id}`}
                                            title="Edit"
                                            className="btn btn-primary mx-1 "
                                          >
                                            <BsPencil />
                                          </Link>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between px-2">
                          <strong>Total count : {sprintListData?.count}</strong>
                          <TablePagination
                            component="div"
                            count={sprintListData?.count}
                            page={rowsPerPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={limit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      </Card>
                    </Tab>
                    <Tab eventKey="activity" title="Activity">
                      <Card body className="mb-5">
                        <div className="text-end mb-2">
                          <Link to={`/activity/add/${domain_id}`}>
                            <Button variant="success" type="submit">
                              <CsLineIcons icon="plus" />
                              Add Activity
                            </Button>
                          </Link>
                          <Button
                            className="mx-2"
                            variant="success"
                            type="button"
                            onClick={() => setactvityShow(true)}
                          >
                            <CsLineIcons icon="file-text" />
                            Upload Activities
                          </Button>
                        </div>

                        {/* List Header Start */}
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>S.NO</th>
                              <th>Sprint Name</th>
                              <th>Activity Name</th>
                              <th>Attachment Count</th>
                              <th>Duration (Hrs)</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {activityListData &&
                              activityListData?.rows?.map(
                                (activityValues, index) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{activityValues?.sprint?.name}</td>
                                        <td>{activityValues?.name}</td>
                                        <td>
                                          {
                                            activityValues?.activityattachment
                                              ?.length
                                          }
                                        </td>
                                        <td>{activityValues?.duration}</td>
                                        <td>
                                          {activityValues?.status == 1
                                            ? "Active"
                                            : "In-Active"}
                                        </td>
                                        <td>
                                          <Link
                                            to={`/activity/${domain_id}/edit/${activityValues?.sprint_id}/${activityValues?.id}`}
                                            title="Edit"
                                            className="btn btn-primary mx-1 "
                                          >
                                            <BsPencil />
                                          </Link>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between px-2">
                          <strong>
                            Total count : {activityListData?.count}
                          </strong>
                          <TablePagination
                            component="div"
                            count={activityListData?.count}
                            page={activityrowsPerPage}
                            onPageChange={activityhandleChangePage}
                            rowsPerPage={activitylimit}
                            onRowsPerPageChange={
                              activityhandleChangeRowsPerPage
                            }
                          />
                        </div>
                      </Card>
                    </Tab>
                    <Tab eventKey="attachments" title="Attachments">
                      <Card body className="mb-5">
                        <div className="text-end mb-2">
                          <Link to={`/attachment/add/${domain_id}`}>
                            <Button variant="success" type="submit">
                              <CsLineIcons icon="plus" />
                              Add Attachment
                            </Button>
                          </Link>
                          <Button
                            className="mx-2"
                            variant="success"
                            type="button"
                            onClick={() => setattachmentShow(true)}
                          >
                            <CsLineIcons icon="file-text" />
                            Upload Activities
                          </Button>
                        </div>

                        {/* List Header Start */}
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>S.NO</th>
                              <th>Activity Name</th>
                              <th>Urls</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {attachmentListData &&
                              attachmentListData?.rows?.map(
                                (attachmentValues, index) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          {
                                            attachmentValues?.sprintactivity
                                              ?.name
                                          }
                                        </td>
                                        <td>
                                          <a
                                            href={attachmentValues?.file}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {attachmentValues?.file}{" "}
                                          </a>
                                        </td>
                                        <td>
                                          {attachmentValues?.status == 1
                                            ? "Active"
                                            : "In-Active"}
                                        </td>
                                        <td>
                                          <Link
                                            to={`/attachment/${domain_id}/edit/${attachmentValues?.id}`}
                                            title="Edit"
                                            className="btn btn-primary mx-1 "
                                          >
                                            <BsPencil />
                                          </Link>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between px-2">
                          <strong>
                            Total count : {attachmentListData?.count}
                          </strong>
                          <TablePagination
                            component="div"
                            count={attachmentListData?.count}
                            page={attachmentrowsPerPage}
                            onPageChange={attachmenthandleChangePage}
                            rowsPerPage={attachmentlimit}
                            onRowsPerPageChange={
                              attachmenthandleChangeRowsPerPage
                            }
                          />
                        </div>
                      </Card>
                    </Tab>
                    <Tab eventKey={"Questions"} title="Questions">
                      <div className="text-end mb-2">
                        <Link to={`/question/add/${domain_id}`}>
                          <Button
                            className="mx-1"
                            variant="success"
                            type="submit"
                          >
                            <CsLineIcons icon="plus" />
                            Add Questions
                          </Button>
                        </Link>
                        {/* <Button
                          className="mx-2"
                          variant="success"
                          type="button"
                          onClick={() => setsprintShow(true)}
                        >
                          <CsLineIcons icon="file-text" />
                          Upload Sprints
                        </Button> */}
                      </div>

                      <Card body className="mb-5">
                        {/* List Header Start */}
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>S.NO</th>
                              <th>Question</th>
                              <th>Group</th>
                              <th>Type</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {questionByDepartmentData &&
                              questionByDepartmentData?.rows?.map(
                                (questionValues, index) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{questionValues?.question}</td>
                                        <td>
                                          {
                                            QUESTION_GROUP[
                                              questionValues?.question_group_id
                                            ]
                                          }
                                        </td>
                                        <td>
                                          {QUESTION_TYPE[questionValues?.type]}
                                        </td>
                                        <td>
                                          {questionValues?.status == 1
                                            ? "Active"
                                            : "In-Active"}
                                        </td>
                                        <td>
                                          <Link
                                            to={`/question/edit/${domain_id}/${questionValues.id}`}
                                            title="Edit"
                                            className="btn btn-primary mx-1 "
                                          >
                                            <BsPencil />
                                          </Link>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between px-2">
                          <strong>
                            Total count : {questionByDepartmentData?.count}
                          </strong>
                          <TablePagination
                            component="div"
                            count={questionByDepartmentData?.count}
                            page={questionrowsPerPage}
                            onPageChange={questionhandleChangePage}
                            rowsPerPage={questionlimit}
                            onRowsPerPageChange={
                              questionhandleChangeRowsPerPage
                            }
                          />
                        </div>
                      </Card>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Container>
          </section>

          {/* Sprint Upload */}
          <Modal
            show={sprintShow}
            id="sprintUpload"
            dialogClassName="modal-100w"
            aria-labelledby="mentorAssign"
            tabIndex="-1"
            scrollable
            size="xl"
          >
            <div className="p-3 mt-2" style={{ overflow: "auto" }}>
              <>
                <h1>Upload Sprints</h1>
                <Row>
                  <Col md="6">
                    <h3>
                      Domain :{" "}
                      <b>{sprintListData?.rows?.[0]?.department?.name}</b>
                    </h3>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file" />
                      <Form.Control
                        type="file"
                        name="sprint_file"
                        onChange={(e) => handleSprint(e)}
                        placeholderText="Upload Sprints"
                        values={fileSprint}
                      />
                      {sprinterrorMsg && (
                        <p className="text-danger">File required</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="text-end">
                  <LoadingButton
                    variant="contained"
                    loading={
                      ![API_STATUS.FULFILLED, "initial"].includes(
                        sprint_upload_Loading
                      )
                    }
                    onClick={sprintUpload}
                  >
                    Upload
                  </LoadingButton>
                  <Button
                    variant="primary"
                    className="mx-1"
                    onClick={() => setsprintShow(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            </div>
          </Modal>
          {/* Activity Upload */}
          <Modal
            show={actvityShow}
            id="actvityUpload"
            dialogClassName="modal-100w"
            aria-labelledby="mentorAssign"
            tabIndex="-1"
            scrollable
            size="xl"
          >
            <div className="p-3 mt-2" style={{ overflow: "auto" }}>
              <>
                <h1>Upload Sprints</h1>
                <Row>
                  <Col md="6">
                    <h3>
                      Domain :{" "}
                      <b>{sprintListData?.rows?.[0]?.department?.name}</b>
                    </h3>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file" />
                      <Form.Control
                        type="file"
                        name="activity_file"
                        onChange={(e) => handleActivity(e)}
                        placeholderText="Upload Actvities"
                        values={fileActivity}
                      />
                      {activityerrorMsg && (
                        <p className="text-danger">File required</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="text-end">
                  <LoadingButton
                    variant="contained"
                    loading={
                      ![API_STATUS.FULFILLED, "initial"].includes(
                        activity_upload_Loading
                      )
                    }
                    onClick={actvityUpload}
                  >
                    Upload
                  </LoadingButton>
                  <Button
                    variant="primary"
                    className="mx-1"
                    onClick={() => setactvityShow(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            </div>
          </Modal>

          {/* Attachment Upload */}
          <Modal
            show={attachmentShow}
            id="attachmentUpload"
            dialogClassName="modal-100w"
            aria-labelledby="mentorAssign"
            tabIndex="-1"
            scrollable
            size="xl"
          >
            <div className="p-3 mt-2" style={{ overflow: "auto" }}>
              <>
                <h1>Upload Sprints</h1>
                <Row>
                  <Col md="6">
                    <h3>
                      Domain :{" "}
                      <b>{sprintListData?.rows?.[0]?.department?.name}</b>
                    </h3>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file" />
                      <Form.Control
                        type="file"
                        name="attachment_file"
                        onChange={(e) => handleAttachment(e)}
                        placeholderText="Upload Attachments"
                        values={fileAttachment}
                      />
                      {attachmenterrorMsg && (
                        <p className="text-danger">File required</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="text-end">
                  <LoadingButton
                    variant="contained"
                    loading={
                      ![API_STATUS.FULFILLED, "initial"].includes(
                        attachment_upload_Loading
                      )
                    }
                    onClick={attachmentUpload}
                  >
                    Upload
                  </LoadingButton>
                  <Button
                    variant="primary"
                    className="mx-1"
                    onClick={() => setattachmentShow(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            </div>
          </Modal>
          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default SprintDetails;
