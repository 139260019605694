import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Accordion,
  Card,
  Col,
  Row,
  Container,
  Modal,
  Tab,
  Button as Buttoned,
  Tabs,
  ProgressBar,
  Breadcrumb,
} from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import {
  faBolt,
  faFire,
  faIdBadge,
  faPlus,
  faSuitcase,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { MdClose, MdCheck, MdPersonPin } from "react-icons/md";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import NODATA from "../../../src/assets/img/nojobs.jpg";
import NOMATCH from "../../../src/assets/images/nomatch.jpg";
import { FcRating } from "react-icons/fc";
import {
  GetCertData,
  GetEduData,
  OverallFeedback,
  OverallSprintFeedback,
  ProfileStudent,
  RegisteredSessionList,
  StudentListData,
  StudentEduScheduler,
  StudentAcceptanceUpdate,
  StudentSelector,
  clearData,
} from "../../store/reducer/StudentReducer";
import "../../assets/css/test.css";
import { useDispatch, useSelector } from "react-redux";
import { FaUser } from "react-icons/fa";
import {
  courseDetails,
  courseSelector,
  planDetail,
} from "../../store/reducer/CourseReducer";
import "react-datepicker/dist/react-datepicker.css";
import { SkillList, settingSelector } from "../../store/reducer/settingsSlice";
import { API_BASE, gradeCalc } from "../../services/config";
import { Button, TextField } from "@mui/material";
import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import {
  DashboardSelector,
  SessionList,
} from "../../store/reducer/DashboardReducer";
import _ from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ViewStudent = () => {
  const name = localStorage.getItem("username");
  const role = localStorage.getItem("role");
  const title = "Culture Catalyst";
  const { studentId } = useParams();
  console.log(studentId, "studentId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [actCount, setActCount] = useState(0);
  const [reject_show, setRejectShow] = useState(false);
  const [VideOn, setVideeoOn] = useState(false);
  const [validated, setValidated] = useState(false);
  const videoRef = useRef(null);
  const [educationData, setEducationData] = useState({
    educations: [
      {
        education: "",
        institution: "",
        percentage: "",
        from_date: "",
        to_date: "",
      },
    ],
  });
  const [certificatData, setCertificateData] = useState({
    certificates: [
      {
        name: "",
        description: "",
        conducted_by: "",
        grade: "",
        from_date: "",
        to_date: "",
      },
    ],
  });
  const {
    studentDatas,
    studentProfileDatas,
    overallFeedbackData,
    studentEduLoad,
    EduDetails,
    CertDetails,
    studentCertLoad,
    GetEduDataLoad,
    GetCertDataLoad,
    updateVideoResumeLoad,
    OverallSprintFeedbackData,
    registeredSessionData,
    studentAcceptanceLoading,
    studentAcceptanceMessage,
  } = useSelector(StudentSelector);
  console.log(
    OverallSprintFeedbackData,
    overallFeedbackData,
    "overallFeedbackDataoverallFeedbackData"
  );
  const [averages, setAverages] = useState({});
  const [overallAverage, setOverallAverage] = useState(0);
  console.log(averages, "averagesaverages");
  const { skillDatas } = useSelector(settingSelector);
  const { courseData, planDetails } = useSelector(courseSelector);
  const { sessionDetails } = useSelector(DashboardSelector);
  const currentSprint = courseData?.rows?.find((ele) => {
    return ele.id === studentProfileDatas?.studentData?.current_sprint;
  });
  const now = 60;
  const [show, setShow] = useState(false);
  const [certshow, setCertShow] = useState(false);
  const [sessionData, setSessionDatas] = useState([]);
  console.log(sessionData, "sessionData");
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.stopPropagation();
    setShow(true);
  };

  const handleCertClose = () => setCertShow(false);
  const handleCertShow = (e) => {
    e.stopPropagation();
    setCertShow(true);
  };
  const handleJoinMeet = (data) => {
    window.open(data?.meeting_link, "_blank");
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const courserank = OverallSprintFeedbackData?.rankings_by_id;
    const projectrank = overallFeedbackData?.rankings_by_id;
    console.log(courserank, "courserankcourserank");
    let combined;
    if (courserank && projectrank) combined = [...courserank, ...projectrank];
    else if (courserank) combined = [...courserank];
    const course = OverallSprintFeedbackData?.overall_rankings ?? 0;
    const project = overallFeedbackData?.overall_rankings ?? 0;
    let avg;
    if (project > 0) avg = (parseFloat(course) + parseFloat(project)) / 2;
    else avg = course;
    const grouped = _.groupBy(combined, "skill_id");
    const average = _.mapValues(grouped, (group) =>
      _.meanBy(group, "rankings")
    );
    setAverages(average);
    setOverallAverage(avg);
  }, [OverallSprintFeedbackData, overallFeedbackData]);

  useEffect(() => {
    let formData = studentId;
    dispatch(ProfileStudent(formData));
    var video = videoRef.current;

    video?.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });

    video?.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    let live = [];

    live = registeredSessionData?.filter((status) => {
      if (status?.status === 0) {
        return status;
      }
    });
    setSessionDatas(live);
  }, [registeredSessionData]);
  useEffect(() => {
    var video = videoRef.current;

    video?.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });

    video?.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
    dispatch(RegisteredSessionList({ student_id: studentId }));
    dispatch(
      courseDetails({
        department_id: studentProfileDatas?.studentData?.department,
        id: "",
        order: "",
        limit: "",
        sort: "",
        page: "",
        query: "",
      })
    );
    dispatch(
      OverallFeedback({
        page: "",
        limit: "",
        query: "",
        sortby: "",
        order: "",
        status: "",
        student_id: studentId,
      })
    );
    dispatch(
      OverallSprintFeedback({
        page: "",
        limit: "",
        query: "",
        sortby: "",
        order: "",
        status: "",
        student_id: studentId,
      })
    );
    dispatch(
      SkillList({
        page: "",
        limit: "",
        query: studentProfileDatas?.studentData?.department,
        sortby: "",
        order: "",
      })
    );
    console.log(studentProfileDatas, "studentProfileDatas");
  }, [studentProfileDatas]);

  useEffect(() => {
    dispatch(
      planDetail({
        order: "",
        planlimit: "",
        sortby: "",
        planpage: "",
        planquery: "",
        status: "",
        student_id: studentId,
        sprint_id: studentProfileDatas?.studentData?.current_sprint,
        date: "",
      })
    );
    dispatch(GetEduData({ student_id: studentId }));
    dispatch(GetCertData({ student_id: studentId }));
  }, [currentSprint, studentProfileDatas]);

  useEffect(() => {
    let counter = 0;
    courseData?.rows?.map((activities) => {
      counter += activities?.sprintactivity.length;
    });
    setActCount(counter);
  }, [courseData]);

  const handleVideoShow = async () => {
    setVideeoOn(true);
  };

  const hitEducation = () => {
    dispatch(GetEduData({ student_id: studentId }));
  };

  const hitCertificates = () => {
    dispatch(GetCertData({ student_id: studentId }));
  };

  const handleModalHide = () => {
    setVideeoOn(false);
  };

  const acceptStudent = (id, type) => {
    let formData = {};
    formData.id = id;
    formData.student_acceptance_status = type;
    dispatch(StudentAcceptanceUpdate({ formData }));
  };
  const rejectStudent = () => {
    setRejectShow(true);
  };

  const initialValues = { reject_remarks: "" };
  const validationSchema = Yup.object().shape({
    reject_remarks: Yup.string().required("Remarks is required"),
  });

  const onSubmit = (formData) => {
    let data = Object.keys(formData);
    data.forEach((items) => {
      if (!formData[items]) delete formData[items];
    });
    formData.id = studentId;
    formData.student_acceptance_status = 2;
    dispatch(StudentAcceptanceUpdate({ formData }));
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  useEffect(() => {
    if (studentEduLoad === API_STATUS.FULFILLED) {
      handleClose();
      dispatch(clearData());
      Swal.fire({
        title: "Added Succesfully!",
        icon: "success",
      });
      hitEducation();
      setEducationData({
        educations: [
          {
            education: "",
            institution: "",
            percentage: "",
            from_date: "",
            to_date: "",
          },
        ],
      });
    }
    if (studentCertLoad === API_STATUS.FULFILLED) {
      handleCertClose();
      dispatch(clearData());
      Swal.fire({
        title: "Added Succesfully!",
        icon: "success",
      });
      hitCertificates();
      setCertificateData({
        certificates: [
          {
            name: "",
            description: "",
            conducted_by: "",
            grade: "",
            from_date: "",
            to_date: "",
          },
        ],
      });
    }
    if (GetCertDataLoad === API_STATUS.FULFILLED) {
      dispatch(clearData());
    }
    if (GetEduDataLoad === API_STATUS.FULFILLED) {
      dispatch(clearData());
    }
    if (updateVideoResumeLoad === API_STATUS.FULFILLED) {
      let formData = {};
      dispatch(ProfileStudent(formData));
      dispatch(clearData());
      Swal.fire({
        title: "Added Succesfully!",
        icon: "success",
      });
      setVideeoOn(false);
    }

    if (studentAcceptanceLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: studentAcceptanceMessage?.message,
        icon: studentAcceptanceMessage?.type,
      });
      dispatch(clearData());
      setRejectShow(false);
      let formData = studentId;
      dispatch(ProfileStudent(formData));
    }
  }, [
    studentEduLoad,
    studentCertLoad,
    GetEduDataLoad,
    GetCertDataLoad,
    updateVideoResumeLoad,
    studentAcceptanceLoading,
  ]);
  console.log(
    studentProfileDatas?.studentData?.hiring_partner_student?.[0]
      ?.student_acceptance_status,
    "DFHFDJKHGUKJ"
  );

  return (
    <div className="container-fluid mt-4">
      <div className="">
        <section className="scroll-section" id="title">
          <div className="page-title-container">
            <h1 className="mb-0 pb-0 display-4">{title}</h1>
            <Breadcrumb className="breadcrumb-container d-inline-block">
              <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </section>
        <div className="d-flex w-100 justify-content-end">
          {role == 4 &&
          studentProfileDatas?.studentData?.hiring_partner_student?.[0]
            ?.student_acceptance_status == 0 ? (
            <>
              <Buttoned
                variant="warning"
                title="Accept Culture Catalyst"
                className="mb-3 mx-1"
                onClick={(e) => {
                  acceptStudent(
                    studentProfileDatas?.studentData
                      ?.hiring_partner_student?.[0]?.id,
                    1
                  );
                }}
              >
                <MdCheck /> Accept
              </Buttoned>
              <Buttoned
                variant="danger"
                title="Reject Culture Catalyst"
                className="mb-3 mx-1"
                onClick={(e) => {
                  rejectStudent();
                }}
              >
                <MdClose /> Reject
              </Buttoned>
            </>
          ) : (
            <></>
          )}
          <Buttoned
            className="mb-3 mx-1"
            variant="success"
            onClick={(e) => {
              navigate(-1);
            }}
          >
            Back
          </Buttoned>
        </div>
      </div>
      <div>
        <div>
          <section className="profile-head">
            <Container>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={1} sm={2}>
                      <div className="profile-img">
                        <div className="bg-gradient-light user-image rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <FaUser size={60} color="white" />
                        </div>
                      </div>
                    </Col>
                    <Col md={7} sm={6}>
                      <div className="profile-content mt-3">
                        <h3 className="text-capitalize mb-0">
                          {studentProfileDatas?.studentData?.name}
                        </h3>

                        <p className="mt-0">
                          {studentProfileDatas?.studentData?.specialization}
                        </p>
                        <p>
                          <span className="loc">
                            {studentProfileDatas?.studentData?.address ? (
                              <FontAwesomeIcon
                                icon={faLocationDot}
                                style={{ marginRight: "7px" }}
                              />
                            ) : (
                              <></>
                            )}
                            {studentProfileDatas?.studentData?.address}
                          </span>
                        </p>
                      </div>
                    </Col>
                    <Col className="align-items-center">
                      <Row className="h-100 align-items-center">
                        <Col sm={6}>
                          <FontAwesomeIcon
                            className="font"
                            icon={faBolt}
                            size="3x"
                            style={{ color: "#FFD43B" }}
                          />
                          <b>
                            <h3 className="number">
                              {overallAverage * 4} Points
                            </h3>{" "}
                          </b>
                        </Col>
                        <Col sm={6}>
                          <FontAwesomeIcon
                            icon={faIdBadge}
                            size="3x"
                            style={{ color: "#990000" }}
                          />
                          <h3 className="level">
                            {gradeCalc(overallAverage * 4)} Grade
                          </h3>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </section>

          <section className="tab mt-2">
            <Container>
              <Card>
                <Card.Body>
                  <Tabs
                    defaultActiveKey="sessions"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey={"sessions"} title="Sessions">
                      <Card>
                        <Card.Body className="slider-container bg-light">
                          <h3>
                            <strong>Upcoming Sessions</strong>
                          </h3>
                          <Slider {...settings}>
                            {sessionData?.length > 0 ? (
                              sessionData?.map((datas) => {
                                const data = datas?.guest_speaker_session;
                                return (
                                  <>
                                    <Card>
                                      <Card.Body className="d-flex justify-content-start align-items-center w-100">
                                        <div>
                                          <h4 className="text-uppercase">
                                            {data?.topic}
                                          </h4>
                                          <p>
                                            {dayjs(data?.date_time).format(
                                              "DD/MM/YYYY - hh:mm:ss"
                                            )}
                                          </p>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </>
                                );
                              })
                            ) : (
                              <>No Data's</>
                            )}
                          </Slider>
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="home" title="Stats">
                      <Row>
                        <Col md={6}>
                          <Card className="home-tab">
                            <Card.Header>LEVELUP STATS</Card.Header>
                            <Card.Body>
                              <Card.Text>
                                <Row className="just">
                                  <Col md={6} sm={6}>
                                    <Row>
                                      <Col md={3} sm={3}>
                                        <FontAwesomeIcon
                                          className="font"
                                          icon={faBolt}
                                          size="3x"
                                          style={{ color: "#FFD43B" }}
                                        />
                                      </Col>
                                      <Col md={6} sm={6}>
                                        <b>
                                          <h3 className="number">
                                            {overallAverage * 4} Points
                                          </h3>{" "}
                                        </b>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={3} sm={3}>
                                        <FontAwesomeIcon
                                          icon={faIdBadge}
                                          size="3x"
                                          style={{ color: "#990000" }}
                                        />
                                      </Col>
                                      <Col md={6} sm={6}>
                                        <b>
                                          <h3 className="level">
                                            {gradeCalc(
                                              parseInt(overallAverage * 4)
                                            )}{" "}
                                            Grade
                                          </h3>
                                        </b>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <p className="mt-4">
                                    Your Profile Level evaluates your overall
                                    technical mastery and success techical
                                    interviews. It updates after each technical
                                    round.
                                  </p>
                                </Row>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card className="home-tab">
                            <Card.Header>TECHNICAL SCORECARD</Card.Header>
                            <Card.Body>
                              <p className="skill">
                                We assess your DSA skills on the following
                                criteria, view scores for each category.
                              </p>
                              {skillDatas?.rows?.map((skills) => {
                                const keys = Object.keys(averages);
                                const _target = keys.find((ele) => {
                                  return ele == skills?.id;
                                });
                                const value = averages[_target] ?? 0 * 20;
                                return (
                                  <div className="m-1">
                                    <p>
                                      {skills?.name} ({value?.toFixed(2)}%)
                                    </p>
                                    <ProgressBar now={value} />
                                  </div>
                                );
                              })}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="profile" title="Profile">
                      <Card className="home-tab record personality">
                        <Card.Header className="text-uppercase">
                          Video Resume
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            {studentProfileDatas?.studentData?.video_resume ? (
                              <>
                                <h1>
                                  <strong>Video resume</strong>
                                </h1>
                                <video
                                  src={
                                    API_BASE +
                                    "/" +
                                    studentProfileDatas?.studentData
                                      ?.video_resume
                                  }
                                  ref={videoRef}
                                  width="600"
                                  height="300"
                                  autoplay={true}
                                  controls="controls"
                                  controlsList="nodownload"
                                />{" "}
                                :
                              </>
                            ) : (
                              <></>
                            )}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card className="home-tab record">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="d-flex justify-content-between me-3 w-100">
                                <h5>EDUCATION</h5>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              {EduDetails.map((edu) => {
                                return (
                                  <>
                                    <hr />
                                    <div className="education">
                                      <p className="degree">
                                        {edu?.education} -{" "}
                                        <span>{edu?.percentage}%</span>
                                      </p>
                                      <p className="school">
                                        {edu?.institution}{" "}
                                      </p>
                                      <p className="location">
                                        {dayjs(edu?.from_date).format(
                                          "DD/MM/YYYY"
                                        )}{" "}
                                        -{" "}
                                        {dayjs(edu?.to_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Card>

                      <Card className="home-tab record">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="d-flex justify-content-between me-3 w-100">
                                <h5>CERTIFICATE</h5>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              {CertDetails?.map((certificates) => {
                                return (
                                  <>
                                    <hr />
                                    <div className="certificate">
                                      <p className="name">
                                        {certificates?.name} -{" "}
                                        <span>{certificates?.grade} Grade</span>
                                      </p>
                                      <p className="name">
                                        {certificates?.description}
                                      </p>
                                      <p className="org">
                                        {certificates?.conducted_by}
                                      </p>
                                      <div className="date-link">
                                        <p className="date">
                                          {dayjs(
                                            certificates?.from_date
                                          ).format("DD/MM/YYYY")}
                                        </p>
                                        <p className="date">
                                          {dayjs(certificates?.to_date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Card>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Container>
          </section>
        </div>
        <div style={{ display: "none" }}>
          <Row>
            <Col md={7}>
              <div className="card">
                <div className="p-4 pb-0">
                  <h2 className="header">Jobs</h2>
                </div>
                <div className="text-center pb-3">
                  <img
                    src={NODATA}
                    alt=""
                    draggable={false}
                    className="w-100"
                  />
                  <p>You have not applied to any jobs yet</p>
                  <Button variant="primary">Apply Jobs</Button>
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="d-flex flex-column">
                <div className="card">
                  <div className="p-4 pb-0">
                    <h3 className="header">Matching Jobs</h3>
                  </div>
                  <div className="w-100 text-center">
                    <img
                      src={NOMATCH}
                      alt=""
                      draggable={false}
                      className="w-50"
                    />
                  </div>
                </div>
                <div className="card mt-2">
                  <div className="p-4 pb-0">
                    <h3 className="header">Loyalty Points</h3>
                  </div>
                  <div className="w-100">
                    <div className="card-body">
                      <h4 className="header">Recently Gained Point</h4>
                      <div>
                        <h3>
                          <FcRating size={24} /> 0
                        </h3>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <p>Total Points Gained</p>
                          <b>0</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* Reject Remarks */}
        <Modal
          show={reject_show}
          id="reject_remarks"
          dialogClassName="modal-100w"
          aria-labelledby="reject_remarks"
          tabIndex="-1"
          scrollable
          size="xl"
        >
          <div className="p-3 mt-2" style={{ overflow: "auto" }}>
            <>
              <form
                id="studentRejectForm"
                className={
                  validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                }
                onSubmit={(e) => {
                  e.preventDefault();
                  setValidated(true);
                  handleSubmit();
                }}
              >
                <h1>Reject Remarks</h1>
                <Row>
                  <Col md="6">
                    <h5>
                      <label htmlFor="">
                        Culture Catalyst Name:{" "}
                        <strong>
                          {studentProfileDatas?.studentData?.name}
                        </strong>
                      </label>
                    </h5>
                  </Col>
                  <Col md="6">
                    <h5>
                      <label htmlFor="">
                        Domain Name:{" "}
                        <strong>
                          {studentProfileDatas?.studentData?.departments?.name}
                        </strong>
                      </label>
                    </h5>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md="6">
                    <h5>
                      {" "}
                      <label htmlFor="">Remarks : </label>
                    </h5>
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file-text" />
                      <div className="filled form-group tooltip-end-top mt-3">
                        {/* <CsLineIcons icon="user" /> */}
                        <Form.Control
                          as="textarea"
                          name="reject_remarks"
                          placeholder="Reject Remarks"
                          value={values.reject_remarks}
                          className="is-invalid"
                          onChange={handleChange}
                        />
                        {errors.reject_remarks && touched.reject_remarks && (
                          <Form.Control.Feedback type="invalid">
                            {errors.reject_remarks}
                          </Form.Control.Feedback>
                        )}
                      </div>
                      {/* <Form.Control
                                        as="select"
                                        onChange={(e) => {
                                            setmentorId(e.target.value);
                                        }}
                                        value={mentorId}
                                        name="mentor_id"
                                        className="is-invalid"
                                        placeholder="Domain">
                                        <option>Select Mentor</option>
                                        {mentorListDatas &&
                                            mentorListDatas.map((fields) => {
                                                return (
                                                    <option value={fields.id}>{fields.name}</option>
                                                );
                                            })}
                                    </Form.Control> */}
                    </div>
                  </Col>
                </Row>
                <div className="text-end">
                  <LoadingButton
                    variant="contained"
                    loading={
                      ![API_STATUS.FULFILLED, "initial"].includes(
                        studentAcceptanceLoading
                      )
                    }
                    type="submit"
                  >
                    Reject
                  </LoadingButton>
                  <Button
                    variant="primary"
                    className="mx-1"
                    onClick={() => setRejectShow(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ViewStudent;
