import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import {
  ProfileStudent,
  StudentSelector,
  EditStudentData,
  clearData,
} from "../../store/reducer/StudentReducer";

import {
  settingsSelector,
  collegeList,
  signUpSelector,
} from "../../store/reducer/SignUpReducer";

import { API_STATUS } from "../../utils/Constants";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StudentProfile = () => {
  const title = "Profile";
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [dobValue, setDobValue] = useState();
  const [fieldValue, setFieldValue] = useState({});
  const [collegeValue, setCollgeValue] = useState();
  const [courseValue, setCourseValues] = useState();
  const [yearValue, setYearValues] = useState();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone_no: Yup.string().required("Phone is required"),
    roll_no: Yup.string().required("Roll Number is required"),
  });
  useEffect(() => {
    let formData = {};
    formData.query = "";
    formData.limit = 0;
    formData.page = 0;
    dispatch(collegeList(formData));
    dispatch(ProfileStudent({}));
  }, []);
  const {
    studentProfileDatas,
    studentEditLoading,
    studentLoading,
    errorMessage,
  } = useSelector(StudentSelector);

  const { collegeData, collegeLoading } = useSelector(signUpSelector);

  useEffect(() => {
    console.log(studentProfileDatas, "studentProfileDatas");
  }, [studentProfileDatas]);

  const [initialValues, setInitState] = useState({
    name: "",
    phone_no: "",
    dob: "",
    address: "",
    email: "",
    specialization: "",
    roll_no: "",
    college_code: "",
  });
  useEffect(() => {
    if (studentProfileDatas != null) {
      formik.setValues({
        name: studentProfileDatas?.studentData?.name || "",
        phone_no: studentProfileDatas?.studentData?.phone_no,
        email: studentProfileDatas?.studentData?.email || "",
        address: studentProfileDatas?.studentData?.address || "",
        specialization: studentProfileDatas?.studentData?.specialization || "",
        roll_no: studentProfileDatas?.studentData?.roll_no || "",
        college_code: studentProfileDatas?.studentData?.college_code || "",
      });
      const formattedDate = new Date(
        studentProfileDatas?.studentData?.dob || ""
      );
      setDobValue(formattedDate);
      console.log(studentProfileDatas, "studentProfileDatascollege");
      setCollgeValue(studentProfileDatas?.studentData?.college);
      setCourseValues(studentProfileDatas?.studentData?.course);
      setYearValues(studentProfileDatas?.studentData?.year);
    }
  }, [studentProfileDatas]);

  const onSubmit = (formData) => {
    formData.user_id = studentProfileDatas?.studentData?.user_id;
    formData.id = studentProfileDatas?.studentData?.id;
    formData.dob = dobValue;
    formData.college = collegeValue;
    formData.course = courseValue;
    formData.year = yearValue;
    dispatch(EditStudentData({ formData }));
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  console.log(values, "Sdfsdfsda");

  const setSelectedValues = (e, type) => {
    if (type == "college") setCollgeValue(e);
    else if (type == "course") setCourseValues(e);
    else setYearValues(e);
  };

  useEffect(() => {
    console.log(studentEditLoading, "studentEditLoading");
    if (studentEditLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Profile Updated Successfully!",
        icon: "success",
      });
      dispatch(clearData());
    }
    if (studentLoading === API_STATUS.REJECTED && errorMessage) {
      Swal.fire({
        title: errorMessage.errors,
        icon: "error",
      });
      setLoginerror(errorMessage.errors);
      dispatch(clearData());
    }
    if (studentEditLoading === API_STATUS.REJECTED && errorMessage) {
      Swal.fire({
        title: errorMessage.message,
        icon: "error",
      });
      setLoginerror(errorMessage.message);
      dispatch(clearData());
    }
  }, [studentLoading, studentEditLoading, errorMessage]);

  const handleDateChange = (date) => {
    console.log(date, "SETDOBDATE");
    setDobValue(date);
  };

  return (
    <>
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{title}</h1>
              <Breadcrumb className="breadcrumb-container d-inline-block">
                <Breadcrumb.Item key={`breadCrumb1`}></Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          {/* Title End */}

          {/* Standard Start */}
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              <form
                initialValues={initialValues}
                id="loginForm"
                className={
                  validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                }
                onSubmit={(e) => {
                  e.preventDefault();
                  setValidated(true);
                  handleSubmit();
                }}
              >
                <div className="mb-3 filled form-group tooltip-end-top">
                  {Loginerror && Loginerror.error && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {Loginerror.error}
                    </Form.Control.Feedback>
                  )}
                </div>
                {console.log(errors, "SDFSDFSDf")}
                <Row className="mb-3 g-3">
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="user" />
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name"
                        defaultValue={values.name}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.name && touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="email" />
                      <Form.Control
                        type="text"
                        name="email"
                        defaultValue={values.email}
                        onChange={handleChange}
                        className="is-invalid"
                        placeholder="Email"
                        readOnly
                      />
                      {errors.email && touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="mobile" />
                      <Form.Control
                        type="text"
                        name="phone_no"
                        defaultValue={values.phone_no}
                        onChange={handleChange}
                        className="is-invalid"
                        placeholder="Phone"
                      />
                      {errors.phone_no && touched.phone_no && (
                        <Form.Control.Feedback type="invalid">
                          {errors.phone_no}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="calendar" />
                      <Form.Group controlId="formDate">
                        <DatePicker
                          selected={dobValue}
                          placeholderText="Date of Birth"
                          onChange={handleDateChange}
                          dateFormat="dd/MM/yyyy" // Customize the date format
                          className="form-control" // Use Bootstrap styling
                        />
                      </Form.Group>
                      {errors.dob && touched.dob && (
                        <Form.Control.Feedback type="invalid">
                          {errors.dob}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="building-large" />
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          setSelectedValues(e.target.value, "college")
                        }
                        value={collegeValue}
                        className="is-invalid"
                        placeholder="College"
                      >
                        <option value="">Choose College</option>
                        {collegeData &&
                          collegeData?.rows?.map((collegeValue) => {
                            return (
                              <>
                                <option
                                  selected={
                                    collegeValue?.name == values.college
                                  }
                                  value={collegeValue?.name}
                                >
                                  {collegeValue?.name}
                                </option>
                              </>
                            );
                          })}
                      </Form.Control>
                      {errors.college && touched.college && (
                        <Form.Control.Feedback type="invalid">
                          {errors.college}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="pen" />
                      <Form.Control
                        type="text"
                        name="college_code"
                        onChange={handleChange}
                        value={values.college_code}
                        className="is-invalid"
                        placeholder="College Code"
                      />
                      {errors.college_code && touched.college_code && (
                        <Form.Control.Feedback type="invalid">
                          {errors.college_code}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="graduation" />
                      <Form.Control
                        as="select"
                        value={courseValue}
                        onChange={(e) =>
                          setSelectedValues(e.target.value, "course")
                        }
                        className="is-invalid"
                        placeholder="Course"
                      >
                        <option value="">Choose Course</option>
                        <option value="Arts and Science">
                          Arts and Science
                        </option>
                        <option value="BE">B.E</option>
                        <option value="B.Tech">B.Tech</option>
                        <option value="MBA">MBA</option>
                      </Form.Control>
                      {errors.course && touched.course && (
                        <Form.Control.Feedback type="invalid">
                          {errors.course}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="diploma" />
                      <Form.Control
                        type="text"
                        name="specialization"
                        placeholder="Specialization"
                        value={values.specialization}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.specialization && touched.specialization && (
                        <Form.Control.Feedback type="invalid">
                          {errors.specialization}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="board-2" />
                      <Form.Control
                        as="select"
                        value={yearValue}
                        onChange={(e) =>
                          setSelectedValues(e.target.value, "year")
                        }
                        className="is-invalid"
                        placeholder="Year"
                      >
                        <option value="">Choose Year</option>
                        <option value="1st Year">1st Year</option>
                        <option value="2nd Year">2nd Year</option>
                        <option value="3rd Year">3rd Year</option>
                        <option value="4th Year">4th Year</option>
                      </Form.Control>
                      {errors.year && touched.year && (
                        <Form.Control.Feedback type="invalid">
                          {errors.year}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="form" />
                      <Form.Control
                        type="text"
                        name="roll_no"
                        placeholder="Roll Number"
                        value={values.roll_no}
                        className="is-invalid"
                        onChange={handleChange}
                      />
                      {errors.roll_no && touched.roll_no && (
                        <Form.Control.Feedback type="invalid">
                          {errors.roll_no}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="text-end">
                  <Button size="lg" type="submit" className="">
                    Submit
                  </Button>
                </div>
              </form>
            </Card>
          </section>

          {/* Positions End */}
        </Col>
      </Row>
    </>
  );
};

export default StudentProfile;
