import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  student_profile,
  profile_update_student,
  student_list,
  student_acceptance_status,
  student_by_id,
  getProjectList,
  postProjectPlan,
  postProjectEOD,
  getProjectFeedbackList,
  overall_feedback,
  studentEdu,
  getEdu,
  studentCertificates,
  getCert,
  uploadVR,
  overallSprintfeedback,
  registersession,
  registeredsession,
  get_student_data,
  update_sessions,
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "student";

const initialState = {
  loading: "initial",
  errorMessage: "",
  studenterrorMessage: "",
  studentAcceptanceMessage: "",
  studentProfileDatas: null,
  studentLoading: "initial",
  studentEditLoading: "initial",
  studentEduLoad: "initial",
  studentCertLoad: "initial",
  GetEduDataLoad: "initial",
  GetCertDataLoad: "initial",
  studentAcceptanceLoading: "initial",
  updateVideoResumeLoad: "initial",
  fitmentListLoading: "initial",
  createProjectPlanLoad: "initial",
  createProjectEODLoad: "initial",
  MentorProjectFeedbackLoad: "initial",
  overallFeedbackLoad: "initial",
  OverallSprintFeedbackLoad: "initial",
  RegisteredSessionListLoad: "initial",
  RegisterSessionLoad: "initial",
  studentDetailsDatas: null,
  studentDatas: [],
  fitmentData: [],
  fitmentActivity: [],
  mentorProjectFeedback: [],
  overallFeedbackData: [],
  EduDetails: [],
  CertDetails: [],
  OverallSprintFeedbackData: [],
  registeredSessionData: [],
  count: 0,
};

export const ProfileStudent = createAsyncThunk(
  `${namespace}/ProfileStudent`,
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      console.log(Object.keys(formData), "ProfileStudent");
      if (Object.keys(formData).length > 0) {
        const { data } = await student_profile(formData);
        return data;
      } else {
        const { data } = await get_student_data(formData);
        return data;
      }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const EditStudentData = createAsyncThunk(
  `${namespace}/EditStudentData`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "EditStudentData");
      const { data } = await profile_update_student(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const StudentListData = createAsyncThunk(
  `${namespace}/StudentListData`,
  async ({ query, limit, page, org_id }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await student_list(query, limit, page, org_id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const StudentAcceptanceUpdate = createAsyncThunk(
  `${namespace}/StudentAcceptanceUpdate`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "REDUCERFE");
      const { data } = await student_acceptance_status(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const StudentById = createAsyncThunk(
  `${namespace}/StudentById`,
  async ({ studentParam }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await student_by_id(studentParam);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createProjectPlan = createAsyncThunk(
  `${namespace}/createProjectPlan`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await postProjectPlan(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createProjectEOD = createAsyncThunk(
  `${namespace}/createProjectEOD`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await postProjectEOD(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const fitmentProjectScheduler = createAsyncThunk(
  `${namespace}/fitmentProjectScheduler`,
  async ({ payload }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getProjectList(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const StudentEduScheduler = createAsyncThunk(
  `${namespace}/StudentEduScheduler`,
  async ({ payload }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await studentEdu(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const StudentCertScheduler = createAsyncThunk(
  `${namespace}/StudentCertScheduler`,
  async ({ payload }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await studentCertificates(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const MentorProjectFeedback = createAsyncThunk(
  `${namespace}/MentorProjectFeedback`,
  async (
    { page, limit, query, sortby, order, status, student_id, project_id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("MentorUpdate");
      const { data } = await getProjectFeedbackList(
        page,
        limit,
        query,
        sortby,
        order,
        status,
        student_id,
        project_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const GetEduData = createAsyncThunk(
  `${namespace}/GetEduData`,
  async ({ student_id }, { rejectWithValue, dispatch }) => {
    try {
      console.log("MentorUpdate");
      const { data } = await getEdu(student_id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const GetCertData = createAsyncThunk(
  `${namespace}/GetCertData`,
  async ({ student_id }, { rejectWithValue, dispatch }) => {
    try {
      console.log("MentorUpdate");
      const { data } = await getCert(student_id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateVideoResume = createAsyncThunk(
  `${namespace}/updateVideoResume`,
  async ({ payload }, { rejectWithValue, dispatch }) => {
    try {
      console.log("MentorUpdate");
      const { data } = await uploadVR(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const OverallFeedback = createAsyncThunk(
  `${namespace}/OverallFeedback`,
  async (
    { page, limit, query, sortby, order, status, student_id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log(student_id, "MentorUpdate");
      const { data } = await overall_feedback(
        page,
        limit,
        query,
        sortby,
        order,
        status,
        student_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const OverallSprintFeedback = createAsyncThunk(
  `${namespace}/OverallSprintFeedback`,
  async (
    { page, limit, query, sortby, order, status, student_id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log(student_id, "MentorUpdate");
      const { data } = await overallSprintfeedback(
        page,
        limit,
        query,
        sortby,
        order,
        status,
        student_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const RegisterSession = createAsyncThunk(
  `${namespace}/RegisterSession`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await registersession(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const RegisteredSessionList = createAsyncThunk(
  `${namespace}/RegisteredSessionList`,
  async ({ student_id }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await registeredsession(student_id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateSessions = createAsyncThunk(
  `${namespace}/updateSessions`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {      
      console.log(formData,"RESUDS")
      const { data } = await update_sessions(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const studentSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearStudentData: () => {
      return initialState;
    },
    clearData: (state) => {
      state.studentLoading = "initial";
      state.studentEditLoading = "initial";
      state.studentAcceptanceLoading = "initial";
      state.updateVideoResumeLoad = "initial";
      state.fitmentListLoading = "initial";
      state.createProjectPlanLoad = "initial";
      state.createProjectEODLoad = "initial";
      state.MentorProjectFeedbackLoad = "initial";
      state.studentEduLoad = "initial";
      state.studentCertLoad = "initial";
      state.GetEduDataLoad = "initial";
      state.GetCertDataLoad = "initial";
      state.OverallSprintFeedbackLoad = "initial";
      state.RegisterSessionLoad = "initial";
      state.RegisteredSessionListLoad = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(ProfileStudent.pending, (state) => {
      state.studentLoading = API_STATUS.PENDING;
    });
    builder.addCase(ProfileStudent.fulfilled, (state, { payload }) => {
      state.studentLoading = API_STATUS.FULFILLED;
      state.studentProfileDatas = payload?.data;
      console.log(payload, "JUDJDJ");
    });
    builder.addCase(ProfileStudent.rejected, (state, action) => {
      state.studentLoading = API_STATUS.REJECTED;
      console.log(action.payload, "studentProfile");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(EditStudentData.pending, (state) => {
      state.studentEditLoading = API_STATUS.PENDING;
    });
    builder.addCase(EditStudentData.fulfilled, (state, { payload }) => {
      state.studentEditLoading = API_STATUS.FULFILLED;
      console.log(state.studentEditLoading, "sastudentEditLoading");
    });
    builder.addCase(EditStudentData.rejected, (state, action) => {
      state.studentEditLoading = API_STATUS.REJECTED;
      console.log(action.payload, "EditStudentData");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(RegisterSession.pending, (state) => {
      state.RegisterSessionLoad = API_STATUS.PENDING;
    });
    builder.addCase(RegisterSession.fulfilled, (state, { payload }) => {
      state.RegisterSessionLoad = API_STATUS.FULFILLED;
      console.log(state.RegisterSessionLoad, "saRegisterSessionLoad");
    });
    builder.addCase(RegisterSession.rejected, (state, action) => {
      state.RegisterSessionLoad = API_STATUS.REJECTED;
      console.log(action.payload, "RegisterSession");
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(RegisteredSessionList.pending, (state) => {
      state.RegisteredSessionListLoad = API_STATUS.PENDING;
    });
    builder.addCase(RegisteredSessionList.fulfilled, (state, { payload }) => {
      state.RegisteredSessionListLoad = API_STATUS.FULFILLED;
      console.log(payload, "saRegisteredSessionListLoad");
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.registeredSessionData = data?.data;
    });
    builder.addCase(RegisteredSessionList.rejected, (state, action) => {
      state.RegisteredSessionListLoad = API_STATUS.REJECTED;
      console.log(action.payload, "RegisteredSessionList");
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(StudentEduScheduler.pending, (state) => {
      state.studentEduLoad = API_STATUS.PENDING;
    });
    builder.addCase(StudentEduScheduler.fulfilled, (state, { payload }) => {
      state.studentEduLoad = API_STATUS.FULFILLED;
      console.log(payload, "StudentEduScheduler");
    });
    builder.addCase(StudentEduScheduler.rejected, (state, action) => {
      state.studentEduLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(StudentCertScheduler.pending, (state) => {
      state.studentCertLoad = API_STATUS.PENDING;
    });
    builder.addCase(StudentCertScheduler.fulfilled, (state, { payload }) => {
      state.studentCertLoad = API_STATUS.FULFILLED;
      console.log(payload, "StudentCertScheduler");
    });
    builder.addCase(StudentCertScheduler.rejected, (state, action) => {
      state.studentCertLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(GetEduData.pending, (state) => {
      state.GetEduDataLoad = API_STATUS.PENDING;
    });
    builder.addCase(GetEduData.fulfilled, (state, { payload }) => {
      state.GetEduDataLoad = API_STATUS.FULFILLED;
      state.EduDetails = payload?.data;
      console.log(payload, "GetEduData");
    });
    builder.addCase(GetEduData.rejected, (state, action) => {
      state.GetEduDataLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(GetCertData.pending, (state) => {
      state.GetCertDataLoad = API_STATUS.PENDING;
    });
    builder.addCase(GetCertData.fulfilled, (state, { payload }) => {
      state.GetCertDataLoad = API_STATUS.FULFILLED;
      state.CertDetails = payload?.data;
      console.log(payload, "GetCertData");
    });
    builder.addCase(GetCertData.rejected, (state, action) => {
      state.GetCertDataLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(updateVideoResume.pending, (state) => {
      state.updateVideoResumeLoad = API_STATUS.PENDING;
    });
    builder.addCase(updateVideoResume.fulfilled, (state, { payload }) => {
      state.updateVideoResumeLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(updateVideoResume.rejected, (state, action) => {
      state.updateVideoResumeLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(MentorProjectFeedback.pending, (state) => {
      state.MentorProjectFeedbackLoad = API_STATUS.PENDING;
    });
    builder.addCase(MentorProjectFeedback.fulfilled, (state, { payload }) => {
      state.MentorProjectFeedbackLoad = API_STATUS.FULFILLED;
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.mentorProjectFeedback = data?.data;
    });
    builder.addCase(MentorProjectFeedback.rejected, (state, action) => {
      state.MentorProjectFeedbackLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(StudentListData.pending, (state) => {
      state.studentLoading = API_STATUS.PENDING;
    });
    builder.addCase(StudentListData.fulfilled, (state, { payload }) => {
      state.studentLoading = API_STATUS.FULFILLED;
      state.studentDatas = payload;
      console.log(state.studentDatas, "JUDJDJ");
    });
    builder.addCase(StudentListData.rejected, (state, action) => {
      state.studentLoading = API_STATUS.REJECTED;
      console.log(action.payload, "studentProfile");
      state.studenterrorMessage = action?.payload?.data;
    });
    builder.addCase(fitmentProjectScheduler.pending, (state) => {
      state.fitmentListLoading = API_STATUS.PENDING;
    });
    builder.addCase(fitmentProjectScheduler.fulfilled, (state, { payload }) => {
      state.fitmentListLoading = API_STATUS.FULFILLED;
      console.log(payload, "payload fitment scheduler");
      state.fitmentData = payload;
      state.fitmentActivity =
        payload?.data && payload?.data[0]?.project_fitment?.project_activity;
    });
    builder.addCase(fitmentProjectScheduler.rejected, (state, action) => {
      state.fitmentListLoading = API_STATUS.REJECTED;
    });
    builder.addCase(createProjectPlan.pending, (state) => {
      state.createProjectPlanLoad = API_STATUS.PENDING;
    });
    builder.addCase(createProjectPlan.fulfilled, (state, { payload }) => {
      state.createProjectPlanLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(createProjectPlan.rejected, (state, action) => {
      state.createProjectPlanLoad = API_STATUS.REJECTED;
    });

    builder.addCase(createProjectEOD.pending, (state) => {
      state.createProjectEODLoad = API_STATUS.PENDING;
    });
    builder.addCase(createProjectEOD.fulfilled, (state, { payload }) => {
      state.createProjectEODLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(createProjectEOD.rejected, (state, action) => {
      state.createProjectEODLoad = API_STATUS.REJECTED;
    });
    builder.addCase(StudentAcceptanceUpdate.pending, (state) => {
      state.studentAcceptanceLoading = API_STATUS.PENDING;
    });
    builder.addCase(StudentAcceptanceUpdate.fulfilled, (state, { payload }) => {
      state.studentAcceptanceLoading = API_STATUS.FULFILLED;
      console.log(payload, "UPDATESTUS");
      state.studentAcceptanceMessage = payload;
    });
    builder.addCase(StudentAcceptanceUpdate.rejected, (state, action) => {
      state.studentAcceptanceLoading = API_STATUS.REJECTED;
      console.log(action.payload, "studentProfile");
      state.studenterrorMessage = action?.payload?.message;
    });
    builder.addCase(StudentById.pending, (state) => {
      state.studentLoading = API_STATUS.PENDING;
    });
    builder.addCase(StudentById.fulfilled, (state, { payload }) => {
      state.studentLoading = API_STATUS.FULFILLED;
      state.studentDetailsDatas = payload?.data;
      console.log(payload?.data, "JUDJDJ");
    });
    builder.addCase(StudentById.rejected, (state, action) => {
      state.studentLoading = API_STATUS.REJECTED;
      console.log(action.payload, "studentProfile");
      state.studenterrorMessage = action?.payload?.data;
    });
    builder.addCase(OverallFeedback.pending, (state) => {
      state.overallFeedbackLoad = API_STATUS.PENDING;
    });
    builder.addCase(OverallFeedback.fulfilled, (state, { payload }) => {
      state.MentorProjectFeedbackLoad = API_STATUS.FULFILLED;
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.overallFeedbackData = data;
    });
    builder.addCase(OverallFeedback.rejected, (state, action) => {
      state.overallFeedbackLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(OverallSprintFeedback.pending, (state) => {
      state.OverallSprintFeedbackLoad = API_STATUS.PENDING;
    });
    builder.addCase(OverallSprintFeedback.fulfilled, (state, { payload }) => {
      state.OverallSprintFeedbackLoad = API_STATUS.FULFILLED;
      console.log(payload, "payloadpayload 11");
      const data = JSON.parse(
        EncryptDecrypt.decryptdata(payload, "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)")
      );
      state.OverallSprintFeedbackData = data;
    });
    builder.addCase(OverallSprintFeedback.rejected, (state, action) => {
      state.OverallSprintFeedbackLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearStudentData, clearData } = studentSlice.actions;

export const StudentSelector = (state) => state.student;

export default studentSlice.reducer;
