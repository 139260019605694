import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import {
    getMentor,
    UpdateProfileData,
    hiringmentorSelector,
    clearData,
} from "../store/reducer/HiringMentorReducer";
import { departmentLists, departmentUpdates, placementSelector, testLists } from '../store/reducer/PlacementReducer';

import { API_STATUS } from "../utils/Constants";
import Swal from "sweetalert2";


const MentorProfile = () => {
    const title = "Mentor Profile";
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [Loginerror, setLoginerror] = useState(false);
    const [filedValue, setFieldValue] = useState({});
    const [departmentID, setDepartmentID] = useState("");
    const [isClicked, setIsClicked] = useState(false)
    const [errorMsg, seterrorMsg] = useState({
        doj: false,
    });
    useEffect(() => {
        let departmentQuery = {};
        departmentQuery.page = 0;
        departmentQuery.limit = 0;
        departmentQuery.query = "";        
        dispatch(departmentLists(departmentQuery ));        
        dispatch(getMentor({}));
    }, [])

    const { hiringMentorProfileDatas, mentorLoading,mentorProfileUpdateLoading, errorMessage } = useSelector(hiringmentorSelector);

    const {
        departmentData,
    } = useSelector(placementSelector);

    useEffect(() => {
        console.log(hiringMentorProfileDatas, "hiringMentorProfileDatas");
    }, [hiringMentorProfileDatas])


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email().required("Email is required"),
        avaiablity: Yup.number().required("Availability Hours is required"),
    })
    const initialValues = { name: "", phone_no: "", email: "", avaiablity: "" };


    useEffect(() => {
        formik.setValues(
            {
                name: hiringMentorProfileDatas?.name || '', phone_no: hiringMentorProfileDatas?.phone_no, email: hiringMentorProfileDatas?.email || '', avaiablity: hiringMentorProfileDatas?.avaiablity || ''
            })
        const JobValue = (hiringMentorProfileDatas?.domain)
        setDepartmentID(JobValue)

    }, [hiringMentorProfileDatas]);

    const fileValue = (event) => {
        console.log(event.currentTarget.files, "event.currentTarget.files[0]");
        setFieldValue(event.currentTarget.files);
    }

    const onSubmit = (formData) => {
        formData.id = hiringMentorProfileDatas.id;
        formData.domain = departmentID;
        dispatch(UpdateProfileData({ formData }));
    };


    const formik = useFormik({ initialValues, validationSchema, onSubmit });
    const { handleSubmit, handleChange, values, touched, errors } = formik;
    console.log(values, "Sdfsdfsda");

    useEffect(() => {
        console.log(mentorProfileUpdateLoading, "mentorProfileUpdateLoading");
        if (mentorProfileUpdateLoading === API_STATUS.FULFILLED) {
            Swal.fire({
                title: 'Profile Updated Successfully!',
                icon: 'success'
            });
            dispatch(clearData())
        }
        if (mentorProfileUpdateLoading === API_STATUS.REJECTED && errorMessage) {
            Swal.fire({
                title: errorMessage.error,
                icon: 'error'
            });
            setLoginerror(errorMessage.error);
            dispatch(clearData())
        }
    }, [mentorProfileUpdateLoading, errorMessage]);

    return (
        <>
            <Row>
                <Col>
                    {/* Title Start */}
                    <section className="scroll-section" id="title">
                        <div className="page-title-container">
                            <h1 className="mb-0 pb-0 display-4">{title}</h1>
                            <Breadcrumb className="breadcrumb-container d-inline-block">
                                <Breadcrumb.Item key={`breadCrumb1`}>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        {/* <Card className="mb-5" body>
                            <Card.Text>{description}</Card.Text>
                        </Card> */}
                    </section>
                    {/* Title End */}

                    {/* Standard Start */}
                    <section className="scroll-section" id="standard">
                        {/* <h2 className="small-title">Standard</h2> */}
                        <Card body className="mb-5">
                            <form
                                id="mentorEditForm"
                                className={
                                    validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                                }
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setValidated(true);
                                    handleSubmit();
                                }}
                            >
                                <div className="mb-3 filled form-group tooltip-end-top">
                                    {Loginerror && Loginerror.error && (
                                        <Form.Control.Feedback
                                            type="invalid"
                                            style={{ display: "block" }}
                                        >
                                            {Loginerror.error}
                                        </Form.Control.Feedback>
                                    )}
                                </div>
                                <Row className="mb-3 g-3">
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="user" />
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder="Mentor Name"
                                                value={values.name}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.name && touched.name && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="file-text" />
                                            <Form.Control
                                                as="select"
                                                onChange={(e) => {
                                                    setDepartmentID(e.target.value);
                                                }}
                                                value={departmentID}
                                                className="is-invalid"
                                                placeholder="Domain">
                                                {
                                                    !isClicked ? <option>Select Domain</option> : ""
                                                }
                                                {departmentData &&
                                                    departmentData?.rows?.map((fields) => {
                                                        return (
                                                            <option value={fields.id}>{fields.name}</option>
                                                        );
                                                    })}
                                            </Form.Control>
                                            {errorMsg?.doj &&
                                                (
                                                    <p className='text-danger'>Domain is required</p>
                                                )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="email" />
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                onChange={handleChange}
                                                value={values.email}
                                                disabled
                                                className="is-invalid"
                                                placeholder="Email"
                                            />
                                            {errors.email && touched.email && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <CsLineIcons icon="clock" />
                                            <Form.Control
                                                type="text"
                                                name="avaiablity"
                                                placeholder="Availability Hours"
                                                value={values.avaiablity}
                                                className="is-invalid"
                                                onChange={handleChange}
                                            />
                                            {errors.avaiablity && touched.avaiablity && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.avaiablity}
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-end">
                                    <Button size="lg" type="submit" onClick={() => {
                                        if (!departmentID) {
                                            seterrorMsg({
                                                doj: !departmentID
                                            });
                                            setValidated(false)
                                        }
                                    }}>
                                        Update
                                    </Button>
                                </div>
                            </form>
                        </Card>
                    </section>

                    {/* Positions End */}
                </Col>
            </Row>
        </>
    );
};

export default MentorProfile;
