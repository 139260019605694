import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import { certificateData ,certificateUploadData } from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "certificate";

const initialState = {
  certificateerrorMessage: "",
  certificateDataLoading: "initial",
  certificateUploadLoading:"initial",
  certificateData: [],
  
};

export const certificateView = createAsyncThunk(
  `${namespace}/certificateView`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "certificateView");
      const { data } = await certificateData(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const certificateUpload = createAsyncThunk(
  `${namespace}/certificateUpload`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "certificateUpload");
      const { data } = await certificateUploadData(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
)


const CertificateSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearDatas: () => {
      return initialState;
    },
    certificateclearData: (state) => {
      state.certificateDataLoading = "initial";

      state.certificateUploadLoading="initial"
    },
  },

  extraReducers: (builder) => {
    builder.addCase(certificateView.pending, (state) => {
      state.certificateDataLoading = API_STATUS.PENDING;
    });
    builder.addCase(certificateView.fulfilled, (state, { payload }) => {
      state.certificateDataLoading = API_STATUS.FULFILLED;
      const payloaddata = JSON.parse(
        EncryptDecrypt.decryptdata(
          payload?.datas,
          "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)"
        )
      );
      state.certificateData = payloaddata?.studentData;
      console.log(state.certificateData, "certificateData1");
    });
    builder.addCase(certificateView.rejected, (state, action) => {
      state.certificateDataLoading = API_STATUS.REJECTED;
      console.log(action.payload, "certificateDataError");
      state.certificateerrorMessage = action?.payload?.data;
    });

    //certificate upload
    builder.addCase(certificateUpload.pending, (state)=>{
      state.certificateUploadLoading=API_STATUS.PENDING
    })
    builder.addCase(certificateUpload.fulfilled,(state, {payload})=>{
      state.certificateUploadLoading=API_STATUS.FULFILLED;
     })
     builder.addCase(certificateUpload.rejected, (state, action)=>{
      state.certificateUploadLoading=API_STATUS.REJECTED;
     state.certificateerrorMessage=action?.payload?.data;
     })
  },
});

export const { certificateclearData, clearDatas } = CertificateSlice.actions;

export const CertificateSelector = (state) => state.certificate;

export default CertificateSlice.reducer;
