import { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API_STATUS, COURSE_STATUS } from "../../utils/Constants";
import { TablePagination } from "@mui/material";
import {
  ProfileStudent,
  StudentSelector,
  fitmentProjectScheduler,
} from "../../store/reducer/StudentReducer";

const Projects = () => {
  const name = localStorage.getItem("username");
  const [course, setCourse] = useState({});
  const [isNew, setIsNew] = useState();
  const [navSprint, setNavSprint] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hiring_partner_id } = useParams();
  const { studentProfileDatas, fitmentData } = useSelector(StudentSelector);
  useEffect(() => {
    let query = studentProfileDatas?.studentData?.hiring_partner_student?.find(
      (ele) => {
        return ele?.org_id == hiring_partner_id;
      }
    );
    let payload = {
      mentor_id: query?.mentor_id,
      student_id: studentProfileDatas?.studentData?.id,
      project_id: "",
    };
    dispatch(fitmentProjectScheduler({ payload }));
  }, [studentProfileDatas]);
  useEffect(() => {
    console.log(fitmentData, "fitmentData");
  }, [fitmentData]);

  useEffect(() => {
    dispatch(ProfileStudent({}));
  }, []);

  const handleSprintNavigate = (projectId, mentorId) => {
    // let payload = {
    //     mentor_id: mentorId,
    //     student_id: studentProfileDatas?.studentData?.id,
    //     project_id: projectId
    // }
    // dispatch(fitmentProjectScheduler({ payload }))
    
    navigate(`${projectId}`);
  };

  return (
    <div className="container-fluid mt-4">
      <h1>Hi {name}, </h1>
      <div>
        <Card>
          <div className="container">
            <Row className="mt-5">
              <Col md={8} className="pe-0 ps-5">
                <h1>
                  <strong>{course?.name}</strong>
                </h1>
              </Col>
              <Col md={4} className="text-end pe-5 ps-0"></Col>
            </Row>
            <div className="container-fluid">
              <hr />
              <h3>
                <strong>Total Projects({fitmentData?.data?.length})</strong>
              </h3>
              <div className="p-3">
                <Accordion defaultActiveKey="0">
                  {fitmentData?.data?.map((sprints, i) => {
                    const sprintStatus = "Show";
                    return (
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header className="w=100">
                          <div className="d-flex justify-content-between w-100">
                            <div>
                              <h6>{sprints?.project_fitment?.title}</h6>
                            </div>
                            <div className="d-flex align-items-center">
                              <Button
                                className="me-5"
                                onClick={() => {
                                  handleSprintNavigate(
                                    sprints?.project_fitment?.id,
                                    sprints?.mentor_id
                                  );
                                }}
                              >
                                <div style={{ fontSize: "15px" }}>
                                  {sprintStatus}
                                </div>
                              </Button>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <p>{sprints?.project_fitment?.description}</p>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Projects;
