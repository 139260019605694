import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createProjectValidator } from "../../validators/Validators";
import {
  departmentLists,
  departmentUpdates,
  placementSelector,
  testLists,
  questionCreate,
  questionUpdate,
  questionById,
  clearData,
} from "../../store/reducer/PlacementReducer";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  API_STATUS,
  QUESTION_GROUP,
  QUESTION_TYPE,
} from "../../utils/Constants";
import Feedback from "react-bootstrap/esm/Feedback";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";

const QuestionManagement = () => {
  const { domain_id, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobTypeValue, setjobTypeValues] = useState();
  const [salaryLable, setsalaryLable] = useState("Salary");
  const [validated, setValidated] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [departmentID, setDepartmentID] = useState(domain_id);
  const [isClicked, setIsClicked] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const [questions, setQuestions] = useState([
    { question: "", order_no: "", options: "", type: 0, group: 0 },
  ]);
  const [errorMsg, seterrorMsg] = useState({
    domainerror: false,
    grouperror: false,
    typeerror: false,
  });

  const [initialValues, setInitialValues] = useState({
    question: "",
    order_no: "",
    options: "",
  });

  const SubmitForm = () => {
    let formData = {};
    formData.questions = questions;
    formData.department_id = departmentID;
    setSubmit(true);
    if (questions[0]?.question != "") {
      if (id) {
        dispatch(questionUpdate({ id, formData }));
      } else {
        dispatch(questionCreate({ formData }));
      }
    }
  };

  const add_questions = () => {
    questions.push({ question: "", order_no: "", options: "", id: "" });
    setQuestions([...questions]);
  };

  useEffect(() => {
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    dispatch(departmentLists(departmentQuery));
  }, []);

  const {
    departmentData,
    questionsByDepartmentLoading,
    updatequestionLoading,
    questionByIdData,
    viewquestionsLoading,
    createquestionsLoading,
  } = useSelector(placementSelector);

  const formik = useFormik({
    initialValues,
    validationSchema: createProjectValidator,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  useEffect(() => {
    console.log(questions, "activities");
    console.log(errors, "errors");
  }, [questions, errors]);

  useEffect(() => {
    dispatch(questionById({ id }));
  }, [id]);

  useEffect(() => {
    if (id && questionByIdData != null) {
      setQuestions([
        {
          question: questionByIdData?.question,
          order_no: questionByIdData?.order_no,
          options: questionByIdData?.options,
          type: questionByIdData?.type,
          group: questionByIdData?.question_group_id,
        },
      ]);
    }

    dispatch(clearData());
  }, [questionByIdData]);

  const handleActivityChange = (e, row, type) => {
    console.log(row, e.target.value);
    if (type == "question") {
      questions[row].question = e.target.value;
    }
    if (type == "order_no") {
      questions[row].order_no = e.target.value;
    }
    if (type == "options") {
      questions[row].options = e.target.value;
    }
    if (type == "group") {
      questions[row].group = e.target.value;
    }
    if (type == "type") {
      questions[row].type = e.target.value;
    }
    setQuestions([...questions]);
  };
  useEffect(() => {
    if (createquestionsLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Question Created Successfully!",
        icon: "success",
      });
      dispatch(clearData());
      navigate("/domain/sprint/" + domain_id);
    }
    if (updatequestionLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Question Updated Successfully!",
        icon: "success",
      });
      dispatch(clearData());
      navigate("/domain/sprint/" + domain_id);
    }
  }, [createquestionsLoading, updatequestionLoading]);
  return (
    <div className="container">
      <section className="scroll-section" id="title">
        <div className="page-title-container">
          <h1 className="mb-0 pb-0 display-4">
            {id ? "Edit Questions" : "Create Questions"}
          </h1>
        </div>
        <Link to={`/domain/sprint/${domain_id}`}>
          <div className="text-end mb-2">
            <Button variant="success" type="submit">
              Back
            </Button>
          </div>
        </Link>
      </section>
      <Row>
        <Col>
          <section className="scroll-section" id="standard">
            {/* <h2 className="small-title">Standard</h2> */}
            <Card body className="mb-5">
              {/* List Header Start */}
              <form
                id="questionAddForm"
                className={
                  validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                }
              >
                <div className="mb-3 filled form-group tooltip-end-top">
                  {Loginerror && Loginerror.error && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {Loginerror.error}
                    </Form.Control.Feedback>
                  )}
                </div>
                <Row>
                  <Col md="6">
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <CsLineIcons icon="file-text" />
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setDepartmentID(e.target.value);
                        }}
                        value={departmentID}
                        className="is-invalid"
                        placeholder="Domain"
                        disabled
                      >
                        {!isClicked ? <option>Select Domain</option> : ""}
                        {departmentData &&
                          departmentData?.rows?.map((fields) => {
                            return (
                              <option value={fields.id}>{fields.name}</option>
                            );
                          })}
                      </Form.Control>
                      {errorMsg?.domainerror && (
                        <p className="text-danger">Domain is required</p>
                      )}
                    </div>
                  </Col>
                  {id != "" ? (
                    <></>
                  ) : (
                    <>
                      <Col md="6" className="text-end">
                        <Button variant="success" onClick={add_questions}>
                          Add Questions
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
                {questions &&
                  questions.map((questionData, index) => {
                    return (
                      <>
                        <Row className="mt-2">
                          <Col md="12">
                            <div className="filled form-group tooltip-end-top">
                              {/* <CsLineIcons icon="user" /> */}
                              <Form.Control
                                as="textarea"
                                name="question"
                                placeholder="Question"
                                value={questionData.question}
                                className="is-invalid"
                                onChange={(e) => {
                                  handleActivityChange(e, index, "question");
                                }}
                              />
                              {submit && questionData.question == "" && (
                                <Form.Control.Feedback type="invalid">
                                  Question is required
                                </Form.Control.Feedback>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3 g-3 mt-2">
                          <Col md="6">
                            <div className="mb-3 filled form-group tooltip-end-top">
                              <CsLineIcons icon="file-text" />
                              <Form.Control
                                as="select"
                                onChange={(e) => {
                                  handleActivityChange(e, index, "group");
                                }}
                                value={questionData.group}
                                className="is-invalid"
                                placeholder="Group"
                              >
                                <option value="">Select Group</option>
                                {_.toArray(QUESTION_GROUP) &&
                                  _.toArray(QUESTION_GROUP).map(
                                    (fields, key) => {
                                      return (
                                        <option value={key + 1}>
                                          {fields}
                                        </option>
                                      );
                                    }
                                  )}
                              </Form.Control>
                              {submit && questionData.group == 0 && (
                                <Form.Control.Feedback type="invalid">
                                  Group is required
                                </Form.Control.Feedback>
                              )}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3 filled form-group tooltip-end-top">
                              <CsLineIcons icon="file-text" />
                              <Form.Control
                                as="select"
                                onChange={(e) => {
                                  handleActivityChange(e, index, "type");
                                }}
                                value={questionData.type}
                                className="is-invalid"
                                placeholder="Group"
                              >
                                <option value="">Select Type</option>
                                {_.toArray(QUESTION_TYPE) &&
                                  _.toArray(QUESTION_TYPE).map(
                                    (fields, key) => {
                                      return (
                                        <option value={key + 1}>
                                          {" "}
                                          {fields}
                                        </option>
                                      );
                                    }
                                  )}
                              </Form.Control>
                              {submit && questionData.type == 0 && (
                                <Form.Control.Feedback type="invalid">
                                  Type is required
                                </Form.Control.Feedback>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <div className="mb-3 filled form-group tooltip-end-top">
                              <CsLineIcons icon="file" />
                              <Form.Control
                                type="text"
                                name="options"
                                placeholder="Options"
                                value={questionData.options}
                                className="is-invalid"
                                onChange={(e) => {
                                  handleActivityChange(e, index, "options");
                                }}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3 filled form-group tooltip-end-top">
                              <CsLineIcons icon="file" />
                              <Form.Control
                                type="text"
                                name="order_no"
                                placeholder="Order No"
                                value={questionData.order_no}
                                className="is-invalid"
                                onChange={(e) => {
                                  handleActivityChange(e, index, "order_no");
                                }}
                              />
                              {submit && questionData.order_no == "" && (
                                <Form.Control.Feedback type="invalid">
                                  Order No is required
                                </Form.Control.Feedback>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <hr />
                      </>
                    );
                  })}

                <div className="text-end mt-2">
                  <LoadingButton
                    variant="contained"
                    loading={
                      ![API_STATUS.FULFILLED, "initial"].includes(
                        createquestionsLoading || updatequestionLoading
                      )
                    }
                    type="button"
                    onClick={SubmitForm}
                  >
                    {id ? "Update" : "Create"}
                  </LoadingButton>
                </div>
              </form>
            </Card>
          </section>
        </Col>
      </Row>
    </div>
  );
};

export default QuestionManagement;
